import { Grid, SvgIcon } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { EventStatus } from "../../../../models/enums/eventStatus.enum";
import { SpeakerData } from "../../../../models/speakerData";
import { AppState } from "../../../../redux";
import CustomCheckbox from "../../../forms/CustomCheckbox";
import CustomButton from "../../../theming/CustomButton";

interface SpeakerRowProps {
  speaker: SpeakerData;
  action: (speaker: SpeakerData) => void;
  Icon: typeof SvgIcon;
  customColor: string;
  hoverColor: string;
  textColor?: string;
  iconColor?:
    | "inherit"
    | "primary"
    | "disabled"
    | "action"
    | "secondary"
    | "error"
    | undefined;
  substituteAction?: (speaker: SpeakerData) => void;
}

const SpeakerRow: React.FC<SpeakerRowProps> = ({
  speaker,
  action,
  Icon,
  customColor,
  hoverColor,
  textColor,
  iconColor,
  substituteAction,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false); // set the hovered state of the home link to style the element manually
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const editEvent = useSelector((state: AppState) => state.event.editEvent);

  return (
    <>
      <Grid container className={"speakers-list-item"}>
        <Grid item xs={3} className={"speakers-list-item-col"}>
          {speaker.first_name} {speaker.last_name}
        </Grid>
        <Grid item xs={7} className={"speakers-list-item-col"}>
          <Grid container>
            <Grid item xs={10} className={"speakers-list-item-col"}>
              {speaker.email}
            </Grid>
            <Grid item xs={2}>
              {substituteAction && (
                <CustomCheckbox
                  name="is_substitute"
                  label={<>Vertretung</>}
                  onChange={() => substituteAction(speaker)}
                  checked={speaker.is_substitute}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          className={"speakers-list-item-col"}
          style={{ textAlign: "right" }}
        >
          <CustomButton
            disabled={editEvent.status === EventStatus.Completed}
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
            className={"speakers-list-item-addButton"}
            onClick={() => {
              action(speaker);
            }}
            customColor={accessibility.monoMode ? "#000" : customColor}
            hoverColor={accessibility.monoMode ? "#fff" : hoverColor}
            textColor={accessibility.monoMode ? "#fff" : textColor}
          >
            <Icon
              color={
                isHovered
                  ? accessibility.monoMode
                    ? "primary"
                    : iconColor
                    ? iconColor
                    : "primary"
                  : "inherit"
              }
            />
          </CustomButton>
        </Grid>
      </Grid>
    </>
  );
};

export default SpeakerRow;
