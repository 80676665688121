import {
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { CustomSelectArrow } from "../../../assets/icons/custom-svg-components/KvhSvgCollection";
import loginInfoPicture from "../../../assets/login/Deckblatt-Fobi.svg";
import { LoginData } from "../../../models/userData";
import { error } from "../../../redux/alerts/actions";
import { login } from "../../../redux/auth/actions";
import CustomTextField from "../../forms/CustomTextField";
import {
  CHANGE_ALIAS,
  DOCTORS_OFFICE_LANR,
  FORGOT_PASSWORD_URL,
  REGISTER_URL,
} from "../../routing/externalUrls";
import routes from "../../routing/routes";
import HeadingKvh from "../../theming/HeadingKvh";
import styles from "./../../../styles/custom-styles.module.scss";
import "./Login.scss";

const useStyles = makeStyles((theme: Theme) => ({
  formGrid: {
    paddingLeft: "11.25px",
    paddingRight: "11.25px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  loginForm: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "100%",
    },
  },
}));

export const Login: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);
  // !NOTE: for svg use fixed width
  const imageWidth = 500;
  const { from } = (location.state as any) || {
    from: { pathname: routes.login },
  };

  const [loginAttempts, setloginAttempts] = useState<number>(0);

  const handleFalseLogin = () => {
    if (loginAttempts !== 5) {
      setloginAttempts(loginAttempts + 1);
      if (loginAttempts === 1) {
        setTimeout(() => {
          setloginAttempts(0);
        }, 300000);
      }
    }
  };

  return (
    <>
      <HeadingKvh>Benutzeranmeldung</HeadingKvh>
      <Typography>
        Geben Sie Ihre Benutzer-ID und Ihr Passwort ein, um sich an der Website
        anzumelden.
      </Typography>
      <Grid container alignItems="center" style={{ marginTop: "20px" }}>
        <Grid item xs={12} md={9} style={{ paddingBottom: "6px" }}>
          <Box style={{ width: "100%", textAlign: "center" }}>
            <img
              src={loginInfoPicture}
              style={{
                maxHeight: `${imageWidth / (1 + 1 / 5)}px`,
                width: "inherit",
                objectFit: "cover",
                objectPosition: "bottom",
              }}
              alt="Deckblatt Fortbildungsmaßnahmen 2022"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box>
            <Formik
              onSubmit={(data: LoginData) => {
                if (loginAttempts === 5) {
                  dispatch(
                    error(
                      {
                        message:
                          "Sie haben Ihr Passwort oder Ihren Benutzernamen zu oft falsch eingegeben. Bitte versuchen Sie es später nochmal.",
                      },
                      50000
                    )
                  );
                } else {
                  dispatch(login(data, from));
                  handleFalseLogin();
                }
              }}
              initialValues={
                {
                  username: "",
                  password: "",
                } as LoginData
              }
            >
              {({ values }) => (
                <Form
                  className={classes.loginForm}
                  style={{ marginTop: "20px" }}
                  data-testid="login-form"
                >
                  <Grid container direction={"row"} spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <CustomTextField
                          autoComplete="username"
                          name="username"
                          label="Benutzer-ID"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <CustomTextField
                          autoComplete="current-password"
                          name="password"
                          type="password"
                          label="Passwort"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>
                    <Grid item xs={12} sm={6}>
                      <Link
                        href={FORGOT_PASSWORD_URL}
                        className={"icon-arrow-right"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Passwort vergessen
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Button
                          type="submit"
                          size="large"
                          variant="contained"
                          color="primary"
                          disableElevation
                          disableRipple
                        >
                          Anmelden
                        </Button>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Link
                        className={"icon-arrow-right"}
                        href={REGISTER_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Registrieren
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography style={{ marginBottom: "1rem", fontSize: "smaller" }}>
            <strong>Hinweis:</strong> Praxismitarbeitende können die bestehenden
            Zugangsdaten der Helferzugänge nicht verwenden. Bitte registrieren
            Sie sich{" "}
            <Link href={REGISTER_URL} target="_blank" rel="noopener noreferrer">
              hier
            </Link>
            .
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Link
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                style={{ cursor: "pointer" }}
              >
                Was ist meine Benutzer-ID?
                <span
                  style={{
                    margin: "0.5em",
                    position: "relative",
                    top: "5px",
                    left: "5px",
                  }}
                >
                  <CustomSelectArrow
                    className={`event-item-${
                      expanded ? "shrink" : "expand"
                    }-icon`}
                  />
                </span>
              </Typography>
            </Box>
          </Link>

          <Box
            my={2}
            style={{
              height: "2px",
              width: "100px",
              backgroundColor: styles.orange,
            }}
          />
          <Collapse in={expanded} collapsedHeight={0}>
            <ol type="1" className="ordered-list">
              <li>
                <strong>
                  Zugang mit lebenslanger Arztnummer (Ärztinnen, Ärzte,
                  Psychotherapeutinnen, -therapeuten):
                </strong>{" "}
                Lebenslange Arztnummer (7-stellige{" "}
                <Link
                  href={DOCTORS_OFFICE_LANR}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"icon-arrow-right"}
                >
                  LANR
                </Link>
                ) oder selbstvergebener{" "}
                <Link
                  href={CHANGE_ALIAS}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"icon-arrow-right"}
                >
                  Alias
                </Link>
              </li>
              <li>
                <strong>
                  Zugang ohne lebenslange Arztnummer ohne Mitgliedschaft bei der
                  KVH:
                </strong>{" "}
                E-Mail-Adresse (bei Registrierung angegeben)
              </li>
              {/* <li>
                <strong>
                  Institutionen (Krankenhäuser, MVZ oder Laborgemeinschaften):
                </strong>{" "}
                Siebenstellige Benutzer-ID, die Sie nach der Registrierung per
                Post erhalten haben
              </li> */}
              {/* TODO: don't use for now until it is clear what should be displayed on the login page */}
              {/* <li>
                <strong>Helferzugänge...</strong>
                <ol className="alphabetical-list">
                  <li>
                    <strong>
                      ...für Arzthelferinnen, -helfer und Mitarbeitende
                      ärztlicher oder psychotherapeutischer Praxen:
                    </strong>{" "}
                    Lebenslange Arztnummer (7-stellige LANR) plus zweistelliger
                    Login-Zusatz oder selbstvergebener{" "}
                    <Link
                      href={CHANGE_ALIAS}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Alias
                    </Link>
                  </li>
                  <li>
                    <strong>
                      ...für Arzthelferinnen, -helfer und Mitarbeitende von
                      Institutionen (Krankenhäuser, MVZ oder
                      Laborgemeinschaften):
                    </strong>{" "}
                    Benutzer-ID und zweistelliger Login-Zusatz oder
                    selbstvergebener{" "}
                    <Link
                      href={CHANGE_ALIAS}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Alias
                    </Link>
                  </li>
                </ol>
              </li> */}
            </ol>
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
};
