import { Box, Button, Collapse, Grid, Hidden } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ToggleButton } from "@material-ui/lab";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Field, useFormikContext } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomSelectArrow } from "../../../../assets/icons/custom-svg-components/KvhSvgCollection";
import {
  EventType,
  mapToEventTypeString,
} from "../../../../models/enums/eventType.enum";
import { EventsSortBy } from "../../../../models/enums/sortBy.enum";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../../models/enums/trainingType.enum";
import { EventFilterData } from "../../../../models/eventData";
import { AppState } from "../../../../redux";
import { getTargetGroups } from "../../../../redux/target-groups/actions";
import usePermission from "../../../../services/usePermissions";
import { Utils } from "../../../../services/utils";
import CustomDatepicker from "../../../forms/CustomDatepicker";
import CustomSearchField from "../../../forms/CustomSearchField";
import CustomMultiSelect from "../../../forms/selects/CustomMultiSelect";
import CustomSelect from "../../../forms/selects/CustomSelect";
import { SelectData } from "../../../forms/selects/selectData";
import SpecialFieldSearch from "../shared/SpecialFieldSearch";

interface EventFilterFormProps {}

const EventFilterForm: React.FC<EventFilterFormProps> = (props) => {
  const { setFieldValue, values } = useFormikContext<EventFilterData>();

  const eventState = useSelector((state: AppState) => state.event);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const isLoggedIn = useSelector((state: AppState) => state.auth.loggedIn);
  const targetGroups = useSelector((state: AppState) => state.targetGroups);

  const {
    getEventStatusForSelectBasedOnUserTypeForFilter,
    isStaff,
    isSpeaker,
    isParticipant,
  } = usePermission(currentUser?.user_type);

  const dispatch = useDispatch();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const mdDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [expanded, setExpanded] = useState(false);
  const [myTargetGroupSelected, setMyTargetGroupSelected] = useState(
    values.my_target_group
  );

  // filter keys that are needed for multi-select filter
  const trainingTypeFilterKey = "training_type";
  const eventTypeFilterKey = "event_type";
  const locationFilterKey = "location";

  useEffect(() => {
    if (!targetGroups.isLoading && !targetGroups.targetGroupsLoaded) {
      dispatch(getTargetGroups());
    }
  }, [dispatch, targetGroups]);

  function getSortOptions() {
    let options = (Object.values(EventsSortBy) as string[]).map(
      (sortByEnumValue) => {
        return {
          key: Utils.getEnumKeyByValue(EventsSortBy, sortByEnumValue),
          value: sortByEnumValue,
        };
      }
    );
    options = options.filter((x) => x.value !== EventsSortBy.BeginOldest);

    return options;
  }

  function getEventTypeOptions() {
    let options = (Object.values(EventType) as number[])
      .filter(
        (eventType) =>
          eventType !== EventType.Undefined && typeof eventType === "number"
      )
      .map((eventTypeByEnumValue) => {
        return {
          key: Utils.getEnumKeyByValue(EventType, eventTypeByEnumValue),
          value: `${eventTypeFilterKey}=${eventTypeByEnumValue}`,
          label: mapToEventTypeString(eventTypeByEnumValue),
        };
      });

    // event_type=0
    options.push({
      key: Utils.getEnumKeyByValue(EventType, EventType.Undefined),
      value: `${eventTypeFilterKey}=${EventType.Undefined}`,
      label: mapToTrainingTypeString(TrainingType.ELearning),
    });

    return options;
  }

  const eventTypeOptions = React.useMemo(() => {
    return getEventTypeOptions();
  }, []);

  // this is unused for now because we filter by event_type=0 instead
  // eslint-disable-next-line
  const trainingTypeOptions = {
    options: [
      {
        key: Utils.getEnumKeyByValue(TrainingType, TrainingType.ELearning),
        value: `${trainingTypeFilterKey}=${TrainingType.ELearning}`,
        label: mapToTrainingTypeString(TrainingType.ELearning),
      },
    ],
    groupCategoryHeader: "",
    groupKey: trainingTypeFilterKey,
  };

  function splitByEqualSign(filterValue: string) {
    const groupedFilterString = filterValue.split("=");
    const typeOfGroupedFilter = groupedFilterString[0];
    const valueOfGroupedFilter = groupedFilterString[1];
    const participationFormat = {
      key: typeOfGroupedFilter,
      value: valueOfGroupedFilter,
    };
    return participationFormat;
  }

  const sortOptions = React.useMemo(() => {
    return getSortOptions();
  }, []);

  const locations = React.useMemo(() => {
    return eventState.filterValues.locations.length > 0 && !eventState.isLoading
      ? eventState.filterValues.locations
          .filter((x) => x !== "Online-Veranstaltung" && x !== "")
          .map((location, index) => {
            return {
              key: `location-${index}`,
              value: `location=${location}`,
              label: location,
            };
          })
      : [];
  }, [eventState.filterValues.locations, eventState.isLoading]);

  const processFormatFilters = (filterValues: string[]) => {
    const locations: string[] = [];
    const eventTypes: number[] = [];
    const trainingTypes: number[] = [];

    filterValues.forEach((filterString: string) => {
      // split the key and the value ...
      const keyValuePairFilterObject = splitByEqualSign(filterString);

      // ... and append to corresponding array of values based on the key
      if (keyValuePairFilterObject.key === locationFilterKey) {
        locations.push(keyValuePairFilterObject.value);
      } else if (keyValuePairFilterObject.key === eventTypeFilterKey) {
        // map value to number
        eventTypes.push(Utils.tryParseInt(keyValuePairFilterObject.value, 0));
      } else if (keyValuePairFilterObject.key === trainingTypeFilterKey) {
        trainingTypes.push(
          Utils.tryParseInt(keyValuePairFilterObject.value, 0)
        );
      }
    });

    // eventually set the field values
    setFieldValue(locationFilterKey, locations);
    setFieldValue(eventTypeFilterKey, eventTypes);
    setFieldValue(trainingTypeFilterKey, trainingTypes);
  };

  return (
    <>
      <Collapse in={expanded || !isXS} collapsedHeight={0}>
        <Box py={mdDown ? 2 : 0}>
          <Grid container spacing={mdDown ? 2 : 4}>
            <Grid item xs={12} sm={8}>
              <CustomSearchField
                name="search"
                id="search"
                label="Suche"
                initialValue={values.search}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomSelect
                name="sorting"
                id="sorting-select"
                label="Sortierung"
                defaultValueForEmptyElement={EventsSortBy.BeginOldest}
                defaultText={EventsSortBy.BeginOldest}
                labelId="sorting-select-label"
                options={sortOptions}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                as={CustomDatepicker}
                fullWidth
                id="begin_gte"
                label="Startdatum von"
                name="begin_gte"
                customChangeHandler={(date: MaterialUiPickersDate) => {
                  setFieldValue("begin_gte", date?.format("YYYY-MM-DD"), false);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                as={CustomDatepicker}
                fullWidth
                id="begin_lte"
                label="Startdatum bis"
                name="begin_lte"
                customChangeHandler={(date: MaterialUiPickersDate) => {
                  setFieldValue("begin_lte", date?.format("YYYY-MM-DD"), false);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomMultiSelect
                defaultOptions={
                  [
                    ...values.location.map((locationValue) => {
                      return `${locationFilterKey}=${locationValue}`;
                    }),
                    ...values.event_type.map((eventType) => {
                      return `${eventTypeFilterKey}=${eventType}`;
                    }),
                    ...values.training_type.map((trainingType) => {
                      return `${trainingTypeFilterKey}=${trainingType}`;
                    }),
                  ] || []
                }
                onBlur={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const filterValues = event.target.value as string[];
                  processFormatFilters(filterValues);
                }}
                onClose={() => {
                  // !workaround to make the onBlur method work

                  setTimeout(() => {
                    const participationFormat = document.getElementById(
                      "participation-format-multiselect"
                    );

                    if (participationFormat) participationFormat.blur();
                  }, 0);
                }}
                onClick={(event: any) => {
                  // do not filter event types if the training type filter is selected
                  let filterValues = event.target.value as string[];
                  if (filterValues === undefined) return;
                  const eLearningFilter =
                    "training_type=" + TrainingType.ELearning;
                  if (
                    filterValues[filterValues.length - 1] === eLearningFilter
                  ) {
                    filterValues = [eLearningFilter];
                  } else {
                    filterValues = filterValues.filter(
                      (value) => value !== eLearningFilter
                    );
                  }
                  processFormatFilters(filterValues);
                }}
                isGrouped
                name="participation_format"
                id="participation-format-multiselect"
                label="Teilnahmeformat"
                labelId="participation-format-multiselect-label"
                options={[
                  {
                    options: eventTypeOptions,
                    groupCategoryHeader: "Veranstaltungstyp",
                    groupKey: eventTypeFilterKey,
                  },
                  // use event_type=0 in above options
                  // instead of training_type=10
                  {
                    options: locations,
                    groupCategoryHeader: "Veranstaltungsort",
                    groupKey: locationFilterKey,
                  },
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomSelect
                defaultText="Alle"
                name="status"
                id="status"
                label="Status der Veranstaltung"
                labelId="status-label"
                options={getEventStatusForSelectBasedOnUserTypeForFilter()}
              />
            </Grid>

            {isLoggedIn && !isStaff() && !isSpeaker() && (
              <Grid item xs={12} sm={6} md={3}>
                <ToggleButton
                  value="check"
                  selected={myTargetGroupSelected}
                  className="fill-full-width"
                  onChange={() => {
                    setMyTargetGroupSelected(!myTargetGroupSelected);
                    setFieldValue("my_target_group", !myTargetGroupSelected);
                  }}
                  size="medium"
                  color={"primary"}
                  style={{
                    marginLeft: "auto",
                    minWidth: "12px",
                    borderRadius: "1px",
                  }}
                >
                  {values.my_target_group
                    ? "Meine Zielgruppen ausgewählt"
                    : "Meine Zielgruppen"}
                </ToggleButton>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              sm={6}
              md={isParticipant() && isLoggedIn ? 3 : 4}
            >
              <CustomMultiSelect
                defaultOptions={values.participation_types}
                hasCustomChangeHandler={true}
                customChangeHandler={(values) => {
                  setFieldValue("participation_types", values);
                }}
                name="participation_types"
                id="participation-types"
                label="Teilnahmetyp"
                labelId="participation-types-label"
                options={
                  targetGroups.targetGroups.participation_types.map((x) => {
                    const kvhMembers = x.only_kvh_members
                      ? x.type + " (KVH-Mitglied)"
                      : x.type;
                    return {
                      key: kvhMembers,
                      value: x.id,
                      label: kvhMembers,
                    };
                  }) as SelectData[]
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={isParticipant() && isLoggedIn ? 3 : 4}
            >
              <SpecialFieldSearch
                onChangeFields={(special_fields) => {
                  let specialFieldsToSet = [
                    ...special_fields.map((x) => x?.id),
                  ];
                  specialFieldsToSet = specialFieldsToSet.filter(
                    (x) => x !== undefined
                  );
                  setFieldValue("special_fields", specialFieldsToSet);
                }}
                initialValues={targetGroups.targetGroups.special_fields.filter(
                  (specialField) =>
                    values.special_fields.findIndex(
                      (x) => specialField.id === x
                    ) !== -1
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={isParticipant() && isLoggedIn ? 3 : 4}
            >
              <CustomMultiSelect
                defaultOptions={values.coverage_areas}
                hasCustomChangeHandler={true}
                customChangeHandler={(values) => {
                  setFieldValue("coverage_areas", values);
                }}
                name="coverage_areas"
                id="coverage-areas"
                label="Versorgungsgebiet"
                labelId="coverage-areas-label"
                options={
                  targetGroups.targetGroups.coverage_areas.map((x) => {
                    return {
                      key: x.key,
                      value: x.id,
                      label: x.name.includes(" ")
                        ? x.name.replace(" ", " und ")
                        : x.name,
                    };
                  }) as SelectData[]
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
      <Hidden smUp>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            {expanded ? (
              <Box m={1} display="flex" justifyContent="center">
                <Box px={1}> Suchoptionen einklappen </Box>
                <CustomSelectArrow className={"event-item-shrink-icon"} />
              </Box>
            ) : (
              <Box m={1} display="flex" justifyContent="center">
                <Box px={1}> Suchoptionen ausklappen </Box>
                <CustomSelectArrow className={"event-item-expand-icon"} />
              </Box>
            )}
          </Button>
        </Box>
      </Hidden>
    </>
  );
};

export default EventFilterForm;
