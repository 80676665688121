import {
  LinkAccountActionTypes,
  LinkAccountState,
  VERIFY_LANR_FAILURE,
  VERIFY_LANR_SUCCESS,
  CLEAR_LANR,
  LINK_LANR_REQUEST_SUCCESS,
  LINK_LANR_CODE_REQUEST,
  LINK_LANR_CODE_SUCCESS,
  LINK_LANR_CODE_FAILURE,
  VERIFY_LANR_REQUEST,
  LINK_LANR_REQUEST,
  SET_LINK_ACCOUNT_LIST,
  DELETE_LINK_ACCOUNT_FROM_LIST,
  SET_TRY_LINK_ACCOUNT_WITHOUT_LOGIN,
  FETCH_LINKED_ACCOUNTS_FAILURE,
  FETCH_LINKED_ACCOUNTS_REQUEST,
} from "./types";
import { LinkAccountData } from "../../models/linkAccountData";

export const initialLinkAccountState: LinkAccountState = {
  linkAccountList: [],
  linkAccountListLoaded: false,
  isLoading: false,
  verifyLanr: false,
  verifiedLanr: false,
  linkLanrRequest: false,
  linkLanrRequestSuccess: false,
  verifyingLanrRequestCode: false,
  verifyLanrRequestCodeAccepted: false,
  verifyLanrRequestCodeErrorMessage: "",
  tryLinkAccountWithoutLogin: false,
};

function removeAccountFromList(linkAccountList: LinkAccountData[], guid: string) {
  // ! NOTE: always use copy and don't work on state directly to prevent nasty side effects
  const copiedLinkAccountList = [...linkAccountList];
  let index = copiedLinkAccountList.findIndex(
    (LinkAccount) => LinkAccount.guid === guid
  );
  copiedLinkAccountList.splice(index, 1);
  return copiedLinkAccountList;
}

export function linkAccountReducer(
  state = initialLinkAccountState,
  action: LinkAccountActionTypes
): LinkAccountState {
  switch (action.type) {
    case FETCH_LINKED_ACCOUNTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SET_LINK_ACCOUNT_LIST:
      return {
        ...state,
        linkAccountListLoaded: true,
        linkAccountList: [...action.data],
        isLoading: false,
      };
    case DELETE_LINK_ACCOUNT_FROM_LIST:
      return {
        ...state,
        linkAccountList: removeAccountFromList(
          state.linkAccountList,
          action.guid
        ),
        isLoading: false,
      };
    case FETCH_LINKED_ACCOUNTS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case VERIFY_LANR_REQUEST:
      return {
        ...state,
        verifyLanr: true,
      };
    case VERIFY_LANR_FAILURE:
      return {
        ...state,
        verifyLanr: false,
        linkLanrRequest: false,
      };
    case VERIFY_LANR_SUCCESS:
      return {
        ...state,
        verifyLanr: false,
        verifiedLanr: true,
      };
    case CLEAR_LANR:
      return {
        ...state,
        verifyLanr: false,
        verifiedLanr: false,
        linkLanrRequest: false,
        linkLanrRequestSuccess: false,
        verifyingLanrRequestCode: false,
        verifyLanrRequestCodeAccepted: false,
        verifyLanrRequestCodeErrorMessage: "",
      };
    case LINK_LANR_REQUEST:
      return {
        ...state,
        linkLanrRequest: true,
      };
    case LINK_LANR_REQUEST_SUCCESS:
      return {
        ...state,
        linkLanrRequest: false,
        linkLanrRequestSuccess: true,
        linkAccountList:
          state.linkAccountList.findIndex((x) => x.guid === action.data.guid) === -1
            ? [...state.linkAccountList, action.data]
            : state.linkAccountList,
      };
    case LINK_LANR_CODE_REQUEST:
      return {
        ...state,
        verifyingLanrRequestCode: true,
      };
    case LINK_LANR_CODE_SUCCESS:
      return {
        ...state,
        verifyingLanrRequestCode: false,
        verifyLanrRequestCodeAccepted: true,
      };
    case SET_TRY_LINK_ACCOUNT_WITHOUT_LOGIN:
      return {
        ...state,
        tryLinkAccountWithoutLogin: true,
      };
    case LINK_LANR_CODE_FAILURE:
      return {
        ...state,
        verifyingLanrRequestCode: false,
        verifyLanrRequestCodeAccepted: false,
        verifyLanrRequestCodeErrorMessage: action.errorMessage
          ? action.errorMessage
          : "",
      };
    default:
      return state;
  }
}

export default linkAccountReducer;
