import { faStar as faStarOutline } from "@fortawesome/free-regular-svg-icons/faStar";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { KvhIdSvg } from "../../../../../assets/icons/custom-svg-components/KvhSvgCollection";
import { ParticipationStatus } from "../../../../../models/enums/participationStatus.enum";
import { TrainingType } from "../../../../../models/enums/trainingType.enum";
import { EventListData } from "../../../../../models/eventData";
import { AppState } from "../../../../../redux";
import { clearDocuments } from "../../../../../redux/documents/actions";
import { clearEditEvent } from "../../../../../redux/events/actions";
import {
  addFavorite,
  deleteFavorite,
} from "../../../../../redux/favorites/actions";
import { clearParticipant } from "../../../../../redux/participants/actions";
import { clearSpeakers } from "../../../../../redux/speaker/actions";
import { clearTemplateEvent } from "../../../../../redux/templates/events/actions";
import usePermission from "../../../../../services/usePermissions";
import routes from "../../../../routing/routes";
import TargetGroupListEventOverview from "./TargetGroupListEventOverview";

interface EventItemTitleProps {
  event: EventListData;
  preview?: boolean;
  favorite?: boolean;
}

export const EventItemTitle: React.FC<EventItemTitleProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state: AppState) => state.auth);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  const { isStaff } = usePermission(currentUser?.user_type);
  const { isParticipant } = usePermission(currentUser?.user_type);
  const [showEditButton, setShowEditButton] = useState(false); // set the hovered state of the home link to style the element manually

  return (
    <>
      <Box
        component="span"
        className={"event-item-title"}
        display="flex"
        alignItems="center"
        onMouseEnter={() => {
          setShowEditButton(true);
        }}
        onMouseLeave={() => {
          setShowEditButton(false);
        }}
      >
        {auth.loggedIn && isStaff() && !props.event.has_open_tasks && (
          <DoneIcon
            style={{
              color: accessibility.monoMode ? "#000" : green[500],
              margin: "10px",
            }}
          />
        )}
        {auth.loggedIn &&
          isParticipant() &&
          (props.favorite ? (
            <FontAwesomeIcon
              icon={faTimes}
              size={"xs"}
              color={accessibility.monoMode ? "#000" : "#f44336"}
              style={{ marginRight: "5px", cursor: "pointer" }}
              onClick={() => {
                dispatch(deleteFavorite(props.event.guid));
              }}
            />
          ) : props.event.participation_status ===
              ParticipationStatus.Undefined ||
            props.event.participation_status ===
              ParticipationStatus.Cancelled ||
            props.event.participation_status ===
              ParticipationStatus.CancelledByStaff ||
            props.event.participation_status ===
              ParticipationStatus.IsFavorite ? (
            props.event.is_favorite ? (
              <FontAwesomeIcon
                icon={faStar}
                size={"xs"}
                color={accessibility.monoMode ? "#000" : "#36a9e0"}
                style={{ marginRight: "5px", cursor: "pointer" }}
                onClick={() => {
                  dispatch(deleteFavorite(props.event.guid));
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faStarOutline}
                size={"xs"}
                color={accessibility.monoMode ? "#000" : "#36a9e0"}
                style={{ marginRight: "5px", cursor: "pointer" }}
                onClick={() => {
                  dispatch(addFavorite(props.event.guid));
                }}
              />
            )
          ) : (
            <FontAwesomeIcon
              icon={faStarOutline}
              size={"xs"}
              color={accessibility.monoMode ? "#777" : "#efefef"}
              style={{ marginRight: "5px" }}
            />
          ))}
        {props.event.title}{" "}
        {auth.loggedIn && isStaff() && showEditButton && !props.preview && (
          <EditIcon
            onClick={() => {
              dispatch(clearEditEvent());
              dispatch(clearSpeakers());
              dispatch(clearDocuments());
              dispatch(clearParticipant());
              dispatch(clearTemplateEvent());
              if (props.event.training_type === TrainingType.DefaultEvent) {
                history.push(`${routes.event_edit}/${props.event.guid}`);
              } else if (props.event.training_type === TrainingType.ELearning) {
                history.push(`${routes.elearning_edit}/${props.event.guid}`);
              } else if (
                props.event.training_type === TrainingType.BlendedLearning
              ) {
                history.push(
                  `${routes.blended_learning_edit}/${props.event.guid}`
                );
              }
            }}
            style={{ cursor: "pointer", marginLeft: "10px" }}
          />
        )}
      </Box>
      <Box component="span" display="flex" alignItems="center">
        <KvhIdSvg /> {props.event.public_id !== 0 ? props.event.public_id : ""}{" "}
        <div style={{ width: "10px" }} />
        <TargetGroupListEventOverview
          targetGroups={{
            coverage_areas: props.event.coverage_areas,
            participation_types: props.event.participation_types,
            special_fields: props.event.special_fields,
          }}
        />
      </Box>
    </>
  );
};
