import * as yup from "yup";

// ! outsourced validation rules for numeric values to don't repeat the same code
export const integer = yup
  .number()
  .integer("Bitte geben Sie eine Ganzzahl ein.");

export const positiveInteger = integer.positive(
  "Der eingegebene Wert muss größer als 0 sein."
);
