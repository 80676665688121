import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { Pagination } from "../models/pagination";
import { MyEventListData } from "../models/eventData";
import { BookingData } from "../models/bookingData";
import { ParticipationStatus } from "../models/enums/participationStatus.enum";
import { MyELearningListData } from "../models/elearning";

export class BookingService extends BaseCrudService {
  async bookEvent(events: BookingData[]) {
    const response = await this.create(apiRoutes.booking, events);
    return response;
  }

  async bookEventForOther(event: BookingData, userGuid: string) {
    const response = await this.create(
      `${apiRoutes.users}/${userGuid.toString()}${apiRoutes.book_for_users}`,
      event
    );
    return response;
  }

  async putOnWaitingList(event: BookingData) {
    const response = await this.create(apiRoutes.booking, event);
    return response;
  }

  async putOtherUserOnWaitingList(event: BookingData, userGuid: string) {
    const response = await this.create(
      `${apiRoutes.users}/${userGuid.toString()}${apiRoutes.book_for_users}`,
      event
    );
    return response;
  }

  async getMyEventList(filter: string) {
    const response = await this.getAll<Pagination<MyELearningListData>>(
      apiRoutes.booking + filter
    );
    return response.data;
  }

  async getMyEventListForPrint(filter: string) {
    const response = await this.getAll<MyEventListData[]>(
      apiRoutes.booking_all + filter
    );
    return response.data;
  }

  async getMyEvent(bookingId: string) {
    const response = await this.get<MyELearningListData>(
      apiRoutes.booking,
      bookingId
    );
    return response.data;
  }

  async cancelEvent(guid: string) {
    const response = await this.edit<{ status: number }>(
      apiRoutes.booking,
      guid.toString(),
      { status: ParticipationStatus.Cancelled }
    );
    return response;
  }

  async patchUserHasClickedLink(
    guid: string,
    participationData: { user_has_clicked_evaluation_link: boolean }
  ) {
    const response = await this.edit(
      apiRoutes.booking,
      guid.toString(),
      participationData
    );
    return response;
  }

  async removeFromWaitingList(guid: string) {
    const response = await this.delete(apiRoutes.booking, guid.toString());
    return response;
  }
}
