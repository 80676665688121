import { Box } from "@material-ui/core";
import React from "react";
import { KvhTargetGroupSvg } from "../../../../../assets/icons/custom-svg-components/KvhSvgCollection";
import {
  ReducedParticipationType,
  TargetGroupIdData,
} from "../../../../../models/targetGroupData";
import { useGroupMapping } from "./EventGroupMappers";

interface ITargetGroupListProps {
  targetGroups: TargetGroupIdData;
}

const TargetGroupList: React.FunctionComponent<ITargetGroupListProps> = (
  props
) => {
  const {
    mapTargetGroupIdToString,
    mapSpecialFieldIdToString,
    mapCoverageAreaIdToString,
  } = useGroupMapping();

  function mapParticipationTypesToValidItemsString(
    participationTypeIds: number[],
    addComma: boolean = false
  ): string {
    const participationTypeItems: ReducedParticipationType[] = participationTypeIds.map(
      (id) => {
        return { id, type: mapTargetGroupIdToString(id) };
      }
    );
    const validParticipationTypeItems: ReducedParticipationType[] = participationTypeItems.filter(
      (item) => item.type.length > 0
    );
    let validParticipationTypeItemsString = validParticipationTypeItems
      .map((item) => item.type)
      .join(", ");
    if (addComma) validParticipationTypeItemsString += ", ";
    return validParticipationTypeItemsString;
  }

  return (
    <Box component="span" display="flex" alignItems="center">
      {props.targetGroups.participation_types.length > 0 && (
        <>
          <KvhTargetGroupSvg />
          {mapParticipationTypesToValidItemsString(
            props.targetGroups.participation_types,
            props.targetGroups.special_fields.length > 0
          )}
        </>
      )}
      {props.targetGroups.special_fields.length > 0 && (
        <>
          {props.targetGroups.special_fields.map((item, index) => (
            <React.Fragment key={index}>
              {(index ? ", " : "") + mapSpecialFieldIdToString(item)}
            </React.Fragment>
          ))}
          {props.targetGroups.coverage_areas.length > 0 && ", "}
        </>
      )}
      {props.targetGroups.coverage_areas.map((item, index) => (
        <React.Fragment key={index}>
          {(index ? ", " : "") + mapCoverageAreaIdToString(item)}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default TargetGroupList;
