import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { bookingService } from "../../api";
import { ApiHelper } from "../../api/apiHelper";
import { history } from "../../components/routing/history";
import routes, {
  getEditRouteBasedOnTrainingType,
} from "../../components/routing/routes";
import { BookingData, BookingFilterData } from "../../models/bookingData";
import { CartData } from "../../models/cartData";
import { MyELearningListData } from "../../models/elearning";
import { MyEventListData } from "../../models/eventData";
import { Pagination } from "../../models/pagination";
import { BookingFilterService } from "../../services/filters/bookingFilterService";
import { error, success } from "../alerts/actions";
import { AlertActionTypes, ErrorAction } from "../alerts/types";
import { billingAddressClear } from "../billingAddress/actions";
import { clearCart } from "../cart/actions";
import { CartActionTypes } from "../cart/types";
import { clearEditEvent, clearEventList } from "../events/actions";
import { EventActionTypes } from "../events/types";
import { clearFavoriteList } from "../favorites/actions";
import { FavoriteActionTypes } from "../favorites/types";
import { AppState } from "../index";
import { clearParticipant } from "../participants/actions";
import { ClearParticipantAction } from "../participants/types";
import {
  getParticipantsTabBasedOnTrainingType,
  setOpenTab,
} from "../tabs/actions";
import { TabsActionTypes } from "../tabs/types";
import { BillingAddressActionTypes } from "./../billingAddress/types";
import { ClearEventListAction } from "./../events/types";
import { initialBookingState } from "./reducers";
import {
  BookingActionTypes,
  BookingsFailureAction,
  BookingsRequestAction,
  BOOKINGS_FAILURE,
  BOOKINGS_REQUEST,
  ClearBookingsAction,
  CLEAR_BOOKINGS,
  SetMyEventAction,
  SetMyEventsListAction,
  SetMyEventsListForPrintAction,
  SetPageCountAction,
  SET_BOOKINGS_PAGE_COUNT,
  SET_MYEVENT,
  SET_MYEVENTS_LIST_FOR_PRINT_SUCCESS,
  SET_MYEVENTS_LIST_SUCCESS,
  UpdateMyEventAction,
  UPDATE_MY_EVENT,
} from "./types";
import { UserType } from "../../models/enums/userType.enum";

export function bookEvent(
  cartList: CartData[],
  useAlternativeBillingAddress: boolean,
  bookForOtherUser: boolean,
  ignoreMaxSeats: boolean
) {
  return (
    dispatch: Dispatch<
      | CartActionTypes
      | BookingActionTypes
      | FavoriteActionTypes
      | BillingAddressActionTypes
      | EventActionTypes
      | AlertActionTypes
      | TabsActionTypes
      | ClearParticipantAction
    >,
    getState: () => AppState
  ) => {
    let bookingData: BookingData[] = [];

    dispatch(bookingsRequest());
    if (bookForOtherUser) {
      cartList.map((CartItem) => {
        return bookingService
          .bookEventForOther(
            {
              event: CartItem.guid,
              status: 20,
              catering_option: CartItem.catering_option,
              use_alternative_billing_address: useAlternativeBillingAddress,
              ignore_max_seats: ignoreMaxSeats,
            },
            CartItem.book_for.guid
          )
          .then(() => {
            dispatch(
              success(
                {
                  message:
                    "Für den Nutzer wurden die Veranstaltungen erfolgreich gebucht.",
                },
                3000
              )
            );

            dispatch(clearEditEvent());
            dispatch(clearParticipant());
            dispatch(clearCart());
            dispatch(clearFavoriteList());
            dispatch(clearEventList());
            dispatch(billingAddressClear());

            if (getState().user.currentUser?.user_type === UserType.Employee) {
              // show participants tab
              const trainingType = CartItem.training_type;
              const eventId = CartItem.guid;
              history.push(
                `${getEditRouteBasedOnTrainingType(trainingType)}/${eventId}`
              );
              dispatch(
                setOpenTab(getParticipantsTabBasedOnTrainingType(trainingType))
              );
            } else {
              // if a user has booked for another user, route back to event list
              history.push(routes.events);
            }
          })
          .catch((err: AxiosError) => {
            const errorStatus = err.response?.status;
            const errorObject = err.response?.data;

            if (errorStatus === 400) {
              dispatch(
                error(
                  {
                    message: ApiHelper.showErrorMessageFromApi(errorObject),
                  },
                  3000
                )
              );
            } else {
              dispatch(
                error(
                  {
                    message:
                      "Es ist ein Fehler bei der Buchung aufgetreten. Bitte versuchen Sie es erneut!",
                  },
                  5000
                )
              );
            }
            dispatch(bookingsFailure());
          });
      });
    } else {
      cartList.map((CartItem) => {
        return bookingData.push({
          event: CartItem.guid,
          status: 20,
          catering_option: CartItem.catering_option,
          use_alternative_billing_address: useAlternativeBillingAddress,
        });
      });
      bookingService
        .bookEvent(bookingData)
        .then(() => {
          history.push(routes.my_events);

          dispatch(clearCart());
          dispatch(clearBookings());
          dispatch(clearFavoriteList());
          dispatch(clearEventList());
          dispatch(billingAddressClear());
          dispatch(
            success(
              {
                message: "Ihre Veranstaltungen wurden erfolgreich gebucht.",
              },
              3000
            )
          );
        })
        .catch((err: AxiosError) => {
          const errorStatus = err.response?.status;
          const errorObject = err.response?.data;

          if (errorStatus === 400) {
            dispatch(
              error(
                {
                  message: ApiHelper.showErrorMessageFromApi(errorObject),
                },
                3000
              )
            );
          } else {
            dispatch(
              error(
                {
                  message:
                    "Es ist ein Fehler bei der Buchung aufgetreten. Bitte versuchen Sie es erneut!",
                },
                5000
              )
            );
          }
          dispatch(bookingsFailure());
        });
    }
  };
}

export function getAllMyEvents(page: number, filterData: BookingFilterData) {
  return (dispatch: Dispatch<BookingActionTypes | ErrorAction>) => {
    const bookingFilterService = new BookingFilterService(page, filterData);
    const filterHasChanged = bookingFilterService.checkIfFilterHasChanged();

    if (filterHasChanged) {
      page = initialBookingState.currentPage;
      bookingFilterService.searchFilter = { page: page };
      dispatch(clearBookings()); // set initial state for booking list
    }
    dispatch(bookingsRequest());

    const route = bookingFilterService.getRoute();
    bookingFilterService.reflectFiltersInUrl(route);

    bookingService
      .getMyEventList(route)
      .then((response: Pagination<MyELearningListData>) => {
        dispatch(setMyEventsList(response));
      })
      .catch(() => {
        dispatch(bookingsFailure());
      });
  };
}

export function getAllMyEventsForPrint(filterData: BookingFilterData) {
  return (dispatch: Dispatch<BookingActionTypes | ErrorAction>) => {
    const bookingFilterService = new BookingFilterService(0, filterData);
    const filterHasChanged = bookingFilterService.checkIfFilterHasChanged();

    if (filterHasChanged) {
      bookingFilterService.searchFilter = { page: 0 };
      dispatch(clearBookings()); // set initial state for booking list
    }

    const route = bookingFilterService.getRoute();
    const searchTermPage = route.replace("page=0&", ""); //removing page attribute from URL, because we need all entries

    bookingService
      .getMyEventListForPrint(searchTermPage)
      .then((response: MyEventListData[]) => {
        dispatch(setMyEventForPrint(response));
      })
      .catch(() => {
        dispatch(bookingsFailure());
      });
  };
}

export function getMyEvent(bookingId: string) {
  return (dispatch: Dispatch<BookingActionTypes | ErrorAction>) => {
    dispatch(bookingsRequest());

    bookingService
      .getMyEvent(bookingId)
      .then((response: MyELearningListData) => {
        dispatch(SetMyEvent(response));
      })
      .catch(() => {
        dispatch(bookingsFailure());
      });
  };
}

export function clickedEvaluationLink(
  participantGuid: string,
  hasClickedLink: boolean,
  isELearningPartOfBlendedLearning: boolean
) {
  return (dispatch: Dispatch<BookingActionTypes | ErrorAction>) => {
    dispatch(bookingsRequest());

    bookingService
      .patchUserHasClickedLink(participantGuid, {
        user_has_clicked_evaluation_link: hasClickedLink,
      })
      .then((response) => {
        if (isELearningPartOfBlendedLearning) {
          updateUserHasClickEvaluationLinkBlendedLearning(hasClickedLink);
        } else {
          updateUserHasClickEvaluationLink(hasClickedLink);
        }
      })
      .catch((err: AxiosError) => {
        dispatch(bookingsFailure());
      });
  };
}

export function cancelMyEvent(guid: string) {
  return (
    dispatch: Dispatch<
      BookingActionTypes | AlertActionTypes | ClearEventListAction
    >
  ) => {
    dispatch(bookingsRequest());
    bookingService
      .cancelEvent(guid)
      .then(() => {
        history.push(routes.my_events);
        dispatch(
          success(
            {
              message: "Die Veranstaltung wurde erfolgreich storniert.",
            },
            3000
          )
        );
        dispatch(clearBookings());
        dispatch(clearEventList());
      })
      .catch(() => {
        dispatch(bookingsFailure());
      });
  };
}

export function removeFromWaitingList(guid: string) {
  return (dispatch: Dispatch<BookingActionTypes | AlertActionTypes>) => {
    bookingService
      .removeFromWaitingList(guid)
      .then(() => {
        history.push(routes.my_events);
        dispatch(
          success(
            {
              message: "Sie wurden erfolgreich von der Warteliste entfernt.",
            },
            3000
          )
        );
      })
      .catch((err: AxiosError) => {
        dispatch(bookingsFailure());
      });
  };
}

export function bookingsRequest(): BookingsRequestAction {
  return {
    type: BOOKINGS_REQUEST,
  };
}
export function bookingsFailure(): BookingsFailureAction {
  return {
    type: BOOKINGS_FAILURE,
  };
}

export function setMyEventsList(
  data: Pagination<MyELearningListData>
): SetMyEventsListAction {
  return {
    type: SET_MYEVENTS_LIST_SUCCESS,
    data,
  };
}

export function setMyEventForPrint(
  data: MyEventListData[]
): SetMyEventsListForPrintAction {
  return {
    type: SET_MYEVENTS_LIST_FOR_PRINT_SUCCESS,
    data,
  };
}

export function SetMyEvent(data: MyELearningListData): SetMyEventAction {
  return {
    type: SET_MYEVENT,
    data,
  };
}

export function clearBookings(): ClearBookingsAction {
  return { type: CLEAR_BOOKINGS };
}

export function setPageCount(pageCount: number): SetPageCountAction {
  return {
    type: SET_BOOKINGS_PAGE_COUNT,
    pageCount: pageCount,
  };
}

export function updateUserHasClickEvaluationLink(
  userHasClickEvaluationLink: boolean
): UpdateMyEventAction {
  return {
    type: UPDATE_MY_EVENT,
    userHasClickEvaluationLink: userHasClickEvaluationLink,
  };
}

export function updateUserHasClickEvaluationLinkBlendedLearning(
  userHasClickEvaluationLinkBlendedLearning: boolean
): UpdateMyEventAction {
  return {
    type: UPDATE_MY_EVENT,
    userHasClickEvaluationLinkBlendedLearning: userHasClickEvaluationLinkBlendedLearning,
  };
}
