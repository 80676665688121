import * as React from "react";
import HeadingKvh from "../../theming/HeadingKvh";
import TabsKvh from "../../theming/tabs/TabsKvh";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux";
import { getTabsByRole } from "./tabsData";

const ProfileSettingsWrapper: React.FC = () => {
  const userData = useSelector((state: AppState) => state.user.currentUser);

  return (
    <>
      <HeadingKvh>Einstellungen</HeadingKvh>

      <TabsKvh tabs={getTabsByRole(userData?.user_type)} />
    </>
  );
};

export default ProfileSettingsWrapper;
