export const borderFocus = {
  borderBottom: "2px solid #0094ff",
  borderLeft: "0px solid #0094ff",
  borderRight: "0px solid #0094ff",
  borderTop: "0px solid #0094ff",
};

export const borderFocusMono = {
  borderBottom: "2px solid #000",
  borderLeft: "0px solid #000",
  borderRight: "0px solid #000",
  borderTop: "0px solid #000",
};

export const borderDefault = {
  borderBottom: "2px solid #a2a2a2",
  borderLeft: "0px solid #a2a2a2",
  borderRight: "0px solid #a2a2a2",
  borderTop: "0px solid #a2a2a2",
};

export const borderError = {
  borderBottom: "2px solid #f44336",
  borderLeft: "0px solid #f44336",
  borderRight: "0px solid #f44336",
  borderTop: "0px solid #f44336",
};

export const borderMono = {
  borderBottom: "2px solid #000",
  borderLeft: "0px solid #000",
  borderRight: "0px solid #000",
  borderTop: "0px solid #000",
};

export const validationErrorPrefix = {
  content: '"!"',
  display: "block",
  position: "absolute",
  width: "20px",
  height: "20px",
  top: "20px",
  border: "1px solid #d71541",
  borderRadius: "50%",
  color: "#d71541",
  textAlign: "center",
  left: "calc((-20px) - 5px)",
  fontSize: "calc(20px - 5px)",
  fontWeight: 600,
};

export const validationErrorPrefixMono = {
  content: '"!"',
  display: "block",
  position: "absolute",
  width: "20px",
  height: "20px",
  top: "20px",
  border: "1px solid #000",
  borderRadius: "50%",
  color: "#000",
  textAlign: "center",
  left: "calc((-20px) - 5px)",
  fontSize: "calc(20px - 5px)",
  fontWeight: 600,
};
