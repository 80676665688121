import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ELearningFormData,
  mapAddELearningToDto,
} from "../../../../models/elearning";
import {
  EventStatus,
  mapStatusIdToStatusVerb,
} from "../../../../models/enums/eventStatus.enum";
import {
  mapToTrainingTypeString,
  mapTrainingTypeToArticleWithNoun,
  mapTrainingTypeToArticleWithNounPossessiveCase,
  TrainingType,
} from "../../../../models/enums/trainingType.enum";
import { mapAddEventToDto } from "../../../../models/eventData";
import { AppState } from "../../../../redux";
import { showDialog } from "../../../../redux/dialogs/actions";
import { deleteDocument } from "../../../../redux/documents/actions";
import { editELearning } from "../../../../redux/e-learnings/actions";
import { editEvent } from "../../../../redux/events/actions";

const useEditFormContext = (
  id: string,
  typeOfTrainingToBeCreated: TrainingType
) => {
  const markedForDelete = useSelector(
    (state: AppState) => state.documents.documentsMarkedForDelete
  );
  const initialStatus = useSelector(
    (state: AppState) => state.event.editEvent.status
  );

  const dispatch = useDispatch();

  const trainingType = mapToTrainingTypeString(typeOfTrainingToBeCreated);

  function removeDocumentsMarkedForDeletion() {
    markedForDelete.forEach((document) => {
      // don't delete again, because it is already removed from documents list
      dispatch(deleteDocument(document, false));
    });
  }

  const editEventWithStatusChecks = (data: ELearningFormData) => {
    if (
      initialStatus !== data.status &&
      [EventStatus.Completed, EventStatus.Cancelled].includes(data.status)
    ) {
      if (data.status === EventStatus.Completed) {
        if (!data.evaluationLink || !data.evaluationLinkSpeaker) {
          dispatch(
            showDialog({
              title: `${trainingType} abschließen`,
              message: (
                <>
                  Sind Sie sich sicher, dass Sie{" "}
                  {mapTrainingTypeToArticleWithNoun(
                    typeOfTrainingToBeCreated,
                    trainingType
                  )}{" "}
                  abschließen möchten?
                  <br />
                  Dieser Vorgang kann nicht wieder rückgängig gemacht werden.
                  <br />
                  <br />
                  <b>
                    Achtung, die Eingabe der Evaluationslinks ist nicht
                    vollständig.
                    <br />
                    Überprüfen Sie folgende Links:
                    <br />
                    {!data.evaluationLinkBlendedLearning &&
                      data.trainingType === TrainingType.BlendedLearning && (
                        <>
                          - Es wurde noch kein Evaluationslink für den
                          Teilnehmer angegeben (E-Learning-Teil des
                          Blended-Learnings)
                          <br />
                        </>
                      )}
                    {!data.evaluationLink && (
                      <>
                        - Es wurde noch kein Evaluationslink für den Teilnehmer
                        angegeben <br />
                      </>
                    )}
                    {!data.evaluationLinkSpeaker &&
                      data.trainingType !== TrainingType.ELearning && (
                        <>
                          - Es wurde noch kein Evaluationslink für den
                          Referenten angegeben <br />
                        </>
                      )}
                    <br />
                  </b>
                  Die Evaluationslinks können im Tab "Sonstiges" ergänzt werden.
                  <br />
                  <br />
                  Wenn Sie ohne Eingabe des Links fortfahren wollen, klicken Sie
                  auf "Trotzdem abschließen". In diesem Fall wird den
                  entsprechenden Nutzern nach Abschluss{" "}
                  {mapTrainingTypeToArticleWithNounPossessiveCase(
                    typeOfTrainingToBeCreated,
                    trainingType
                  )}{" "}
                  kein Link zur Durchführung der Evaluation angezeigt.
                  <br />
                </>
              ),
              action: () => {
                removeDocumentsMarkedForDeletion();
                dispatch(editEvent(mapAddEventToDto(data), id));
              },
              confirmButtonText: "Trotzdem abschließen",
            })
          );
        }
      } else {
        dispatch(
          showDialog({
            title: `Status ${mapTrainingTypeToArticleWithNounPossessiveCase(
              typeOfTrainingToBeCreated,
              trainingType
            )} ändern`,
            message: `Sind Sie sich sicher, dass Sie ${mapTrainingTypeToArticleWithNoun(
              typeOfTrainingToBeCreated,
              trainingType
            )} ${mapStatusIdToStatusVerb(
              data.status
            )} möchten? Dieser Vorgang kann nicht wieder rückgängig gemacht werden`,
            action: () => {
              dispatch(editEvent(mapAddEventToDto(data), id));
            },
          })
        );
      }
    } else {
      removeDocumentsMarkedForDeletion();
      if (typeOfTrainingToBeCreated === TrainingType.DefaultEvent) {
        dispatch(editEvent(mapAddEventToDto(data), id));
      } else {
        dispatch(editELearning(mapAddELearningToDto(data), id));
      }
    }
  };

  return {
    editEventWithStatusChecks,
  };
};

export default useEditFormContext;
