import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { templateService } from "../../../api";
import { history } from "../../../components/routing/history";
import routes from "../../../components/routing/routes";
import { mapAddEventToDto } from "../../../models/eventData";
import { Pagination } from "../../../models/pagination";
import {
  EventTemplateData,
  EventTemplateFilterData,
  EventTemplateFilterOptions,
} from "../../../models/templateData";
import { EventTemplateFilterService } from "../../../services/filters/eventTemplateFilterService";
import { success } from "../../alerts/actions";
import { AlertActionTypes, ErrorAction } from "../../alerts/types";
import { ClearDocumentsAction } from "../../documents/types";
import { addEventFromTemplate } from "../../events/actions";
import { ClearSpeakersAction } from "../../speaker/types";
import { EventFormData } from "./../../../models/eventData";
import { EventActionTypes } from "./../../events/types";
import { initialEventTemplateState } from "./reducers";
import {
  AddOrEditEventTemplateSuccessAction,
  ADD_OR_EDIT_EVENT_TEMPLATE_SUCCESS,
  CLEAR_EVENT_TEMPLATE,
  DeleteEventTemplateSuccessAction,
  DELETE_EVENT_TEMPLATE,
  EventTemplateClearAction,
  EventTemplatesActionTypes,
  EVENT_TEMPLATE_FAILURE,
  EVENT_TEMPLATE_REQUEST,
  SetEventTemplateModeAndEventTemplateAction,
  SetEventTemplatePageCountAction,
  SetEventTemplateSuccessAction,
  SetListOfEventTemplatesSuccessAction,
  SET_EVENT_TEMPLATE_MODE_AND_EVENT_TEMPLATE,
  SET_EVENT_TEMPLATE_PAGE_COUNT,
  SET_EVENT_TEMPLATE_SUCCESS,
  SET_LIST_OF_EVENT_TEMPLATES_SUCCESS,
  TemplateFailureAction,
  TemplateRequestAction,
} from "./types";

export function getAllEventTemplates(
  page: number = 1,
  filters: EventTemplateFilterOptions = {}
) {
  return (dispatch: Dispatch<EventTemplatesActionTypes | AlertActionTypes>) => {
    const eventTemplateFilterService = new EventTemplateFilterService(page, {
      ...filters,
    } as EventTemplateFilterData);
    const filterHasChanged = eventTemplateFilterService.checkIfFilterHasChanged();

    if (filterHasChanged) {
      page = initialEventTemplateState.currentPage;
      eventTemplateFilterService.searchFilter = { page: page };
      dispatch(clearTemplateEvent()); // set initial state for lecture list
    }

    dispatch(templateRequest());

    const route = eventTemplateFilterService.getRoute();
    eventTemplateFilterService.reflectFiltersInUrl(route);

    templateService
      .getAllEventTemplates(route)
      .then((response: Pagination<EventTemplateData>) => {
        response.page = page;
        dispatch(setEventTemplatePageCount(page));
        dispatch(setListOfEventTemplatesSuccess(response));
      })
      .catch((err: AxiosError) => {
        dispatch(templateFailure(err.response?.status === 404));
      });
  };
}

export function getEventTemplate(id: string) {
  return (dispatch: Dispatch<EventTemplatesActionTypes | ErrorAction>) => {
    dispatch(templateRequest());

    templateService
      .getEventTemplate(id)
      .then((response) => {
        dispatch(setEventTemplateSuccess(response));
      })
      .catch((err: AxiosError) => {
        dispatch(templateFailure(err.response?.status === 404));
      });
  };
}

export function addEventTemplate(
  data: EventTemplateData,
  forward?: boolean,
  fromTemplate: boolean = false,
  event?: EventFormData
) {
  return (
    dispatch: Dispatch<
      | EventActionTypes
      | EventTemplatesActionTypes
      | AlertActionTypes
      | ClearSpeakersAction
      | ClearDocumentsAction
    >
  ) => {
    dispatch(templateRequest());
    templateService
      .addEventTemplate(data)
      .then((response) => {
        if (fromTemplate && event) {
          addEventFromTemplate(mapAddEventToDto(event, response.guid), dispatch);
        }
        dispatch(addOrEditEventTemplateSuccess(response));
        dispatch(
          success(
            {
              message: "Die Veranstaltungsvorlage wurde erfolgreich angelegt.",
            },
            3000
          )
        );

        if (forward) {
          history.push(routes.templates);
        }
      })
      .catch((err: AxiosError) => {
        dispatch(templateFailure(err.response?.status === 404));
      });
  };
}

export function editEventTemplate(
  data: EventTemplateData,
  id: string,
  forward: boolean
) {
  return (dispatch: Dispatch<EventTemplatesActionTypes | AlertActionTypes>) => {
    dispatch(templateRequest());
    templateService
      .editEventTemplate(data, id)
      .then((response) => {
        dispatch(addOrEditEventTemplateSuccess(response));
        dispatch(
          success(
            {
              message:
                "Die Veranstaltungsvorlage wurde erfolgreich bearbeitet.",
            },
            3000
          )
        );
        if (forward) {
          history.push(routes.templates);
        }
      })
      .catch((err: AxiosError) => {
        dispatch(templateFailure(err.response?.status === 404));
      });
  };
}

export function deleteEventTemplate(id: string) {
  return (dispatch: Dispatch<EventTemplatesActionTypes | AlertActionTypes>) => {
    templateService
      .deleteEventTemplate(id)
      .then(() => {
        dispatch(deleteEventTemplateSuccess(id));
        dispatch(
          success(
            {
              message: "Die Veranstaltungsvorlage wurde erfolgreich gelöscht.",
            },
            3000
          )
        );
      })
      .catch((err: AxiosError) => {
        dispatch(templateFailure(err.response?.status === 404));
      });
  };
}

export function applyTemplateForEvent(data: EventTemplateData) {
  return (dispatch: Dispatch<EventTemplatesActionTypes | AlertActionTypes>) => {
    dispatch(setEventTemplateModeAndTemplateEvent(data));
    history.push(routes.event_create);
  };
}

export function templateRequest(): TemplateRequestAction {
  return {
    type: EVENT_TEMPLATE_REQUEST,
  };
}
export function templateFailure(
  notFoundError: boolean = false
): TemplateFailureAction {
  return {
    type: EVENT_TEMPLATE_FAILURE,
    notFoundError: notFoundError,
  };
}

export function setListOfEventTemplatesSuccess(
  data: Pagination<EventTemplateData>
): SetListOfEventTemplatesSuccessAction {
  return {
    type: SET_LIST_OF_EVENT_TEMPLATES_SUCCESS,
    data,
  };
}

export function setEventTemplateSuccess(
  data: EventTemplateData
): SetEventTemplateSuccessAction {
  return {
    type: SET_EVENT_TEMPLATE_SUCCESS,
    data,
  };
}

export function addOrEditEventTemplateSuccess(
  data: EventTemplateData
): AddOrEditEventTemplateSuccessAction {
  return {
    type: ADD_OR_EDIT_EVENT_TEMPLATE_SUCCESS,
    data,
  };
}

export function deleteEventTemplateSuccess(
  id: string
): DeleteEventTemplateSuccessAction {
  return {
    type: DELETE_EVENT_TEMPLATE,
    id,
  };
}

export function setEventTemplatePageCount(
  pageCount: number
): SetEventTemplatePageCountAction {
  return {
    type: SET_EVENT_TEMPLATE_PAGE_COUNT,
    pageCount: pageCount,
  };
}

export function setEventTemplateModeAndTemplateEvent(
  eventTemplate: EventTemplateData
): SetEventTemplateModeAndEventTemplateAction {
  return {
    type: SET_EVENT_TEMPLATE_MODE_AND_EVENT_TEMPLATE,
    data: eventTemplate,
  };
}

export function clearTemplateEvent(): EventTemplateClearAction {
  return {
    type: CLEAR_EVENT_TEMPLATE,
  };
}
