import { CardHeader } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux";
import "./Dashboard.scss";

interface ELearningCardHeaderProps {
  title: React.ReactNode; // don't use title of CardHeaderProps because ReactNode is not supported
}

const ELearningCardHeader: React.FC<ELearningCardHeaderProps> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);

  return (
    <CardHeader
      titleTypographyProps={{ variant: "h4" }}
      className={
        accessibility.monoMode
          ? "dashboard-cardheader-mono"
          : "dashboard-cardheader"
      }
      title={props.title}
    />
  );
};

export default ELearningCardHeader;
