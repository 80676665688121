import * as React from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Popover,
  Theme,
  Typography,
} from "@material-ui/core";

interface TargetStopSignUpPopoverProps {
  openStopSignUp: boolean;
  anchorElStopSignUp: HTMLElement | null;
  handlePopoverCloseStopSignUp: () => void;
  book_for_me?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
  })
);

const TargetStopSignUpPopover: React.FC<TargetStopSignUpPopoverProps> = (
  props
) => {
  const classes = useStyles();

  return (
    <Popover
      id="stop-signup-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={props.openStopSignUp}
      anchorEl={props.anchorElStopSignUp}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={props.handlePopoverCloseStopSignUp}
      disableRestoreFocus
    >
      <Box component={"div"} style={{ textAlign: "center" }}>
        <Typography>
          <b>Für diese Veranstaltung gilt ein Anmeldestop.</b>
          <br />
          <br />
          Möchten Sie trotzdem{" "}
          {props.book_for_me ? "teilnehmen" : "für diesen Nutzer buchen"}?
          <br />
          Wenden Sie sich bitte per Kontaktformular an unser Team.
        </Typography>
      </Box>
    </Popover>
  );
};

export default TargetStopSignUpPopover;
