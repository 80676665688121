import { DatePickerProps, KeyboardDatePicker } from "@material-ui/pickers";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { FieldAttributes, useField, useFormikContext } from "formik";
import * as React from "react";
import { EventFormData } from "../../models/eventData";

interface CustomDatepickerProps {
  value?: ParsableDate;
  customChangeHandler?: (
    date: MaterialUiPickersDate | null,
    value?: string | null
  ) => void;
}

const CustomDatepicker: React.FC<
  FieldAttributes<{}> & DatePickerProps & CustomDatepickerProps
> = ({ customChangeHandler, ...props }) => {
  const [field, meta] = useField<{}>(props);
  const { setFieldValue } = useFormikContext<EventFormData>();
  const error = meta.error && meta.touched ? meta.error : ""; // get validation error text

  const handleDateChange = (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => {
    if (customChangeHandler) {
      customChangeHandler(date, value);
    } else {
      setFieldValue(field.name, date);
    }
  };

  return (
    <KeyboardDatePicker
      {...field}
      {...props}
      autoComplete="off"
      InputProps={{ required: false }}
      inputVariant="filled"
      variant="inline"
      format="DD.MM.YYYY"
      helperText={error}
      error={!!error}
      value={field.value || null}
      onChange={handleDateChange}
      KeyboardButtonProps={{
        "aria-label": `change ${field.name}`,
      }}
    />
  );
};

export default CustomDatepicker;
