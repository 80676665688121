import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Theme,
  Hidden,
} from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { KvhPrintSvg } from "../../../assets/icons/custom-svg-components/KvhSvgCollection";
import { CookieConsent } from "../../core/events/shared/CookieConsent";
import routes from "../../routing/routes";
import HeadingKvh from "../../theming/HeadingKvh";
import TypographyWhiteText from "../../theming/TypographyWhiteText";
import "./Footer.scss";
import { useDispatch, useSelector } from "react-redux";
import { contactClear } from "../../../redux/contact/actions";
import { AppState } from "../../../redux";
import {
  PRIVACY_URL,
  IMPRINT_URL,
  TERMS_AND_CONDITIONS_URL,
} from "../../routing/externalUrls";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "#fff",
    "&:hover": {
      color: "#fff",
      textDecoration: "underline",
    },
  },
  margin: {
    marginLeft: theme.spacing(10),
  },
}));

export const Footer: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const accessibility = useSelector((state: AppState) => state.accessibility);

  return (
    <footer id="footer" className="app-footer">
      {location.pathname !== routes.contact && (
        <>
          {accessibility.monoMode && (
            <Box bgcolor={"#000"} style={{ height: "2px" }}></Box>
          )}
          <Box
            className="col s6"
            color={accessibility.monoMode ? "#000" : "#fff"}
            bgcolor={accessibility.monoMode ? "#fff" : "#777"}
            py={"60px"}
            mb={10}
          >
            <Container>
              <HeadingKvh textColor={accessibility.monoMode ? "#000" : "#fff"}>
                Kontakt
              </HeadingKvh>
              <Grid container spacing={4}>
                <Grid item md={8}>
                  <TypographyWhiteText variant="body1">
                    Sie haben Fragen zu unseren Veranstaltungen? Das Team
                    Qualitäts- und Veranstaltungsmanagement freut sich auf Ihre
                    Anfragen.
                  </TypographyWhiteText>
                </Grid>
                <Grid item md={4}>
                  <Button
                    variant="contained"
                    size="large"
                    style={{ padding: "6px 40px" }}
                    color="primary"
                    onClick={() => {
                      dispatch(contactClear());
                      history.push(routes.contact);
                    }}
                    className={classes.margin}
                  >
                    Zum Kontaktformular
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Box>
          {accessibility.monoMode && (
            <Box bgcolor={"#000"} style={{ height: "2px" }}></Box>
          )}
        </>
      )}

      <CookieConsent />

      <Box
        color={"#fff"}
        bgcolor={accessibility.monoMode ? "#000" : "#3c3c3c"}
        className="footer-bottom"
      >
        <Container>
          <Box display="flex">
            <Box flexGrow={1}>
              <Box display="flex">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Box px={"5px"} flexWrap="wrap">
                      © {moment().year()} Kassenärztliche Vereinigung Hessen
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box display="flex" flexWrap="wrap">
                      <Hidden smDown>
                        <Box flexGrow={1}></Box>
                      </Hidden>
                      <Box px={"5px"}>
                        <Link
                          className={classes.root}
                          href={PRIVACY_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Datenschutz
                        </Link>
                      </Box>
                      <Box px={"5px"}>
                        <Link
                          className={classes.root}
                          href={IMPRINT_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Impressum
                        </Link>
                      </Box>
                      <Box px={"5px"}>
                        <Link
                          className={classes.root}
                          href={TERMS_AND_CONDITIONS_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Nutzungsbedingungen
                        </Link>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box>
              <IconButton
                onClick={() => window.print()}
                aria-label="print"
                style={{
                  padding: "0 5px",
                  paddingBottom: "4px",
                }}
                color="inherit"
              >
                <KvhPrintSvg />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};
