import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import routes from "../components/routing/routes";
import { store } from "../redux";
import { error as errorAlert, info } from "../redux/alerts/actions";
import { loggingOut } from "../redux/auth/actions";
import { API_URL, CURRENT_API_VERSION } from "./apiConfig";
import { ApiHelper } from "./apiHelper";
import { history } from "./../components/routing/history";

const BASE_CONFIG: AxiosRequestConfig = {
  baseURL: API_URL + CURRENT_API_VERSION,
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrftoken",
  withCredentials: true,
};

/**
 * Class that holds base CRUD functionality for API calls.
 */
export class BaseCrudService {
  protected axiosInstance: AxiosInstance;

  public constructor(
    config?: AxiosRequestConfig,
    useInterceptor: boolean = true
  ) {
    this.axiosInstance = axios.create({ ...BASE_CONFIG, ...config });

    this.axiosInstance.interceptors.request.use(
      (request) => request, // don't check for session if the flag is false
      this.handleErrorResponse
    );

    if (useInterceptor) {
      this.axiosInstance.interceptors.response.use(
        (response) => response,
        this.handleErrorResponse
      );
    }
  }

  /**
   * Function that is responsible for intercepting all responses that come from API to check for errors.
   * In this specific case listens for 401 (unauthorized) and 403 (forbidden) errors (indicates that the user isn't logged in anymore)
   * and logs out automatically and also shows an info alert.
   * @param error Error object that holds information of the status, message and data of the error that occured.
   */
  protected handleErrorResponse(error: AxiosError) {
    const currentState = store.getState();
    const auth = currentState.auth;
    const tryLinkAccountWithoutLogin =
      currentState.linkAccount.tryLinkAccountWithoutLogin;

    const wasLoggedIn = auth.loggedIn; // check if state was logged in

    const errorStatus = error.response?.status;
    const errorObject = error.response?.data;

    if (errorStatus === 400) {
      // for 400 status codes show the error message coming from API
      store.dispatch(
        errorAlert(
          {
            message: ApiHelper.showErrorMessageFromApi(errorObject),
          },
          5000
        )
      );
    } else if (errorStatus === 404) {
      store.dispatch(
        errorAlert(
          {
            message: "Die gesuchte Seite konnte nicht gefunden werden.",
          },
          5000
        )
      );
    } else if (errorStatus === 403 || errorStatus === 401) {
      if (wasLoggedIn) {
        store.dispatch(loggingOut()); // set logout state
        store.dispatch(
          info(
            {
              message:
                "Ihre Sitzung ist abgelaufen, bitte loggen Sie sich erneut ein!",
            },
            4000
          )
        );
      } else {
        // ! this is a special case: show info alert if user tries to link accounts but is not logged in
        if (tryLinkAccountWithoutLogin) {
          store.dispatch(
            info(
              {
                message:
                  "Bitte loggen Sie sich ein, um die Verifizierung der Kontenverknüpfung abzuschließen.",
              },
              6000
            )
          );
        } else {
          store.dispatch(
            errorAlert(
              {
                message:
                  "Sie sind nicht autorisiert, auf diese Seite zuzugreifen.",
              },
              4000
            )
          );
        }
      }
    } else if (errorStatus === 503) {
      // maintenance
      history.push(routes.maintenance);
    } else {
      store.dispatch(
        errorAlert(
          {
            message:
              "Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen, wenden Sie sich an Ihren System-Administrator!",
          },
          6000
        )
      );
    }

    return Promise.reject<AxiosError>(error);
  }

  /**
   * Implements the /POST call to API endpoint (/url) via axios which creates one object of the given type.
   * @param url API path to which the call is sent
   * @param data data which is send to the API that should be created
   * @param params query parameters that are append to the url (are passed as JSON object)
   * @param config optional config for the API call e.g. setting specific headers
   */
  protected async create<T, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    params?: string | {},
    config?: AxiosRequestConfig | undefined
  ): Promise<R> {
    const urlWithParams = ApiHelper.parseUrlWithParams(url, params);
    // return axios.post(urlWithParams, data, config);
    return this.axiosInstance.post(urlWithParams, data, config);
  }

  /**
   * Implements the /GET call to API endpoint (/url/{id}) via axios to receive one object with the given id of the given type.
   * @param url API path to which the call is sent
   * @param id identifier of the object that should be received
   * @param config optional config for the API call e.g. setting specific headers
   */
  protected async get<T, R = AxiosResponse<T>>(
    url: string,
    id: string,
    config?: AxiosRequestConfig | undefined
  ): Promise<R> {
    return this.axiosInstance.get(`${url}/${id}`, config);
  }

  /**
   * Implements the /GET call to API endpoint (/url) via axios to receive all objects of the given type.
   * @param url  API path to which the call is sent
   * @param params query parameters that are append to the url (are passed as JSON object)
   * @param config optional config for the API call e.g. setting specific headers
   */
  protected async getAll<T, R = AxiosResponse<T>>(
    url: string,
    params?: string | {},
    config?: AxiosRequestConfig | undefined
  ): Promise<R> {
    const urlWithParams = ApiHelper.parseUrlWithParams(url, params);
    return this.axiosInstance.get(urlWithParams, config);
  }

  /**
   * Implements the /PATCH call to API endpoint (/url/{id}) via axios to edit (only parts of) one object with the given id of the given type.
   * @param url API path to which the call is sent
   * @param id identifier of the object that should be edited
   * @param data data which is send to the API that should be edited
   * @param config optional config for the API call e.g. setting specific headers
   */
  protected async edit<T, R = AxiosResponse<T>>(
    url: string,
    id: string,
    data: T,
    config?: AxiosRequestConfig | undefined
  ): Promise<R> {
    return this.axiosInstance.patch(`${url}/${id}`, data, config);
  }

  /**
   * Implements the /PUT call to API endpoint (/url/{id}) via axios to update the whole object with the given id of the given type.
   * @param url API path to which the call is sent
   * @param id identifier of the object that should be updated
   * @param data data which is send to the API that should be updated
   * @param config optional config for the API call e.g. setting specific headers
   */
  protected async update<T, R = AxiosResponse<T>>(
    url: string,
    id: string,
    data: T,
    config?: AxiosRequestConfig | undefined
  ): Promise<R> {
    return this.axiosInstance.put(`${url}/${id}`, data, config);
  }

  /**
   * Implements the /DELETE call to API endpoint (/url/{id}) via axios to delete one object with the given id of the given type.
   * @param url API path to which the call is sent
   * @param id identifier of the object that should be deleted
   * @param config optional config for the API call e.g. setting specific headers
   */
  protected async delete<T, R = AxiosResponse<T>>(
    url: string,
    id: string,
    config?: AxiosRequestConfig | undefined
  ): Promise<R> {
    return this.axiosInstance.delete(`${url}/${id}`, config);
  }
}
