import {
  GET_CURRENT_USER,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  UserActionTypes,
  UserState,
} from "./types";

export const initialUserState: UserState = {
  isLoading: false,
  currentUser: undefined,
};

export function userReducer(
  state = initialUserState,
  action: UserActionTypes
): UserState {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.user,
        isLoading: false,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default userReducer;
