import * as React from "react";
import { Box, Button, Grid, Tooltip } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BillingAddressForm from "../../profile-settings/participant/billingAddressForm";
import CustomCheckbox from "../../../forms/CustomCheckbox";
import { useFormikContext } from "formik";
import {
  CheckoutFormData,
  CrudBillingAddressData,
} from "../../../../models/billingAddressData";
import { AppState } from "../../../../redux";
import { useSelector } from "react-redux";
import { Info } from "@material-ui/icons";
import { ChangeEvent } from "react";
import { UserAddress } from "../../../../models/userData";
import { LinkAccountData } from "../../../../models/linkAccountData";
import { MY_ACCOUNT_URL } from "../../../routing/externalUrls";

interface BillingAddressProps {
  bookForOtherUser: boolean;
  billingAddress: CrudBillingAddressData | null;
  bookForUser?: LinkAccountData;
  address?: UserAddress;
}

const BillingAddress: React.FC<BillingAddressProps> = ({
  bookForOtherUser,
  bookForUser,
  billingAddress,
  address,
}) => {
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const DefaultAddress = () => {
    const user = bookForOtherUser ? bookForUser : currentUser;
    const userAddress = bookForOtherUser ? address : currentUser?.address;

    return (
      <Box>
        {user?.first_name} {user?.last_name}
        <br />
        {userAddress?.street_address}
        <br />
        {userAddress?.zip_code} {userAddress?.city}
      </Box>
    );
  };

  const theme = useTheme();
  const {
    values,
    setFieldValue,
    submitForm,
  } = useFormikContext<CheckoutFormData>();
  const small = useMediaQuery(theme.breakpoints.down("xs"));

  const [isSubmitted, setIsSubmitted] = React.useState(false);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={8}>
          <h3>
            {bookForOtherUser
              ? `Rechnungsadresse von ${bookForUser?.first_name} ${bookForUser?.last_name}`
              : "Ihre Rechnungsadresse"}
          </h3>
        </Grid>
        {!bookForUser && (
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                window.open(MY_ACCOUNT_URL, "_blank", "noopener,noreferrer");
              }}
              variant="contained"
              color="primary"
            >
              Benutzerdaten ändern
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {!values.useAlternativeBillingAddress && !isSubmitted ? (
            bookForOtherUser && address ? (
              <Box>
                {bookForUser?.first_name} {bookForUser?.last_name}
                <br />
                {address.street_address}
                <br />
                {address.zip_code} {address.city}
              </Box>
            ) : (
              <DefaultAddress />
            )
          ) : billingAddress !== null ? (
            <Box>
              {billingAddress.medical_office_or_company && (
                <>
                  {billingAddress.medical_office_or_company} <br />
                </>
              )}
              {billingAddress.medical_office_or_company_extra && (
                <>
                  {billingAddress.medical_office_or_company_extra} <br />
                </>
              )}
              {billingAddress.first_name} {billingAddress.last_name}
              <br />
              {billingAddress.address} {billingAddress.street_address}
              <br />
              {billingAddress.zip_code} {billingAddress.city}
              <br />
              {billingAddress.email}
            </Box>
          ) : (
            <DefaultAddress />
          )}
        </Grid>
        {/* {(bookForOtherUser && billingAddress !== null) || */}
        {/* (!bookForOtherUser && ( */}
        <Grid item xs={12} sm={6}>
          <Box display="flex">
            <CustomCheckbox
              onChange={(
                event: ChangeEvent<HTMLInputElement>,
                checked?: boolean
              ) => {
                if (checked !== undefined) {
                  setFieldValue("useAlternativeBillingAddress", checked);
                  if (!checked) {
                    setIsSubmitted(false);
                  }
                }
              }}
              name="useAlternativeBillingAddress"
              label={
                bookForOtherUser
                  ? "Alternative Rechnungsadresse des Nutzers verwenden"
                  : "Andere Rechnungsadresse verwenden"
              }
            />
            <Tooltip
              placement="right"
              enterDelay={500}
              leaveDelay={200}
              title={
                !bookForOtherUser
                  ? `Sollten Sie bereits eine alternative Rechnungsadresse in Ihrem Nutzerkonto hinterlegt haben, wird diese standardmäßig verwendet, 
              wenn Sie "Andere Rechnungsadresse verwenden" wählen. Haben Sie noch keine Rechnungsadresse hinterlegt, können Sie hier eine neue Adresse angeben.`
                  : `Sollte der Nutzer bereits eine alternative Rechnungsadresse in seinem Nutzerkonto hinterlegt haben, wird diese verwendet, 
              wenn Sie "Alternative Rechnungsadresse des Nutzers verwenden" wählen. Bitte sorgen Sie dafür, dass die richtigen Rechnungsdaten vorliegen.`
              }
              arrow
            >
              <Info style={{ marginLeft: "0.5em" }} color="primary" />
            </Tooltip>
          </Box>
        </Grid>
        {/* ))} */}
      </Grid>
      {currentUser?.address !== null &&
        values.useAlternativeBillingAddress &&
        !bookForOtherUser && (
          <Grid container spacing={0}>
            <Grid item xs={8}>
              <h3>Alternative Rechnungsadresse</h3>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={small ? 2 : 4}>
                <BillingAddressForm editMode={true} />
                <Grid item xs={6} sm={3}>
                  <Button
                    style={{ width: "auto" }}
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      setIsSubmitted(true);
                      submitForm();
                    }}
                  >
                    Rechnungsadresse speichern und verwenden
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
    </>
  );
};

export default BillingAddress;
