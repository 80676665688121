import * as React from "react";
import HeadingKvh from "../../theming/HeadingKvh";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux";
import { useEffect } from "react";
import {
  getAllFavorites,
  setPageCount,
} from "../../../redux/favorites/actions";
import { EventItem } from "../events/list/eventItem/EventItem";
import { Box, Divider, Grid } from "@material-ui/core";
import { Pagination as PaginationUI } from "@material-ui/lab";
import { PAGE_SIZE } from "../../../models/pagination";
import Loader from "../../theming/loader/Loader";
import { getTargetGroups } from "../../../redux/target-groups/actions";

const Favorites: React.FC = (props) => {
  const dispatch = useDispatch();
  const favorite = useSelector((state: AppState) => state.favorite);
  const targetGroups = useSelector((state: AppState) => state.targetGroups);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setPageCount(value));
  };

  const loadData = React.useCallback(() => {
    if (!favorite.isLoading && !favorite.eventFavoriteListLoaded) {
      dispatch(getAllFavorites(favorite.currentPage));
    }
  }, [
    favorite.isLoading,
    favorite.eventFavoriteListLoaded,
    favorite.currentPage,
    dispatch,
  ]);

  useEffect(loadData, [loadData]);

  useEffect(() => {
    if (!targetGroups.isLoading && !targetGroups.targetGroupsLoaded) {
      dispatch(getTargetGroups());
    }
  }, [dispatch, targetGroups]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <HeadingKvh>Favoriten</HeadingKvh>
        </Grid>
      </Grid>
      {favorite.isLoading ? (
        <Loader />
      ) : favorite.eventFavoriteList.count === 0 ? (
        <Box
          component="span"
          display="flex"
          alignItems="center"
          style={{ height: "100px" }}
        >
          Sie haben keine Favoriten.
        </Box>
      ) : (
        favorite.eventFavoriteList.results.map((favorite) => {
          return (
            <React.Fragment key={`event-${favorite.guid}`}>
              <EventItem event={favorite.event} favorite={true} />
              <Divider className={"event-list-separator"} />
            </React.Fragment>
          );
        })
      )}
      <Grid container style={{ marginTop: "10px" }}>
        <Box component={"div"} style={{ margin: "auto" }}>
          <PaginationUI
            count={Math.ceil(favorite.eventFavoriteList.count / PAGE_SIZE)}
            page={favorite.currentPage}
            onChange={handleChange}
          />
        </Box>
      </Grid>
    </>
  );
};

export default Favorites;
