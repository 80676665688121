import { faEnvelope, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  ChangeEvent,
  default as React,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { v4 as uuidv4 } from "uuid";
import { participantsService, usersService } from "../../../../../api";
import SvgIconBase from "../../../../../assets/icons/custom-svg-components/SvgIconBase";
import { ReactComponent as Print } from "../../../../../assets/icons/print.svg";
import { EventStatus } from "../../../../../models/enums/eventStatus.enum";
import {
  ELearningParticipationStatus,
  ParticipationStatus,
} from "../../../../../models/enums/participationStatus.enum";
import { TrainingType } from "../../../../../models/enums/trainingType.enum";
import { UserType } from "../../../../../models/enums/userType.enum";
import { EventDataDto } from "../../../../../models/eventData";
import { LecturesData } from "../../../../../models/lecturesData";
import {
  ParticipantData,
  ParticipantListData,
} from "../../../../../models/participantData";
import { UserData } from "../../../../../models/userData";
import { AppState } from "../../../../../redux";
import { error } from "../../../../../redux/alerts/actions";
import { addEventToCart } from "../../../../../redux/cart/actions";
import { showDialog } from "../../../../../redux/dialogs/actions";
import {
  clearEditEvent,
  triggerInvoice,
  triggerInvoiceCorrection,
} from "../../../../../redux/events/actions";
import {
  getParticipants,
  reactivateELearning,
  sendEmailToParticipants,
} from "../../../../../redux/participants/actions";
import {
  getParticipantsTabBasedOnTrainingType,
  setOpenTab,
} from "../../../../../redux/tabs/actions";
import { putOtherParticipantToWaitingList } from "../../../../../redux/waitinglist/actions";
import usePermission from "../../../../../services/usePermissions";
import CustomTextField from "../../../../forms/CustomTextField";
import routes from "../../../../routing/routes";
import Loader from "../../../../theming/loader/Loader";
import Cart from "../../../booking/Cart";
import { EventItemCapCheckbox } from "../../list/eventItem/EventItemCapCheckbox";
import { isEventOver } from "../checkEventIsOver";
import "./Participants.scss";
import { ParticipantsHeader } from "./ParticipantsHeader";
import { ParticipantsItem } from "./ParticipantsItem";
import { ParticipantsListPrint } from "./ParticipantsListPrint";
import { pdfParticipantTestData } from "../../../../../test/pdfParticipantListTestData";

interface ParticipantsProps {
  eventData: EventDataDto | LecturesData;
  trainingType: TrainingType;
}

// set temporarly to true, to test PDF with entries over multiple pages
const USE_TEST_DATA = false;

export const Participants: React.FC<ParticipantsProps> = (props) => {
  let { id } = useParams<{ id: string }>();

  const [open, setOpen] = React.useState(false);
  const [textValue, setTextValue] = React.useState("");

  let noParticipant = "Es gibt keine Teilnehmer für diese Veranstaltung.";
  if (props.trainingType === TrainingType.ELearning)
    noParticipant = "Es gibt keine aktiven Teilnehmer für dieses E-Learning.";
  if (props.trainingType === TrainingType.BlendedLearning)
    noParticipant = "Es gibt keine Teilnehmer für dieses Blended-Learning.";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleExport = () => {
    participantsService.getCSV(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [limitation, setLimitation] = useState<boolean>(false);

  const handleSend = () => {
    if (!limitation) {
      dispatch(sendEmailToParticipants(id, textValue));
      setOpen(false);
      setLimitation(true);
      setTimeout(() => {
        setLimitation(false);
      }, 10000);
    } else {
      dispatch(
        error({
          message:
            "Das Versenden einer Nachricht an alle Teilnehmer ist nur alle 5 Minuten möglich!",
        })
      );
    }
  };

  const handleParticipantMessageChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setTextValue(event.target.value);
  };

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const participants = useSelector((state: AppState) => state.participant);

  /* search with autocomplete */
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [searchOptions, setSearchOptions] = React.useState<UserData[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [needsRerender, setNeedsRerender] = React.useState(false);

  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const { isStaff } = usePermission(currentUser?.user_type);

  const isCurrentParticipant = useCallback(
    (participation: ParticipantListData) => {
      return ![
        ParticipationStatus.WaitingList,
        ParticipationStatus.ReservedCart,
        ParticipationStatus.Cancelled,
        ParticipationStatus.CancelledByStaff,
      ].includes(participation.status);
    },
    []
  );

  const isActiveParticipant = useCallback(
    (participation: ParticipantListData) => {
      return props.eventData.training_type === TrainingType.ELearning
        ? (participation.is_within_elearning_period &&
            participation.elearning_status !==
              ELearningParticipationStatus.Passed) ||
            participation.reactivated
        : participation.is_within_elearning_period ||
            isCurrentParticipant(participation);
    },
    [isCurrentParticipant, props.eventData.training_type]
  );

  const sortedList = (list: ParticipantListData[]) => {
    return list.sort((firstUser, secondUser) =>
      firstUser.user.last_name > secondUser.user.last_name
        ? 1
        : firstUser.user.last_name === secondUser.user.last_name
        ? firstUser.user.first_name > secondUser.user.first_name
          ? 1
          : -1
        : -1
    );
  };

  // put the calls into useMemo hook so the values are cached
  // as long as the participantList does not change
  const activeParticipants = useMemo(() => {
    return sortedList(
      participants.participantList.filter((participant) =>
        isActiveParticipant(participant)
      )
    );
  }, [isActiveParticipant, participants.participantList]);

  const waitingParticipants = useMemo(() => {
    return sortedList(
      participants.participantList.filter(
        (participant) =>
          participant.status === ParticipationStatus.WaitingList ||
          participant.status === ParticipationStatus.ReservedCart
      )
    );
  }, [participants.participantList]);

  const cancelledParticipants = useMemo(() => {
    return sortedList(
      participants.participantList.filter(
        (participant) =>
          participant.status === ParticipationStatus.Cancelled ||
          participant.status === ParticipationStatus.CancelledByStaff
      )
    );
  }, [participants.participantList]);

  const isDisabledSelect = () => {
    if (
      props.eventData.status === EventStatus.Completed ||
      props.eventData.status === EventStatus.Cancelled ||
      props.trainingType === TrainingType.ELearning
    ) {
      return true;
    } else {
      isEventOver(props.eventData.end);
    }
    return false;
  };

  const handleSearchQueryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  const checkParticipantParticipationStatus = () => {
    if (
      participants.participantList.filter(
        (participant) => participant.status === ParticipationStatus.Booked
      ).length === 0 &&
      participants.participantList.filter(
        (participant) =>
          participant.status === ParticipationStatus.Participated ||
          participant.status === ParticipationStatus.NotParticipated
      ).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let query = searchQuery;

      if (query != null) {
        (async () => {
          if (query === searchQuery && query.length > 0) {
            let result: UserData[] = await usersService.getUsers(
              query,
              UserType.Participant,
              id
            );
            // filter out the current participations but keep blended-learning ones
            // because they include an e-learning-period which shoud be restartable (-> reactivated)
            if (
              props.eventData.training_type !== TrainingType.BlendedLearning
            ) {
              // TODO: filtering should be done in backend
              result = result.filter(
                (item) =>
                  participants.participantList.filter(
                    (p) => p.user.guid === item.guid && isActiveParticipant(p)
                  ).length === 0
              );
            }
            setSearchOptions(result);
          }
        })();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    searchQuery,
    participants,
    id,
    setSearchOptions,
    isActiveParticipant,
    props.eventData.training_type,
  ]);

  useEffect(() => {
    if (!searchOpen) {
      setSearchOptions([]);
    }
  }, [searchOpen]);

  useEffect(() => {
    dispatch(getParticipants(id));
  }, [dispatch, id]);

  /////////////

  const [selectedActiveParticipants, setSelectedActiveParticipants] = useState<
    boolean[]
  >(new Array(activeParticipants.length).fill(false));

  const [
    selectedWaitingParticipants,
    setSelectedWaitingParticipants,
  ] = useState<boolean[]>(new Array(waitingParticipants.length).fill(false));

  const [
    selectedCancelledParticipants,
    setSelectedCancelledParticipants,
  ] = useState<boolean[]>(new Array(cancelledParticipants.length).fill(false));

  const [
    selectedActiveParticipantsForChange,
    setSelectedActiveParticipantsForChange,
  ] = useState<ParticipantListData[]>(
    activeParticipants.filter((d, ind) => selectedActiveParticipants[ind])
  );
  const [
    selectedWaitingParticipantsForChange,
    setSelectedWaitingParticipantsForChange,
  ] = useState<ParticipantListData[]>(
    waitingParticipants.filter((d, ind) => selectedWaitingParticipants[ind])
  );

  const [
    selectedCancelledParticipantsForChange,
    setSelectedCancelledParticipantsForChange,
  ] = useState<ParticipantListData[]>(
    cancelledParticipants.filter((d, ind) => selectedCancelledParticipants[ind])
  );

  // pre-fill the selected array with false values
  // if the number of elements in each list change (e.g. if an employee adds a participant)
  useEffect(() => {
    setSelectedActiveParticipants(
      new Array(activeParticipants.length).fill(false)
    );
    setSelectedWaitingParticipants(
      new Array(waitingParticipants.length).fill(false)
    );

    setSelectedCancelledParticipants(
      new Array(cancelledParticipants.length).fill(false)
    );
  }, [
    activeParticipants.length,
    cancelledParticipants.length,
    waitingParticipants.length,
  ]);

  // do this so the participants for the multiselect checkbox of each list are set correctly
  useEffect(() => {
    setSelectedActiveParticipantsForChange(
      activeParticipants.filter((d, ind) => selectedActiveParticipants[ind])
    );
    setSelectedWaitingParticipantsForChange(
      waitingParticipants.filter((d, ind) => selectedWaitingParticipants[ind])
    );
    setSelectedCancelledParticipantsForChange(
      cancelledParticipants.filter(
        (d, ind) => selectedCancelledParticipants[ind]
      )
    );
  }, [
    activeParticipants,
    cancelledParticipants,
    selectedActiveParticipants,
    selectedCancelledParticipants,
    selectedWaitingParticipants,
    waitingParticipants,
  ]);

  const handleActiveParticipantsClick = (index: number) => {
    setSelectedWaitingParticipants(
      new Array(waitingParticipants.length).fill(false)
    );
    setSelectedCancelledParticipants(
      new Array(cancelledParticipants.length).fill(false)
    );
    setSelectedActiveParticipants((prevState) =>
      prevState.map((item, idx) => (idx !== index ? item : !item))
    );
  };

  const handleWaitingParticipantsClick = (index: number) => {
    setSelectedActiveParticipants(
      new Array(activeParticipants.length).fill(false)
    );
    setSelectedCancelledParticipants(
      new Array(cancelledParticipants.length).fill(false)
    );
    setSelectedWaitingParticipants((prevState) =>
      prevState.map((item, idx) => (idx !== index ? item : !item))
    );
  };

  const handleCancelledParticipantsClick = (index: number) => {
    setSelectedWaitingParticipants(
      new Array(waitingParticipants.length).fill(false)
    );
    setSelectedActiveParticipants(
      new Array(activeParticipants.length).fill(false)
    );
    setSelectedCancelledParticipants((prevState) =>
      prevState.map((item, idx) => (idx !== index ? item : !item))
    );
  };

  const handleSelectAllActiveParticipants = () => {
    setSelectedWaitingParticipants(
      new Array(waitingParticipants.length).fill(false)
    );
    setSelectedCancelledParticipants(
      new Array(cancelledParticipants.length).fill(false)
    );

    if (
      selectedActiveParticipantsForChange.length === activeParticipants.length
    ) {
      setSelectedActiveParticipants((prevState) => prevState.map(() => false));
    } else {
      setSelectedActiveParticipants((prevState) => prevState.map(() => true));
    }
  };

  const handleSelectAllWaitingParticipants = () => {
    setSelectedActiveParticipants(
      new Array(activeParticipants.length).fill(false)
    );
    setSelectedCancelledParticipants(
      new Array(cancelledParticipants.length).fill(false)
    );
    if (
      selectedWaitingParticipantsForChange.length === waitingParticipants.length
    ) {
      setSelectedWaitingParticipants((prevState) => prevState.map(() => false));
    } else {
      setSelectedWaitingParticipants((prevState) => prevState.map(() => true));
    }
  };

  const handleSelectAllCancelledParticipants = () => {
    setSelectedActiveParticipants(
      new Array(activeParticipants.length).fill(false)
    );
    setSelectedWaitingParticipants(
      new Array(waitingParticipants.length).fill(false)
    );

    if (
      selectedCancelledParticipantsForChange.length ===
      cancelledParticipants.length
    ) {
      setSelectedCancelledParticipants((prevState) =>
        prevState.map(() => false)
      );
    } else {
      setSelectedCancelledParticipants((prevState) =>
        prevState.map(() => true)
      );
    }
  };

  return (
    <Grid container spacing={4}>
      {participants.isLoading ? (
        <Loader />
      ) : (
        <>
          {/*Printable version of participant list */}
          <Box style={{ display: "none" }}>
            <ParticipantsListPrint
              ref={componentRef}
              participantList={
                USE_TEST_DATA
                  ? pdfParticipantTestData
                  : participants.participantList.filter((participant) =>
                      isActiveParticipant(participant)
                    )
              }
              eventData={props.eventData}
              currentUser={currentUser}
            />
          </Box>
          {/*End of Printable version of participant list */}
          <Grid item xs={12} sm={12}>
            <EventItemCapCheckbox event={props.eventData as EventDataDto} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <h2>
              Teilnehmerliste{" "}
              <Badge
                color="secondary"
                className={"participant-list-count-badge"}
                max={999}
                badgeContent={
                  participants.participantList.filter((participant) =>
                    isActiveParticipant(participant)
                  ).length
                }
                style={{
                  paddingLeft: "50px",
                }}
              />
              <Box
                style={{
                  float: "right",
                  marginBottom: "20px",
                }}
              >
                <Button onClick={handleExport}>
                  <FontAwesomeIcon
                    icon={faFileCsv}
                    size={"2x"}
                    color="#777"
                    style={{
                      fontSize: "25px",
                      marginRight: "7px",
                      verticalAlign: "middle",
                    }}
                  />
                  Teilnehmerliste exportieren
                </Button>
                {isStaff() && (
                  <>
                    <Button onClick={handleClickOpen}>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        size={"2x"}
                        color="#777"
                        style={{
                          fontSize: "25px",
                          marginRight: "7px",
                          verticalAlign: "middle",
                        }}
                      />
                      Teilnehmer kontaktieren
                    </Button>
                    <Dialog open={open} onClose={handleClose}>
                      <DialogTitle
                        style={{
                          marginLeft: "150px",
                          marginRight: "150px",
                        }}
                        id="alert-dialog-title"
                      >
                        {"Teilnehmer kontaktieren"}
                      </DialogTitle>
                      <DialogContent>
                        <CustomTextField
                          fullWidth
                          multiline
                          rows={6}
                          name="message"
                          label="Ihre Nachricht an alle Teilnehmer"
                          onChange={handleParticipantMessageChange}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleClose}
                          variant="contained"
                          color="secondary"
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          Abbrechen
                        </Button>
                        <Button
                          autoFocus
                          variant="contained"
                          color="primary"
                          onClick={handleSend}
                          style={{
                            marginRight: "17px",
                            marginBottom: "10px",
                          }}
                        >
                          Absenden
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
                {activeParticipants.length !== 0 && (
                  <IconButton
                    onClick={handlePrint}
                    aria-label="print participants"
                    style={{
                      cursor: "pointer",
                    }}
                    color={"inherit"}
                  >
                    <SvgIconBase
                      style={{ stroke: "#3c3c3c", fontSize: "25px" }}
                      component={Print}
                    />
                  </IconButton>
                )}
              </Box>
            </h2>
            <ParticipantsHeader
              participants={selectedActiveParticipantsForChange}
              index={11}
              trainingType={props.trainingType}
              checkHandler={handleSelectAllActiveParticipants}
              checked={selectedActiveParticipants.every((obj) => obj === true)}
              noParticipants={!activeParticipants.length}
              value={20}
            />
            {activeParticipants.length === 0
              ? noParticipant
              : sortedList(participants.participantList)
                  .filter((participant) => isActiveParticipant(participant))
                  .map((participant, index) => {
                    return (
                      <ParticipantsItem
                        participant={participant}
                        index={index}
                        key={participant.guid}
                        disabledSelect={isDisabledSelect()}
                        trainingType={props.trainingType}
                        checkedHandler={() =>
                          handleActiveParticipantsClick(index)
                        }
                        checked={selectedActiveParticipants[index] ?? false}
                      />
                    );
                  })}
            {props.eventData.price > 0 &&
              isStaff() &&
              isEventOver(props.eventData.end) &&
              participants.participantList.filter((participant) =>
                isActiveParticipant(participant)
              ).length > 0 && (
                <>
                  <br />
                  <Grid container spacing={4}>
                    <Grid item>
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        style={{ width: "200px", marginTop: "10px" }}
                        disabled={props.eventData.is_invoiced}
                        onClick={() => {
                          checkParticipantParticipationStatus()
                            ? dispatch(
                                showDialog({
                                  title: "Rechnungsstellung starten",
                                  message:
                                    "Möchten Sie die Rechnungsstellung für diese Veranstaltung starten?",
                                  action: () => {
                                    dispatch(triggerInvoice(id));
                                  },
                                })
                              )
                            : dispatch(
                                showDialog(
                                  {
                                    title:
                                      "Rechnungsstellung kann nicht gestartet werden",
                                    message: (
                                      <>
                                        Stellen Sie sicher, dass alle Teilnehmer
                                        auf der Teilnehmerliste den Status{" "}
                                        <b>teilgenommen</b> oder{" "}
                                        <b>nicht teilgenommen</b> haben.
                                      </>
                                    ),
                                  },
                                  true
                                )
                              );
                        }}
                      >
                        Rechnungsstellung
                      </Button>
                    </Grid>
                    {props.eventData.is_invoiced ? (
                      <Grid item>
                        <Button
                          size="large"
                          variant="contained"
                          color="primary"
                          style={{ width: "200px", marginTop: "10px" }}
                          disabled={
                            props.eventData.is_invoiced === null ||
                            selectedActiveParticipantsForChange.length === 0
                          }
                          onClick={() => {
                            dispatch(
                              showDialog({
                                title: "Rechnungskorrektur starten",
                                message:
                                  "Möchten Sie die Rechnungskorrektur für diese Veranstaltung starten?",
                                action: () => {
                                  dispatch(
                                    triggerInvoiceCorrection(
                                      id,
                                      selectedActiveParticipantsForChange.map(
                                        (p) => {
                                          return p.user.guid;
                                        }
                                      )
                                    )
                                  );
                                },
                              })
                            );
                          }}
                        >
                          Rechnungskorrektur
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                </>
              )}
            {props.trainingType !== TrainingType.ELearning &&
              currentUser?.user_type !== UserType.Speaker && (
                <>
                  <Grid item xs={12} sm={12}>
                    <h2>
                      Warteliste{" "}
                      <Badge
                        color="secondary"
                        className={"participant-list-count-badge"}
                        badgeContent={
                          participants.participantList.filter(
                            (participant) =>
                              participant.status ===
                                ParticipationStatus.WaitingList ||
                              participant.status ===
                                ParticipationStatus.ReservedCart
                          ).length
                        }
                      />
                    </h2>
                    <ParticipantsHeader
                      participants={selectedWaitingParticipantsForChange}
                      index={22}
                      trainingType={props.trainingType}
                      checkHandler={handleSelectAllWaitingParticipants}
                      checked={selectedWaitingParticipants.every(
                        (obj) => obj === true
                      )}
                      noParticipants={!waitingParticipants.length}
                      value={10}
                    />
                    {participants.participantList.filter(
                      (participant) =>
                        participant.status ===
                          ParticipationStatus.WaitingList ||
                        participant.status === ParticipationStatus.ReservedCart
                    ).length === 0
                      ? "Es gibt keine Teilnehmer auf der Warteliste."
                      : waitingParticipants.map((participant, index) => {
                          return (
                            <ParticipantsItem
                              participant={participant}
                              index={index}
                              key={participant.guid}
                              disabledSelect={isDisabledSelect()}
                              trainingType={props.trainingType}
                              checkedHandler={() =>
                                handleWaitingParticipantsClick(index)
                              }
                              checked={
                                selectedWaitingParticipants[index] ?? false
                              }
                            />
                          );
                        })}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <h2>
                      Storniert{" "}
                      <Badge
                        color="secondary"
                        className={"participant-list-count-badge"}
                        badgeContent={
                          participants.participantList.filter(
                            (participant) =>
                              participant.status ===
                                ParticipationStatus.Cancelled ||
                              participant.status ===
                                ParticipationStatus.CancelledByStaff
                          ).length
                        }
                      />
                    </h2>
                    <ParticipantsHeader
                      participants={selectedCancelledParticipantsForChange}
                      index={33}
                      trainingType={props.trainingType}
                      checkHandler={handleSelectAllCancelledParticipants}
                      checked={selectedCancelledParticipants.every(
                        (obj) => obj === true
                      )}
                      noParticipants={!cancelledParticipants.length}
                      value={30}
                    />
                    {participants.participantList.filter(
                      (participant) =>
                        participant.status === ParticipationStatus.Cancelled ||
                        participant.status ===
                          ParticipationStatus.CancelledByStaff
                    ).length === 0
                      ? "Es gibt keine Teilnehmer auf der Stornoliste."
                      : cancelledParticipants.map((participant, index) => {
                          return (
                            <ParticipantsItem
                              participant={participant}
                              index={index}
                              key={"cancelledList" + index}
                              disabledSelect={isDisabledSelect()}
                              trainingType={props.trainingType}
                              checkedHandler={() =>
                                handleCancelledParticipantsClick(index)
                              }
                              checked={
                                selectedCancelledParticipants[index] ?? false
                              }
                            />
                          );
                        })}
                  </Grid>
                </>
              )}
          </Grid>
          {props.eventData.status !== EventStatus.Completed && isStaff() && (
            <Grid item xs={12} sm={12}>
              <h3>Teilnehmer hinzufügen:</h3>
              <Autocomplete
                key={
                  needsRerender ? uuidv4() : "autocomplete-search-participants"
                }
                freeSolo
                id="addParticipant"
                noOptionsText={"Keine Nutzer gefunden"}
                // react shows loadingText if no options provided
                loadingText={"Keine Nutzer gefunden"}
                style={{ width: 300 }}
                open={searchOpen}
                onOpen={() => {
                  setSearchOpen(true);
                }}
                onClose={() => {
                  setSearchOpen(false);
                }}
                // backend will filter the results
                filterOptions={(options, _) => {
                  return options;
                }}
                options={searchOptions}
                getOptionSelected={(option, value) =>
                  option.fullname === value.fullname
                }
                getOptionLabel={(option) => option.fullname}
                onChange={(event, newValue) => {
                  if (newValue != null) {
                    setNeedsRerender(false);
                    var participantToAdd = (newValue as any) as ParticipantData;
                    const booking = participants.participantList.filter(
                      (p) =>
                        p.user.guid === participantToAdd.guid &&
                        p.event === props.eventData.guid
                    );
                    if (booking.length === 1) {
                      if (
                        booking[0].elearning_status ===
                        ELearningParticipationStatus.Passed
                      ) {
                        dispatch(
                          showDialog({
                            title: `E-Learning bestanden`,
                            message: `${participantToAdd.fullname} hat das E-Learning bereits erfolgreich bearbeitet.`,
                          })
                        );
                      } else if (booking[0].is_within_elearning_period) {
                        dispatch(
                          showDialog({
                            title: `E-Learning-Teilnahme aktiv`,
                            message: `${participantToAdd.fullname} befindet sich gerade innerhalb seines E-Learning-Bearbeitungszeitraums.`,
                          })
                        );
                      } else {
                        dispatch(
                          showDialog({
                            title: `E-Learning-Teilnahme für ${participantToAdd.fullname} reaktivieren`,
                            message: `Möchen Sie den E-Learning-Bearbeitungszeitraum für ${participantToAdd.fullname} jetzt neustarten?`,
                            action: () => {
                              dispatch(
                                reactivateELearning(
                                  booking[0].event.toString(),
                                  booking[0].guid
                                )
                              );
                              setNeedsRerender(true);
                            },
                          })
                        );
                      }
                    } else {
                      if (props.eventData.status === EventStatus.Completed) {
                        dispatch(
                          showDialog({
                            title: "Veranstaltung abgeschlossen",
                            message:
                              "Die Veranstaltung wurde abgeschlossen und kann nicht mehr gebucht werden.",
                          })
                        );
                      } else if (
                        props.eventData.status === EventStatus.Full ||
                        (props.eventData as EventDataDto)?.max_seats_taken
                      ) {
                        dispatch(
                          showDialog({
                            title: "Teilnehmer auf Warteliste vermerken",
                            message:
                              "Die Veranstaltung ist ausgebucht. Möchen Sie den Teilnehmer dennoch zu der Veranstaltung hinzufügen? Wenn Sie fortfahren, wird der Teilnehmer automatisch auf der Warteliste vermerkt.",
                            action: () => {
                              dispatch(clearEditEvent());
                              dispatch(
                                setOpenTab(
                                  getParticipantsTabBasedOnTrainingType(
                                    props.eventData.training_type
                                  )
                                )
                              );
                              dispatch(
                                putOtherParticipantToWaitingList(
                                  props.eventData.guid,
                                  participantToAdd.guid
                                )
                              );
                            },
                          })
                        );
                      } else {
                        dispatch(
                          showDialog({
                            title: "Veranstaltung für Teilnehmer buchen",
                            message:
                              "Möchten Sie den Teilnehmer zur Veranstaltung hinzufügen? Sie werden im nächsten Schritt zum Checkout weitergeleitet, welchen Sie stellvertretend für den Nutzer durchführen müssen.",
                            action: () => {
                              dispatch(
                                addEventToCart(
                                  props.eventData.guid,
                                  participantToAdd.guid
                                )
                              );
                              setNeedsRerender(true);
                              history.push(routes.checkout);
                            },
                          })
                        );
                      }
                    }
                  }
                }}
                renderOption={(option) => (
                  <Grid container justify="center" alignItems="center">
                    <Grid item xs={10}>
                      {`${option.academic_title || ""} ${option.fullname}${
                        option.username ? " (" + option.username + ")" : ""
                      }`}
                      <br />
                      {option.lanr && (
                        <>
                          LANR: {option.lanr} <br />
                        </>
                      )}
                      {option.email &&
                        option.username !== option.email &&
                        option.email}
                    </Grid>
                    <Grid item xs={2}>
                      <AddIcon />
                    </Grid>
                  </Grid>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="LANR / User-ID / Name / E-Mail"
                    variant="filled"
                    onChange={handleSearchQueryChange}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
          )}
          <Cart />
        </>
      )}
    </Grid>
  );
};
