import { Button, Tooltip } from "@material-ui/core";
import { useFormikContext } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { EventStatus } from "../../../../../models/enums/eventStatus.enum";
import {
  mapToTrainingTypeString,
  mapTrainingTypeToArticleWithNoun,
  TrainingType,
} from "../../../../../models/enums/trainingType.enum";
import { EventFormData } from "../../../../../models/eventData";
import { AppState } from "../../../../../redux";
import { Utils } from "../../../../../services/utils";

interface CreateOrUpdateButtonProps {
  isEditMode: boolean;
  typeOfTrainingToBeCreated: TrainingType;
}

const CreateOrUpdateButton: React.FC<CreateOrUpdateButtonProps> = (props) => {
  const { setFieldValue, isSubmitting } = useFormikContext<EventFormData>();

  const editEventState = useSelector(
    (state: AppState) => state.event.editEvent
  );

  const event = useSelector((state: AppState) => state.event);
  const trainingType = mapToTrainingTypeString(props.typeOfTrainingToBeCreated);

  return (
    <>
      <Tooltip
        arrow
        title={
          editEventState.status === EventStatus.Completed
            ? `${Utils.capitalizeFirstLetter(
                mapTrainingTypeToArticleWithNoun(
                  props.typeOfTrainingToBeCreated,
                  trainingType
                )
              )} kann nicht mehr verändert werden, da ${
                props.typeOfTrainingToBeCreated === TrainingType.DefaultEvent
                  ? "sie"
                  : "es"
              } bereits abgeschlossen wurde.`
            : ""
        }
      >
        <span>
          <Button
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            disabled={
              props.isEditMode
                ? editEventState.status === EventStatus.Completed
                : isSubmitting || event.isLoading
            }
            type="submit"
            onClick={() => {
              setFieldValue("submitWithTemplate", false);
            }}
          >
            {`${trainingType} speichern`}
          </Button>
        </span>
      </Tooltip>
    </>
  );
};

export default CreateOrUpdateButton;
