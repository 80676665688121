import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Form, Formik } from "formik";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LinkAccountStatus,
  mapLinkStatusIdToLinkStatusString,
} from "../../../../models/linkAccountData";
import { AppState } from "../../../../redux";
import { showDialog } from "../../../../redux/dialogs/actions";
import {
  clearVerifyLanr,
  deleteLinkAccount,
  getLinkedAccounts,
  linkLanr,
  verifyLanr,
} from "../../../../redux/linkAccount/actions";
import CustomTextField from "../../../forms/CustomTextField";
import "./Combine.scss";
import combineValidationSchema from "./combineValidationSchema";

const Combine: React.FC = () => {
  const dispatch = useDispatch();
  const linkAccount = useSelector((state: AppState) => state.linkAccount);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  useEffect(() => {
    if (!linkAccount.linkAccountListLoaded && !linkAccount.isLoading) {
      dispatch(getLinkedAccounts());
    }
  }, [linkAccount.linkAccountListLoaded, linkAccount.isLoading, dispatch]);

  return (
    <>
      <Formik
        onSubmit={(data) => {
          if (!linkAccount.verifiedLanr) {
            dispatch(verifyLanr(data.lanrSearch));
          } else {
            dispatch(linkLanr(data.lanrSearch));
          }
        }}
        enableReinitialize
        validationSchema={combineValidationSchema}
        initialValues={{
          lanrSearch: "",
          validateLanr: false,
          linkLanrRequest: false,
        }}
      >
        {({ values, setFieldValue, submitCount }) => (
          <Form>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                Hier können Sie Ihr Benutzerkonto mit anderen LANR-Konten
                verknüpfen, um für den Kontoinhaber des verknüpften Kontos
                Veranstaltungen zu buchen.
              </Grid>
              <Grid item xs={12}>
                <b>Konto verknüpfen</b>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  disabled={linkAccount.verifiedLanr}
                  autoComplete="lanr_search"
                  name="lanrSearch"
                  label="LANR Suche"
                />
              </Grid>

              {!linkAccount.verifiedLanr ? (
                linkAccount.verifyLanr ? (
                  <Grid item xs={12} sm={4} style={{ margin: "auto 0" }}>
                    <Box display="flex" alignItems="center">
                      <CircularProgress style={{ marginRight: "10px" }} />{" "}
                      Überprüfe LANR...
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4} style={{ marginTop: "7px" }}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={values.lanrSearch === ""}
                      fullWidth
                      type="submit"
                    >
                      LANR überprüfen
                    </Button>
                  </Grid>
                )
              ) : !linkAccount.linkLanrRequestSuccess ? (
                linkAccount.linkLanrRequest ? (
                  <Grid item xs={12} sm={4} style={{ margin: "auto 0" }}>
                    <Box display="flex" alignItems="center">
                      <CircularProgress style={{ marginRight: "10px" }} />{" "}
                      Anfrage zur Verknüpfung wird gesendet...
                    </Box>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                      >
                        LANR verknüpfen
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Button
                        size="large"
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => {
                          dispatch(clearVerifyLanr());
                        }}
                      >
                        Abbrechen
                      </Button>
                    </Grid>
                    {linkAccount.verifiedLanr && (
                      <>
                        <Grid item sm={12} md={6}></Grid>
                        <Grid
                          item
                          sm={12}
                          md={6}
                          style={{ marginTop: "-0.75em" }}
                        >
                          <small>
                            Bitte beachten Sie: erst mit einer bestätigten
                            Anfrage wird die Verknüpfung aktiviert.
                          </small>
                        </Grid>
                      </>
                    )}
                  </>
                )
              ) : (
                <>
                  <Grid item xs={12} sm={3} style={{ margin: "auto 0" }}>
                    Anfrage erfolgreich verschickt
                  </Grid>
                  <Grid item xs={12} sm={3} style={{ margin: "auto 0" }}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        setFieldValue("lanrSearch", "");
                        dispatch(clearVerifyLanr());
                      }}
                    >
                      Neue Anfrage
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
      <Grid container className={"link-account-list-header"}>
        <Grid item xs={12}>
          <h3>Verknüpfte Konten</h3>
        </Grid>
        <Grid item xs={5}>
          Name
        </Grid>
        <Grid item xs={3}>
          LANR
        </Grid>
        <Grid item xs={2}>
          Status
        </Grid>
        <Grid item xs={2}>
          Löschen
        </Grid>
      </Grid>

      {linkAccount.linkAccountList.length === 0 ? (
        <Grid item xs={12} style={{ marginTop: "30px" }}>
          {!linkAccount.linkAccountListLoaded && linkAccount.isLoading ? (
            <Box display="flex" alignItems="center">
              <CircularProgress style={{ marginRight: "10px" }} /> Lade
              verknüpfte Konten...
            </Box>
          ) : (
            "Keine verknüpften Accounts vorhanden"
          )}
        </Grid>
      ) : (
        linkAccount.linkAccountList.map((linkAccount, index) => {
          return (
            <React.Fragment key={index}>
              <Grid container className={"link-account-list-item"}>
                <Grid
                  item
                  xs={5}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {linkAccount.first_name} {linkAccount.last_name}
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {!linkAccount.lanr || linkAccount.lanr.includes("FL")
                    ? "keine LANR"
                    : linkAccount.lanr}
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {mapLinkStatusIdToLinkStatusString(linkAccount.status)}
                </Grid>
                <Grid item xs={2}>
                  {linkAccount.status === LinkAccountStatus.Linked ? (
                    <DeleteIcon
                      className={
                        accessibility.monoMode
                          ? "preview-task-list-item-delButton-mono"
                          : "preview-task-list-item-delButton"
                      }
                      onClick={() => {
                        // only destroy if the account is linked
                        if (linkAccount.status === LinkAccountStatus.Linked) {
                          dispatch(
                            showDialog({
                              title: "Verknüpften Account löschen",
                              message:
                                "Sind Sie sicher, dass Sie den verknüpften Account löschen möchten?",
                              action: () => {
                                dispatch(deleteLinkAccount(linkAccount.guid));
                              },
                            })
                          );
                        }
                      }}
                    />
                  ) : (
                    <DeleteIcon
                      className={
                        accessibility.monoMode
                          ? "preview-task-list-item-delButton-mono"
                          : "documents-list-item-delButtonDisabled"
                      }
                    />
                  )}
                </Grid>
              </Grid>
              <Divider />
            </React.Fragment>
          );
        })
      )}
    </>
  );
};

export default Combine;
