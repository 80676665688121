import { Grid } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import * as React from "react";
import { useState } from "react";
import { mapToParticipationStatusesString } from "../../../models/enums/participationStatus.enum";
import { ActivitiyHistory } from "../../../models/mailHistory";
import CustomDatepicker from "../../forms/CustomDatepicker";
import CustomSearchField from "../../forms/CustomSearchField";
import CustomMultiSelect from "../../forms/selects/CustomMultiSelect";
import { SelectData } from "../../forms/selects/selectData";

interface ActivityHistoryFilterData {
  public_id?: string;
  name?: string;
  status?: string[];
  locations?: string[];
  begin_gte?: string;
  begin_lte?: string;
  sent_gte?: string;
  sent_lte?: string;
}

interface ActivityHistoryFilterFormProps {
  statistics: ActivitiyHistory;
  updateStatistics: (stats: ActivitiyHistory) => void;
  isParticipationsTab: boolean;
}

const ActivityHistoryFilterForm: React.FC<ActivityHistoryFilterFormProps> = (
  props
) => {
  const allStats: ActivitiyHistory = useState<ActivitiyHistory>(
    props.statistics
  )[0];
  const [locations, setLocations] = useState<string[]>([]),
    [stati, setStati] = useState<string[]>([]);
  const [publicId, setPublicId] = useState<string>(""),
    [name, setName] = useState<string>("");
  const [sentFrom, setSentFrom] = useState<MaterialUiPickersDate>(null),
    [sentTo, setSentTo] = useState<MaterialUiPickersDate>(null);
  const [beginFrom, setBeginFrom] = useState<MaterialUiPickersDate>(null),
    [beginTo, setBeginTo] = useState<MaterialUiPickersDate>(null);

  const defaultLocationOptions: string[] = Array.from(
      new Set(
        allStats.participations
          .map((participation) => participation.event.location)
          .filter((location) => location.trim() !== "")
      )
    ),
    defaultStatusOptions: string[] = Array.from(
      new Set(
        allStats.participations
          .map((participation) =>
            mapToParticipationStatusesString(participation.status)
          )
          .filter((status) => status.trim() !== "")
      )
    );
  const locationOptions: SelectData[] = defaultLocationOptions.map(
      (location) => {
        return {
          key: location,
          value: location,
          label: location,
        };
      }
    ),
    statusOptions: SelectData[] = defaultStatusOptions.map((status) => {
      return {
        key: status,
        value: status,
        label: status,
      };
    });

  const filterStats = (
    allStats: ActivitiyHistory,
    publicId: string,
    name: string,
    location: string[],
    status: string[],
    beginGte: MaterialUiPickersDate,
    beginLte: MaterialUiPickersDate,
    sentGte: MaterialUiPickersDate,
    sentLte: MaterialUiPickersDate
  ) => {
    const participations = allStats.participations.filter(
      (participation) =>
        (publicId === ""
          ? participation.event.public_id > 0
          : participation.event.public_id.toString() === publicId) &&
        participation.event.title.includes(name) &&
        (location.length === 0 ||
          location.includes(participation.event.location)) &&
        (status.length === 0 ||
          status.includes(
            mapToParticipationStatusesString(participation.status)
          )) &&
        (beginGte === null ||
          moment(participation.overall_begin).isSameOrAfter(beginGte, "day")) &&
        (beginLte === null ||
          moment(participation.overall_begin).isSameOrBefore(beginLte, "day"))
    );
    const mail_history = allStats.mail_history.filter(
      (mail) =>
        (publicId === ""
          ? mail.mail_sent_for_event !== null &&
            mail.mail_sent_for_event.public_id > 0
          : mail.mail_sent_for_event !== null &&
            mail.mail_sent_for_event.public_id.toString() === publicId) &&
        mail.mail_sent_for_event !== null &&
        mail.mail_sent_for_event.title.includes(name) &&
        (location.length === 0 ||
          location.includes(mail.mail_sent_for_event.location)) &&
        (sentGte === null ||
          moment(mail.sent_date).isSameOrAfter(sentGte, "day")) &&
        (sentLte === null ||
          moment(mail.sent_date).isSameOrBefore(sentLte, "day"))
    );
    props.updateStatistics({
      ...props.statistics,
      participations,
      mail_history,
    });
  };

  const filterPublicId = (value: string) => {
      setPublicId(value);
      filterStats(
        allStats,
        value,
        name,
        locations,
        stati,
        beginFrom,
        beginTo,
        sentFrom,
        sentTo
      );
    },
    filterName = (term: string) => {
      setName(term);
      filterStats(
        allStats,
        publicId,
        term,
        locations,
        stati,
        beginFrom,
        beginTo,
        sentFrom,
        sentTo
      );
    };
  const filterLocation = (values: string[]) => {
      setLocations(values);
      filterStats(
        allStats,
        publicId,
        name,
        values,
        stati,
        beginFrom,
        beginTo,
        sentFrom,
        sentTo
      );
    },
    filterStatus = (values: string[]) => {
      setStati(values);
      filterStats(
        allStats,
        publicId,
        name,
        locations,
        values,
        beginFrom,
        beginTo,
        sentFrom,
        sentTo
      );
    };

  return (
    <Formik
      onSubmit={(data: ActivityHistoryFilterData) => {}}
      initialValues={{}}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={12} md={2}>
              <CustomSearchField
                name="public_id"
                id="public_id"
                label="Kurs-Nr."
                customChangeHandler={(value) => {
                  setFieldValue("public_id", value);
                  filterPublicId(value);
                }}
                hideEndAdornment={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomSearchField
                name="name"
                id="name"
                label="Titel"
                customChangeHandler={(term) => {
                  setFieldValue("name", term);
                  filterName(term);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomMultiSelect
                name="location"
                id="activity-history-location-multiselect"
                label="Veranstaltungsort"
                labelId="activity-history-location-multiselect-label"
                defaultOptions={locations}
                hasCustomChangeHandler={true}
                customChangeHandler={(changedMultiSelectValues) => {
                  filterLocation(changedMultiSelectValues as string[]);
                }}
                options={locationOptions}
              />
            </Grid>

            {props.isParticipationsTab ? (
              <>
                <Grid item xs={12} sm={4}>
                  <Field
                    as={CustomDatepicker}
                    fullWidth
                    id="begin_gte"
                    label="Fortbildungsstart von"
                    name="begin_gte"
                    customChangeHandler={(date: MaterialUiPickersDate) => {
                      let begin_lte = beginTo;
                      setFieldValue("begin_gte", date);
                      if (
                        values.begin_lte &&
                        moment(values.begin_lte).isBefore(date, "day")
                      ) {
                        setFieldValue("begin_lte", date);
                        begin_lte = date;
                      }
                      setSentFrom(date);
                      setSentTo(begin_lte);
                      filterStats(
                        allStats,
                        publicId,
                        name,
                        locations,
                        stati,
                        date,
                        begin_lte,
                        sentFrom,
                        sentTo
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    as={CustomDatepicker}
                    fullWidth
                    id="begin_lte"
                    label="Fortbildungsstart bis"
                    name="begin_lte"
                    customChangeHandler={(date: MaterialUiPickersDate) => {
                      let begin_gte = beginFrom;
                      setFieldValue("begin_lte", date);
                      if (
                        values.begin_gte &&
                        moment(values.begin_gte).isAfter(date, "day")
                      ) {
                        setFieldValue("begin_gte", date);
                        begin_gte = date;
                      }
                      setBeginTo(date);
                      setBeginFrom(begin_gte);
                      filterStats(
                        allStats,
                        publicId,
                        name,
                        locations,
                        stati,
                        begin_gte,
                        date,
                        sentFrom,
                        sentTo
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomMultiSelect
                    name="status"
                    id="activity-history-status-multiselect"
                    label="Status"
                    labelId="activity-history-status-multiselect-label"
                    defaultOptions={stati}
                    hasCustomChangeHandler={true}
                    customChangeHandler={(changedMultiSelectValues) => {
                      filterStatus(
                        (changedMultiSelectValues as string[]).filter((value) =>
                          isNaN(parseInt(value))
                        )
                      );
                    }}
                    options={statusOptions}
                    style={{ marginBottom: "-5px" }}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item sm={4}>
                  <Field
                    as={CustomDatepicker}
                    fullWidth
                    id="sent_gte"
                    label="Versanddatum von"
                    name="sent_gte"
                    customChangeHandler={(date: MaterialUiPickersDate) => {
                      let sent_lte = sentTo;
                      setFieldValue("sent_gte", date);
                      if (
                        values.sent_lte &&
                        moment(values.sent_lte).isBefore(date, "day")
                      ) {
                        setFieldValue("sent_lte", date);
                        sent_lte = date;
                      }
                      setSentFrom(date);
                      setSentTo(sent_lte);
                      filterStats(
                        allStats,
                        publicId,
                        name,
                        locations,
                        stati,
                        beginFrom,
                        beginTo,
                        date,
                        sent_lte
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    as={CustomDatepicker}
                    fullWidth
                    id="sent_lte"
                    label="Versanddatum bis"
                    name="sent_lte"
                    customChangeHandler={(date: MaterialUiPickersDate) => {
                      let sent_gte = sentFrom;
                      setFieldValue("sent_lte", date);
                      if (
                        values.sent_gte &&
                        moment(values.sent_gte).isAfter(date, "day")
                      ) {
                        setFieldValue("sent_gte", date);
                        sent_gte = date;
                      }
                      setSentTo(date);
                      setSentFrom(sent_gte);
                      filterStats(
                        allStats,
                        publicId,
                        name,
                        locations,
                        stati,
                        beginFrom,
                        beginTo,
                        sent_gte,
                        date
                      );
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ActivityHistoryFilterForm;
