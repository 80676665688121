import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { ParticipantListData } from "../models/participantData";
import { API_URL, CURRENT_API_VERSION } from "./apiConfig";

export class ParticipantsService extends BaseCrudService {
  async getParticipant(eventId: string) {
    const response = await this.getAll<ParticipantListData[]>(
      apiRoutes.events + "/" + eventId + apiRoutes.participants
    );
    return response.data;
  }

  async editParticipantStatus(
    eventId: string,
    participantGuid: string,
    participantStatus: number
  ) {
    const response = await this.edit(
      apiRoutes.events + "/" + eventId + apiRoutes.participants,
      participantGuid.toString(),
      { status: participantStatus }
    );
    return response;
  }

  async bulkEditParticipantStatus(
    eventId: string,
    participantIds: number[],
    status: number
  ) {
    return Promise.all(
      participantIds.map(async (id) => {
        const response = await this.edit(
          apiRoutes.events + "/" + eventId + apiRoutes.participants,
          id.toString(),
          { status: status }
        );
        return response;
      })
    );
  }

  async editReactivated(
    eventId: string,
    participantGuid: string,
    isReactivated: boolean
  ) {
    const response = await this.edit(
      apiRoutes.events + "/" + eventId + apiRoutes.participants,
      participantGuid.toString(),
      { reactivated: isReactivated }
    );
    return response;
  }

  async sendEmailToParticipants(eventId: string, message: string) {
    const response = await this.create<object>(
      apiRoutes.events +
        "/" +
        eventId +
        apiRoutes.participants +
        apiRoutes.contact,
      { message: message }
    );
    return response;
  }

  getCSV(eventId: String) {
    window.location.href =
      API_URL +
      CURRENT_API_VERSION +
      apiRoutes.events +
      "/" +
      eventId +
      apiRoutes.participants_csv;
  }
}
