import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useSelector } from "react-redux";
import { KvhUserSvg } from "../../../../assets/icons/custom-svg-components/KvhSvgCollection";
import { mapToUserTypeString } from "../../../../models/enums/userType.enum";
import { AppState } from "../../../../redux";
import "./Profile.scss";
import { useHistory } from "react-router";
import routes from "../../../routing/routes";
import usePermission from "../../../../services/usePermissions";
import { FavoriteStar } from "./FavoriteStar";

export const Profile: React.FC = () => {
  const userData = useSelector((state: AppState) => state.user.currentUser);

  const { isParticipant } = usePermission(userData?.user_type);

  const history = useHistory();

  return (
    <Box display="flex" alignItems="center">
      {isParticipant() && <FavoriteStar />}
      <Box mx={2} my={1}>
        <Typography>
          <Box component={"span"} className={"header-profile-role-text"}>
            {mapToUserTypeString(userData?.user_type)}
          </Box>
          <br />
          <Box component={"span"} className={"header-profile-role-name"}>
            {userData?.fullname}
          </Box>
        </Typography>
      </Box>
      <Box
        mb={1}
        className={"header-profile-role-picture"}
        onClick={() => {
          history.push(routes.settings);
        }}
      >
        <KvhUserSvg />
      </Box>
    </Box>
  );
};
