import { UserType } from "../models/enums/userType.enum";
import { ActivitiyHistory } from "../models/mailHistory";
import { MoodleBaseData, UserData } from "../models/userData";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { AuthenticationStatus } from "./authStatus";

export class UsersService extends BaseCrudService {
  // was moved into userService because of the session is tied to the user
  async checkCurrentSession() {
    const response = await this.getAll<AuthenticationStatus>(apiRoutes.session);
    return response;
  }
  async getCurrentUser() {
    const response = await this.getAll<UserData>(apiRoutes.user);
    return response.data;
  }

  async getUsers(
    search: string | null,
    user_type: UserType | null,
    eventId: string
  ) {
    var data = {};
    if (search) data = { search: search };
    if (user_type) data = { ...data, user_type: user_type, event: eventId };

    const response = await this.getAll<UserData[]>(apiRoutes.users, data);
    return response.data;
  }

  async getUser(guid: string) {
    const response = await this.get<UserData>(apiRoutes.users, guid.toString());
    return response.data;
  }

  async getStatistics(filter: string) {
    const response = await this.getAll<ActivitiyHistory[]>(
      apiRoutes.statistics + filter
    );
    return response.data;
  }

  async getStatistic(guid: string) {
    const response = await this.get<ActivitiyHistory>(
      apiRoutes.statistics,
      guid.toString()
    );
    return response.data;
  }

  async fetchMoodleData(guid: string) {
    const response = await this.get<MoodleBaseData>(
      `${apiRoutes.events}`,
      `${guid}${apiRoutes.moodle_data}`
    );
    return response.data;
  }

  async loginUserInMoodle(guid: string) {
    const response = await this.get<{}>(
      `${apiRoutes.events}`,
      `${guid}${apiRoutes.moodle_login}`
    );
    return response;
  }
}
