import { useState, useEffect } from "react";
import { pinboardNoAlertService } from "../../api";

const usePinboardActivated = (
  eventId: string,
  fetchPinboardIsActive = true
) => {
  const [isPinboardActivated, setIsPinboardActivated] = useState(false);

  useEffect(() => {
    if (eventId !== "" && fetchPinboardIsActive) {
      pinboardNoAlertService
        .getPinboardIsActivated(eventId)
        .then((response) => {
          if (response.length === 0) {
            setIsPinboardActivated(false);
          } else {
            setIsPinboardActivated(true);
          }
        })
        .catch(() => {
          setIsPinboardActivated(true);
        });
    }
  }, [eventId, fetchPinboardIsActive]);

  return isPinboardActivated;
};

export default usePinboardActivated;
