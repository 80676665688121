import { SET_OPEN_TAB, TabsActionTypes, TabsState } from "./types";

export const initialTabsState: TabsState = {
  openTab: 0,
};

export function tabsReducer(
  state = initialTabsState,
  action: TabsActionTypes
): TabsState {
  switch (action.type) {
    case SET_OPEN_TAB:
      return {
        ...state,
        openTab: action.tab,
      };
    default:
      return state;
  }
}

export default tabsReducer;
