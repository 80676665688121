import React from "react";
import { Box, Collapse, Grid, Link } from "@material-ui/core";
import { CustomSelectArrow } from "../../../../../../assets/icons/custom-svg-components/KvhSvgCollection";
import DOMPurify from "dompurify";
import { Utils } from "../../../../../../services/utils";
import "./Pinboard.scss";

interface PinboardTextProps {
  content: string;
  float?: "left" | "right";
}

export const PinboardText: React.FC<PinboardTextProps> = (props) => {
  const [checked, setChecked] = React.useState(false);
  const direction = props.float ? props.float : "left";

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  let html = DOMPurify.sanitize(
    Utils.convertRteStateToHtml(Utils.convertJsonToRteState(props.content))
  );

  return html === "<p><br></p>" ? (
    <></>
  ) : (
    <>
      <Collapse in={checked} collapsedHeight={100}>
        <Box
          style={{ margin: "10px", float: direction }}
          dangerouslySetInnerHTML={{
            __html: html.replaceAll(
              /<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/g,
              "<a $1 onclick=\"if (!this.href.includes('kvhessen.de')) return confirm('" +
                "Hinweis: Externe Links unterliegen der Haftung der jeweiligen Betreiber.\\n\\n" +
                "Möchten Sie folgende Webseite öffnen?\\n' + this.href)" +
                '" target="_blank">$2</a>'
            ),
          }}
        />
      </Collapse>

      <Grid container style={{ marginBottom: props.float ? "-20px" : "-30px" }}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Link
              onClick={handleChange}
              className={"pinboard-post-details-icon"}
            >
              {checked ? (
                <CustomSelectArrow className={"pinboard-post-shrink-icon"} />
              ) : (
                <CustomSelectArrow className={"pinboard-post-expand-icon"} />
              )}
            </Link>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PinboardText;
