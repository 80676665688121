import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Switch,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Alert } from "@material-ui/lab";
import { Form, Formik } from "formik";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { CheckoutFormData } from "../../../../models/billingAddressData";
import { CartData } from "../../../../models/cartData";
import { TrainingType } from "../../../../models/enums/trainingType.enum";
import { AppState } from "../../../../redux";
import {
  createBillingAddress,
  editBillingAddress,
  getBillingAddresses,
  getBillingAddressesForUser,
} from "../../../../redux/billingAddress/actions";
import { bookEvent } from "../../../../redux/bookings/actions";
import { getCart } from "../../../../redux/cart/actions";
import { showDialog } from "../../../../redux/dialogs/actions";
import {
  getParticipantsTabBasedOnTrainingType,
  setOpenTab,
} from "../../../../redux/tabs/actions";
import usePermission from "../../../../services/usePermissions";
import {
  PRIVACY_URL,
  TERMS_AND_CONDITIONS_URL,
} from "../../../routing/externalUrls";
import routes, {
  getEditRouteBasedOnTrainingType,
} from "../../../routing/routes";
import HeadingKvh from "../../../theming/HeadingKvh";
import Loader from "../../../theming/loader/Loader";
import billingAddressValidationSchema from "../../profile-settings/participant/billingAddressValidationSchema";
import CartList from "../CartList";
import EmptyCartButton from "../EmptyCartButton";
import BillingAddress from "./BillingAddress";
import "./Checkout.scss";

const Checkout: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector((state: AppState) => state.cart);
  const booking = useSelector((state: AppState) => state.booking);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const billingAddress = useSelector((state: AppState) => state.billingAddress);
  const isUserLoggedIn = useSelector((state: AppState) => state.auth.loggedIn);
  const { isStaff } = usePermission(currentUser?.user_type);

  const [ATBandPrivacy, setATBandPrivacy] = React.useState(false);
  const [
    useAlternativeBillingAddress,
    setUseAlternativeBillingAddress,
  ] = React.useState(false);
  const [submitCount, setSubmitCount] = React.useState(0);
  const [bookForOtherUser, setBookForOtherUser] = React.useState(false);

  useEffect(() => {
    if (!cart.cartInitialLoaded && isUserLoggedIn) {
      dispatch(getCart());
    }
  }, [cart.cartInitialLoaded, dispatch, isUserLoggedIn]);

  useEffect(() => {
    if (cart.cartList.length > 0) {
      if (
        cart.cartList[0].book_for.guid !== currentUser?.guid ||
        (cart.cartList[0].book_for.guid !== currentUser?.guid &&
          cart.cartList[0].book_for.guid !== billingAddress.linkedUser)
      ) {
        setBookForOtherUser(true);
        const userId = cart.cartList[0].book_for.guid;
        if (
          (!billingAddress.isSending && !billingAddress.billingAddressLoaded) ||
          cart.cartList[0].book_for.guid !== billingAddress.linkedUser
        ) {
          dispatch(getBillingAddressesForUser(userId));
        }
      } else if (
        cart.cartList[0].book_for.guid === currentUser?.guid ||
        billingAddress.linkedUser === ""
      ) {
        setBookForOtherUser(false);
        if (!billingAddress.isSending && !billingAddress.billingAddressLoaded) {
          dispatch(getBillingAddresses());
        }
      }
    }
  }, [
    dispatch,
    billingAddress.billingAddressLoaded,
    billingAddress.isSending,
    cart.cartList,
    currentUser?.guid,
    billingAddress.linkedUser,
  ]);

  const checkIfCateringIsSelected = (cartList: CartData[]) => {
    let allCateringSelected: boolean = true;
    cartList.forEach((cartItem) => {
      if (cartItem.catering && cartItem.catering_option === undefined) {
        allCateringSelected = false;
      }
    });
    return allCateringSelected;
  };

  const submitBooking = (
    values: CheckoutFormData,
    ignoreMaxSeats: boolean = false
  ) => {
    setSubmitCount(submitCount + 1);
    if (ATBandPrivacy && checkIfCateringIsSelected(cart.cartList)) {
      dispatch(
        bookEvent(
          cart.cartList,
          values.useAlternativeBillingAddress,
          bookForOtherUser,
          ignoreMaxSeats
        )
      );
    }
  };

  return (
    <>
      <HeadingKvh>Checkout</HeadingKvh>
      {cart.cartList.length > 0 ? (
        <Formik
          onSubmit={(data: CheckoutFormData) => {
            setUseAlternativeBillingAddress(data.useAlternativeBillingAddress);
            if (bookForOtherUser) {
              dispatch(
                editBillingAddress(
                  billingAddress.billingAddressOfLinkedUser
                    .additional_billing_address.guid,
                  data
                )
              );
            } else {
              if (billingAddress.billingAddressList.length > 0) {
                dispatch(
                  editBillingAddress(
                    billingAddress.billingAddressList[0].guid,
                    data
                  )
                );
              } else {
                dispatch(createBillingAddress(data));
              }
            }
          }}
          enableReinitialize
          validationSchema={billingAddressValidationSchema}
          initialValues={
            bookForOtherUser
              ? {
                  ...billingAddress.billingAddressOfLinkedUser
                    .additional_billing_address,
                  useAlternativeBillingAddress: useAlternativeBillingAddress,
                }
              : billingAddress.billingAddressList.length > 0
              ? {
                  ...billingAddress.billingAddressList[0],
                  useAlternativeBillingAddress: useAlternativeBillingAddress,
                }
              : ({
                  medical_office_or_company: "",
                  medical_office_or_company_extra: "",
                  first_name: "",
                  last_name: "",
                  address: "",
                  street_address: "",
                  zip_code: "",
                  city: "",
                  phone: "",
                  email: "",
                  useAlternativeBillingAddress: false,
                } as CheckoutFormData)
          }
        >
          {({ values }) => (
            <Form>
              <BillingAddress
                bookForUser={cart.cartList[0].book_for}
                bookForOtherUser={bookForOtherUser}
                billingAddress={
                  bookForOtherUser
                    ? billingAddress.billingAddressOfLinkedUser
                        .additional_billing_address
                    : billingAddress.billingAddressList.length > 0
                    ? billingAddress.billingAddressList[0]
                    : null
                }
                address={
                  bookForOtherUser
                    ? billingAddress.billingAddressOfLinkedUser.address
                    : undefined
                }
              />
              <Box my={3}>
                <Divider />
              </Box>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <h3>
                    {bookForOtherUser
                      ? "Gewählte Veranstaltungen"
                      : "Ihre gewählten Veranstaltungen"}
                  </h3>
                </Grid>
              </Grid>
              <CartList checkout={true} />
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Box component="div" className={"checkout-atb-box"}>
                    Ich habe die{" "}
                    <Link
                      href={TERMS_AND_CONDITIONS_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ATB
                    </Link>{" "}
                    und die{" "}
                    <Link
                      href={PRIVACY_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Datenschutzerklärung
                    </Link>{" "}
                    gelesen und akzeptiere sie
                    <Switch
                      checked={ATBandPrivacy}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setATBandPrivacy(event.target.checked);
                      }}
                      color="primary"
                      name="ATBandPrivacyCheck"
                      inputProps={{ "aria-label": "ATB and Privacy checkbox" }}
                    />
                  </Box>
                  {!ATBandPrivacy && submitCount > 0 && (
                    <Box my={2}>
                      <Alert severity="error">
                        Bitte akzeptieren Sie unsere ATB und
                        Datenschutzrichtlinien.
                      </Alert>
                    </Box>
                  )}
                  {!checkIfCateringIsSelected(cart.cartList) &&
                    submitCount > 0 && (
                      <Box my={2}>
                        <Alert severity="error">
                          Bitte wählen Sie für alle Veranstaltungen mit Catering
                          eine Option.
                        </Alert>
                      </Box>
                    )}
                </Grid>
                <Grid item xs={12}>
                  <Box
                    component={"div"}
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                    className={"shopping-cart-bookingbutton-container"}
                  >
                    <EmptyCartButton cartList={cart.cartList} />
                    <Button
                      size="medium"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        if (isStaff()) {
                          const trainingType = cart.cartList[0].training_type;
                          const eventId = cart.cartList[0].guid;
                          history.push(
                            `${getEditRouteBasedOnTrainingType(
                              trainingType
                            )}/${eventId}`
                          );
                          dispatch(
                            setOpenTab(
                              getParticipantsTabBasedOnTrainingType(
                                trainingType
                              )
                            )
                          );
                        } else {
                          history.push(routes.home);
                        }
                      }}
                      style={{ marginRight: "20px" }}
                    >
                      Zurück
                    </Button>
                    {booking.isLoading && submitCount > 0 ? (
                      <Box display="flex" alignItems="center">
                        <CircularProgress style={{ marginRight: "10px" }} />{" "}
                        Buche Veranstaltung{cart.cartList.length > 1 && "en"}...
                      </Box>
                    ) : (
                      <Button
                        size="medium"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          const eLearningEvents = cart.cartList.filter(
                            (cartItem) =>
                              cartItem.training_type === TrainingType.ELearning
                          );
                          if (eLearningEvents.length > 0) {
                            dispatch(
                              showDialog({
                                title:
                                  "Wichtiger Hinweis zur Buchung von E-Learnings",
                                message:
                                  "Bitte beachten Sie, dass E-Learnings von der Stornierung ausgeschlossen sind. Möchten Sie verbindlich buchen?",
                                action: () => {
                                  submitBooking(values);
                                },
                              })
                            );
                          } else {
                            bookForOtherUser
                              ? submitBooking(values, true)
                              : submitBooking(values);
                          }
                        }}
                        disabled={cart.cartList.length === 0 || !ATBandPrivacy}
                      >
                        {bookForOtherUser
                          ? `Jetzt für ${cart.cartList[0].book_for.first_name} ${cart.cartList[0].book_for.last_name} buchen`
                          : "Jetzt buchen"}
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          {cart.isLoading ? (
            <Loader />
          ) : (
            "Es befinden sich keine Veranstaltungen im Warenkorb."
          )}
        </>
      )}
    </>
  );
};

export default Checkout;
