import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { elearningService } from "../../api";
import { history } from "../../components/routing/history";
import routes from "../../components/routing/routes";
import { error, success } from "../alerts/actions";
import { AlertActionTypes, ErrorAction } from "../alerts/types";
import { clearDocuments } from "../documents/actions";
import { ClearDocumentsAction } from "../documents/types";
import {
  clearEditEvent,
  clearEventList,
  setEditEventSuccess,
} from "../events/actions";
import { ClearEventListAction } from "../events/types";
import { clearParticipant } from "../participants/actions";
import { ClearParticipantAction } from "../participants/types";
import { clearSpeakers } from "../speaker/actions";
import { ClearSpeakersAction } from "../speaker/types";
import { ELearningData, ELearningDto } from "./../../models/elearning";
import { SuccessAction } from "./../alerts/types";
import { EventActionTypes } from "./../events/types";
import {
  AddELearningSuccessAction,
  ADD_ELEARNING_SUCCESS,
  ELearningActionTypes,
  ELearningFailureAction,
  ELearningFetchingFinishedAction,
  ELearningRequestAction,
  ELEARNING_FAILURE,
  ELEARNING_REQUEST,
  FINISH_FETCHING_ELEARNING,
} from "./types";

export function getELearning(guid: string) {
  return (
    dispatch: Dispatch<
      | ELearningRequestAction
      | ELearningFailureAction
      | ELearningFetchingFinishedAction
      | EventActionTypes
      | SuccessAction
      | ErrorAction
    >
  ) => {
    dispatch(elearningRequest());

    elearningService
      .getElearning(guid)
      .then((response) => {
        dispatch(elearningfinishedFetching());
        // TODO: maybe use own state for retrieving elearning?
        dispatch(setEditEventSuccess(response));
      })
      .catch((err: AxiosError) => {
        dispatch(elearningFailure());
        if (err.response?.status === 404) {
          dispatch(
            error(
              {
                message: "Das gesuchte E-Learning existiert nicht.",
              },
              500
            )
          );
        }
      });
  };
}

export function addELearning(elearning: ELearningData) {
  return (
    dispatch: Dispatch<
      | ClearEventListAction
      | ELearningActionTypes
      | ClearSpeakersAction
      | ClearDocumentsAction
      | AlertActionTypes
    >
  ) => {
    dispatch(elearningRequest());
    elearningService
      .addELearning(elearning)
      .then((response) => {
        dispatch(addELearningSuccess(response.data));
        dispatch(
          success(
            {
              message: "Das E-Learning wurde erfolgreich angelegt.",
            },
            3000
          )
        );
        dispatch(clearSpeakers());
        dispatch(clearDocuments());
        dispatch(clearEventList());
        history.push(routes.events); //load start page
      })
      .catch(() => {
        dispatch(elearningFailure());
      });
  };
}

export function getBlendedLearning(id: string) {
  return (
    dispatch: Dispatch<
      | ELearningRequestAction
      | ELearningFailureAction
      | ELearningFetchingFinishedAction
      | EventActionTypes
      | SuccessAction
      | ErrorAction
    >
  ) => {
    dispatch(elearningRequest());

    elearningService
      .getBlendedlearning(id)
      .then((response) => {
        dispatch(elearningfinishedFetching());
        dispatch(setEditEventSuccess(response));
      })
      .catch((err: AxiosError) => {
        dispatch(elearningFailure());
        if (err.response?.status === 404) {
          dispatch(
            error(
              {
                message: "Das gesuchte Blended-Learning existiert nicht.",
              },
              3000
            )
          );
        }
      });
  };
}

export function addBlendedLearning(blendedLearning: ELearningData) {
  return (
    dispatch: Dispatch<
      | ClearEventListAction
      | ELearningActionTypes
      | ClearSpeakersAction
      | ClearDocumentsAction
      | AlertActionTypes
    >
  ) => {
    dispatch(elearningRequest());
    elearningService
      .addBlendedLearning(blendedLearning)
      .then((response) => {
        dispatch(addELearningSuccess(response.data));
        dispatch(
          success(
            {
              message: "Das Blended-Learning wurde erfolgreich angelegt.",
            },
            3000
          )
        );
        dispatch(clearSpeakers());
        dispatch(clearDocuments());
        dispatch(clearEventList());
        history.push(routes.events); //load start page
      })
      .catch(() => {
        dispatch(elearningFailure());
      });
  };
}

export function editELearning(data: ELearningData, id: string) {
  return (
    dispatch: Dispatch<
      | ELearningRequestAction
      | ELearningFailureAction
      | ELearningFetchingFinishedAction
      | EventActionTypes
      | ClearEventListAction
      | ClearSpeakersAction
      | ClearDocumentsAction
      | ClearParticipantAction
      | AlertActionTypes
    >
  ) => {
    dispatch(elearningRequest());
    elearningService
      .editELearning(data, id)
      .then((response) => {
        dispatch(elearningfinishedFetching());
        dispatch(setEditEventSuccess(response.data as ELearningDto));
        dispatch(
          success(
            {
              message: "Das E-Learning wurde erfolgreich bearbeitet.",
            },
            3000
          )
        );
        dispatch(clearEditEvent());
        dispatch(clearSpeakers());
        dispatch(clearDocuments());
        dispatch(clearParticipant());
        dispatch(clearEventList());
        history.push(routes.events); //load start page
      })
      .catch((error: AxiosError) => {
        let throwError = true;
        if (
          error.response?.status === 400 &&
          JSON.stringify(error.response?.data)
            .toLocaleLowerCase()
            .includes("scorm")
        ) {
          throwError = false;
        }
        dispatch(elearningFailure(throwError));
      });
  };
}

export function elearningRequest(): ELearningRequestAction {
  return {
    type: ELEARNING_REQUEST,
  };
}

export function elearningFailure(
  throwError: boolean = true
): ELearningFailureAction {
  return {
    type: ELEARNING_FAILURE,
    throwError: throwError,
  };
}

export function elearningfinishedFetching(): ELearningFetchingFinishedAction {
  return {
    type: FINISH_FETCHING_ELEARNING,
  };
}

export function addELearningSuccess(
  data: ELearningData
): AddELearningSuccessAction {
  return {
    type: ADD_ELEARNING_SUCCESS,
    data,
  };
}
