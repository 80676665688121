import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useFormikContext } from "formik";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SpeakerData, SpeakerBaseData } from "../../../../models/speakerData";
import { AppState } from "../../../../redux";
import {
  AddSpeakerToEventList,
  EditEventSpeakerList,
  editSpeakersLoaded,
  getSpeakers,
  RemoveSpeakerFromEventList,
  searchSpeakers,
  SyncSpeakersSearchList,
} from "../../../../redux/speaker/actions";
import styles from "../../../../styles/custom-styles.module.scss";
import CustomSearchField from "../../../forms/CustomSearchField";
import routes from "../../../routing/routes";
import Loader from "../../../theming/loader/Loader";
import SpeakerRow from "./SpeakerRow";
import "./Speakers.scss";

interface SpeakersViewProps {
  template_mode?: boolean;
}

export const SpeakersView: React.FC<SpeakersViewProps> = (props) => {
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext();

  const speakers = useSelector((state: AppState) => state.speakers);
  const speakersLoaded = useSelector(
    (state: AppState) => state.speakers.speakerListLoaded
  );
  const editEventSpeakers = useSelector(
    (state: AppState) => state.event.editEvent.speakers
  );
  const templateEventSpeakers = useSelector(
    (state: AppState) => state.eventTemplates.eventTemplate.speakers
  );

  const [
    SpeakersAssignedBaseList,
    setSpeakersAssignedBaseList,
  ] = React.useState<SpeakerBaseData[]>([]);

  const filterSpeakerChoices = (
    speakerSearchList: SpeakerData[],
    speakersAssignedBaseList: SpeakerBaseData[]
  ) => {
    return speakerSearchList.filter(
      (value, index, self) =>
        index === self.findIndex((speaker) => speaker.guid === value.guid) &&
        !speakersAssignedBaseList
          .map((speaker) => speaker.guid)
          .includes(value.guid)
    );
  };

  const addSpeakerToEvent = useCallback(
    (speaker: SpeakerData, sync: boolean = false) => {
      SpeakersAssignedBaseList.push({
        guid: speaker.guid,
        is_substitute: speaker.is_substitute,
      });
      setSpeakersAssignedBaseList(SpeakersAssignedBaseList);
      setFieldValue("speakers", SpeakersAssignedBaseList);
      dispatch(AddSpeakerToEventList(speaker));
      if (sync) dispatch(SyncSpeakersSearchList());
    },
    [dispatch, SpeakersAssignedBaseList, setFieldValue]
  );

  const removeSpeakerFromEvent = (speaker: SpeakerData) => {
    let index = speakers.speakerListForEvent.indexOf(speaker);
    SpeakersAssignedBaseList.splice(index, 1);
    setSpeakersAssignedBaseList([...SpeakersAssignedBaseList]);
    setFieldValue("speakers", SpeakersAssignedBaseList);
    dispatch(RemoveSpeakerFromEventList(speaker));
    dispatch(SyncSpeakersSearchList());
  };

  const editSpeakerSubstitute = (speaker: SpeakerData) => {
    let index = speakers.speakerListForEvent.indexOf(speaker);
    SpeakersAssignedBaseList[index] = {
      guid: speaker.guid,
      is_substitute: !speaker.is_substitute,
    };
    setSpeakersAssignedBaseList(SpeakersAssignedBaseList);
    setFieldValue("speakers", SpeakersAssignedBaseList);
    let eventSpeakerList = speakers.speakerListForEvent;
    eventSpeakerList[index] = {
      ...eventSpeakerList[index],
      is_substitute: !speaker.is_substitute,
    };
    dispatch(EditEventSpeakerList(eventSpeakerList));
  };

  useEffect(() => {
    if (!speakersLoaded) {
      dispatch(getSpeakers());
    }

    let eventSpeakers: SpeakerBaseData[] = [];

    if (props.template_mode) {
      eventSpeakers = templateEventSpeakers;
    } else {
      eventSpeakers = editEventSpeakers;
    }

    if (speakersLoaded) {
      if (eventSpeakers.length !== 0 && !speakers.speakerEditListLoaded) {
        eventSpeakers.forEach((speakerBase) => {
          const index = speakers.speakerList
            .map((speaker) => speaker.guid)
            .indexOf(speakerBase.guid);
          if (index > -1) {
            addSpeakerToEvent({
              ...speakers.speakerList[index],
              is_substitute: speakerBase.is_substitute,
            });
          }
        });
        dispatch(editSpeakersLoaded());
      }
    }
  }, [
    dispatch,
    speakers.speakerList,
    speakers.speakerEditListLoaded,
    speakersLoaded,
    addSpeakerToEvent,
    editEventSpeakers,
    templateEventSpeakers,
    props.template_mode,
  ]);

  return (
    <>
      <h3>ZUGEWIESENE REFERENTEN</h3>
      <Box component={"div"} className={"speakers-list-added"}>
        {speakers.speakerListForEvent.length === 0
          ? "Noch keine Referenten zugewiesen"
          : speakers.speakerListForEvent.map((speaker, index) => {
              return (
                <SpeakerRow
                  key={"assigned-speaker-row-" + index}
                  speaker={speaker}
                  action={() => {
                    removeSpeakerFromEvent(speaker);
                  }}
                  Icon={RemoveIcon}
                  customColor={styles["red-accent-1"]}
                  hoverColor={styles["red-accent-2"]}
                  textColor={styles.red}
                  iconColor={"error"}
                  substituteAction={
                    window.location.pathname.includes(routes.templates)
                      ? undefined
                      : () => {
                          editSpeakerSubstitute(speaker);
                        }
                  }
                ></SpeakerRow>
              );
            })}
      </Box>

      <Grid container>
        <Grid item xs={8}>
          <CustomSearchField
            customChangeHandler={(value: string) => {
              dispatch(searchSpeakers(value, speakers.speakerList));
            }}
            name="searchSpeakers"
            id="referent-search"
            label="Name des Referenten"
          />
        </Grid>
      </Grid>
      <Box component={"div"} className={"speakers-list-select"}>
        {filterSpeakerChoices(
          speakers.speakerSearchList,
          SpeakersAssignedBaseList
        ).length === 0 ? (
          speakers.speakerListLoaded ? (
            <Box component={"div"} style={{ padding: "16px" }}>
              Keine weiteren Referenten vorhanden
            </Box>
          ) : (
            <Loader />
          )
        ) : (
          filterSpeakerChoices(
            speakers.speakerSearchList,
            SpeakersAssignedBaseList
          ).map((speaker, index) => {
            return (
              <SpeakerRow
                key={"add-speaker-row-" + index}
                speaker={speaker}
                action={() => {
                  if (
                    !SpeakersAssignedBaseList.map(
                      (speaker) => speaker.guid
                    ).includes(speaker.guid)
                  )
                    addSpeakerToEvent(speaker);
                }}
                Icon={AddIcon}
                customColor={styles["primary"]}
                hoverColor={styles["primary-accent-1"]}
                textColor={"#fff"}
              ></SpeakerRow>
            );
          })
        )}
      </Box>
    </>
  );
};
