import { faTools } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import * as React from "react";
import HeadingKvh from "../theming/HeadingKvh";
import styles from "./../../styles/custom-styles.module.scss";

interface MaintenanceProps {}

const Maintenance: React.FC<MaintenanceProps> = (props) => {
  return (
    <>
      <HeadingKvh>Wartungsarbeiten</HeadingKvh>
      <Box
        minHeight={"40vh"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        style={{ color: styles.secondary }}
      >
        <FontAwesomeIcon
          icon={faTools}
          size="6x"
          color={styles.orange}
          style={{ marginBottom: "0.5em" }}
        />
        <Typography variant="h2" style={{ textAlign: "center" }}>
          <strong>
            Aufgrund von Wartungsarbeiten sind wir vorübergehend nicht
            erreichbar. <br />
            Der Online-Dienst "Veranstaltungsportal" steht Ihnen in Kürze wieder
            zur Verfügung.
          </strong>
        </Typography>
      </Box>
    </>
  );
};

export default Maintenance;
