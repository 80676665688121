import { DocumentData } from "../../models/documentData";

export interface DocumentsState {
  documentList: DocumentData[];
  documentListTemp: DocumentData[]; // is needed to determine which documents are added newly
  documentListLoaded: boolean;
  isLoading: boolean;
  documentsMarkedForDelete: Array<DocumentData>;
}

export const DOCUMENTS_REQUEST = "DOCUMENTS_REQUEST";
export const DOCUMENTS_FAILURE = "DOCUMENTS_FAILURE";

export const ADD_DOCUMENTS_LIST = "ADD_DOCUMENTS_LIST";
export const SET_DOCUMENTS_LIST = "SET_DOCUMENTS_LIST";
export const ADD_DEFAULT_DOCUMENTS_LIST = "ADD_DEFAULT_DOCUMENTS_LIST";
export const REMOVE_DOCUMENTS_LIST = "REMOVE_DOCUMENTS_LIST";
export const CHANGE_DOCUMENT_PUBLISH_STATE = "CHANGE_DOCUMENT_PUBLISH_STATE";

export const SET_DOCUMENTS_LOADED = "SET_DOCUMENTS_LOADED";
export const CLEAR_DOCUMENTS = "CLEAR_DOCUMENTS";

export const MARK_DOCUMENT_FOR_DELETE = "MARK_DOCUMENT_FOR_DELETE";

export interface DocumentsRequestAction {
  type: typeof DOCUMENTS_REQUEST;
}

export interface DocumentsFailureAction {
  type: typeof DOCUMENTS_FAILURE;
}

export interface AddDocumentsListAction {
  type: typeof ADD_DOCUMENTS_LIST;
  data: DocumentData;
}

export interface SetDocumentsListAction {
  type: typeof SET_DOCUMENTS_LIST;
  data: DocumentData[];
}

export interface AddDefaultDocumentsListAction {
  type: typeof ADD_DEFAULT_DOCUMENTS_LIST;
  data: DocumentData[];
  defaultType: string;
}

export interface RemoveDocumentsListAction {
  type: typeof REMOVE_DOCUMENTS_LIST;
  data: DocumentData;
}

export interface ChangeDocumentsPublishStateListAction {
  type: typeof CHANGE_DOCUMENT_PUBLISH_STATE;
  data: DocumentData;
}

export interface SetDocumentsLoadedAction {
  type: typeof SET_DOCUMENTS_LOADED;
}

export interface ClearDocumentsAction {
  type: typeof CLEAR_DOCUMENTS;
}

export interface MarkDocumentForDelete {
  type: typeof MARK_DOCUMENT_FOR_DELETE;
  document: DocumentData;
}

export type DocumentsActionTypes =
  | DocumentsRequestAction
  | DocumentsFailureAction
  | AddDocumentsListAction
  | SetDocumentsListAction
  | AddDefaultDocumentsListAction
  | RemoveDocumentsListAction
  | ChangeDocumentsPublishStateListAction
  | SetDocumentsLoadedAction
  | ClearDocumentsAction
  | MarkDocumentForDelete;
