import { Dispatch, SetStateAction, useEffect, useState } from "react";

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.onbeforeunload = (event: BeforeUnloadEvent) => {
    // Show prompt based on state
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      return "";
    }
  };
};

const useExitPrompt = (
  showPrompt: boolean
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [showExitPrompt, setShowExitPrompt] = useState<boolean>(showPrompt);

  window.onload = function () {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);

    return () => {
      window.onbeforeunload = null;
    };
  }, [showExitPrompt]);

  return [showExitPrompt, setShowExitPrompt];
};

export default useExitPrompt;
