import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import "./Pinboard.scss";
import { Formik, Form } from "formik";
import {
  PinboardCommentFormData,
  PinboardCommentsListData,
  PinboardFormData,
  PinboardListData,
} from "../../../../../../models/pinboardData";
import styles from "../../../../../../styles/custom-styles.module.scss";
import { showDialog } from "../../../../../../redux/dialogs/actions";
import CustomButton from "../../../../../theming/CustomButton";
import {
  updateQuestion,
  postQuestion,
  archiveQuestion,
  unarchiveQuestion,
  deleteQuestion,
} from "../../../../../../redux/pinboard-question/actions";
import { convertToRaw, EditorState } from "draft-js";
import {
  archiveAnswer,
  deleteAnswer,
  postAnswer,
  unarchiveAnswer,
  updateAnswer,
} from "../../../../../../redux/pinboard-answer/actions";
import {
  mapToPopupAction,
  mapToPopupTitle,
  PinboardPopupType,
} from "../../../../../../models/enums/pinboardType.enum";
import { pinboardClear } from "../../../../../../redux/pinboard/actions";
import PinboardEditor from "./PinboardEditor";
import useBadWordsFilter from "../../../../../hooks/useBadWordsFilter";

export interface IPinboardPopupProps {
  question?: PinboardListData;
  eventId?: string;
  open: boolean;
  close: () => void;
  popupType: PinboardPopupType;
  answer?: PinboardCommentsListData;
}

export const MAX_CHAR_LENGTH_PINBOARD = 30000; // including HTML (?)
export const MAX_TITLE_LENGTH = 255;

const PinboardPopup: React.FunctionComponent<IPinboardPopupProps> = (props) => {
  const dispatch = useDispatch();

  const isQuestionPost = [
      PinboardPopupType.PostQuestion,
      PinboardPopupType.UpdateQuestion,
    ].includes(props.popupType),
    isArchiving = [
      PinboardPopupType.ArchiveQuestion,
      PinboardPopupType.ArchiveAnswer,
    ].includes(props.popupType),
    isUnarchiving = [
      PinboardPopupType.UnarchiveQuestion,
      PinboardPopupType.UnarchiveAnswer,
    ].includes(props.popupType);

  const getInitialValues = () => {
    if (isQuestionPost) {
      return {
        title: props.question ? props.question.title : "",
        post: props.question ? props.question.post : "",
        event: props.question
          ? props.question.event
          : props.eventId
          ? props.eventId
          : "",
      } as PinboardFormData;
    } else {
      return {
        answer: props.answer ? props.answer.answer : "",
        pinboard_question: props.question ? props.question.guid : 0,
      } as PinboardCommentFormData;
    }
  };

  const initialValues = getInitialValues();
  const emptyError = { title: "", text: "" };
  const [data, setData] = useState(initialValues);
  const [plainText, setPlainText] = useState("");
  const [error, setError] = useState(emptyError);
  const { scanInputWords } = useBadWordsFilter();

  const handleTitleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length > MAX_TITLE_LENGTH) {
      setError(
        (prev) =>
          (prev = {
            ...prev,
            title:
              "Der Titel darf maximal " +
              MAX_TITLE_LENGTH +
              " Zeichen enthalten.",
          })
      );
    } else {
      setError((prev) => (prev = { ...prev, title: "" }));
    }
    setData(
      (prev: typeof initialValues) =>
        (prev = { ...prev, title: event.target.value })
    );
  };
  const handlePostChange = (text: EditorState) => {
    const plain = text.getCurrentContent().getPlainText();
    if (plain.length > MAX_CHAR_LENGTH_PINBOARD) {
      setError(
        (prev) =>
          (prev = {
            ...prev,
            text:
              "Der Text darf maximal " +
              MAX_CHAR_LENGTH_PINBOARD +
              " Zeichen enthalten.",
          })
      );
    } else {
      setError((prev) => (prev = { ...prev, text: "" }));
    }
    const rteJson = JSON.stringify(convertToRaw(text.getCurrentContent()));
    setData(
      (prev: typeof initialValues) =>
        (prev = isQuestionPost
          ? { ...prev, post: rteJson }
          : { ...prev, answer: rteJson })
    );
    setPlainText(plain);
  };
  const isValid = () => {
    let valid =
      plainText.length > 0 &&
      plainText.length <= MAX_CHAR_LENGTH_PINBOARD &&
      scanInputWords(plainText).length === 0;
    if (isQuestionPost) {
      const title = (data as PinboardFormData).title;
      return valid && title.length > 0 && scanInputWords(title).length === 0;
    }
    return valid;
  };
  const handleSubmit = () => {
    if (isQuestionPost) {
      const title = (data as PinboardFormData).title;
      if (title.length < 1) {
        setError(
          (prev) =>
            (prev = { ...prev, title: "Das Titelfeld darf nicht leer sein." })
        );
      } else if (scanInputWords(title).length > 0) {
        setError(
          (prev) =>
            (prev = {
              ...prev,
              title: scanInputWords(title),
            })
        );
      }
    }
    if (plainText.length < 1) {
      setError(
        (prev) =>
          (prev = {
            ...prev,
            text: "Das Textfeld darf nicht leer sein.",
          })
      );
    } else if (scanInputWords(plainText).length > 0) {
      setError(
        (prev) =>
          (prev = {
            ...prev,
            text: scanInputWords(plainText),
          })
      );
    }
    if (isValid()) {
      switch (props.popupType) {
        case PinboardPopupType.PostQuestion:
          dispatch(postQuestion(data as PinboardFormData));
          break;
        case PinboardPopupType.UpdateQuestion:
          dispatch(
            updateQuestion(
              (props.question ? props.question.guid : 0).toString(),
              data as PinboardFormData
            )
          );
          break;
        case PinboardPopupType.PostAnswer:
          dispatch(postAnswer(data as PinboardCommentFormData));
          break;
        case PinboardPopupType.UpdateAnswer:
          dispatch(
            updateAnswer(
              (props.answer ? props.answer.guid : 0).toString(),
              data as PinboardCommentFormData
            )
          );
          break;
        default:
          dispatch(pinboardClear());
      }
      props.close();
      setData(initialValues);
    }
  };

  const removeQuestion = () => {
      dispatch(
        deleteQuestion(
          (props.question ? props.question.guid : 0).toString(),
          data as PinboardFormData
        )
      );
      props.close();
    },
    removeAnswer = () => {
      dispatch(
        deleteAnswer(
          (props.answer ? props.answer.guid : 0).toString(),
          data as PinboardCommentFormData
        )
      );
      props.close();
    },
    unpublishQuestion = () => {
      dispatch(
        archiveQuestion(
          (props.question ? props.question.guid : 0).toString(),
          data as PinboardFormData
        )
      );
      props.close();
    },
    unpublishAnswer = () => {
      dispatch(
        archiveAnswer(
          (props.answer ? props.answer.guid : 0).toString(),
          data as PinboardCommentFormData
        )
      );
      props.close();
    },
    publishQuestion = () => {
      dispatch(
        unarchiveQuestion(
          (props.question ? props.question.guid : 0).toString(),
          data as PinboardFormData
        )
      );
      props.close();
    },
    publishAnswer = () => {
      dispatch(
        unarchiveAnswer(
          (props.answer ? props.answer.guid : 0).toString(),
          data as PinboardCommentFormData
        )
      );
      props.close();
    };

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      maxWidth={isArchiving || isUnarchiving ? "xs" : "md"}
      fullWidth={true}
    >
      <DialogTitle
        style={{
          margin: "auto",
        }}
      >
        {mapToPopupTitle(props.popupType)}
      </DialogTitle>
      {isArchiving || isUnarchiving ? (
        <>
          <DialogContent>
            {isArchiving ? (
              <>
                <p>
                  Sie sind im Begriff, den Beitrag zu archivieren. Er wird
                  Nutzern nicht mehr angezeigt. Diese Aktion kann rückgängig
                  gemacht werden.
                </p>
                <p>
                  Über "Archivierte Beiträge einblenden" können Sie sich den
                  Beitrag anzeigen lassen. Er bleibt jedoch für andere Nutzer
                  unsichtbar.
                </p>
                <p>Möchten Sie fortfahren?</p>
              </>
            ) : (
              "Möchten Sie wirklich republizieren?"
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={props.close} variant="contained" color="secondary">
              Abbrechen
            </Button>
            <CustomButton
              customColor={styles.red}
              textColor={"#fff"}
              hoverColor={styles["red-dark"]}
              onClick={
                isArchiving
                  ? props.popupType === PinboardPopupType.ArchiveQuestion
                    ? unpublishQuestion
                    : unpublishAnswer
                  : props.popupType === PinboardPopupType.UnarchiveQuestion
                  ? publishQuestion
                  : publishAnswer
              }
            >
              {isArchiving ? "Archivieren" : "Republizieren"}
            </CustomButton>
          </DialogActions>
        </>
      ) : (
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {() => (
            <Form>
              <DialogContent style={{ paddingLeft: "1.9em" }}>
                {isQuestionPost && (
                  <TextField
                    error={error.title !== ""}
                    helperText={error.title}
                    fullWidth
                    name="title"
                    variant="filled"
                    label="Titel eingeben"
                    style={{
                      marginBottom: "10px",
                    }}
                    onChange={handleTitleChange}
                    defaultValue={(initialValues as PinboardFormData).title}
                    autoComplete="off"
                  />
                )}
                <PinboardEditor
                  error={error.text}
                  isFirstPostOfTopic={isQuestionPost}
                  handleChange={handlePostChange}
                  values={initialValues}
                />
                {error.text !== "" && (
                  <FormHelperText variant="filled" error={true}>
                    {error.text}
                  </FormHelperText>
                )}
              </DialogContent>
              <DialogActions style={{ padding: "20px" }}>
                <Button
                  onClick={props.close}
                  variant="contained"
                  color="secondary"
                >
                  Abbrechen
                </Button>
                <Button
                  type="submit"
                  autoFocus
                  variant="contained"
                  color="primary"
                >
                  {mapToPopupAction(props.popupType)}
                </Button>
                {[
                  PinboardPopupType.UpdateAnswer,
                  PinboardPopupType.UpdateQuestion,
                ].includes(props.popupType) && (
                  <CustomButton
                    customColor={styles.red}
                    textColor={"#fff"}
                    hoverColor={styles["red-dark"]}
                    onClick={() => {
                      dispatch(
                        showDialog({
                          title: "Beitrag löschen",
                          message:
                            "Sind Sie sich sicher, dass Sie den Beitrag löschen möchten?",
                          action: () => {
                            if (isQuestionPost) {
                              removeQuestion();
                            } else {
                              removeAnswer();
                            }
                          },
                        })
                      );
                    }}
                  >
                    Löschen
                  </CustomButton>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default PinboardPopup;
