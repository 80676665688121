import { AuthResultData, LoginData } from "../../models/userData";
import { ApiResponse } from "../apiResponse";
import apiRoutes from "../apiRoutes";
import { BaseCrudService } from "../baseCrudService";

// TODO: check if trailing slash=false necessary?
export class AuthService extends BaseCrudService {
  constructor() {
    super({}, false);
  }

  async login(data: LoginData) {
    const response = await this.create<LoginData, {} | AuthResultData>(
      apiRoutes.login,
      data
    );
    return response;
  }

  async logout() {
    const response = await this.getAll<ApiResponse>(apiRoutes.logout);
    return response;
  }
}
