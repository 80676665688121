import { history } from "../../components/routing/history";
import { EventsSortBy } from "../../models/enums/sortBy.enum";
import {
  EventFilterData,
  EventListPossibleFilters,
} from "../../models/eventData";
import { Utils } from "./../utils";
import FilterService, { SortDirection } from "./filterService";

export class EventFilterService extends FilterService<
  EventFilterData,
  EventsSortBy
> {
  constructor(page: number, filterData: EventFilterData) {
    const sorting = filterData.sorting as EventsSortBy;

    super({
      sort: sorting,
      page: page,
      attributes: filterData,
    });
  }

  /**
   * Gets the current filter values from the query params in the URL and parses the values and assigns them to a EventFilterData object that can be used to initalize a filter form.
   * The value for ordering attribute comes from base filter service.
   */
  parseFiltersFromUrl(): EventFilterData {
    const query = new URLSearchParams(history.location.search);

    const statusValue = query.get("status") || "";
    const search = query.get("search") || "";
    const location = this.parseListFromFilterUrl(query.get("location") || "");
    const eventType = this.parseNumberListFromFilterUrl(
      query.get("eventType") || "",
      0
    );
    const trainingType = this.parseNumberListFromFilterUrl(
      query.get("trainingType") || "",
      0
    );
    const participationTypes = this.parseNumberListFromFilterUrl(
      query.get("participation_types") || "",
      0
    );
    const special_fields = this.parseNumberListFromFilterUrl(
      query.get("special_fields") || "",
      0
    );
    const coverage_areas = this.parseNumberListFromFilterUrl(
      query.get("coverage_areas") || "",
      0
    );
    const myTargetGroup = query.get("my_target_group") === "true";
    const dateFrom = query.get("begin_gte") || "",
      dateTo = query.get("begin_lte") || "";
    const queryFilter = super.parseFiltersFromUrl(query);

    return {
      ...queryFilter,
      search: search,
      location: location,
      training_type: trainingType,
      event_type: eventType,
      participation_types: participationTypes,
      special_fields: special_fields,
      coverage_areas: coverage_areas,
      status: statusValue,
      my_target_group: myTargetGroup,
      begin_gte: dateFrom,
      begin_lte: dateTo,
    };
  }

  /**
   * Maps the string representation of the EventsSortBy enum to one of the provided filters possibilites for sorting.
   * @param sort The enum or string value to parse to actual sort string.
   */
  mapSorting(sort: string | EventsSortBy): string {
    switch (sort) {
      case EventsSortBy.CourseNumber:
        return super.parseOrdering(
          Utils.propertyOf<EventListPossibleFilters>("public_id")
        );
      case EventsSortBy.BeginOldest:
        return super.parseOrdering(
          Utils.propertyOf<EventListPossibleFilters>("begin")
        );
      case EventsSortBy.BeginNewest:
        return super.parseOrdering(
          Utils.propertyOf<EventListPossibleFilters>("begin"),
          SortDirection.Descending
        );
      default:
        return super.parseOrdering(
          Utils.propertyOf<EventListPossibleFilters>("begin")
        );
    }
  }

  /**
   * Maps the sort value and its direction to a possible EventsSortBy enum value. Returns the default sorting if none of the provided values matches.
   * @param sort Value of attribute that should be sorted.
   */
  mapSortStringToEnum(sort: string): EventsSortBy {
    switch (sort) {
      case super.parseOrdering(
        Utils.propertyOf<EventListPossibleFilters>("public_id")
      ):
        return EventsSortBy.CourseNumber;
      case super.parseOrdering(
        Utils.propertyOf<EventListPossibleFilters>("begin")
      ):
        return EventsSortBy.BeginOldest;
      case super.parseOrdering(
        Utils.propertyOf<EventListPossibleFilters>("begin"),
        SortDirection.Descending
      ):
        return EventsSortBy.BeginNewest;
      default:
        return EventsSortBy.BeginOldest;
    }
  }
}
