export enum EventsSortBy {
  BeginOldest = "Startdatum älteste",
  BeginNewest = "Startdatum neueste",
  CourseNumber = "Veranstaltungsnummer",
}

export enum BookingsSortBy {
  BeginAscending = "Startdatum aufsteigend",
  BeginDescending = "Startdatum absteigend",
  EndAscending = "Enddatum aufsteigend",
  EndDescending = "Enddatum absteigend",
  StatusAscending = "Status aufsteigend",
  StatusDescending = "Status absteigend",
}

export enum TasksSortBy {
  StatusAscending = "Status aufsteigend",
  StatusDesending = "Status absteigend",
}
