export const BAD_WORDS: string[] = [
  "Aa-Gesicht",
  "Aa-Kopf",
  "Aalfresse",
  "Aas",
  "Aasfresser",
  "Aasgeier",
  "Abc-Schütze",
  "Abschaum",
  "Abspritzer",
  "Abtörner",
  "Abwichshure",
  "Abzocker",
  "Affe",
  "Affenarsch",
  "Afterarzt",
  "Afterlecker",
  "Allerwelthure",
  "Alman",
  "Almosenfresser",
  "Alpha-Kevin",
  "Alptraum",
  "Amateur",
  "Amateuse",
  "Analakrobat",
  "Analbergsteiger",
  "Analbesamer",
  "Analbohrer",
  "Analfetichist",
  "Analfotze",
  "Analgesicht",
  "Analphabet",
  "Anarchist",
  "Angsthase",
  "Antichrist",
  "Anus",
  "Anuslecker",
  "Arsch",
  "Arschbacke",
  "Arschbohrer",
  "Arschbolzen",
  "Arschbratze",
  "Arschbumser",
  "Asrschfalte",
  "Arschficker",
  "Arschfotze",
  "Arschgeburt",
  "Arschgeige",
  "Arschgranate",
  "Arschgucker",
  "Arschi",
  "Arschkind",
  "Arschkrampe",
  "Arschkriecher",
  "Arschkröte",
  "Arschkuh",
  "Arschlappen",
  "Arschlecker",
  "Arschloch",
  "Arschlockficker",
  "Arschlochlecker",
  "Arschlutscher",
  "Assi",
  "Aslak",
  "Asozialer",
  "Ausgeburt",
  "Ausgeburt der Hölle",
  "Aushilfsamöbe",
  "Außenseiter",
  "Bagage",
  "Balg",
  "Banause",
  "Barbar",
  "Bastard",
  "Bauer",
  "Bazillenschleuder",
  "Bengel",
  "Berufslesbe",
  "Berufsschwuler",
  "Bescheißer",
  "Besserwisser",
  "Bestie",
  "Betrüger",
  "Bettler",
  "Biest",
  "Bitch",
  "Blender",
  "Blödmann",
  "Blödkopf",
  "Blödmannsgehilfe",
  "Blondchen",
  "Bluthund",
  "Bock",
  "Bösewicht",
  "Bonze",
  "Bordsteinschwalbe",
  "Bratze",
  "Bremse",
  "Brillenschlange",
  "Bücherwurm",
  "Buhmann",
  "Bulle",
  "Bumskarnickel",
  "Bumskuh",
  "Chaot",
  "Charakterattrape",
  "Charakterschwein",
  "Cheater",
  "Choleriker",
  "Chromosomengepansche",
  "Cloake",
  "Clown",
  "Clownficker",
  "Crackbitch",
  "Crackhure",
  "Cracknutte",
  "Crackschlampe",
  "Cyberschlampe",
  "Dämlack",
  "Danebenpisser",
  "Dapp",
  "Dauerwichser",
  "Daumenlutscher",
  "Depp",
  "Depp vom Dienst",
  "Dickarsch",
  "Dickbauch",
  "Dickkopf",
  "Dicknase",
  "Dickschädel",
  "Dickschwein",
  "Dirne",
  "Dödel",
  "Donnerfotze",
  "Doofkopf",
  "Doofian",
  "Doofmann",
  "Dorfdepp",
  "Dorfkuh",
  "Dorftrottel",
  "Dreckhure",
  "Drecksack",
  "Drecksau",
  "Dreckschleuder",
  "Dreckschwein",
  "Dreckstück",
  "Dreikäsehoch",
  "Dummbeutel",
  "Dummerchen",
  "Dummi",
  "Dummfotze",
  "Dummkopf",
  "Dummschwätzer",
  "Dumpfbacke",
  "Dunkelmann",
  "Dussel",
  "Edelhure",
  "Egoist",
  "Egozentriker",
  "Eierkopf",
  "Einfaltspinsel",
  "Ekelmann",
  "Ekelschwein",
  "Emanze",
  "Erbsenzähler",
  "Esel",
  "Eselficker",
  "Eumel",
  "Evolutionsbremse",
  "Exhibitionist",
  "Exkrementenfresser",
  "Fachidiot",
  "Fatzke",
  "Faulenzer",
  "Faultier",
  "Faulpelz",
  "Feigling",
  "Ferkel",
  "Fettarsch",
  "Fettsack",
  "Fettsau",
  "Fettwanst",
  "Ficker",
  "Ficknudel",
  "Fickschwein",
  "Ficksau",
  "Fiesling",
  "Fixer",
  "Flachpfeife",
  "Flachwichser",
  "Flegel",
  "Flasche",
  "Flittchen",
  "Fotze",
  "Freak",
  "Früchtchen",
  "Furie",
  "Furz",
  "Fuzzi",
  "Gammler",
  "Gans",
  "Gangster",
  "Gaukler",
  "Gaul",
  "Gauner",
  "Gehirnloser",
  "Geizhals",
  "Geizkragen",
  "Gesichtsarsch",
  "Gesichtsbaracke",
  "Gesindel",
  "Gestörter",
  "Gewitterhexe",
  "Giftzwerg",
  "Göre",
  "Grantler",
  "Großkotz",
  "Grünschnabel",
  "Großmaul",
  "Grufti",
  "Hackfresse",
  "Hänfling",
  "Hallodri",
  "Halunke",
  "Hammel",
  "Hanswurst",
  "Hartzer",
  "Heuchler",
  "Heuchlerin",
  "heuchlerisch",
  "Heulsuse",
  "hinterfotzig",
  "Hippie",
  "Hirnamputierter",
  "Hirni",
  "Holzkopf",
  "Hoink",
  "Homo",
  "Homo erectus",
  "Honk",
  "Hool",
  "Hornochse",
  "Hosenpisser",
  "Hosenscheißer",
  "Hurensohn",
  "Hurenbock",
  "Hupe",
  "Hurenficker",
  "Hurenbastard",
  "Hurentochter",
  "Huso",
  "Idiot",
  "Idiotenarsch",
  "Ignorant",
  "Intrigant",
  "Inzestgeburt",
  "Irrer",
  "Ische",
  "Jammersack",
  "Jungfer",
  "Junkie",
  "Kackbraten",
  "Kackbratze",
  "Kackfresse",
  "Kasper",
  "Kiffer",
  "Killer",
  "Kinderschänder",
  "Kindskopf",
  "Klassenkasper",
  "Kleinhirn",
  "Klugscheißer",
  "Knallbirne",
  "Knallbohne",
  "Knalltüte",
  "Knecht",
  "Knilch",
  "Knirps",
  "Kötel",
  "Komiker",
  "Kuh",
  "Lakai",
  "Landei",
  "Lappen",
  "Langweiler",
  "Lauch",
  "Lausbub",
  "Lausejunge",
  "Loser",
  "Luder",
  "Lügner",
  "Lümmel",
  "Luftpumpe",
  "Lump",
  "Lügenschwein",
  "Luftikus",
  "Lumpensack",
  "Lurch",
  "Lutscher",
  "Lusche",
  "Lustmolch",
  "Macho ",
  "Macker",
  "Mamasöhnchen",
  "Matschbirne",
  "Mauerblümchen",
  "Mastsau",
  "Maulaffe",
  "Maulficker",
  "Massenmörder",
  "Meckerliese",
  "Memme",
  "Menschenschinder",
  "Mephisto",
  "Miesepeter",
  "Milchbubi",
  "Mimose",
  "Missgeburt",
  "Mist",
  "Mistkerl",
  "Miststück",
  "Möchtegern",
  "Monster",
  "Moralapostel",
  "Muffel",
  "Murkser",
  "Narr",
  "Nassauer",
  "Nervensäge",
  "Nichtskönner",
  "Nichtsnutz",
  "Niete",
  "Nutte",
  "Nuttenficker",
  "Nuttensau",
  "Oberaffe",
  "Oberpfeife",
  "Ölgötze",
  "Oimel",
  "Opfer",
  "Opferkind",
  "Opportunist",
  "Panscher",
  "Pantoffelheld",
  "Pappenheimer",
  "Pappnase",
  "Parasit",
  "Paragraphenreiter",
  "Pechvogel",
  "Penner",
  "Penislutscher",
  "Pessimist",
  "Petze",
  "Pfeife",
  "Pfurz",
  "Pflaume",
  "Pfuscher",
  "Phantast",
  "Piefke",
  "Piepmatz",
  "Pillendreher",
  "Pimmelficker",
  "Pisser",
  "Pissgesicht",
  "Pissgurke",
  "Pisskröte",
  "Pissvogel",
  "Pissnelke",
  "Plagegeist",
  "Plagiator",
  "Plappermaul",
  "Pöbel",
  "Pleitegeier",
  "Poperze",
  "Pottsau",
  "Prahler",
  "Prahlhans",
  "Proll",
  "Prolet",
  "Psychopath",
  "Puffmutter",
  "Pumuckl",
  "Punk",
  "Pupsbacke",
  "Pussy",
  "Quacksalber",
  "Quadratfresse",
  "Quälgeist",
  "Quassler",
  "Quatscher",
  "Quatschkopf",
  "Querdenker",
  "Querulant",
  "Rabauke",
  "Ratte",
  "Rotzlöffel",
  "Ritznase",
  "Sack",
  "Sackgesicht",
  "Sacknase",
  "Sackratte",
  "Sadist",
  "Säufer",
  "Saftpresse",
  "Saftschubse",
  "Satan",
  "Satanist",
  "Satansbraten",
  "Sau",
  "Schabrake",
  "Schaf",
  "Schädling",
  "Schandmaul",
  "Scheißarsch",
  "Scheinheiliger",
  "Scheißer",
  "Scheißkerl",
  "Schelm",
  "Scheusal",
  "Schietbüdel",
  "Schinder",
  "Schissbolzen",
  "Schisser",
  "Schläger",
  "Schlampe",
  "Schlamper",
  "Schlawiner",
  "Schlingel",
  "Schlitzer",
  "Schlitzohr",
  "Schluckspecht",
  "Schludrian",
  "Schlumpf",
  "Schmarotzer",
  "Schmierfink",
  "Schmock",
  "Schmeißfliege",
  "Schmutzfink",
  "Schnalle",
  "Schnarchzapfen",
  "Schnecke",
  "Schnellchecker",
  "Schnepfe",
  "Schnödel",
  "Schnorrer",
  "Schrapnelle",
  "Schrubber",
  "Schrulle",
  "Schürzenjäger",
  "Schüssel",
  "Schuft",
  "Schurke",
  "Schwachkopf",
  "Schwächling",
  "Schwätzer",
  "Schwanzdenker",
  "Schwanzfresse",
  "Schwein",
  "Schwanzlutscher",
  "Schweinehund",
  "Schwindler",
  "Schwuchtel",
  "Schwulette",
  "Schwuli",
  "Selbstmörder",
  "Sesselfurzer",
  "Simpel",
  "Snob",
  "Softie",
  "Spacken",
  "Spachtel",
  "Spacko",
  "Spanner",
  "Spasti",
  "Spießer",
  "Spinatwachtel",
  "Stänkerer",
  "Stinker",
  "Stoffel",
  "Streber",
  "Stricher",
  "Stubenhocker",
  "Sünder",
  "Suffkopp",
  "Swinger",
  "Teufel",
  "Tölpel",
  "Träumer",
  "Transuse",
  "Trantüte",
  "Troll",
  "Trotzkopf",
  "Tunte",
  "Tunichtgut",
  "Tusse",
  "Tussi",
  "Tyrann",
  "unfähig",
  "Unmensch",
  "Verbrecher",
  "Verderber",
  "Vergewaltiger",
  "Verlierer",
  "Versager",
  "Verräter",
  "Viech",
  "Vielfraß",
  "Vollassi",
  "Volldepp",
  "Vollhorst",
  "Vollpfosten",
  "Vollspacko",
  "Walross",
  "Wanze",
  "Warmduscher",
  "Warzenschwein",
  "Waschweib",
  "Weib",
  "Weiberheld",
  "Weichei",
  "Wichser",
  "Widerling",
  "Witzbold",
  "Wüstling",
  "Wüterich",
  "Wurm",
  "Zahnstocher",
  "Ziege",
  "Ziegenficker",
  "Zimperliese",
  "Zimtzicke",
  "Zuhälter",
  "Zwergnase",
  "Zyniker",
  "S******",
  "S*****",
  "S****",
  "S***",
  "S**",
  "F****",
  "F***",
  "F**",
];

const useBadWordsFilter = () => {
  /**
   * Checks for bad word(s) occurence.
   * @returns string information with detected bad word(s)
   */
  const scanInputWords: (input: string) => string = (input) => {
    // 1. transform input surrounded with spaces into lowercase characters
    // 2. replace non-alphanumerics and non-whitespaces into whitespaces (removing punctuation)
    // 3. collapse multiple adjacent whitespaces to single whitespaces
    const inputWords = ` ${input.toLowerCase()} `
      .replace(/[^\w\s]|_/g, " ")
      .replace(/\s+/g, " ");

    const foundWords = BAD_WORDS.filter((badWord) => {
      // match whole words, case insensitive (e.g. netter affe)
      const isBadWord = inputWords.includes(` ${badWord.toLowerCase()} `);
      // match word with asterisc, case sensitive (e.g. F***)
      if (badWord.includes("*")) return isBadWord || input.includes(badWord);
      return isBadWord;
    });
    let info = "";
    if (foundWords.length > 0) {
      info =
        foundWords.length > 1 ? "Folgende Wörter sind" : "Folgendes Wort ist";
      info += " nicht erlaubt: ";
      info += foundWords.join(", ");
    }
    return info;
  };
  return { scanInputWords };
};
export default useBadWordsFilter;
