import * as React from "react";
import "./Cart.scss";
import { Box, TableCell, TableRow, Tooltip, Hidden } from "@material-ui/core";
import { CartData } from "../../../models/cartData";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import { removeEventFromCart } from "../../../redux/cart/actions";
import { Utils } from "../../../services/utils";
import CateringOptions from "./CateringOptions";
import { AppState } from "../../../redux";

interface CartItemProps {
  event: CartData;
  checkout?: boolean;
}

const CartItem: React.FC<CartItemProps> = (props) => {
  const dispatch = useDispatch();
  const userData = useSelector((state: AppState) => state.user.currentUser);

  return (
    <>
      <TableRow>
        <Hidden smDown>
          <TableCell component="th" scope="row">
            {props.event.public_id}
          </TableCell>
        </Hidden>
        <TableCell align="left">
          <Hidden mdUp>
            #{props.event.public_id}
            <br />
          </Hidden>
          {props.event.title.length > 30 ? (
            <Tooltip title={props.event.title} arrow>
              <Box component={"span"}>
                {props.event.title
                  .substring(0, 25)
                  .split(" ")
                  .slice(0, -1)
                  .join(" ") + "..."}
              </Box>
            </Tooltip>
          ) : (
            props.event.title
          )}
          <Hidden smUp>
            <br />
            {new Date(props.event.begin).toLocaleDateString()}
          </Hidden>
          {props.checkout && props.event.catering ? (
            <Hidden mdUp>
              <br />
              <CateringOptions event={props.event} />
            </Hidden>
          ) : (
            <Hidden mdUp>
              <br />
              Keine Verpflegung
            </Hidden>
          )}
          <Hidden mdUp>
            <br />
            gebucht für:{" "}
            {props.event.book_for.guid === userData?.guid ? (
              <b>mich</b>
            ) : (
              <b>
                {props.event.book_for.first_name}{" "}
                {props.event.book_for.last_name}
              </b>
            )}
          </Hidden>
          <Hidden mdUp>
            <br />
            {props.event.speakers.length === 1 && "Referent:"}
            {props.event.speakers.length > 1 && "Referenten:"}
            <br />
            {props.event.speakers.map((speaker, index) => {
              return (
                <React.Fragment key={index}>
                  {speaker}
                  <br />
                </React.Fragment>
              );
            })}
          </Hidden>
        </TableCell>
        <Hidden xsDown>
          <TableCell align="left">
            {new Date(
              props.event.begin || props.event.created_on
            ).toLocaleDateString()}
          </TableCell>
        </Hidden>
        <Hidden smDown>
          <TableCell align="left">
            {props.event.speakers.length > 0
              ? props.event.speakers.map((speaker, index) => {
                  return (
                    <React.Fragment key={index}>
                      {speaker}
                      <br />
                    </React.Fragment>
                  );
                })
              : "-"}
          </TableCell>
        </Hidden>
        {props.checkout && (
          <>
            {props.event.catering ? (
              <Hidden smDown>
                <TableCell align="left">
                  <CateringOptions event={props.event} />
                </TableCell>
              </Hidden>
            ) : (
              <Hidden smDown>
                <TableCell align="left">Keine Verpflegung</TableCell>
              </Hidden>
            )}
          </>
        )}
        <Hidden smDown>
          <TableCell align="left">
            {props.event.book_for.guid === userData?.guid ? (
              <>mich</>
            ) : (
              <>
                {props.event.book_for.first_name}{" "}
                {props.event.book_for.last_name}
              </>
            )}
          </TableCell>
        </Hidden>
        <TableCell align="right">
          {Utils.formatPrice(props.event.price)}
        </TableCell>
        <TableCell align="center">
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(removeEventFromCart(props.event.guid))}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default CartItem;
