import * as yup from "yup";
import { ContactMessageType } from "../../../models/enums/contactMessageType.enum";

const contactValidationSchema = yup.object({
  salutation: yup.string().required("Die Anrede ist ein Pflichtfeld."),
  firstname: yup.string().required("Der Vorname ist ein Pflichtfeld."),
  lastname: yup.string().required("Der Nachname ist ein Pflichtfeld."),
  topic: yup
    .string()
    .required(
      "Der Betreff ist ein Pflichtfeld, damit wir Ihre Kontaktanfrage zuordnen können."
    ),
  message: yup
    .string()
    .required(
      "Ihre Nachricht an uns ist leer. Bitte füllen Sie dieses Feld aus."
    )
    .max(1024),
  mail: yup.string().when("response_type", {
    is: (value: number) => value === ContactMessageType.Mail,
    then: yup
      .string()
      .email(
        "Ihre E-Mail-Adresse ist nicht gültig. Bitte überprüfen Sie Ihre Eingabe und versuchen es erneut!"
      )
      .required(
        "Die Angabe der E-Mail-Adresse ist Pflicht, damit wir Sie kontaktieren können!"
      ),
    otherwise: yup.string().nullable(),
  }),
  phone: yup.string().when("response_type", {
    is: (value: number) => value === ContactMessageType.Phone,
    then: yup
      .string()
      .required(
        "Die Angabe der Telefonnummer ist Pflicht, damit wir Sie kontaktieren können!"
      ),
    otherwise: yup.string().nullable(),
  }),
  response_type: yup
    .number()
    .integer(
      "Bitte geben Sie einen bevorzugten Kontaktweg an, damit wir Ihnen antworten können."
    )
    .positive(
      "Bitte geben Sie einen bevorzugten Kontaktweg an, damit wir Ihnen antworten können."
    )
    .required(
      "Bitte geben Sie einen bevorzugten Kontaktweg an, damit wir Ihnen antworten können."
    ),
  subject_area: yup
    .number()
    .integer("Bitte wählen Sie den Themenbereich aus.")
    .min(0, "Bitte wählen Sie den Themenbereich aus.")
    .required("Bitte wählen Sie den Themenbereich aus."),
});

export default contactValidationSchema;
