export enum TaskType {
  Undefined = 0,
  OnSite = 10,
  Online = 20,
}

export function mapToTaskTypeString(taskType?: TaskType): string {
  switch (taskType) {
    case TaskType.Undefined:
      return "Präsenz und Online";
    case TaskType.OnSite:
      return "Präsenz";
    case TaskType.Online:
      return "Online";
    default:
      return "";
  }
}
