import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import * as React from "react";

interface FieldsMissingAlertProps {}

const FieldsMissingAlert: React.FC<FieldsMissingAlertProps> = () => {
  return (
    <Box my={2}>
      <Alert severity="error">
        Es wurden noch nicht alle Felder korrekt ausgefüllt. Kontrollieren Sie
        Ihre Eingaben und versuchen Sie es erneut!
      </Alert>
    </Box>
  );
};

export default FieldsMissingAlert;
