import { Dispatch } from "redux";
import { templateService } from "../../../api";
import { history } from "../../../components/routing/history";
import {
  TEMPLATE_FAILURE,
  TEMPLATE_REQUEST,
  TemplateFailureAction,
  TemplateRequestAction,
  TaskTemplatesActionTypes,
  SET_LIST_OF_TASK_TEMPLATES_SUCCESS,
  SetListOfTaskTemplatesSuccessAction,
  DELETE_TASK_TEMPLATE,
  DeleteTaskTemplateSuccessAction,
  SetTaskTemplatePageCountAction,
  SET_TASK_TEMPLATE_PAGE_COUNT,
  SET_TASK_TEMPLATE_SUCCESS,
  SetTaskTemplateSuccessAction,
  CLEAR_TASK_TEMPLATE,
  TaskTemplateClearAction,
  SET_DEFAULT_TASKS_FOR_EVENT_PREVIEW,
  SetDefaultTasksForEventPreviewAction,
  RESET_DEFAULT_TASKS_FOR_EVENT_PREVIEW,
  ResetDefaultTasksForEventPreviewAction,
} from "./types";
import { AlertActionTypes, ErrorAction } from "../../alerts/types";
import { success } from "../../alerts/actions";

import { TaskTemplateData } from "../../../models/taskData";
import { Pagination } from "../../../models/pagination";
import routes from "../../../components/routing/routes";
import { EventType } from "../../../models/enums/eventType.enum";
import { AxiosError } from "axios";

export function getAllTaskTemplates(page: number) {
  return (dispatch: Dispatch<TaskTemplatesActionTypes | ErrorAction>) => {
    dispatch(templateRequest());

    templateService
      .getAllTaskTemplates(page)
      .then((response: Pagination<TaskTemplateData>) => {
        dispatch(setListOfTaskTemplatesSuccess(response));
      })
      .catch((err: AxiosError) => {
        dispatch(templateFailure(err.response?.status === 404));
      });
  };
}

export function getTaskTemplate(id: string) {
  return (dispatch: Dispatch<TaskTemplatesActionTypes | ErrorAction>) => {
    dispatch(templateRequest());

    templateService
      .getTaskTemplate(id)
      .then((response) => {
        dispatch(setTaskTemplateSuccess(response));
      })
      .catch((err: AxiosError) => {
        dispatch(templateFailure(err.response?.status === 404));
      });
  };
}

export function addTaskTemplate(data: TaskTemplateData) {
  return (dispatch: Dispatch<TaskTemplatesActionTypes | AlertActionTypes>) => {
    dispatch(templateRequest());
    templateService
      .addTaskTemplate(data)
      .then((response) => {
        dispatch(
          success(
            {
              message: "Die Standardaufgabe wurde erfolgreich angelegt.",
            },
            3000
          )
        );
        history.push(routes.templates);
      })
      .catch((err: AxiosError) => {
        dispatch(templateFailure(err.response?.status === 404));
      });
  };
}

export function editTaskTemplate(data: TaskTemplateData, id: string) {
  return (dispatch: Dispatch<TaskTemplatesActionTypes | AlertActionTypes>) => {
    dispatch(templateRequest());
    templateService
      .editTaskTemplate(data, id)
      .then((response) => {
        dispatch(
          success(
            {
              message: "Die Standardaufgabe wurde erfolgreich bearbeitet.",
            },
            3000
          )
        );
        history.push(routes.templates);
      })
      .catch((err: AxiosError) => {
        dispatch(templateFailure(err.response?.status === 404));
      });
  };
}

export function deleteTaskTemplate(id: string) {
  return (dispatch: Dispatch<TaskTemplatesActionTypes | AlertActionTypes>) => {
    templateService
      .deleteTaskTemplate(id)
      .then(() => {
        dispatch(deleteTaskTemplateSuccess(id));
        dispatch(
          success(
            {
              message: "Die Aufgabenvorlage wurde erfolgreich gelöscht.",
            },
            3000
          )
        );
      })
      .catch((err: AxiosError) => {
        dispatch(templateFailure(err.response?.status === 404));
      });
  };
}

export function getDefaultTasksForEventPreview(eventType: number) {
  return (dispatch: Dispatch<TaskTemplatesActionTypes | ErrorAction>) => {
    dispatch(templateRequest());

    let defaultTasks: TaskTemplateData[] = [];
    templateService
      .getDefaultTasksByEventType(EventType.Undefined)
      .then((response: TaskTemplateData[]) => {
        defaultTasks = response;
        if (eventType !== EventType.Undefined) {
          templateService
            .getDefaultTasksByEventType(eventType)
            .then((response: TaskTemplateData[]) => {
              defaultTasks = defaultTasks.concat(response);
              dispatch(SetDefaultTasksForEventPreview(defaultTasks));
            })
            .catch((err: AxiosError) => {
              dispatch(templateFailure(err.response?.status === 404));
            });
        } else {
          dispatch(SetDefaultTasksForEventPreview(defaultTasks));
        }
      })
      .catch((err: AxiosError) => {
        dispatch(templateFailure(err.response?.status === 404));
      });
  };
}

export function templateRequest(): TemplateRequestAction {
  return {
    type: TEMPLATE_REQUEST,
  };
}
export function templateFailure(
  notFoundError: boolean = false
): TemplateFailureAction {
  return {
    type: TEMPLATE_FAILURE,
    notFoundError: notFoundError,
  };
}

export function setListOfTaskTemplatesSuccess(
  data: Pagination<TaskTemplateData>
): SetListOfTaskTemplatesSuccessAction {
  return {
    type: SET_LIST_OF_TASK_TEMPLATES_SUCCESS,
    data,
  };
}

export function setTaskTemplateSuccess(
  data: TaskTemplateData
): SetTaskTemplateSuccessAction {
  return {
    type: SET_TASK_TEMPLATE_SUCCESS,
    data,
  };
}

export function deleteTaskTemplateSuccess(
  id: string
): DeleteTaskTemplateSuccessAction {
  return {
    type: DELETE_TASK_TEMPLATE,
    id,
  };
}

export function clearTaskTemplate(): TaskTemplateClearAction {
  return {
    type: CLEAR_TASK_TEMPLATE,
  };
}

export function setPageCount(
  pageCount: number
): SetTaskTemplatePageCountAction {
  return {
    type: SET_TASK_TEMPLATE_PAGE_COUNT,
    pageCount: pageCount,
  };
}

export function SetDefaultTasksForEventPreview(
  data: TaskTemplateData[]
): SetDefaultTasksForEventPreviewAction {
  return {
    type: SET_DEFAULT_TASKS_FOR_EVENT_PREVIEW,
    data,
  };
}

export function resetDefaultTasksForEventPreview(): ResetDefaultTasksForEventPreviewAction {
  return {
    type: RESET_DEFAULT_TASKS_FOR_EVENT_PREVIEW,
  };
}
