import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { NoMatch } from "../../errorPages/noMatch";
import routes from "../../routing/routes";
import MyTasks from "./MyTasks";
import CrudTask from "./CrudTask";

interface TaskRoutesWrapperProps {}

const TaskRoutesWrapper: React.FC<TaskRoutesWrapperProps> = (props) => {
  return (
    <>
      <Switch>
        <Route exact path={routes.tasks} component={MyTasks} />
        <Route exact path={routes.tasks_new} component={CrudTask} />
        <Route exact path={routes.tasks_edit_id} component={CrudTask} />
        <Route component={NoMatch} />
      </Switch>
    </>
  );
};

export default TaskRoutesWrapper;
