import {
  CLOSE,
  DialogActionTypes,
  DialogBody,
  DialogState,
  SHOW,
} from "./types";

export const initialDialogState: DialogState = {
  show: false,
  dialog: {
    message: () => null,
    action: () => {},
    confirmButtonText: "OK",
  },
  size: "sm",
  buttonAppearance: "default",
  simple: false,
  redirect: false,
};

export function dialogReducer(
  state = initialDialogState,
  action: DialogActionTypes
): DialogState {
  switch (action.type) {
    case SHOW:
      return {
        ...state,
        show: true,
        dialog: Object.assign({}, action.dialog, {
          title: action.dialog.title,
          message: action.dialog.message,
          action: action.dialog.action,
          confirmButtonText: action.dialog.confirmButtonText
            ? action.dialog.confirmButtonText
            : initialDialogState.dialog.confirmButtonText,
        } as DialogBody),
        simple: action.simple !== undefined ? action.simple : state.simple,
        redirect:
          action.redirect !== undefined ? action.redirect : state.redirect,
        size: action.size !== undefined ? action.size : state.size,
        buttonAppearance:
          action.buttonApperance !== undefined
            ? action.buttonApperance
            : state.buttonAppearance,
      };
    case CLOSE:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
}

export default dialogReducer;
