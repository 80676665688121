import * as React from "react";
import { KvhTimeSvg } from "../../../../../../assets/icons/custom-svg-components/KvhSvgCollection";
import EventItemDateTime from "./EventItemDateTime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons/faFlagCheckered";

interface EventItemEndDateTimeProps {
  startDate: Date;
  endDate: Date;
}

const EventItemEndDateTime: React.FC<EventItemEndDateTimeProps> = ({
  startDate,
  endDate,
}) => {
  const eventOnlyTakesPlaceOnOneDay =
    startDate.toDateString() === endDate.toDateString();
  return (
    <>
      {eventOnlyTakesPlaceOnOneDay ? (
        <>
          <KvhTimeSvg />
          {startDate.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}{" "}
          -{" "}
          {endDate.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </>
      ) : (
        <>
          <FontAwesomeIcon
            icon={faFlagCheckered}
            style={{ fontSize: "16px", marginRight: "5px" }}
          />
          {endDate.toLocaleDateString()}
          <EventItemDateTime date={endDate} />
        </>
      )}
    </>
  );
};

export default EventItemEndDateTime;
