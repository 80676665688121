import { EventStatus } from "../../models/enums/eventStatus.enum";
import { BookingsSortBy } from "../../models/enums/sortBy.enum";
import { Utils } from "../utils";
import {
  LectureFilterData,
  MyLectureListPossibleFilters,
} from "./../../models/lecturesData";
import FilterService, { SortDirection } from "./filterService";
import { history } from "../../components/routing/history";

export class LectureFilterService extends FilterService<
  LectureFilterData,
  BookingsSortBy
> {
  constructor(page: number, filterData: LectureFilterData) {
    const sorting = filterData.sorting as BookingsSortBy;

    super({
      sort: sorting,
      page: page,
      attributes: filterData,
    });
  }

  /**
   * Gets the current filter values from the query params in the URL and parses the values and assigns them to a LectureFilterData object that can be used to initalize a filter form.
   * The value for ordering attribute comes from base filter service.
   */
  parseFiltersFromUrl(): LectureFilterData {
    const query = new URLSearchParams(history.location.search);

    const statusValues = this.parseNumberListFromFilterUrl(
      query.get("status") || "",
      EventStatus.Undefined
    );
    const queryFilter = super.parseFiltersFromUrl(query);

    return {
      ...queryFilter,
      status: statusValues,
    };
  }

  /**
   * Maps the string representation of the BookingsSortBy enum to one of the provided filters possibilites for sorting.
   * @param sort The enum or string value to parse to actual sort string.
   */
  mapSorting(sort: string | BookingsSortBy): string {
    switch (sort) {
      case BookingsSortBy.StatusAscending:
        return super.parseOrdering(
          Utils.propertyOf<MyLectureListPossibleFilters>("status")
        );
      case BookingsSortBy.StatusDescending:
        return super.parseOrdering(
          Utils.propertyOf<MyLectureListPossibleFilters>("status"),
          SortDirection.Descending
        );
      case BookingsSortBy.BeginAscending:
        return super.parseOrdering(
          Utils.propertyOf<MyLectureListPossibleFilters>("begin")
        );
      case BookingsSortBy.BeginDescending:
        return super.parseOrdering(
          Utils.propertyOf<MyLectureListPossibleFilters>("begin"),
          SortDirection.Descending
        );
      default:
        return super.parseOrdering(
          Utils.propertyOf<MyLectureListPossibleFilters>("begin")
        );
    }
  }

  /**
   * Maps the sort value and its direction to a possible BookingsSortBy enum value. Returns the default sorting if none of the provided values matches.
   * @param sort Value of attribute that should be sorted.
   */
  mapSortStringToEnum(sort: string): BookingsSortBy {
    switch (sort) {
      case super.parseOrdering(
        Utils.propertyOf<MyLectureListPossibleFilters>("status")
      ):
        return BookingsSortBy.StatusAscending;
      case super.parseOrdering(
        Utils.propertyOf<MyLectureListPossibleFilters>("status"),
        SortDirection.Descending
      ):
        return BookingsSortBy.StatusDescending;
      case super.parseOrdering(
        Utils.propertyOf<MyLectureListPossibleFilters>("begin")
      ):
        return BookingsSortBy.BeginAscending;
      case super.parseOrdering(
        Utils.propertyOf<MyLectureListPossibleFilters>("begin"),
        SortDirection.Descending
      ):
        return BookingsSortBy.BeginDescending;
      default:
        return BookingsSortBy.BeginAscending;
    }
  }
}
