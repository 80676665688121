import { Box, Button, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { AppState } from "../../../redux";
import { getCart, removeEventFromCart } from "../../../redux/cart/actions";
import { showDialog } from "../../../redux/dialogs/actions";
import useValidId from "../../hooks/useValidId";
import routes from "../../routing/routes";
import HeadingKvh from "../../theming/HeadingKvh";

export const WaitingListResponse: React.FC = () => {
  let { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector((state: AppState) => state.cart);
  const { isValidId } = useValidId(routes.waiting_list_response, id);

  const getEventTitleFromCartId = (id: string) => {
    let title = "";
    cart.cartList.forEach((cartItem) => {
      if (cartItem.guid.toString() === id) {
        title = cartItem.title;
      }
    });
    return title;
  };

  useEffect(() => {
    if (isValidId) {
      dispatch(getCart());
    }
  }, [dispatch, id, isValidId]);

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <HeadingKvh>Ihr Wartelistenplatz</HeadingKvh>
      <Grid container spacing={small ? 1 : 4}>
        <Grid item xs={12}>
          <Box component="div" textAlign={"center"} m={small ? 3 : 10}>
            <h3>Ihr Veranstaltungsplatz ist reserviert</h3>
            Sie können nun die Veranstaltung{" "}
            <b>{getEventTitleFromCartId(id)}</b> direkt über unseren
            Checkout-Prozess verbindlich buchen oder mitteilen, dass Sie doch
            nicht teilnehmen möchten und die Reservierung an den nächsten
            Wartelistenplatz weitergeben.
            <br />
            <br />
            Vielen Dank!
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box component="div" textAlign={"center"}>
            <Button
              size="medium"
              variant="contained"
              color="secondary"
              onClick={() => {
                dispatch(
                  showDialog({
                    title: "Nicht teilnehmen",
                    message:
                      "Hiermit geben Sie ihren reservierten Veranstaltungsplatz wieder frei. Diese Aktion ist nicht widerrufbar!",
                    action: () => {
                      dispatch(removeEventFromCart(id, true));
                      history.push(routes.home);
                    },
                  })
                );
              }}
              style={{
                marginLeft: "50px",
                marginRight: "50px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Nicht teilnehmen
            </Button>
            <Button
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(routes.checkout);
              }}
              style={{
                marginLeft: "50px",
                marginRight: "50px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              Zum Checkout
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
