import {
  UserType,
  mapToUserTypeString,
} from "../../models/enums/userType.enum";
import {
  PinboardListData,
  PinboardCommentsListData,
} from "../../models/pinboardData";

export const formatDateInfo = (date: Date) => {
  return `${date.getDate()}. ${date.toLocaleString("default", {
    month: "long",
  })} um ${date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })} Uhr`;
};

/**
 * Custom hook used to display information about creation (and last edit) of a pinboard-post.
 * !NOTE: Do not use last_changed_on because it is also updated on follow/archive click.
 * @param postType string question (first post of a topic) or answer (comment)
 * @param postObject corresponding question- or answer-data
 * @returns string meta-data containing author and date(s)
 */
const useMetaData = (
  postType: "Beitrag" | "Antwort",
  postObject: PinboardListData | PinboardCommentsListData,
  self: boolean
) => {
  const created = new Date(postObject.created_on),
    edited = new Date(postObject.last_edited_on);
  let author = self ? "mir" : postObject.author;
  if ([UserType.Employee, UserType.Speaker].includes(postObject.user_type))
    author += ` (${mapToUserTypeString(postObject.user_type)})`;
  let mutedInfo = `${postType} von ${author} am ` + formatDateInfo(created);
  if (self) {
    mutedInfo = `${
      postType === "Beitrag" ? "Mein Beitrag" : "Meine Antwort"
    } vom ${formatDateInfo(created)}`;
  }
  if (edited.toString() !== created.toString())
    mutedInfo += ` - ${postType} geändert am ` + formatDateInfo(edited);
  return mutedInfo;
};

export default useMetaData;
