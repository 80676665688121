import {
  CONTACT_CLEAR,
  CONTACT_FAILURE,
  CONTACT_REQUEST,
  CONTACT_SUCCESS,
  ContactActionTypes,
  ContactState,
} from "./types";

export const initialContactState: ContactState = {
  sendSuccess: false,
  isSending: false,
};

export function contactReducer(
  state = initialContactState,
  action: ContactActionTypes
): ContactState {
  switch (action.type) {
    case CONTACT_REQUEST:
      return {
        ...state,
        isSending: true,
      };
    case CONTACT_FAILURE:
      return {
        ...state,
        isSending: false,
      };
    case CONTACT_SUCCESS:
      return {
        ...state,
        sendSuccess: true,
        isSending: false,
      };
    case CONTACT_CLEAR:
      return initialContactState;
    default:
      return state;
  }
}

export default contactReducer;
