import { Box, Button, Divider, Grid, Hidden } from "@material-ui/core";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { KvhEducationSvg } from "../../../../../assets/icons/custom-svg-components/KvhSvgCollection";
import { EdgeDates, getELearningDates } from "../../../../../models/elearning";
import { mapCountToProgressStatus } from "../../../../../models/enums/elearningProgressStatus.enum";
import {
  ELearningParticipationStatus,
  ParticipationStatus,
} from "../../../../../models/enums/participationStatus.enum";
import { TrainingType } from "../../../../../models/enums/trainingType.enum";
import { AppState } from "../../../../../redux";
import {
  clickedEvaluationLink,
  getMyEvent,
} from "../../../../../redux/bookings/actions";
import { showDialog } from "../../../../../redux/dialogs/actions";
import { getDocumentsByEvent } from "../../../../../redux/documents/actions";
import usePermission from "../../../../../services/usePermissions";
import { Utils } from "../../../../../services/utils";
import useMobile from "../../../../hooks/useMobile";
import useValidId from "../../../../hooks/useValidId";
import routes from "../../../../routing/routes";
import HeadingKvh from "../../../../theming/HeadingKvh";
import { isEventOver } from "../../shared/checkEventIsOver";
import "../MyEvents.scss";
import styles from "./../../../../../styles/custom-styles.module.scss";
import EventDescription from "./EventDescription";
import EventDocuments from "./EventDocuments";
import PinboardLink from "./pinboard/PinboardLink";

const MyELearningDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const bookings = useSelector((state: AppState) => state.booking);
  const documents = useSelector((state: AppState) => state.documents);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const { isSpeaker } = usePermission(currentUser?.user_type);
  const { isValidId } = useValidId(
    isSpeaker()
      ? routes.my_lectures_details_elearning_id
      : routes.my_events_details_elearning_id,
    id
  );

  const [edgeDates, setEdgeDates] = React.useState<EdgeDates>({
    begin: new Date(),
    end: new Date(),
  });
  const progress = mapCountToProgressStatus(
    bookings.myEvent.elearning_started_count,
    bookings.myEvent.elearning_status === ELearningParticipationStatus.Passed
  );

  const { isMobile } = useMobile();

  const restrictAccessToLms = bookings.myEvent.restrict_access && !isSpeaker();

  useEffect(() => {
    if (
      bookings.myEvent.event.training_type === TrainingType.ELearning &&
      bookings.myEvent.elearning_status ===
        ELearningParticipationStatus.Passed &&
      !bookings.myEvent.reactivated &&
      bookings.myEvent.event.evaluation_link &&
      !bookings.myEvent.user_has_clicked_evaluation_link &&
      bookings.myEvent.guid !== ""
    ) {
      dispatch(
        showDialog({
          title: "Bewertung des E-Learnings",
          message: (
            <>
              Möchten Sie sich einen kurzen Moment Zeit nehmen, um das
              E-Learning zu bewerten?
            </>
          ),
          confirmButtonText: "Zur Evaluation",
          action: () => {
            window.open(bookings.myEvent.event.evaluation_link);
            dispatch(clickedEvaluationLink(bookings.myEvent.guid, true, false));
          },
        })
      );
    } else if (
      isEventOver(bookings.myEvent.event.end) &&
      bookings.myEvent.event.training_type === TrainingType.BlendedLearning &&
      bookings.myEvent.status === ParticipationStatus.Participated &&
      bookings.myEvent.elearning_status ===
        ELearningParticipationStatus.Passed &&
      !bookings.myEvent.reactivated &&
      bookings.myEvent.event.evaluation_link_blended_learning &&
      !bookings.myEvent.user_has_clicked_evaluation_link_blended_learning &&
      bookings.myEvent.guid !== ""
    ) {
      dispatch(
        showDialog({
          title: "Bewertung des E-Learning-Teils des Blended-Learnings",
          message: (
            <>
              Möchten Sie sich einen kurzen Moment Zeit nehmen, um das
              E-Learning zu bewerten?
            </>
          ),
          confirmButtonText: "Zur Evaluation",
          action: () => {
            window.open(
              bookings.myEvent.event.evaluation_link_blended_learning
            );
            dispatch(clickedEvaluationLink(bookings.myEvent.guid, true, true));
          },
        })
      );
    }
    setEdgeDates(getELearningDates(bookings.myEvent));
  }, [bookings.myEvent, dispatch]);

  useEffect(() => {
    if (isValidId && !bookings.isLoading && !bookings.myEventLoaded) {
      dispatch(getMyEvent(id));
    }
  }, [dispatch, id, isValidId, bookings]);

  useEffect(() => {
    if (
      [
        ParticipationStatus.Booked,
        ParticipationStatus.Participated,
        ParticipationStatus.Cancelled,
      ].includes(bookings.myEvent.status)
    ) {
      dispatch(
        getDocumentsByEvent(bookings.myEvent.event.guid, false, "elearning")
      );
    }
  }, [bookings.myEvent.event.guid, bookings.myEvent.status, dispatch]);

  const eventTitle =
    bookings.myEvent.event.title.length > 0
      ? bookings.myEvent.event.title
      : "(Kein Titel)";

  return (
    <>
      <HeadingKvh>{eventTitle}</HeadingKvh>
      <Grid container style={{ marginTop: "20px", padding: "8px" }} spacing={2}>
        <Grid item xs={12} md={5}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sm={6}
              className={
                "myevents-details-property-label myevents-details-property-container"
              }
            >
              Kursnummer
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={"myevents-details-property-container"}
            >
              {bookings.myEvent.event.public_id}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={
                "myevents-details-property-label myevents-details-property-container"
              }
            >
              Preis
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={"myevents-details-property-container"}
            >
              {Utils.formatPrice(bookings.myEvent.event.price)}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={
                "myevents-details-property-label myevents-details-property-container"
              }
            >
              Punkte
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={"myevents-details-property-container"}
            >
              {bookings.myEvent.event.score}
            </Grid>
          </Grid>
        </Grid>

        <Hidden mdUp>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Hidden>

        <Grid item xs={12} md={4}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={6}
              className={
                "myevents-details-property-label myevents-details-property-container"
              }
            >
              Startzeit
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={"myevents-details-property-container"}
            >
              {edgeDates.begin.toLocaleDateString()} (
              {edgeDates.begin.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
              Uhr)
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={
                "myevents-details-property-label myevents-details-property-container"
              }
            >
              Endzeit
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={"myevents-details-property-container"}
            >
              {edgeDates.end.toLocaleDateString()} (
              {edgeDates.end.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
              Uhr)
            </Grid>
            {bookings.myEvent.reactivated && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={
                    "myevents-details-property-label myevents-details-property-container"
                  }
                >
                  Verlängert bis
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={"myevents-details-property-container"}
                >
                  {new Date(bookings.myEvent.overall_end).toLocaleDateString()}{" "}
                  (
                  {new Date(bookings.myEvent.overall_end).toLocaleTimeString(
                    [],
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )}{" "}
                  Uhr)
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box display={"flex"} flexDirection={"column"}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {/* <ProgressPieChart progress={progress} /> */}
              {/* <Box ml={2}>
                <strong>Fortschritt: {progress}%</strong>
              </Box> */}
              <Box>
                <strong>
                  Fortschritt:{" "}
                  {/* <span style={{ color: getProgressColor(progress) }}> */}
                </strong>
                <span>{progress}</span>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <Button
                disabled={restrictAccessToLms}
                onClick={() => {
                  if (!restrictAccessToLms) {
                    if (!isMobile) {
                      window.open(
                        `${routes.lms}/${bookings.myEvent.guid}`,
                        "_blank",
                        "noopener, noreferrer"
                      );
                    } else {
                      dispatch(
                        showDialog(
                          {
                            title:
                              "Bearbeitung des Kurses auf mobilen Endgeräten mit geringer Auflösung nicht möglich",
                            message: (
                              <>
                                Die Bearbeitung von E-Learnings ist auf mobilen
                                Endgeräten mit geringer Auflösung nicht möglich.
                                Wir können Ihnen so nicht die vollständige
                                Benutzererfahrung bieten. Um eine reibungslose
                                Bearbeitung des Kurses zu erlauben, wechseln Sie
                                bitte auf einen Desktop-PC oder ein mobiles
                                Endgerät mit einer Bildschirm-Auflösung von
                                mindestens 1024x768.
                              </>
                            ),
                          },
                          true
                        )
                      );
                    }
                  }
                }}
              >
                <KvhEducationSvg
                  fontSize="4.5em"
                  strokeColor={
                    !restrictAccessToLms
                      ? styles.primary
                      : styles["secondary-accent-2"]
                  }
                />
                <strong>E-Learning starten</strong>
              </Button>
            </Box>
          </Box>
        </Grid>
        {bookings.myEvent.event.pinboard_is_activated &&
          bookings.myEvent.is_within_elearning_period && (
            <PinboardLink
              eventId={bookings.myEvent.event.guid.toString()}
              booking={bookings.myEvent}
            />
          )}
        <EventDescription description={bookings.myEvent.event.description} />
        <EventDocuments
          documents={documents}
          no_automatic_participation_certificate={
            bookings.myEvent.event.no_automatic_participation_certificate
          }
        />
      </Grid>
    </>
  );
};

export default MyELearningDetails;
