import {
  AlertActionTypes,
  AlertState,
  CLEAR,
  ERROR,
  SUCCESS,
  AlertBody,
  INFO,
} from "./types";

export const initialAlertState: AlertState = {
  alert: {},
  delay: 100,
  show: false,
};

const defaultDelay = 5000;

export function alertReducer(
  state = initialAlertState,
  action: AlertActionTypes
): AlertState {
  switch (action.type) {
    case SUCCESS:
      return {
        ...state,
        alert: Object.assign({}, action.alert, {
          type: "success",
          message: action.alert.message,
        } as AlertBody),
        delay: action.delay || defaultDelay,
        show: true,
      };
    case ERROR:
      return {
        ...state,
        alert: Object.assign({}, action.alert, {
          type: "error",
          message: action.alert.message,
        } as AlertBody),
        delay: action.delay || defaultDelay,
        show: true,
      };
    case INFO:
      return {
        ...state,
        alert: Object.assign({}, action.alert, {
          type: "info",
          message: action.alert.message,
        } as AlertBody),
        delay: action.delay || defaultDelay,
        show: true,
      };
    case CLEAR:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
}

export default alertReducer;
