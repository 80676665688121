import {
  FilledInput,
  FilledInputProps,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import { FieldAttributes, useField, useFormikContext } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { KvhSearchSvg } from "../../assets/icons/custom-svg-components/KvhSvgCollection";

interface CustomSearchFieldProps {
  label: string;
  customChangeHandler?: (values: string) => void;
  initialValue?: string;
  hideEndAdornment?: boolean;
}

const CustomSearchField: React.FC<
  FieldAttributes<{}> & FilledInputProps & CustomSearchFieldProps
> = ({ label, customChangeHandler, initialValue, ...props }) => {
  const [field] = useField<{}>(props);
  const { setFieldValue } = useFormikContext();
  const [searchTerm, setSearchTerm] = useState(
    initialValue ? initialValue : ""
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm !== field.value) {
        if (customChangeHandler) customChangeHandler(searchTerm);
        setFieldValue(field.name, searchTerm);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [customChangeHandler, field.name, field.value, searchTerm, setFieldValue]);

  return (
    <>
      <FormControl fullWidth variant="filled">
        <InputLabel htmlFor="search">{label}</InputLabel>
        <FilledInput
          {...props}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          autoComplete="off"
          endAdornment={
            props.hideEndAdornment ? (
              <></>
            ) : (
              <InputAdornment position="end">
                <IconButton
                  aria-label="search for keyword"
                  onClick={() => {
                    setFieldValue(field.name, searchTerm);
                  }}
                  edge="end"
                >
                  <KvhSearchSvg />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </FormControl>
    </>
  );
};

export default CustomSearchField;
