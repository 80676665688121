import Draft, { EditorState } from "draft-js";
import { TAutocompleteItem, TCustomControl } from "mui-rte";

const useEmojis = () => {
  const emojis: TAutocompleteItem[] = [
    {
      keys: ["big", "grinning", "face", "eyes"],
      value: "😃",
      content: "😃",
    },
    {
      keys: ["big-smile", "grinning", "face", "eyes"],
      value: "😄",
      content: "😄",
    },
    {
      keys: ["beaming", "face", "smiling", "eyes"],
      value: "😁",
      content: "😁",
    },
    {
      keys: ["squinting", "face"],
      value: "😆",
      content: "😆",
    },
    {
      keys: ["sweat", "grinning"],
      value: "😅",
      content: "😅",
    },
    {
      keys: ["rolling", "laughing"],
      value: "🤣",
      content: "🤣",
    },
    {
      keys: ["joy", "face", "tears"],
      value: "😂",
      content: "😂",
    },
    {
      keys: ["slightly", "smiling", "face"],
      value: "🙂",
      content: "🙂",
    },
    {
      keys: ["upside-down", "face"],
      value: "🙃",
      content: "🙃",
    },
    {
      keys: ["winking", "face"],
      value: "😉",
      content: "😉",
    },
    {
      keys: ["smiling", "face", "smiling", "eyes"],
      value: "😊",
      content: "😊",
    },
    {
      keys: ["smiling", "face", "halo"],
      value: "😇",
      content: "😇",
    },
    {
      keys: ["smiling", "face", "hearts"],
      value: "🥰",
      content: "🥰",
    },
    {
      keys: ["smiling", "face", "heart-eyes"],
      value: "😍",
      content: "😍",
    },
    {
      keys: ["star-struck"],
      value: "🤩",
      content: "🤩",
    },
    {
      keys: ["face", "blowing", "kiss"],
      value: "😘",
      content: "😘",
    },
    {
      keys: ["kissing", "face"],
      value: "😗",
      content: "😗",
    },
    {
      keys: ["kissing", "face", "closed", "eyes"],
      value: "😚",
      content: "😚",
    },
    {
      keys: ["kissing", "face", "smiling", "eyes"],
      value: "😙",
      content: "😙",
    },
    {
      keys: ["face", "savoring", "food"],
      value: "😋",
      content: "😋",
    },
    {
      keys: ["face", "tongue"],
      value: "😛",
      content: "😛",
    },
    {
      keys: ["winking", "face", "tongue"],
      value: "😜",
      content: "😜",
    },
    {
      keys: ["zany", "face"],
      value: "🤪",
      content: "🤪",
    },
    {
      keys: ["hugging", "face"],
      value: "🤗",
      content: "🤗",
    },
    {
      keys: ["face", "hand", "over", "mouth"],
      value: "🤭",
      content: "🤭",
    },
    {
      keys: ["shushing", "face"],
      value: "🤫",
      content: "🤫",
    },
    {
      keys: ["thinking", "face"],
      value: "🤔",
      content: "🤔",
    },
    {
      keys: ["zipper-mouth", "face"],
      value: "🤐",
      content: "🤐",
    },
    {
      keys: ["face", "raised", "eyebrow"],
      value: "🤨",
      content: "🤨",
    },
    {
      keys: ["neutral", "face"],
      value: "😐",
      content: "😐",
    },
    {
      keys: ["expressionless", "face"],
      value: "😑",
      content: "😑",
    },
    {
      keys: ["face", "without", "mouth"],
      value: "😶",
      content: "😶",
    },
    {
      keys: ["face", "in", "clouds"],
      value: "😶‍🌫️",
      content: "😶‍🌫️",
    },
    {
      keys: ["smirking", "face"],
      value: "😏",
      content: "😏",
    },
    {
      keys: ["unamused", "face"],
      value: "😒",
      content: "😒",
    },
    {
      keys: ["face", "rolling", "eyes"],
      value: "🙄",
      content: "🙄",
    },
    {
      keys: ["grimacing", "face"],
      value: "😬",
      content: "😬",
    },
    {
      keys: ["face", "exhaling"],
      value: "😮‍💨",
      content: "😮‍💨",
    },
    {
      keys: ["lying", "face"],
      value: "🤥",
      content: "🤥",
    },
    {
      keys: ["relieved", "face"],
      value: "😌",
      content: "😌",
    },
    {
      keys: ["pensive", "face"],
      value: "😔",
      content: "😔",
    },
    {
      keys: ["cold", "face"],
      value: "🥶",
      content: "🥶",
    },
    {
      keys: ["woozy", "face"],
      value: "🥴",
      content: "🥴",
    },
    {
      keys: ["dizzy", "face"],
      value: "😵",
      content: "😵",
    },
    {
      keys: ["face", "spiral", "eyes"],
      value: "😵‍💫",
      content: "😵‍💫",
    },
    {
      keys: ["exploding", "head"],
      value: "🤯",
      content: "🤯",
    },
    {
      keys: ["cowboy", "hat", "face"],
      value: "🤠",
      content: "🤠",
    },
    {
      keys: ["partying", "face"],
      value: "🥳",
      content: "🥳",
    },
    {
      keys: ["sunglasses", "smiling", "face"],
      value: "😎",
      content: "😎",
    },
    {
      keys: ["nerd", "face"],
      value: "🤓",
      content: "🤓",
    },
    {
      keys: ["face", "monocle"],
      value: "🧐",
      content: "🧐",
    },
    {
      keys: ["confused", "face"],
      value: "😕",
      content: "😕",
    },
    {
      keys: ["worried", "face"],
      value: "😟",
      content: "😟",
    },
    {
      keys: ["frowning", "slightly", "face"],
      value: "🙁",
      content: "🙁",
    },
    {
      keys: ["mouth", "face", "open"],
      value: "😮",
      content: "😮",
    },
    {
      keys: ["hushed", "face"],
      value: "😯",
      content: "😯",
    },
    {
      keys: ["astonished", "face"],
      value: "😲",
      content: "😲",
    },
    {
      keys: ["flushed", "face"],
      value: "😳",
      content: "😳",
    },
    {
      keys: ["fearful", "face"],
      value: "😨",
      content: "😨",
    },
    {
      keys: ["anxious", "face", "sweat"],
      value: "😰",
      content: "😰",
    },
    {
      keys: ["sad", "but", "relieved", "face"],
      value: "😥",
      content: "😥",
    },
    {
      keys: ["crying", "face"],
      value: "😢",
      content: "😢",
    },
    {
      keys: ["loudly", "crying", "face"],
      value: "😭",
      content: "😭",
    },
    {
      keys: ["waving", "hand"],
      value: "👋",
      content: "👋",
    },
    {
      keys: ["raised", "back", "hand"],
      value: "🤚",
      content: "🤚",
    },
    {
      keys: ["splayed", "hand"],
      value: "🖐️",
      content: "🖐️",
    },
    {
      keys: ["raised", "hand"],
      value: "✋",
      content: "✋",
    },
    {
      keys: ["vulcan", "salute"],
      value: "🖖",
      content: "🖖",
    },
    {
      keys: ["ok", "hand"],
      value: "👌",
      content: "👌",
    },
    {
      keys: ["pinching", "hand"],
      value: "🤏",
      content: "🤏",
    },
    {
      keys: ["victory", "hand"],
      value: "✌️",
      content: "✌️",
    },
    {
      keys: ["crossed", "fingers"],
      value: "🤞",
      content: "🤞",
    },
    {
      keys: ["call-me", "hand"],
      value: "🤙",
      content: "🤙",
    },
    {
      keys: ["backhand", "index", "pointing", "left"],
      value: "👈",
      content: "👈",
    },
    {
      keys: ["backhand", "index", "pointing", "right"],
      value: "👉",
      content: "👉",
    },
    {
      keys: ["backhand", "index", "pointing", "up"],
      value: "👆",
      content: "👆",
    },
    {
      keys: ["backhand", "index", "pointing", "down"],
      value: "👇",
      content: "👇",
    },
    {
      keys: ["index", "pointing", "up"],
      value: "☝️",
      content: "☝️",
    },
    {
      keys: ["thumbs-up", "up", "thumbs"],
      value: "👍",
      content: "👍",
    },
    {
      keys: ["thumbs", "down"],
      value: "👎",
      content: "👎",
    },
    {
      keys: ["clapping", "hands"],
      value: "👏",
      content: "👏",
    },
    {
      keys: ["raising", "hands"],
      value: "🙌",
      content: "🙌",
    },
    {
      keys: ["open", "hands"],
      value: "👐",
      content: "👐",
    },
    {
      keys: ["palms", "up", "together"],
      value: "🤲",
      content: "🤲",
    },
    {
      keys: ["handshake"],
      value: "🤝",
      content: "🤝",
    },
    {
      keys: ["folded", "hands"],
      value: "🙏",
      content: "🙏",
    },
    {
      keys: ["writing", "hand"],
      value: "✍️",
      content: "✍️",
    },
    {
      keys: ["flexed", "biceps"],
      value: "💪",
      content: "💪",
    },
  ];

  const emojiTitle =
    "Um ein Emoji-Symbol auszuwählen, können Sie z.B. auch :face eingeben.";
  const faces = emojis.map((emoji) => {
    return `${emoji.content} :${emoji.keys
      .join(" :")
      .replaceAll("/:$/", "\n")}`;
  });

  const basicEmojis = [
    "big",
    "big-smile",
    "beaming",
    "squinting",
    "sweat",
    "rolling",
    "joy",
    "slightly",
    "upside-down",
    "confused",
    "winking",
    "hugging",
    "thinking",
    "astonished",
    "crying",
    "waving",
    "ok",
    "victory",
    "crossed",
    "flexed",
    "thumbs-up",
  ];
  let controls = [
    "title",
    "bold",
    "italic",
    "underline",
    "undo",
    "redo",
    "numberList",
    "bulletList",
    "indentLine",
    "link",
  ];
  controls = [...controls, ...basicEmojis, "emojisHint"];

  const emojiControls: TCustomControl[] = basicEmojis.map((emojiName) => {
    return {
      name: emojiName,
      icon: (
        <small>
          {emojis.filter((emoji) => emoji.keys[0] === emojiName)[0].value}
        </small>
      ),
      type: "callback",
      onClick: (editorState: EditorState, name: string) => {
        return EditorState.createWithContent(
          Draft.ContentState.createFromText(
            editorState.getCurrentContent().getPlainText() +
              emojis.filter((emoji) => emoji.keys[0] === name)[0].value
          )
        );
      },
    };
  });

  return { emojis, basicEmojis, emojiTitle, faces, controls, emojiControls };
};

export default useEmojis;
