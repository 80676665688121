import * as React from "react";
import "./Cart.scss";
import { useDispatch } from "react-redux";
import { removeEventFromCart } from "../../../redux/cart/actions";
import { CartData } from "../../../models/cartData";
import CustomButton from "../../theming/CustomButton";
import styles from "./../../../styles/custom-styles.module.scss";

interface EmptyCartButtonProps {
  cartList: CartData[];
}

const EmptyCartButton: React.FC<EmptyCartButtonProps> = (props) => {
  const dispatch = useDispatch();

  return (
    <>
      {props.cartList.length > 0 && (
        <>
          <CustomButton
            onClick={() => {
              props.cartList.forEach((cartItem) => {
                dispatch(removeEventFromCart(cartItem.guid));
              });
            }}
            customColor={styles.red}
            textColor={styles.white}
            hoverColor={styles["red-dark"]}
            style={{ marginRight: "20px" }}
          >
            Warenkorb leeren
          </CustomButton>
        </>
      )}
    </>
  );
};

export default EmptyCartButton;
