import { Alert } from "@material-ui/lab";
import * as React from "react";

interface MaintenanceBannerProps {}

const MAINTENANCE_YEAR: number =
    Number(process.env.REACT_APP_KVH_MAINTENANCE_YEAR) || 2023,
  MAINTENANCE_MONTH: number = Number(process.env.REACT_APP_KVH_MAINTENANCE_MONTH) || 10,
  MAINTENANCE_DAY_OF_MONTH: number =
    Number(process.env.REACT_APP_KVH_MAINTENANCE_DAY_OF_MONTH) || 23;
const MAINTENANCE_TIME_HOUR_BEGIN: string =
    process.env.REACT_APP_KVH_MAINTENANCE_TIME_HOUR_BEGIN || "17",
  MAINTENANCE_TIME_HOUR_END: string =
    process.env.REACT_APP_KVH_MAINTENANCE_TIME_HOUR_END || "18";

const MaintenanceBanner: React.FC<MaintenanceBannerProps> = (props) => {
  const isMaintenanceDay = () => {
    const today = new Date();
    const firstTuesdayOfMonth = getFirstTuesdayOfMonth();
    const manuallySetDate = new Date(
      MAINTENANCE_YEAR,
      MAINTENANCE_MONTH - 1,
      MAINTENANCE_DAY_OF_MONTH
    );
    return (
      today.getHours() <= Number(MAINTENANCE_TIME_HOUR_END) &&
      (todayMatchesGivenDate(firstTuesdayOfMonth) ||
        todayMatchesGivenDate(manuallySetDate))
    );
  };

  function todayMatchesGivenDate(dateToMatch: Date) {
    const today = new Date();
    return (
      dateToMatch.getDate() === today.getDate() &&
      dateToMatch.getMonth() === today.getMonth() &&
      dateToMatch.getFullYear() === today.getFullYear()
    );
  }

  function getFirstTuesdayOfMonth() {
    const now = new Date();
    let tuesday = null;

    // start with first day of month
    now.setDate(1);

    // determine the first tuesday of the month
    while (now.getDay() !== 2) {
      // go to next day until first tuesday is found
      now.setDate(now.getDate() + 1);
    }

    tuesday = new Date(now.getTime());

    return tuesday;
  }

  return (
    <>
      {isMaintenanceDay() && (
        <Alert
          severity="info"
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Am {new Date().toLocaleDateString()} von {MAINTENANCE_TIME_HOUR_BEGIN}
          :00 bis {MAINTENANCE_TIME_HOUR_END}:00 Uhr findet eine Wartung der
          Website statt. Während der Wartungsarbeiten steht die Website nicht
          bzw. nur eingeschränkt zur Verfügung.
        </Alert>
      )}
    </>
  );
};

export default MaintenanceBanner;
