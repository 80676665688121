import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { dashboardService } from "../../api";
import { DashboardListData } from "../../models/dashboardData";
import { Pagination } from "../../models/pagination";
import { error, success } from "../alerts/actions";
import { AlertActionTypes, ErrorAction } from "../alerts/types";
import {
  DashboardActionTypes,
  DashboardClearAction,
  DashboardFailureAction,
  DashboardPageCountAction,
  DashboardRequestAction,
  DashboardSuccessAction,
  DASHBOARD_CLEAR,
  DASHBOARD_FAILURE,
  DASHBOARD_PAGE_COUNT,
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
} from "./types";

export function getDashboard(page: number) {
  return (dispatch: Dispatch<DashboardActionTypes | ErrorAction>) => {
    dispatch(dashboardRequest());
    dashboardService
      .getDashboard(page)
      .then((response: AxiosResponse<Pagination<DashboardListData>>) => {
        response.data.page = page;
        dispatch(dashboardPageCount(page));
        dispatch(dashboardSuccess(response.data));
      })
      .catch((err: AxiosError) => {
        if (page > 1) {
          const href = window.location.href;
          window.location.href = href;
        } else {
          dispatch(dashboardFailure());
          dispatch(
            error(
              {
                message:
                  "Beim Laden der E-Learnings ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!",
              },
              5000
            )
          );
        }
      });
  };
}

export function bookFree(eventGuid: string) {
  return (
    dispatch: Dispatch<DashboardClearAction | AlertActionTypes | ErrorAction>
  ) => {
    dashboardService
      .bookFree(eventGuid)
      .then(() => {
        dispatch(dashboardClear());
        dispatch(
          success(
            {
              message:
                "Die kostenlose Test-Buchung wurde erfolgreich angelegt. Sie können jetzt starten!",
            },
            3000
          )
        );
      })
      .catch((err: AxiosError) => {
        dispatch(
          error(
            {
              message:
                "Es ist ein Fehler bei der Buchung aufgetreten. Bitte versuchen Sie es erneut!",
            },
            5000
          )
        );
      });
  };
}

export function dashboardRequest(): DashboardRequestAction {
  return {
    type: DASHBOARD_REQUEST,
  };
}

export function dashboardSuccess(
  data: Pagination<DashboardListData>
): DashboardSuccessAction {
  return {
    type: DASHBOARD_SUCCESS,
    data,
  };
}

export function dashboardFailure(): DashboardFailureAction {
  return {
    type: DASHBOARD_FAILURE,
  };
}

export function dashboardPageCount(
  pageCount: number
): DashboardPageCountAction {
  return {
    type: DASHBOARD_PAGE_COUNT,
    pageCount: pageCount,
  };
}

export function dashboardClear(): DashboardClearAction {
  return {
    type: DASHBOARD_CLEAR,
  };
}
