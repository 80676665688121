import { Box, Grid, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { useFormikContext } from "formik";
import * as React from "react";
import { EventStatus } from "../../../../../models/enums/eventStatus.enum";
import { TrainingType } from "../../../../../models/enums/trainingType.enum";
import { EventFormData } from "../../../../../models/eventData";
import CustomTextField from "../../../../forms/CustomTextField";

interface EvaluationLinkProps {
  trainingType: TrainingType;
}

const EvaluationLink: React.FC<EvaluationLinkProps> = (props) => {
  const { values, initialValues } = useFormikContext<EventFormData>();
  const titleText = () => {
    const speakerAndParticipantLinksHint =
      "Es ist zu beachten, dass Referent(en) und Teilnehmer jeweils unterschiedliche Links angezeigt bekommen.";

    if (props.trainingType === TrainingType.DefaultEvent) {
      return (
        "Links zu externer Evaluation der Veranstaltung: sind nach Abschluss der Veranstaltung sichtbar für Teilnehmer und/oder Referenten. " +
        speakerAndParticipantLinksHint
      );
    } else if (props.trainingType === TrainingType.ELearning) {
      return "Links zu externer Evaluation des E-Learnings: Wenn angegeben, steht dieser Link dem Teilnehmer nach Beendigung des E-Learnings zur Verfügung.";
    } else {
      return (
        "Links zu externer Evaluation des Blended-Learnings: Die Evaluationslinks stehen zum einen nach Ende der Veranstaltung, zum anderen nach Beendigung des E-Learning-Moduls des Blended-Learnings zur Verfügung. " +
        speakerAndParticipantLinksHint +
        " Der Referent kann nur den Teil des Blended-Learnings bewerten, an dessen Durchführung er beteiligt war (Veranstaltung)."
      );
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <h3 style={{ marginRight: "0.3em" }}>Evaluation</h3>
        <Tooltip
          placement="right"
          enterDelay={500}
          leaveDelay={200}
          title={titleText()}
          arrow
        >
          <Info color="primary" />
        </Tooltip>
      </Box>
      {props.trainingType === TrainingType.BlendedLearning && (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              disabled={
                values.status === EventStatus.Completed &&
                initialValues.status === EventStatus.Completed
              }
              fullWidth
              name="evaluationLinkBlendedLearning"
              id="evaluation"
              label={"Evaluationslink Teilnehmer E-Learning"}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            disabled={
              values.status === EventStatus.Completed &&
              initialValues.status === EventStatus.Completed
            }
            fullWidth
            name="evaluationLink"
            id="evaluation"
            label={
              "Evaluationslink Teilnehmer" +
              (props.trainingType === TrainingType.ELearning
                ? " E-Learning"
                : "")
            }
          />
        </Grid>
        {props.trainingType !== TrainingType.ELearning && (
          <Grid item xs={12} sm={6}>
            <CustomTextField
              disabled={
                values.status === EventStatus.Completed &&
                initialValues.status === EventStatus.Completed
              }
              fullWidth
              name="evaluationLinkSpeaker"
              id="evaluation"
              label="Evaluationslink Referent"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default EvaluationLink;
