import { MoodleBaseData } from "../../models/userData";

// auth state contains data that comes from API and logged in state
export interface AuthState {
  loggedIn: boolean;
  isLoading: boolean;
  loggedInFrom: string;
  queryParams: string;
  moddleLoginData: MoodleBaseData;
  moodleDataLoaded: boolean;
  moodleError: boolean;
  isLoggedInToMoodle: boolean;
}

export const REQUEST = "REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const FETCHING_AUTH_DATA_ACTION_FINISHED =
  "FETCHING_AUTH_DATA_ACTION_FINISHED";

export const GET_SESSION_TOKEN_SUCCESS = "GET_SESSION_TOKEN_SUCCESS";

export const FETCH_MOODLE_DATA_SUCCESS = "FETCH_MOODLE_DATA_SUCCESS";
export const LOGIN_MOODLE_SUCCESS = "LOGIN_MOODLE_SUCCESS";
export const MOODLE_ERROR = "MOODLE_ERROR";

export const LOGOUT = "LOGOUT";

export interface RequestAction {
  type: typeof REQUEST;
}

export interface FetchMoodleDataSuccessAction {
  type: typeof FETCH_MOODLE_DATA_SUCCESS;
  data: MoodleBaseData;
}
export interface LoginMoodleSuccessAction {
  type: typeof LOGIN_MOODLE_SUCCESS;
}
export interface MoodleErrorAction {
  type: typeof MOODLE_ERROR;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  from?: any;
}

export interface LoginFailureAction {
  type: typeof LOGIN_FAILURE;
}

export interface GetSessionTokenSuccessAction {
  type: typeof GET_SESSION_TOKEN_SUCCESS;
}

export interface LogoutAction {
  type: typeof LOGOUT;
}

export interface FetchingAuthDataFinishedAction {
  type: typeof FETCHING_AUTH_DATA_ACTION_FINISHED;
}

export type AuthActionTypes =
  | RequestAction
  | LoginSuccessAction
  | FetchMoodleDataSuccessAction
  | LoginMoodleSuccessAction
  | MoodleErrorAction
  | LoginFailureAction
  | GetSessionTokenSuccessAction
  | LogoutAction
  | FetchingAuthDataFinishedAction;
