import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { ApiHelper } from "../../api/apiHelper";
import { bookingService } from "../../api/index";
import { ParticipationStatus } from "../../models/enums/participationStatus.enum";
import { error, success } from "../alerts/actions";
import { ErrorAction, SuccessAction } from "../alerts/types";
import { IncrementWaitingListInEvent } from "../events/actions";
import { EventActionTypes } from "../events/types";
import { clearParticipant } from "../participants/actions";
import { ClearParticipantAction } from "../participants/types";
import {
  WaitingListActionTypes,
  WaitingListFailureAction,
  WaitingListRequestAction,
  WaitingListSuccessAction,
  WAITINGLIST_FAILURE,
  WAITINGLIST_REQUEST,
  WAITINGLIST_SUCCESS,
} from "./types";

export function putParticipantToWaitingList(eventId: string) {
  return (
    dispatch: Dispatch<
      WaitingListActionTypes | EventActionTypes | SuccessAction | ErrorAction
    >
  ) => {
    dispatch(waitingListRequest());
    bookingService
      .putOnWaitingList({ event: eventId, status: 10 })
      .then(() => {
        dispatch(waitingListSuccess());
        dispatch(IncrementWaitingListInEvent(eventId));
        dispatch(
          success(
            {
              message: "Sie wurden erfolgreich auf die Warteliste gesetzt.",
            },
            5000
          )
        );
      })
      .catch((err: AxiosError) => {
        dispatch(waitingListFailure());
        const errorObject = err.response?.data;
        dispatch(
          error(
            {
              message: ApiHelper.showErrorMessageFromApi(errorObject),
            },
            5000
          )
        );
      });
  };
}

export function putOtherParticipantToWaitingList(
  eventGuid: string,
  userGuid: string
) {
  return (
    dispatch: Dispatch<
      | WaitingListActionTypes
      | EventActionTypes
      | SuccessAction
      | ErrorAction
      | ClearParticipantAction
    >
  ) => {
    dispatch(waitingListRequest());
    bookingService
      .putOtherUserOnWaitingList({ event: eventGuid, status: 10 }, userGuid)
      .then(() => {
        dispatch(waitingListSuccess());
        dispatch(
          IncrementWaitingListInEvent(eventGuid, {
            user: userGuid,
            status: ParticipationStatus.WaitingList,
          })
        );
        dispatch(clearParticipant());
        dispatch(
          success(
            {
              message:
                "Der verknüpfte Teilnehmer wurde erfolgreich auf die Warteliste gesetzt.",
            },
            5000
          )
        );
      })
      .catch((err: AxiosError) => {
        dispatch(waitingListFailure());
        const errorObject = err.response?.data;
        dispatch(
          error(
            {
              message: ApiHelper.showErrorMessageFromApi(errorObject),
            },
            5000
          )
        );
      });
  };
}

export function waitingListRequest(): WaitingListRequestAction {
  return {
    type: WAITINGLIST_REQUEST,
  };
}

export function waitingListFailure(): WaitingListFailureAction {
  return {
    type: WAITINGLIST_FAILURE,
  };
}

export function waitingListSuccess(): WaitingListSuccessAction {
  return {
    type: WAITINGLIST_SUCCESS,
  };
}
