import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { eventService } from "../../api";
import { history } from "../../components/routing/history";
import routes, {
  getEditRouteBasedOnTrainingType,
} from "../../components/routing/routes";
import {
  CrudEventDataDto,
  EventDataDto,
  EventFilterData,
  EventFilterValues,
  EventListData,
  UserLinkedAccountStats,
} from "../../models/eventData";
import { LecturesData } from "../../models/lecturesData";
import { Pagination } from "../../models/pagination";
import { EventFilterService } from "../../services/filters/eventFiltersService";
import { error, success } from "../alerts/actions";
import { AlertActionTypes, ErrorAction, SuccessAction } from "../alerts/types";
import { clearDocuments } from "../documents/actions";
import { ClearDocumentsAction } from "../documents/types";
import { clearParticipant } from "../participants/actions";
import { ClearParticipantAction } from "../participants/types";
import { clearSpeakers } from "../speaker/actions";
import { ClearSpeakersAction } from "../speaker/types";
import {
  getParticipantsTabBasedOnTrainingType,
  setOpenTab,
} from "../tabs/actions";
import { SetOpenTabAction, TabsActionTypes } from "../tabs/types";
import { EventTemplatesActionTypes } from "../templates/events/types";
import {
  ELearningData,
  ELearningDto,
  ELearningListData,
} from "./../../models/elearning";
import {
  AddEventSuccessAction,
  AddToListOfEventsSuccessAction,
  ADD_EVENT_SUCCESS,
  ADD_TO_LIST_OF_EVENTS_SUCCESS,
  ClearEditEventAction,
  ClearEventListAction,
  CLEAR_EDIT_EVENT,
  CLEAR_EVENT_LIST,
  DeleteEventSuccessAction,
  DELETE_EVENT_SUCCESS,
  EditEventStatusSuccessAction,
  EDIT_EVENT_STATUS_SUCCESS,
  EventActionTypes,
  EventsFailureAction,
  EventsRequestAction,
  EVENTS_FAILURE,
  EVENTS_REQUEST,
  IncrementWaitingListInEventAction,
  INCREMENT_WAITING_LIST_IN_EVENT,
  RefetchEventsPageSuccessAction,
  REFETCH_EVENTS_PAGE_SUCCESS,
  SetEditEventInvoiceAction,
  SetEditEventSuccessAction,
  SetEventFavoriteStatusAction,
  SetFilterDataAction,
  SetFilterValuesAction,
  SetHasMoreEventsAction,
  SetListOfEventsSuccessAction,
  SetPageCountAction,
  SET_EDIT_EVENT_INVOICE,
  SET_EDIT_EVENT_SUCCESS,
  SET_EVENTS_PAGE_COUNT,
  SET_EVENT_FAVORITE_STATUS,
  SET_FILTER_DATA,
  SET_FILTER_VALUES,
  SET_HAS_MORE_EVENTS,
  SET_LIST_OF_EVENTS_SUCCESS,
} from "./types";

function getFilterValues(
  filters: string,
  dispatch: Dispatch<EventActionTypes | ErrorAction>
) {
  dispatch(eventsRequest());

  return eventService
    .getFilterValues(filters)
    .then((response: AxiosResponse<EventFilterValues>) => {
      dispatch(setFilterValues(response.data));
    })
    .catch((error) => {
      dispatch(eventsFailure(error.response.status === 404));
    });
}

export function getAllEvents(page: number, filterData: EventFilterData) {
  return (
    dispatch: Dispatch<EventActionTypes | ErrorAction | TabsActionTypes>
  ) => {
    // build the filter
    const eventFilterService = new EventFilterService(page, filterData);

    dispatch(eventsRequest());

    // reset active tab to default (0 = first tab)
    dispatch(setOpenTab(0));

    let route = eventFilterService.getRoute();
    eventFilterService.reflectFiltersInUrl(route);
    return eventService
      .getEvents(route)
      .then(
        (
          response: AxiosResponse<Pagination<EventListData | ELearningListData>>
        ) => {
          dispatch(SetPageCount(page));

          if (page === 1) {
            // first page or filter values changed
            dispatch(setListOfEventsSuccess(response.data));
          } else {
            // page was incremented
            dispatch(addToListOfEventsSuccess(response.data));
          }

          // fetch the filter values
          if (page === 1) {
            // filter values depend on filters not on page number
            route = route.replace("page=1", "");
            // locations is a multiselect, so filter values should be locations independend
            if (route.includes("location=")) {
              let index = route.indexOf("location=");
              route =
                route.substring(0, index) +
                route.substring(route.indexOf("&", index));
            }

            if (route.includes("&&")) {
              route = route.replace("&&", "&");
            }
            if (route.includes("?&")) {
              route = route.replace("?&", "?");
            }

            return getFilterValues(route, dispatch);
          }
        }
      )
      .catch((errorResponse) => {
        dispatch(eventsFailure(errorResponse.response.status === 404));
        dispatch(setHasMoreEvents(false));
      });
  };
}

export function getEventById(
  guid: string,
  dispatch: Dispatch<EventActionTypes | AlertActionTypes>
) {
  dispatch(eventsRequest());

  eventService
    .getEvent(guid)
    .then((response) => {
      dispatch(setEditEventSuccess(response));
    })
    .catch((err: AxiosError) => {
      dispatch(eventsFailure(err.response?.status === 404));
      if (err.response?.status === 404) {
        dispatch(
          error(
            {
              message: "Die gesuchte Veranstaltung existiert nicht.",
            },
            3000
          )
        );
      }
    });
}

export function getEvent(guid: string) {
  return (dispatch: Dispatch<EventActionTypes | ErrorAction>) => {
    dispatch(eventsRequest());

    eventService
      .getEvent(guid)
      .then((response) => {
        dispatch(setEditEventSuccess(response));
      })
      .catch((err: AxiosError) => {
        dispatch(eventsFailure(err.response?.status === 404));
        if (err.response?.status === 404) {
          dispatch(
            error(
              {
                message: "Die gesuchte Veranstaltung existiert nicht.",
              },
              3000
            )
          );
        }
      });
  };
}

export function addEventFromTemplate(
  data: CrudEventDataDto,
  dispatch: Dispatch<
    | EventActionTypes
    | EventTemplatesActionTypes
    | ClearSpeakersAction
    | ClearDocumentsAction
    | AlertActionTypes
  >
) {
  dispatch(eventsRequest());
  eventService
    .addEvent(data)
    .then((response) => {
      dispatch(addEventSuccess(response.data));
      dispatch(
        success(
          {
            message: "Die Veranstaltung wurde erfolgreich angelegt.",
          },
          3000
        )
      );
      dispatch(clearSpeakers());
      dispatch(clearDocuments());
      dispatch(clearEventList());
      history.push(routes.events); //load start page
    })
    .catch((err: AxiosError) => {
      dispatch(eventsFailure(err.response?.status === 404));
    });
}

export function addEvent(data: CrudEventDataDto) {
  return (
    dispatch: Dispatch<
      | EventActionTypes
      | ClearSpeakersAction
      | ClearDocumentsAction
      | AlertActionTypes
    >
  ) => {
    dispatch(eventsRequest());
    eventService
      .addEvent(data)
      .then((response) => {
        dispatch(addEventSuccess(response.data));
        dispatch(
          success(
            {
              message: "Die Veranstaltung wurde erfolgreich angelegt.",
            },
            3000
          )
        );
        dispatch(clearSpeakers());
        dispatch(clearDocuments());
        dispatch(clearEventList());
        history.push(routes.events); //load start page
      })
      .catch((err: AxiosError) => {
        dispatch(eventsFailure(err.response?.status === 404));
      });
  };
}

export function editEvent(data: CrudEventDataDto, id: string) {
  return (
    dispatch: Dispatch<
      | EventActionTypes
      | ClearEventListAction
      | ClearSpeakersAction
      | ClearDocumentsAction
      | ClearParticipantAction
      | AlertActionTypes
    >
  ) => {
    dispatch(eventsRequest());
    eventService
      .editEvent(data, id)
      .then((response) => {
        dispatch(setEditEventSuccess(response.data as ELearningDto));
        dispatch(
          success(
            {
              message: "Die Veranstaltung wurde erfolgreich bearbeitet.",
            },
            3000
          )
        );
        dispatch(clearEditEvent());
        dispatch(clearSpeakers());
        dispatch(clearDocuments());
        dispatch(clearParticipant());
        dispatch(clearEventList());
        history.push(routes.events); //load start page
      })
      .catch((err: AxiosError) => {
        dispatch(eventsFailure(err.response?.status === 404));
      });
  };
}

export function deleteEvent(id: string) {
  return (
    dispatch: Dispatch<
      | EventActionTypes
      | ClearEventListAction
      | ClearSpeakersAction
      | ClearDocumentsAction
      | ClearParticipantAction
      | AlertActionTypes
    >
  ) => {
    dispatch(eventsRequest());
    eventService
      .deleteEvent(id)
      .then(() => {
        history.push(routes.events); //load start page
        dispatch(deleteEventSuccess(id));
        dispatch(
          success(
            {
              message: "Die Veranstaltung wurde erfolgreich gelöscht.",
            },
            3000
          )
        );
        dispatch(clearEditEvent());
        dispatch(clearSpeakers());
        dispatch(clearDocuments());
        dispatch(clearParticipant());
        dispatch(clearEventList());
      })
      .catch((err: AxiosError) => {
        dispatch(eventsFailure(err.response?.status === 404));
      });
  };
}

export function editEventStatus(eventStatus: number, id: string) {
  return (
    dispatch: Dispatch<
      | EventActionTypes
      | ClearSpeakersAction
      | ClearDocumentsAction
      | ClearParticipantAction
      | AlertActionTypes
    >
  ) => {
    dispatch(eventsRequest());
    eventService
      .editEventStatus(eventStatus, id)
      .then(() => {
        dispatch(editEventStatusSuccess(id, eventStatus));
        dispatch(
          success(
            {
              message:
                "Der Status der Veranstaltung wurde erfolgreich geändert.",
            },
            3000
          )
        );
      })
      .catch((err: AxiosError) => {
        dispatch(eventsFailure(err.response?.status === 404));
      });
  };
}

export function switchEnrolmentCap(
  isCapped: boolean,
  event: EventDataDto | LecturesData,
  isStaff?: boolean
) {
  return (
    dispatch: Dispatch<EventActionTypes | AlertActionTypes | SetOpenTabAction>
  ) => {
    dispatch(eventsRequest());
    eventService
      .switchEnrolmentCap(isCapped, event.guid)
      .then(() => {
        dispatch(
          success(
            {
              message: `Der Anmeldestopp der Veranstaltung wurde erfolgreich auf ${
                isCapped ? "" : "nicht"
              } aktiv gesetzt.`,
            },
            3000
          )
        );
        if (isStaff) {
          // prevent inifinate loading
          getEventById(event.guid.toString(), dispatch);
          // show participants tab
          history.push(
            `${getEditRouteBasedOnTrainingType(event.training_type)}/${
              event.guid
            }`
          );
          dispatch(
            setOpenTab(
              getParticipantsTabBasedOnTrainingType(event.training_type)
            )
          );
        }
      })
      .catch(() => {
        dispatch(eventsFailure());
      });
  };
}

export function editEventDocumentList(eventDocumentList: string[], id: string) {
  return (
    dispatch: Dispatch<
      | EventActionTypes
      | ClearSpeakersAction
      | ClearDocumentsAction
      | ClearParticipantAction
      | AlertActionTypes
    >
  ) => {
    dispatch(eventsRequest());
    eventService
      .editEventDocumentList(eventDocumentList, id)
      .then(() => {
        dispatch(
          success(
            {
              message: "Die Dokumentenliste wurde erfolgreich aktualisiert.",
            },
            3000
          )
        );
      })
      .catch((err: AxiosError) => {
        dispatch(eventsFailure(err.response?.status === 404));
      });
  };
}

export function triggerInvoice(eventId: string) {
  return (
    dispatch: Dispatch<EventActionTypes | SuccessAction | ErrorAction>
  ) => {
    dispatch(eventsRequest());
    eventService
      .triggerInvoice(eventId)
      .then((response) => {
        dispatch(
          success(
            {
              message: "Die Rechnungsstellung wurde erfolgreich aktiviert.",
            },
            3000
          )
        );
        dispatch(setEditEventInvoice());
        eventService.triggerCsvDownloadOfInvoice(eventId);
      })
      .catch((err: AxiosError) => {
        dispatch(eventsFailure(err.response?.status === 404));
        if (err.response?.status === 404) {
          dispatch(
            error(
              {
                message: "Diese Veranstaltung existiert nicht.",
              },
              3000
            )
          );
        }
      });
  };
}

export function triggerInvoiceCorrection(eventId: string, participantsForInvoiceCorrection: string[]) {
  return (
    dispatch: Dispatch<EventActionTypes | SuccessAction | ErrorAction>
  ) => {
    dispatch(eventsRequest());
    eventService
    .triggerInvoiceCorrection(eventId, participantsForInvoiceCorrection)
    .then((response) =>{
      dispatch(
        success(
          {
            message: "Die Rechnungskorrektur wurde erfolgreich aktiviert."
          },
          3000
        )
      );
    })
    .catch((err: AxiosError) => {
      dispatch(eventsFailure(err.response?.status === 404));
      if (err.response?.status === 404) {
        dispatch(
          error(
            {
              message: "Diese Veranstaltung existiert nicht.",
            },
            3000
          )
        );
      }
    });
};
}

export function refetchEventsPageSuccess(
  page: number,
  data: Pagination<EventListData>
): RefetchEventsPageSuccessAction {
  return {
    type: REFETCH_EVENTS_PAGE_SUCCESS,
    page,
    data,
  };
}

export function eventsRequest(): EventsRequestAction {
  return {
    type: EVENTS_REQUEST,
  };
}

export function eventsFailure(
  notFoundError: boolean = false
): EventsFailureAction {
  return {
    type: EVENTS_FAILURE,
    notFoundError: notFoundError,
  };
}

export function setListOfEventsSuccess(
  data: Pagination<EventListData | ELearningListData>
): SetListOfEventsSuccessAction {
  return {
    type: SET_LIST_OF_EVENTS_SUCCESS,
    data,
  };
}

export function addToListOfEventsSuccess(
  data: Pagination<EventListData>
): AddToListOfEventsSuccessAction {
  return {
    type: ADD_TO_LIST_OF_EVENTS_SUCCESS,
    data,
  };
}

export function addEventSuccess(
  data: CrudEventDataDto | ELearningData
): AddEventSuccessAction {
  return {
    type: ADD_EVENT_SUCCESS,
    data,
  };
}

export function deleteEventSuccess(eventId: string): DeleteEventSuccessAction {
  return {
    type: DELETE_EVENT_SUCCESS,
    eventId: eventId,
  };
}

export function setEditEventSuccess(
  data: ELearningDto
): SetEditEventSuccessAction {
  return {
    type: SET_EDIT_EVENT_SUCCESS,
    data,
  };
}

export function editEventStatusSuccess(
  eventId: string,
  eventStatus: number
): EditEventStatusSuccessAction {
  return {
    type: EDIT_EVENT_STATUS_SUCCESS,
    eventId,
    eventStatus,
  };
}

export function setEventFavorite(
  eventId: string,
  status: boolean
): SetEventFavoriteStatusAction {
  return {
    type: SET_EVENT_FAVORITE_STATUS,
    eventId,
    status,
  };
}

export function clearEditEvent(): ClearEditEventAction {
  return {
    type: CLEAR_EDIT_EVENT,
  };
}

export function setEditEventInvoice(): SetEditEventInvoiceAction {
  return {
    type: SET_EDIT_EVENT_INVOICE,
  };
}

export function clearEventList(): ClearEventListAction {
  return {
    type: CLEAR_EVENT_LIST,
  };
}

export function SetPageCount(pageCount: number): SetPageCountAction {
  return {
    type: SET_EVENTS_PAGE_COUNT,
    pageCount: pageCount,
  };
}

export function setHasMoreEvents(
  hasMoreEvents: boolean = false
): SetHasMoreEventsAction {
  return {
    type: SET_HAS_MORE_EVENTS,
    hasMoreEvents,
  };
}

export function IncrementWaitingListInEvent(
  eventId: string,
  linkedUser: UserLinkedAccountStats | null = null
): IncrementWaitingListInEventAction {
  return {
    type: INCREMENT_WAITING_LIST_IN_EVENT,
    eventId: eventId,
    linkedUser: linkedUser,
  };
}

export function setFilterValues(
  filterValues: EventFilterValues
): SetFilterValuesAction {
  return {
    type: SET_FILTER_VALUES,
    filterValues,
  };
}

export function setFilterData(
  filterData: EventFilterData
): SetFilterDataAction {
  return {
    type: SET_FILTER_DATA,
    filterData,
  };
}
