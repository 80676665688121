import { Box, Container, Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import noMatchImg from "../../assets/general/404.jpg";
import { AppState } from "../../redux";
import routes from "../routing/routes";
import HeadingKvh from "../theming/HeadingKvh";
import Loader from "../theming/loader/Loader";
import TypographyWhiteText from "../theming/TypographyWhiteText";
import "./NoMatch.scss";

// * this component represents a page that is shown if there user navigates to a page that isn't found (route isn't existing, typo etc.)

export const NoMatch: React.FC = () => {
  const user = useSelector((state: AppState) => state.user);

  // ! as long as there is no site matching but the loading of the auth state is not completed, show loader
  return user.isLoading ? (
    <Loader />
  ) : (
    <Box className="no-match">
      <img
        className="no-match-banner"
        src={noMatchImg}
        alt="Doctor with stetoscope"
      ></img>
      <Container>
        <Box className="no-match-text">
          <Box>
            <TypographyWhiteText className="no-match-title" variant="h2">
              Diagnose: 404
            </TypographyWhiteText>
          </Box>
          <br />
          <Box>
            <TypographyWhiteText className="no-match-title">
              Trotz genauer Untersuchung konnten wir die Seite leider nicht
              finden. Klicken Sie{" "}
              <Link component={RouterLink} to={routes.home} title="Startseite">
                hier
              </Link>{" "}
              um auf die Startseite zu gelangen.
            </TypographyWhiteText>
          </Box>
        </Box>
        <HeadingKvh>Therapie</HeadingKvh>
        <Grid container>
          <Grid item md={6}>
            <Typography>
              Die Seite gibt es nicht mehr oder gab es noch nie. Die gute
              Nachricht: Das ist heilbar. Außer für den Link, der ist nämlich
              leider tot.
            </Typography>
            <br />
            <Typography>
              Suchen Sie Ihr Thema oder die Veranstaltung über die Navigation
              und die Suchfilter.
            </Typography>
            <br />
            <Typography>
              Sollten Sie diese URL aufgerufen haben, weil Sie sie als Favorit
              im Browser gespeichert haben, aktualisieren Sie bitte Ihre
              Favoritenliste, damit Sie diese Diagnose beim nächsten Mal nicht
              mehr angezeigt bekommen.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
