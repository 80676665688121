import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { ELearningFormData } from "../../../../../models/elearning";
import { EventStatus } from "../../../../../models/enums/eventStatus.enum";
import { EventFormData } from "../../../../../models/eventData";
import { AppState } from "../../../../../redux";
import CustomSelect from "../../../../forms/selects/CustomSelect";
import { SelectData } from "../../../../forms/selects/selectData";

interface LAEKHDataSectionProps {
  isTemplateView: boolean;
  templateMode?: boolean; // ! NOTE: templateMode and templates.eventTemplateMode are different
  //! the first one indicates if the user is in template view, the other one indicates if an event instance is created out of an event template
}

const LAEKHDataSection: React.FC<LAEKHDataSectionProps> = ({
  isTemplateView,
  ...props
}) => {
  const { values } = useFormikContext<EventFormData | ELearningFormData>();
  const laekhData = useSelector((state: AppState) => state.laekhData);
  const templates = useSelector((state: AppState) => state.eventTemplates);
  const editEventState = useSelector(
    (state: AppState) => state.event.editEvent
  );

  return (
    <>
      <Grid item xs={12}>
        <h3>Für LÄKH relevante Daten</h3>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomSelect
          defaultValueForEmptyElement={0}
          required={
            templates.eventTemplateMode ||
            props.templateMode ||
            values.status === EventStatus.Published
          }
          disabled={
            !isTemplateView
              ? editEventState.status === EventStatus.Completed ||
                templates.eventTemplateMode
              : false
          }
          name="laekh_form_id"
          id="laekh_form_id"
          label="LÄKH Form ID"
          labelId="laekh-form-id-label"
          options={laekhData.laekhData.formIds.map((x) => {
            return {
              key: x.description,
              value: x.guid,
              label: `${x.description} (${x.id})`,
            } as SelectData;
          })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomSelect
          defaultValueForEmptyElement={0}
          required={
            templates.eventTemplateMode ||
            props.templateMode ||
            values.status === EventStatus.Published
          }
          disabled={
            !isTemplateView
              ? editEventState.status === EventStatus.Completed ||
                templates.eventTemplateMode
              : false
          }
          name="laekh_category"
          id="laekh_category"
          label="LÄKH Kategorie"
          labelId="laekh-category-label"
          options={laekhData.laekhData.categories.map((x) => {
            return {
              key: x.category_id_value,
              value: x.guid,
              label: `${x.description} (${x.category_id_value})`,
            } as SelectData;
          })}
        />
      </Grid>
    </>
  );
};

export default LAEKHDataSection;
