import { UserBillingAddressDto } from "./../../models/billingAddressData";
import { CrudBillingAddressData } from "../../models/billingAddressData";

export interface BillingAddressState {
  billingAddressList: CrudBillingAddressData[];
  billingAddressOfLinkedUser: UserBillingAddressDto;
  linkedUser: string;
  billingAddressLoaded: boolean;
  isSending: boolean;
}

export const BILLING_ADDRESS_REQUEST = "BILLING_ADDRESS_REQUEST";
export const BILLING_ADDRESS_FAILURE = "BILLING_ADDRESS_FAILURE";
export const SET_BILLING_ADDRESS = "SET_BILLING_ADDRESS";
export const SET_BILLING_ADDRESS_FOR_LINKED_USER =
  "SET_BILLING_ADDRESS_FOR_LINKED_USER";
export const RELOAD_BILLING_ADDRESS = "RELOAD_BILLING_ADDRESS";
export const BILLING_ADDRESS_CLEAR = "BILLING_ADDRESS_CLEAR";

// actions that are the same for all api calls (request and failure)
export interface BillingAddressRequestAction {
  type: typeof BILLING_ADDRESS_REQUEST;
}

export interface BillingAddressFailureAction {
  type: typeof BILLING_ADDRESS_FAILURE;
}

export interface SetBillingAddressAction {
  type: typeof SET_BILLING_ADDRESS;
  data: CrudBillingAddressData[];
}

export interface SetBillingAddressForLinkedUserAction {
  type: typeof SET_BILLING_ADDRESS_FOR_LINKED_USER;
  data: UserBillingAddressDto;
  linkedUser: string;
}

export interface ReloadBillingAddressAction {
  type: typeof RELOAD_BILLING_ADDRESS;
}

export interface BillingAddressClearAction {
  type: typeof BILLING_ADDRESS_CLEAR;
}

export type BillingAddressActionTypes =
  | BillingAddressRequestAction
  | BillingAddressFailureAction
  | SetBillingAddressAction
  | SetBillingAddressForLinkedUserAction
  | ReloadBillingAddressAction
  | BillingAddressClearAction;
