import { Box, Divider, Grid, InputAdornment } from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { matchPath, useLocation } from "react-router-dom";
import { ELearningFormData } from "../../../../../models/elearning";
import { EventStatus } from "../../../../../models/enums/eventStatus.enum";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../../../models/enums/trainingType.enum";
import { EventFormData } from "../../../../../models/eventData";
import { AppState } from "../../../../../redux";
import {
  getDocumentsByEvent,
  setDocumentsLoaded,
} from "../../../../../redux/documents/actions";
import { getTargetGroups } from "../../../../../redux/target-groups/actions";
import usePermission from "../../../../../services/usePermissions";
import { Utils } from "../../../../../services/utils";
import CustomCheckbox from "../../../../forms/CustomCheckbox";
import CustomTextField from "../../../../forms/CustomTextField";
import CustomSelect from "../../../../forms/selects/CustomSelect";
import routes from "../../../../routing/routes";
import { checkIfCompleteEventIsApproved } from "../checkIfCompleteEventIsApproved";
import ELearningSection from "./ELearningSection";
import EventDataSection from "./EventDataSection";
import LAEKHDataSection from "./LaekhDataSection";
import TargetGroupSection from "./TargetGroupSection";

interface BasicInformationProps {
  typeOfTrainingToBeCreated: TrainingType;
  templateMode?: boolean; // ! NOTE: templateMode and templates.eventTemplateMode are different
  //! the first one indicates if the user is in template view, the other one indicates if an event instance is created out of an event template
}

export const BasicInformation: React.FC<BasicInformationProps> = (props) => {
  const { values, setFieldValue } = useFormikContext<
    EventFormData | ELearningFormData
  >();

  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const targetGroups = useSelector((state: AppState) => state.targetGroups);

  const documents = useSelector((state: AppState) => state.documents);
  const templates = useSelector((state: AppState) => state.eventTemplates);
  const editEventState = useSelector(
    (state: AppState) => state.event.editEvent
  );

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();
  const isEditPage = id ? true : false;

  const {
    getEventStatusForSelectBasedOnEventAction,
    getDisabledStatusSelectState,
  } = usePermission(
    currentUser?.user_type,
    values.status,
    history.location.pathname === routes.event_create ||
      history.location.pathname === routes.elearning_create ||
      history.location.pathname === routes.blended_learning_create
  );

  const isTemplateView =
    matchPath(location.pathname, {
      path: routes.templates_edit_event_id,
      exact: true,
    }) !== null ||
    matchPath(location.pathname, {
      path: routes.templates_new_event,
      exact: true,
    }) !== null;

  useEffect(() => {
    if (!targetGroups.isLoading && !targetGroups.targetGroupsLoaded) {
      dispatch(getTargetGroups());
    }
  }, [dispatch, targetGroups]);

  // ! NOTE: we need to make sure the documents are always loaded and assigned to the event when the event instance is loaded,
  // ! so the documents do not "magically" disappear (see bug ticket KVHVMPS-69) --> this will happen if the user changes something
  // ! in the basic informations of an event e.g. status or price and had documents assigned but did not visit the "documents" tab first, so the documents
  // ! were not loaded / assigned, which causes the event to always have an empty list of documents []
  useEffect(() => {
    if (isEditPage) {
      if (
        documents.documentList.length === 0 &&
        !documents.documentListLoaded
      ) {
        const isValidGuid = Utils.isValidGUIDv4(id);
        if (isValidGuid) {
          dispatch(
            getDocumentsByEvent(id, props.templateMode || false)
          );
          dispatch(setDocumentsLoaded());
        }
      }

      let documentsListIDsOnly: string[] = [];
      documents.documentList.forEach((document) => {
        if (!document.is_default_on_site && !document.is_default_online) {
          documentsListIDsOnly.push(document.guid);
        }
      });
      setFieldValue("documents", documentsListIDsOnly);
    }
  }, [dispatch, documents, id, isEditPage, setFieldValue, props.templateMode]);

  const trainingType = mapToTrainingTypeString(props.typeOfTrainingToBeCreated);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={props.templateMode ? 12 : 8}>
        <CustomTextField
          fullWidth
          required
          disabled={
            !isTemplateView
              ? editEventState.status === EventStatus.Completed ||
                templates.eventTemplateMode
              : false
          }
          name="title"
          id="title"
          label={
            props.typeOfTrainingToBeCreated !== TrainingType.DefaultEvent
              ? `Name ${trainingType}`
              : "Veranstaltungsname"
          }
        />
      </Grid>
      {!props.templateMode && (
        <>
          <Grid item xs={12} sm={4}>
            <CustomTextField
              fullWidth
              disabled
              name="vnr"
              id="vnr"
              label="Veranstaltungsnummer"
            />
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
        sm={
          props.typeOfTrainingToBeCreated !== TrainingType.DefaultEvent ? 4 : 6
        }
      >
        <CustomTextField
          fullWidth
          required={
            templates.eventTemplateMode ||
            props.templateMode ||
            values.status === EventStatus.Published
          }
          disabled={
            !isTemplateView
              ? editEventState.status === EventStatus.Completed ||
                templates.eventTemplateMode
              : false
          }
          InputProps={{ inputProps: { min: 0 } }}
          name="score"
          id="score"
          label="Fortbildungspunkte"
          type="number"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={
          props.typeOfTrainingToBeCreated !== TrainingType.DefaultEvent ? 4 : 6
        }
      >
        <CustomTextField
          fullWidth
          required={values.status === EventStatus.Published}
          disabled={values.status === EventStatus.Completed}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
            inputProps: { min: 0.0, max: 10000.0, step: 0.01 },
          }}
          name="price"
          id="price"
          label="Kosten"
        />
        <Box fontSize={15} paddingLeft={3} height={7}>
          Bei abweichenden Kosten die Finanzbuchhaltung informieren.
        </Box>
      </Grid>
      <>
        {props.typeOfTrainingToBeCreated !== TrainingType.ELearning && (
          <EventDataSection
            typeOfTrainingToBeCreated={props.typeOfTrainingToBeCreated}
          />
        )}
        {props.typeOfTrainingToBeCreated === TrainingType.ELearning && (
          <>
            <ELearningSection
              typeOfTrainingToBeCreated={props.typeOfTrainingToBeCreated}
            />
          </>
        )}
        {!props.templateMode && (
          <>
            <Grid item xs={12} sm={6}>
              {currentUser && values.status !== EventStatus.Undefined && (
                <CustomSelect
                  noEmptyDefaultValue
                  disabled={
                    getDisabledStatusSelectState() &&
                    (editEventState.status === EventStatus.Cancelled ||
                      editEventState.status === EventStatus.Completed)
                  }
                  required
                  name="status"
                  id="status"
                  label="Status der Veranstaltung"
                  labelId="event-status-label"
                  onChange={(
                    event: React.ChangeEvent<{ name?: string; value: unknown }>
                  ) => {
                    if (
                      checkIfCompleteEventIsApproved(
                        event,
                        editEventState,
                        dispatch
                      )
                    ) {
                      setFieldValue("status", event.target.value);
                    }
                  }}
                  options={getEventStatusForSelectBasedOnEventAction(
                    props.typeOfTrainingToBeCreated
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}></Grid>

            {props.typeOfTrainingToBeCreated !== TrainingType.ELearning && (
              <Grid
                item
                xs={12}
                sm={
                  props.typeOfTrainingToBeCreated !== TrainingType.DefaultEvent
                    ? 6
                    : 8
                }
              >
                <CustomCheckbox
                  disabled={values.status === EventStatus.Completed}
                  name="noReminder"
                  label={
                    <>
                      <b>Keine</b> Erinnerungsmail 14 Tage vor
                      Veranstaltungsbeginn senden
                    </>
                  }
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={
                props.typeOfTrainingToBeCreated !== TrainingType.DefaultEvent
                  ? 3
                  : 6
              }
            >
              <CustomCheckbox
                disabled={values.status === EventStatus.Completed}
                name="noDiscount"
                label={
                  <>
                    <b>Keinen</b> Rabatt gewähren
                  </>
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={
                props.typeOfTrainingToBeCreated !== TrainingType.DefaultEvent
                  ? 3
                  : 12
              }
            >
              <CustomCheckbox
                disabled={values.status === EventStatus.Completed}
                name="noAutomaticParticipationCertificate"
                label={
                  <>
                    <b>Keine</b> automatische Teilnehmerbestätigung senden
                  </>
                }
              />
            </Grid>
          </>
        )}
        {props.typeOfTrainingToBeCreated === TrainingType.ELearning && (
          <Grid item xs={6} sm={3}>
            <CustomCheckbox
              name="pinboardIsActivated"
              label={
                <>
                  Pinnwand <b>aktivieren</b>
                </>
              }
            />
          </Grid>
        )}
      </>
      {props.typeOfTrainingToBeCreated === TrainingType.BlendedLearning && (
        <>
          <ELearningSection
            typeOfTrainingToBeCreated={props.typeOfTrainingToBeCreated}
          />
        </>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <TargetGroupSection />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <LAEKHDataSection
        templateMode={props.templateMode}
        isTemplateView={isTemplateView}
      />
    </Grid>
  );
};
