import { ReactNode } from "react";
import { UserType } from "../../../models/enums/userType.enum";
import BillingAddress from "./participant/BillingAddress";
import Combine from "./participant/Combine";
import ProfileContact from "./ProfileContact";

interface TabsData {
  label: string;
  content: ReactNode;
}

const profileContact: TabsData = {
  label: "Benutzerkonto",
  content: <ProfileContact />,
};

const billingAddress: TabsData = {
  label: "Rechnungsadresse",
  content: <BillingAddress />,
};

const combine: TabsData = {
  label: "Verknüpfung",
  content: <Combine />,
};

const tabsItemsUser: TabsData[] = [profileContact];

const tabsItemsSpeaker: TabsData[] = [profileContact];

const tabsItemsParticipant: TabsData[] = [
  profileContact,
  billingAddress,
  combine,
];

export function getTabsByRole(userType?: UserType): TabsData[] {
  switch (userType) {
    case UserType.Undefined:
      return tabsItemsUser;
    case UserType.Admin:
      return tabsItemsUser;
    case UserType.Speaker:
      return tabsItemsSpeaker;
    case UserType.Participant:
      return tabsItemsParticipant;
    case UserType.Employee:
    case UserType.Apprentice:
      return tabsItemsUser;
    default:
      return tabsItemsUser;
  }
}
