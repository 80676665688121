import { Pagination } from "../models/pagination";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { EventTemplateData } from "../models/templateData";
import { TaskTemplateData } from "../models/taskData";

export class TemplateService extends BaseCrudService {
  // implementation for all template events
  async getAllEventTemplates(filter: string) {
    const response = await this.getAll<Pagination<EventTemplateData>>(
      apiRoutes.templates_event + filter
    );
    return response.data;
  }

  async getEventTemplate(id: string) {
    const response = await this.get<EventTemplateData>(
      apiRoutes.templates_event,
      id
    );
    return response.data;
  }

  async addEventTemplate(eventTemplate: EventTemplateData) {
    const response = await this.create<EventTemplateData>(
      apiRoutes.templates_event,
      eventTemplate
    );
    return response.data;
  }

  async editEventTemplate(eventTemplate: EventTemplateData, guid: string) {
    const response = await this.edit<EventTemplateData>(
      apiRoutes.templates_event,
      guid,
      eventTemplate
    );
    return response.data;
  }

  async deleteEventTemplate(guid: string) {
    const response = await this.delete<EventTemplateData>(
      apiRoutes.templates_event,
      guid.toString()
    );
    return response;
  }

  // implementation for all template tasks
  async getAllTaskTemplates(page: number) {
    const response = await this.getAll<Pagination<TaskTemplateData>>(
      apiRoutes.templates_task,
      { page: page }
    );
    return response.data;
  }

  async getDefaultTasksByEventType(eventType: number) {
    const response = await this.getAll<TaskTemplateData[]>(
      apiRoutes.templates_task,
      { task_type: eventType }
    );
    return response.data;
  }

  async getTaskTemplate(guid: string) {
    const response = await this.get<TaskTemplateData>(
      apiRoutes.templates_task,
      guid
    );
    return response.data;
  }

  async addTaskTemplate(taskTemplate: TaskTemplateData) {
    const response = await this.create<TaskTemplateData>(
      apiRoutes.templates_task,
      taskTemplate
    );
    return response;
  }

  async editTaskTemplate(taskTemplate: TaskTemplateData, id: string) {
    const response = await this.update<TaskTemplateData>(
      apiRoutes.templates_task,
      id,
      taskTemplate
    );
    return response;
  }

  async deleteTaskTemplate(id: string) {
    const response = await this.delete<TaskTemplateData>(
      apiRoutes.templates_task,
      id.toString()
    );
    return response;
  }
}
