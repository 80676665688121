import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { ApiHelper } from "../../api/apiHelper";
import { participantsService } from "../../api/index";
import { getEditRouteBasedOnTrainingType } from "../../components/routing/routes";
import { ParticipantListData } from "../../models/participantData";
import { error, success } from "../alerts/actions";
import { AlertActionTypes, ErrorAction, SuccessAction } from "../alerts/types";
import { clearEventList } from "../events/actions";
import {
  setOpenTab,
  getParticipantsTabBasedOnTrainingType,
} from "../tabs/actions";
import { EventActionTypes } from "./../events/types";
import {
  AddParticipantAction,
  ADD_PARTICIPANT,
  ClearParticipantAction,
  CLEAR_PARTICIPANT,
  EditParticipantStatusAction,
  EDIT_PARTICIPANT_REQUEST,
  EDIT_PARTICIPANT_STATUS,
  ParticipantActionTypes,
  ParticipantEditRequestAction,
  ParticipantFailureAction,
  ParticipantRequestAction,
  PARTICIPANT_FAILURE,
  PARTICIPANT_REQUEST,
  SetParticipantListAction,
  SET_PARTICIPANT_LIST,
} from "./types";
import { history } from "../../components/routing/history";
import { ELearningDto } from "../../models/elearning";
import { TabsActionTypes } from "../tabs/types";

export function getParticipants(eventId: string) {
  return (dispatch: Dispatch<ParticipantActionTypes | ErrorAction>) => {
    dispatch(participantsRequest());
    participantsService
      .getParticipant(eventId)
      .then((response) => {
        dispatch(SetParticipantToList(response));
      })
      .catch((err: AxiosError) => {
        dispatch(participantFailure(err));
        dispatch(
          error(
            {
              message: "Die Teilnehmerliste konnte nicht geladen werden!",
            },
            5000
          )
        );
      });
  };
}

export function editParticipantStatus(
  event: ELearningDto,
  participantGuid: string,
  participantStatus: number
) {
  return (
    dispatch: Dispatch<
      | ParticipantActionTypes
      | EventActionTypes
      | AlertActionTypes
      | TabsActionTypes
    >
  ) => {
    dispatch(participantsEditRequest(participantGuid));

    participantsService
      .editParticipantStatus(event.guid, participantGuid, participantStatus)
      .then(() => {
        dispatch(SetParticipantStatus(participantGuid, participantStatus));

        dispatch(clearEventList());

        // show participants tab
        const trainingType = event.training_type;
        history.push(
          `${getEditRouteBasedOnTrainingType(trainingType)}/${event.guid}`
        );
        dispatch(
          setOpenTab(getParticipantsTabBasedOnTrainingType(trainingType))
        );
      })
      .catch((err: AxiosError) => {
        dispatch(participantsEditRequest(participantGuid));
        dispatch(participantFailure(err));
        dispatch(
          error(
            {
              message:
                "Der Status des Teilnehmers konnte nicht geändert werden.\n" +
                ApiHelper.showErrorMessageFromApi(err.response?.data),
            },
            5000
          )
        );
      });
  };
}

export function editMultipleParticipantStatus(
  event: ELearningDto,
  participants: { participantGuid: string; participantStatus: number }[]
) {
  return (
    dispatch: Dispatch<
      | ParticipantActionTypes
      | EventActionTypes
      | AlertActionTypes
      | TabsActionTypes
    >
  ) => {
    participants.forEach(
      ({ participantGuid: participantId, participantStatus }) => {
        dispatch(participantsEditRequest(participantId));
      }
    );
    const eventId = event.guid;
    const promises = participants.map(
      ({ participantGuid: participantId, participantStatus }) => {
        return participantsService.editParticipantStatus(
          eventId,
          participantId,
          participantStatus
        );
      }
    );
    Promise.all(promises)
      .then(() => {
        participants.forEach(
          ({ participantGuid: participantId, participantStatus }) => {
            dispatch(SetParticipantStatus(participantId, participantStatus));
          }
        );
        dispatch(clearEventList());
        // show participants tab
        const trainingType = event.training_type;
        history.push(
          `${getEditRouteBasedOnTrainingType(trainingType)}/${eventId}`
        );
        dispatch(
          setOpenTab(getParticipantsTabBasedOnTrainingType(trainingType))
        );
      })
      .catch((err: AxiosError) => {
        participants.forEach(({ participantGuid: participantId }) => {
          dispatch(participantsEditRequest(participantId));
        });
        dispatch(participantFailure(err));
        dispatch(
          error(
            {
              message:
                "Der Status der Teilnehmer konnte nicht geändert werden.\n" +
                ApiHelper.showErrorMessageFromApi(err.response?.data),
            },
            5000
          )
        );
      });
  };
}

export function reactivateELearning(eventId: string, participantGuid: string) {
  return (
    dispatch: Dispatch<ParticipantActionTypes | ErrorAction | SuccessAction>
  ) => {
    participantsService
      .editReactivated(eventId, participantGuid, true)
      .then(() => {
        dispatch(clearParticipant());
        dispatch(
          success(
            {
              message:
                "Die E-Learning-Teilnahme wurde erfolgreich reaktiviert.",
            },
            3000
          )
        );
      })
      .catch((err: AxiosError) => {
        dispatch(participantFailure(err));
        dispatch(
          error(
            {
              message:
                "Die E-Learning-Teilnahme konnte nicht reaktiviert werden!",
            },
            5000
          )
        );
      });
  };
}

export function cancelReactivated(eventId: string, participantGuid: string) {
  return (
    dispatch: Dispatch<ParticipantActionTypes | ErrorAction | SuccessAction>
  ) => {
    participantsService
      .editReactivated(eventId, participantGuid, false)
      .then(() => {
        dispatch(clearParticipant());
        dispatch(
          success(
            {
              message:
                "Die E-Learning-Teilnahme wurde erfolgreich inaktiviert.",
            },
            3000
          )
        );
      })
      .catch((err: AxiosError) => {
        dispatch(participantFailure(err));
        dispatch(
          error(
            {
              message:
                "Die E-Learning-Teilnahme konnte nicht inaktiviert werden!",
            },
            5000
          )
        );
      });
  };
}

export function sendEmailToParticipants(eventId: string, message: string) {
  return (
    dispatch: Dispatch<ParticipantActionTypes | ErrorAction | SuccessAction>
  ) => {
    participantsService
      .sendEmailToParticipants(eventId, message)
      .then(() => {
        dispatch(
          success(
            {
              message:
                "Nachricht an die Teilnehmer wurde erfolgreich versendet",
            },
            5000
          )
        );
      })
      .catch((err: AxiosError) => {
        dispatch(participantFailure(err));
        dispatch(
          error(
            {
              message:
                "Nachricht an die Teilnehmer wurde nicht versendet!" +
                ApiHelper.showErrorMessageFromApi(err.response?.data),
            },
            5000
          )
        );
      });
  };
}

export function participantsRequest(): ParticipantRequestAction {
  return {
    type: PARTICIPANT_REQUEST,
  };
}

export function participantsEditRequest(
  participantGuid: string
): ParticipantEditRequestAction {
  return {
    type: EDIT_PARTICIPANT_REQUEST,
    participantId: participantGuid,
  };
}

export function participantFailure(error: any): ParticipantFailureAction {
  return {
    type: PARTICIPANT_FAILURE,
    error,
  };
}

export function SetParticipantToList(
  data: ParticipantListData[]
): SetParticipantListAction {
  return {
    type: SET_PARTICIPANT_LIST,
    data,
  };
}

export function SetParticipantStatus(
  participantGuid: string,
  participantStatus: number
): EditParticipantStatusAction {
  return {
    type: EDIT_PARTICIPANT_STATUS,
    participantGuid: participantGuid,
    participantStatus: participantStatus,
  };
}

export function AddParticipantToList(
  participant: ParticipantListData
): AddParticipantAction {
  return {
    type: ADD_PARTICIPANT,
    data: participant,
  };
}

export function clearParticipant(): ClearParticipantAction {
  return {
    type: CLEAR_PARTICIPANT,
  };
}
