import { LAEKHData } from "./../../models/laekhData";

export interface LAEKHState {
  laekhData: LAEKHData;
  isLoading: boolean;
}

export const LAEKH_REQUEST = "LAEKH_REQUEST";
export const LAEKH_FAILURE = "LAEKH_FAILURE";
export const LOAD_LAEKH_DATA_SUCCESS = "LOAD_LAEKH_DATA_SUCCESS";

export interface LAEKHRequestAction {
  type: typeof LAEKH_REQUEST;
}

export interface LAEKHFailureAction {
  type: typeof LAEKH_FAILURE;
}

export interface LoadLAEKHDataAction {
  type: typeof LOAD_LAEKH_DATA_SUCCESS;
  data: LAEKHData;
}

export type LAEKHActionTypes =
  | LAEKHRequestAction
  | LAEKHFailureAction
  | LoadLAEKHDataAction;
