import { Box, Button, Grid, Typography } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { Form, Formik } from "formik";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../../../redux";
import CustomSelect from "../../forms/selects/CustomSelect";
import CustomTextField from "../../forms/CustomTextField";
import HeadingKvh from "../../theming/HeadingKvh";
import contactValidationSchema from "./contactValidationSchema";
import { ContactFormData } from "../../../models/contactFormData";
import { ContactMessageType } from "../../../models/enums/contactMessageType.enum";
import { sendContactMessage } from "../../../redux/contact/actions";
import Loader from "../../theming/loader/Loader";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../models/enums/trainingType.enum";
import { mapToSexTypeString } from "../../../models/enums/sexType.enum";

export const MAX_CHAR_LENGTH = 1024;

interface ContactFormProps {}

const ContactForm: React.FC<ContactFormProps> = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => {
    return state.user.currentUser;
  });
  const contactForm = useSelector((state: AppState) => {
    return state.contact;
  });
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const history = useHistory();

  return (
    <>
      <HeadingKvh>Kontakt</HeadingKvh>
      <Grid container>
        <Grid item xs={12}>
          <Box mb={3}>
            <Typography>
              Sie haben Fragen oder benötigen Hilfe? Bitte verwenden Sie dieses
              Formular oder rufen Sie an, um uns zu kontaktieren.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          {contactForm.isSending ? (
            <Loader height={415} />
          ) : !contactForm.sendSuccess ? (
            <Formik
              onSubmit={(data: ContactFormData) => {
                dispatch(sendContactMessage(data));
              }}
              enableReinitialize
              validationSchema={contactValidationSchema}
              initialValues={
                {
                  salutation: mapToSexTypeString(user?.sex) || "",
                  title: user?.academic_title || "",
                  firstname: user?.first_name || "",
                  lastname: user?.last_name || "",
                  message: "",
                  topic: "",
                  subject_area: -1,
                  response_type: ContactMessageType.Undefined,
                  mail: user?.email || "",
                  phone: user?.phone || "",
                } as ContactFormData
              }
            >
              {({ values, isValid }) => (
                <Form>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                      <CustomSelect
                        name="salutation"
                        id="salutation-select"
                        label="Anrede"
                        labelId="salutation-select-label"
                        options={[
                          {
                            key: "Frau",
                            value: "Frau",
                            label: "Frau",
                          },
                          {
                            key: "Herr",
                            value: "Herr",
                            label: "Herr",
                          },
                        ]}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <CustomTextField fullWidth name="title" label="Titel" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomTextField
                        fullWidth
                        name="firstname"
                        label="Vorname"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomTextField
                        fullWidth
                        name="lastname"
                        label="Nachname"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        fullWidth
                        name="topic"
                        label="Betreff"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomSelect
                        name="response_type"
                        id="response-select"
                        label="Bevorzugter Kontaktweg"
                        labelId="response-select-label"
                        defaultValueForEmptyElement={
                          ContactMessageType.Undefined
                        }
                        options={[
                          {
                            key: "email",
                            value: ContactMessageType.Mail,
                            label: "E-Mail",
                          },
                          {
                            key: "phone",
                            value: ContactMessageType.Phone,
                            label: "Telefon",
                          },
                        ]}
                      />
                    </Grid>
                    {values.response_type !== ContactMessageType.Undefined && (
                      <Grid item xs={12} md={6}>
                        {values.response_type === ContactMessageType.Mail && (
                          <CustomTextField
                            fullWidth
                            name="mail"
                            label="E-Mail"
                          />
                        )}
                        {values.response_type === ContactMessageType.Phone && (
                          <CustomTextField
                            fullWidth
                            name="phone"
                            label="Telefonnummer"
                          />
                        )}
                      </Grid>
                    )}
                    <Grid item xs={12} md={12}>
                      <CustomSelect
                        name="subject_area"
                        id="subject-area"
                        label="Themenbereich"
                        labelId="subject-area-label"
                        required
                        defaultValueForEmptyElement={
                          -1 // Non-valid value, since I didn't wanna specify it in TrainingType
                        }
                        options={[
                          {
                            key: "event",
                            value: TrainingType.DefaultEvent,
                            label: mapToTrainingTypeString(
                              TrainingType.DefaultEvent
                            ),
                          },
                          {
                            key: "elearning",
                            value: TrainingType.ELearning,
                            label: mapToTrainingTypeString(
                              TrainingType.ELearning
                            ),
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        fullWidth
                        multiline
                        rows={7}
                        required
                        name="message"
                        label="Ihre Nachricht an uns"
                        helperText={`${values.message.length}/${MAX_CHAR_LENGTH}`}
                        inputProps={{
                          maxLength: MAX_CHAR_LENGTH,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Box my={3} display="flex" justifyContent="space-between">
                    <Box>
                      <Button
                        disabled={!isValid}
                        type="submit"
                        style={{ height: "inherit" }}
                        variant="contained"
                        color="primary"
                      >
                        Absenden
                      </Button>

                      <Button
                        type="button"
                        onClick={() => {
                          history.goBack();
                        }}
                        style={{
                          height: "inherit",
                          marginLeft: "1rem",
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        Abbrechen
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          ) : (
            <Box textAlign="center" my={10}>
              <CheckCircle
                color="primary"
                style={{ marginBottom: "50px", fontSize: "80px" }}
              />
              <Typography variant="body1">
                Vielen Dank für Ihre Anfrage. Ihre Nachricht wurde an uns
                übermittelt. Wir melden uns bei Ihnen.
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box
            bgcolor={accessibility.monoMode ? "#fff" : "#f8f8f8"}
            border={accessibility.monoMode ? "1px solid #000" : undefined}
            style={{ padding: "16px" }}
            ml={4}
          >
            <Typography variant="h4" style={{ textTransform: "uppercase" }}>
              Ansprechpartner
            </Typography>

            <Typography style={{ margin: "1em 0" }}>
              <b>Kassenärztliche Vereinigung Hessen</b>
              <br />
              Veranstaltungsmanagement
            </Typography>

            <Typography style={{ margin: "1em 0" }}>
              Mo - Fr: 8 - 17 Uhr
            </Typography>

            <Typography style={{ margin: "1em 0" }}>
              Tel 069 24741-7550
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactForm;
