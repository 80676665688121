import { Pagination } from "../models/pagination";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { LecturesData } from "../models/lecturesData";

export class LecturesService extends BaseCrudService {
  async getLectures(filter: string) {
    const response = await this.getAll<Pagination<LecturesData>>(
      apiRoutes.lectures + filter
    );
    return response.data;
  }

  async getLecture(id: string) {
    const response = await this.get<LecturesData>(apiRoutes.lectures, id);
    return response.data;
  }

  async patchUserHasClickedLink(
    eventGuid: string,
    participantGuid: string,
    participationData: { user_has_clicked_evaluation_link: boolean }
  ) {
    const response = await this.edit(
      apiRoutes.events + "/" + eventGuid + apiRoutes.participants,
      participantGuid,
      participationData
    );
    return response;
  }
}
