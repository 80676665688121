import { MyELearningListData } from "./elearning";
import { EventType } from "./enums/eventType.enum";
import { TrainingType } from "./enums/trainingType.enum";

export const DASHBOARD_PAGE_SIZE = 9;

export interface DashboardListData {
  guid: string;
  public_id: number;
  name: string;
  status: number;
  event_type: EventType;
  training_type: TrainingType;
  is_over: boolean;
  evaluation_link: string;
  evaluation_link_blended_learning: string;
  booking: MyELearningListData;
  pinboard_is_activated: boolean;
  publish_elearning_after_event: boolean;
  unread_pinboard_count: number;
  unread_document_count: number;
  is_participation_certificate_downloadable: boolean;
  participation_certificate_download_url: string;
}
