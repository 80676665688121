import { Form, Formik } from "formik";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { TrainingType } from "../../../../models/enums/trainingType.enum";
import { AppState } from "../../../../redux";
import { editChecklistForEvent } from "../../../../redux/checklist/actions";
import { showDialog } from "../../../../redux/dialogs/actions";
import { editEventDocumentList } from "../../../../redux/events/actions";
import {
  clickedEvaluationLink,
  getLecture,
} from "../../../../redux/lectures/actions";
import useValidId from "../../../hooks/useValidId";
import routes from "../../../routing/routes";
import HeadingKvh from "../../../theming/HeadingKvh";
import TabsKvh from "../../../theming/tabs/TabsKvh";
import "../my-events/MyEvents.scss";
import { isEventOver } from "../shared/checkEventIsOver";
import { Documents } from "../shared/Documents";
import { Miscellaneous } from "../shared/misc/Miscellaneous";
import { Participants } from "../shared/participants/Participants";
import MyLecturesBasicInformation from "./MyLecturesBasicInfomation";

const MyLecturesDetails: React.FC = () => {
  let { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const lecture = useSelector((state: AppState) => state.lectures);
  const { isValidId } = useValidId(routes.my_lectures_details_event_id, id);

  useEffect(() => {
    // additionaly check if id is empty because it needs to be initialized
    if (
      isEventOver(lecture.lectureDetails.end) &&
      lecture.lectureDetails.training_type === TrainingType.DefaultEvent &&
      lecture.lectureDetails.evaluation_link_speaker &&
      !lecture.lectureDetails.user_has_clicked_evaluation_link &&
      lecture.lectureDetails.guid !== "" &&
      lecture.lectureDetails.speaking_event !== null
    ) {
      dispatch(
        showDialog({
          title: "Bewertung der Veranstaltung",
          message: (
            <>
              Möchten Sie sich einen kurzen Moment Zeit nehmen, um die
              Organisation und Durchführung der Veranstaltung zu bewerten?
            </>
          ),
          confirmButtonText: "Zur Evaluation",
          action: () => {
            window.open(lecture.lectureDetails.evaluation_link_speaker);
            // if the user clicks the link set flag to true, so the next time the popup doesn't show up
            dispatch(
              clickedEvaluationLink(
                lecture.lectureDetails.guid,
                lecture.lectureDetails.speaking_event,
                true
              )
            );
          },
        })
      );
    }
  }, [lecture.lectureDetails, dispatch]);

  useEffect(() => {
    if (isValidId && !lecture.lectureDetailsLoaded) {
      dispatch(getLecture(id));
    }
  }, [dispatch, id, isValidId, lecture.lectureDetailsLoaded]);

  return (
    <>
      <HeadingKvh>{lecture.lectureDetails.title}</HeadingKvh>
      <Formik
        enableReinitialize
        onSubmit={(data) => {
          if (data.submitChecklist) {
            dispatch(
              editChecklistForEvent(
                id,
                data.checklist,
                data.checklist_additional_info
              )
            );
          } else {
            dispatch(editEventDocumentList(data.documents, id));
          }
        }}
        initialValues={{
          documents: [] as string[],
          checklist: lecture.lectureDetails.checklist,
          checklist_additional_info:
            lecture.lectureDetails.checklist_additional_info,
          submitChecklist: false,
        }}
      >
        {({ values }) => (
          <Form>
            <TabsKvh
              tabs={[
                {
                  label: "Basisinformationen",
                  content: (
                    <MyLecturesBasicInformation
                      lecture={lecture.lectureDetails}
                    />
                  ),
                },
                {
                  label: "Material",
                  content: (
                    <Documents
                      typeOfTrainingToBeCreated={TrainingType.DefaultEvent}
                    />
                  ),
                },
                {
                  label: "Teilnehmer",
                  content: (
                    <Participants
                      eventData={lecture.lectureDetails}
                      trainingType={TrainingType.DefaultEvent}
                    />
                  ),
                },
                {
                  label: "Sonstiges",
                  content: <Miscellaneous eventId={id} />,
                },
              ]}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MyLecturesDetails;
