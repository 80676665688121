import {
  CoverageArea,
  ParticipationType,
  SpecialField,
} from "./../models/targetGroupData";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";

export class TargetGroupService extends BaseCrudService {
  async getParticipationTypes() {
    const response = await this.getAll<ParticipationType[]>(
      apiRoutes.participation_types
    );
    return response.data;
  }

  async getCoverageAreas() {
    const response = await this.getAll<CoverageArea[]>(apiRoutes.coverage_area);
    return response.data;
  }

  async getSpecialFields(filter: string) {
    const response = await this.getAll<SpecialField[]>(
      apiRoutes.special_fields + filter
    );
    return response.data;
  }
}
