import {
  ADD_SPEAKERS_TO_EVENT_LIST,
  REMOVE_SPEAKERS_FROM_EVENT_LIST,
  CLEAR_SPEAKERS,
  SET_SPEAKERS_LIST,
  SPEAKERS_FAILURE,
  SPEAKERS_REQUEST,
  SpeakersActionTypes,
  SpeakersState,
  EDIT_SPEAKERS_LOADED,
  SET_SPEAKERS_SEARCH_LIST,
  SYNC_SPEAKERS_SEARCH_LIST,
  EDIT_EVENT_SPEAKER_LIST,
} from "./types";
import { SpeakerData } from "../../models/speakerData";

export const initialSpeakersState: SpeakersState = {
  speakerListLoaded: false,
  speakerEditListLoaded: false,
  speakerList: [],
  speakerSearchList: [],
  speakersSearchTerm: "",
  speakerListForEvent: [],
  isLoading: true,
};

function addSpeakerToList(
  speakerListForEvent: SpeakerData[],
  speaker: SpeakerData
) {
  // ! NOTE: always use copy and don't work on state directly to prevent nasty side effects
  const copiedSpeakersList = [...speakerListForEvent];
  copiedSpeakersList.push(speaker);
  return copiedSpeakersList;
}

function removeSpeakerFromList(
  speakerListForEvent: SpeakerData[],
  speaker: SpeakerData
) {
  // ! NOTE: always use copy and don't work on state directly to prevent nasty side effects
  const copiedSpeakersList = [...speakerListForEvent];
  let index = copiedSpeakersList.indexOf(speaker);
  copiedSpeakersList.splice(index, 1);
  return copiedSpeakersList;
}

export function speakersReducer(
  state = initialSpeakersState,
  action: SpeakersActionTypes
): SpeakersState {
  switch (action.type) {
    case SPEAKERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SPEAKERS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SET_SPEAKERS_LIST:
      return {
        ...state,
        speakerList: [...action.data],
        speakerSearchList: [...action.data],
        speakerListLoaded: true,
      };
    case SET_SPEAKERS_SEARCH_LIST:
      return {
        ...state,
        speakerSearchList: [...action.data],
        speakersSearchTerm: action.searchTerm,
      };
    case SYNC_SPEAKERS_SEARCH_LIST:
      return {
        ...state,
        speakerSearchList: [...state.speakerList],
      };
    case ADD_SPEAKERS_TO_EVENT_LIST:
      return {
        ...state,
        speakerList: [...removeSpeakerFromList(state.speakerList, action.data)],
        speakerListForEvent: [
          ...addSpeakerToList(state.speakerListForEvent, action.data),
        ],
      };
    case REMOVE_SPEAKERS_FROM_EVENT_LIST:
      return {
        ...state,
        speakerList: [...addSpeakerToList(state.speakerList, action.data)],
        speakerListForEvent: [
          ...removeSpeakerFromList(state.speakerListForEvent, action.data),
        ],
      };
    case EDIT_EVENT_SPEAKER_LIST:
      return {
        ...state,
        speakerListForEvent: action.data,
      };
    case EDIT_SPEAKERS_LOADED:
      return {
        ...state,
        speakerEditListLoaded: true,
      };
    case CLEAR_SPEAKERS:
      return initialSpeakersState;
    default:
      return state;
  }
}

export default speakersReducer;
