import { EditorState } from "draft-js";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ELearningFormData,
  mapEditELearningDtoToEventForm,
} from "../../../../models/elearning";
import { EventStatus } from "../../../../models/enums/eventStatus.enum";
import {
  EventType,
  InternalExternalEventType,
} from "../../../../models/enums/eventType.enum";
import {
  TrainingType,
  mapToTrainingTypeString,
} from "../../../../models/enums/trainingType.enum";
import { EventFormData } from "../../../../models/eventData";
import { mapEventTemplateDtoToFormData } from "../../../../models/templateData";
import { AppState } from "../../../../redux";
import { billingAddressClear } from "../../../../redux/billingAddress/actions";
import {
  getBlendedLearning,
  getELearning,
} from "../../../../redux/e-learnings/actions";
import { getEvent } from "../../../../redux/events/actions";
import { getLAEKHData } from "../../../../redux/laekh/actions";
import { getParticipants } from "../../../../redux/participants/actions";
import { setPreview } from "../../../../redux/preview/actions";
import { Utils } from "../../../../services/utils";
import { NoMatch } from "../../../errorPages/noMatch";
import usePageLoader from "../../../hooks/usePageLoader";
import useValidId from "../../../hooks/useValidId";
import routes from "../../../routing/routes";
import HeadingKvh from "../../../theming/HeadingKvh";
import Loader from "../../../theming/loader/Loader";
import eventValidationSchema from "../shared/eventValidationSchema";
import { PreviewEvent } from "../shared/preview/PreviewEvent";
import EventFormButtons from "./EventFormButtons";
import EventTabs from "./EventTabs";
import FieldsMissingAlert from "./FieldsMissingAlert";
import useEditFormContext from "./useEditEventContext";

export const EVENT_DEFAULT_START_TIME: number = 15; // always start at 15:00
export const EVENT_DEFAULT_INTERVAL: number = 30; // 30 minutes interval for default end time of event

interface CrudEventProps {
  typeOfTrainingToBeCreated?: TrainingType;
}

export const CrudEvent: React.FC<CrudEventProps> = (props: CrudEventProps) => {
  let { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { isLoadingPage } = usePageLoader();

  const templates = useSelector((state: AppState) => state.eventTemplates);
  const preview = useSelector((state: AppState) => state.preview);
  const laekhData = useSelector((state: AppState) => state.laekhData);
  const participant = useSelector((state: AppState) => state.participant);
  const eventIsLoading = useSelector(
    (state: AppState) => state.event.isLoading
  );
  const eLearningIsLoading = useSelector(
    (state: AppState) => state.elearning.isLoading
  );
  const editEventState = useSelector(
    (state: AppState) => state.event.editEvent
  );
  const eventFetchingError = useSelector(
    (state: AppState) => state.event.error
  );
  const notFoundError = useSelector(
    (state: AppState) => state.event.notFoundError
  );
  const elearningFetchingError = useSelector(
    (state: AppState) => state.elearning.error
  );
  const error = eventFetchingError || elearningFetchingError;
  const editEventStateLoaded = useSelector(
    (state: AppState) => state.event.editEventLoaded
  );

  const isLoading = eLearningIsLoading || eventIsLoading;

  const trainingType =
    props.typeOfTrainingToBeCreated || editEventState.training_type;
  const { editEventWithStatusChecks } = useEditFormContext(id, trainingType);

  const routeToCheck =
    trainingType === TrainingType.DefaultEvent
      ? routes.event_edit_id
      : trainingType === TrainingType.ELearning
      ? routes.elearning_edit_id
      : routes.blended_learning_edit_id;

  const { isValidId } = useValidId(routeToCheck, id);

  const isEditMode = id ? true : false;

  const [deleted, setDeleted] = React.useState(false);
  const [showEventPreview, setShowEventPreview] = React.useState({
    open: false,
    template: false,
  });

  const handleClose = () => {
    setShowEventPreview({ open: false, template: false });
  };

  function hasBeenDeletedHandler() {
    setDeleted(true);
  }

  useEffect(() => {
    if (
      isEditMode &&
      isValidId &&
      !error &&
      !participant.participantListLoaded &&
      !participant.isLoading
    ) {
      dispatch(getParticipants(id));
    }
  }, [
    dispatch,
    error,
    id,
    isEditMode,
    isValidId,
    participant.isLoading,
    participant.participantListLoaded,
  ]);

  useEffect(() => {
    if (
      isEditMode &&
      !editEventStateLoaded &&
      !isLoading &&
      !participant.isLoading &&
      !participant.participantListLoaded &&
      !error &&
      !deleted
    ) {
      if (props.typeOfTrainingToBeCreated === TrainingType.DefaultEvent) {
        dispatch(getEvent(id));
      } else if (props.typeOfTrainingToBeCreated === TrainingType.ELearning) {
        dispatch(getELearning(id));
      } else if (
        props.typeOfTrainingToBeCreated === TrainingType.BlendedLearning
      ) {
        dispatch(getBlendedLearning(id));
      }
    }
    dispatch(billingAddressClear());
  }, [
    dispatch,
    editEventState,
    editEventStateLoaded,
    id,
    isLoading,
    isEditMode,
    deleted,
    props.typeOfTrainingToBeCreated,
    error,
    participant.isLoading,
    participant.participantListLoaded,
  ]);

  useEffect(() => {
    if (
      !laekhData.isLoading &&
      laekhData.laekhData.categories.length === 0 &&
      laekhData.laekhData.formIds.length === 0
    ) {
      dispatch(getLAEKHData());
    }
  }, [
    dispatch,
    laekhData.isLoading,
    laekhData.laekhData.categories.length,
    laekhData.laekhData.formIds.length,
  ]);

  const trainingTypeAsString = mapToTrainingTypeString(
    props.typeOfTrainingToBeCreated
  );

  const initData = {
    title: "",
    vnr: "",
    score: 0,
    price: 0,
    minseats: 1,
    maxseats: 1,
    participation_types: [],
    special_fields: [],
    coverage_areas: [],
    status: EventStatus.NotVisible,
    isCapped: false,
    noReminder: false,
    noDiscount: false,
    noAutomaticParticipationCertificate: false,
    description: EditorState.createEmpty().getCurrentContent(),
    descriptionState: EditorState.createEmpty().getCurrentContent(),
    speakers: [],
    location: "",
    city: "Frankfurt",
    zip_code: "60486",
    locationDetails: "",
    eventType: EventType.Undefined,
    trainingType: props.typeOfTrainingToBeCreated,
    beginDate: moment().startOf("day"),
    endDate: moment().startOf("day"),
    beginTime: moment().set({
      hour: EVENT_DEFAULT_START_TIME,
      minute: 0,
      second: 0,
      millisecond: 0,
    }),
    endTime: moment().set({
      hour: EVENT_DEFAULT_START_TIME,
      minute: EVENT_DEFAULT_INTERVAL,
      second: 0,
      millisecond: 0,
    }), // let the event last at least 30 minutes per default
    catering: false,
    internalExternalEvent: InternalExternalEventType.Internal,
    documents: [],
    commentary: "",
    searchSpeakers: "",
    tasks: [],
    checklist: [],
    checklist_additional_info: "",
    evaluationLink: "",
    evaluationLinkSpeaker: "",
    laekh_form_id: "",
    laekh_category: "",
    numberOfUnits: 0,
    durationPerUnitInMinutes: 0,
    maxTimePeriodToFinishElearningModuleInWeeks: 0,
    pinboardIsActivated: false,
    publishELearningAfterEvent: false,
    guid: "",
  } as ELearningFormData;

  return (
    <>
      {(isLoadingPage || isLoading) && !error ? (
        <>
          <Loader loadingText="Bitte warten" />
        </>
      ) : !isLoadingPage &&
        isEditMode &&
        !isLoading &&
        (notFoundError || !isValidId) ? (
        <>
          <NoMatch />
        </>
      ) : (
        <>
          <HeadingKvh>
            {isEditMode
              ? `${trainingTypeAsString} bearbeiten`
              : `Neue${
                  props.typeOfTrainingToBeCreated !== TrainingType.DefaultEvent
                    ? "s"
                    : ""
                } ${trainingTypeAsString} anlegen`}
            {isEditMode && editEventState && editEventState.public_id && (
              <span style={{ textTransform: "none" }}>
                {" "}
                (Kurs {editEventState.public_id})
              </span>
            )}
          </HeadingKvh>
          <Formik
            enableReinitialize
            onSubmit={(data: EventFormData | ELearningFormData) => {
              if (isEditMode) {
                editEventWithStatusChecks(data);
              } else {
                if (data.submitWithTemplate) {
                  dispatch(setPreview(data));
                  setShowEventPreview({ open: true, template: true });
                } else {
                  dispatch(setPreview(data));
                  setShowEventPreview({ open: true, template: false });
                }
              }
            }}
            validationSchema={
              isEditMode
                ? eventValidationSchema(trainingType, editEventState)
                : eventValidationSchema(trainingType)
            }
            initialValues={
              isEditMode && isValidId
                ? mapEditELearningDtoToEventForm(editEventState) || initData
                : preview.previewEventLoaded
                ? preview.previewEvent
                : templates.eventTemplateMode
                ? ({
                    ...initData,
                    ...mapEventTemplateDtoToFormData(templates.eventTemplate),
                  } as EventFormData)
                : initData
            }
          >
            {({ isValid, submitCount }) => (
              <>
                <Form onKeyDown={Utils.preventEnterToSubmit}>
                  <EventTabs
                    typeOfTrainingToBeCreated={trainingType}
                    viewMode={isEditMode ? "editEvent" : "createEvent"}
                    eventId={id}
                  />
                  {!isValid && submitCount > 0 && <FieldsMissingAlert />}
                  <EventFormButtons
                    id={id}
                    isEditMode={isEditMode && isValidId}
                    hasBeenDeleted={hasBeenDeletedHandler}
                    typeOfTrainingToBeCreated={trainingType}
                    {...props}
                  />
                  <PreviewEvent
                    showEventPreview={showEventPreview}
                    typeOfTrainingToBeCreated={trainingType}
                    onClose={handleClose}
                  />
                </Form>
              </>
            )}
          </Formik>
        </>
      )}
    </>
  );
};
