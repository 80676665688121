import { Box, Typography } from "@material-ui/core";
import * as React from "react";

interface NoEventsFoundProps {
  itemsNotFound?: string;
  customText?: string;
}

const NoEventsFound: React.FC<NoEventsFoundProps> = (props) => {
  return (
    <Box key="no-events-found" my={10}>
      <Typography variant="body1" align="center">
        {props.customText ? (
          props.customText
        ) : (
          <>
            Es konnten keine{" "}
            {props.itemsNotFound ? props.itemsNotFound : "Veranstaltungen"}{" "}
            gefunden werden
            {props.itemsNotFound ? "" : ", die diesen Kriterien entsprechen"}.
          </>
        )}
      </Typography>
    </Box>
  );
};

export default NoEventsFound;
