export interface ContactState {
  sendSuccess: boolean;
  isSending: boolean;
}

export const CONTACT_REQUEST = "CONTACT_REQUEST";
export const CONTACT_FAILURE = "CONTACT_FAILURE";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_CLEAR = "CONTACT_CLEAR";

// actions that are the same for all api calls (request and failure)
export interface ContactRequestAction {
  type: typeof CONTACT_REQUEST;
}

export interface ContactFailureAction {
  type: typeof CONTACT_FAILURE;
}

export interface ContactSuccessAction {
  type: typeof CONTACT_SUCCESS;
}

export interface ContactClearAction {
  type: typeof CONTACT_CLEAR;
}

export type ContactActionTypes =
  | ContactRequestAction
  | ContactFailureAction
  | ContactSuccessAction
  | ContactClearAction;
