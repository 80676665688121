import { Box, Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux";
import ELearningCard from "./ELearningCard";

interface DashboardItemsProps {}

const DashboardItems: React.FC<DashboardItemsProps> = (props) => {
  const eLearnings = useSelector((state: AppState) => state.dashboard);
  return (
    <React.Fragment>
      {eLearnings.dashboardList.count === 0 ||
      eLearnings.dashboardList.results.length === 0 ? (
        <Box mt={2} mb={3} display="flex" justifyContent="center">
          Sie haben noch keine E-Learnings gebucht.
        </Box>
      ) : (
        <Grid
          container
          style={{ padding: "8px" }}
          spacing={5}
          alignItems="center"
        >
          {eLearnings.dashboardList.results.map((training) => {
            return <ELearningCard key={training.guid} training={training} />;
          })}
        </Grid>
      )}
    </React.Fragment>
  );
};

export default DashboardItems;
