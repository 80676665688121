import { Dispatch } from "redux";
import { pinboardService } from "../../api";
import { PinboardCommentFormData } from "../../models/pinboardData";
import { Utils } from "../../services/utils";
import { success } from "../alerts/actions";
import { AlertActionTypes } from "../alerts/types";
import { pinboardClear } from "../pinboard/actions";
import { PinboardClearAction } from "../pinboard/types";
import {
  PinboardAnswerActionTypes,
  PinboardPostAnswerAction,
  PinboardPostAnswerFailureAction,
  PinboardPostAnswerSuccessAction,
  PinboardUpdateAnswerAction,
  PinboardUpdateAnswerFailureAction,
  PinboardUpdateAnswerSuccessAction,
  PINBOARD_POST_ANSWER,
  PINBOARD_POST_ANSWER_FAILURE,
  PINBOARD_POST_ANSWER_SUCCESS,
  PINBOARD_UPDATE_ANSWER,
  PINBOARD_UPDATE_ANSWER_FAILURE,
  PINBOARD_UPDATE_ANSWER_SUCCESS,
} from "./types";

export function postAnswer(data: PinboardCommentFormData) {
  return (
    dispatch: Dispatch<
      PinboardAnswerActionTypes | AlertActionTypes | PinboardClearAction
    >
  ) => {
    dispatch(pinboardPostAnswer());
    pinboardService
      .postAnswer(data)
      .then((response) => {
        dispatch(pinboardPostAnswerSuccess(response.data));
        dispatch(pinboardClear());
        dispatch(
          success(
            {
              message: `Die Antwort wurde erfolgreich erstellt: "${Utils.truncateRichText(
                data.answer,
                32
              )}"`,
            },
            3000
          )
        );
      })
      .catch(() => {
        dispatch(pinboardPostAnswerFailure());
      });
  };
}

function editAnswer(
  id: string,
  data: PinboardCommentFormData,
  feedback: string
) {
  return (
    dispatch: Dispatch<
      PinboardAnswerActionTypes | AlertActionTypes | PinboardClearAction
    >
  ) => {
    dispatch(pinboardUpdateAnswer());
    pinboardService
      .updateAnswer(id, data)
      .then((response) => {
        dispatch(pinboardUpdateAnswerSuccess(response.data));
        dispatch(pinboardClear());
        dispatch(
          success(
            {
              message: feedback,
            },
            3000
          )
        );
      })
      .catch(() => {
        dispatch(pinboardUpdateAnswerFailure());
      });
  };
}

export function updateAnswer(id: string, data: PinboardCommentFormData) {
  return editAnswer(
    id,
    data,
    `Die Antwort wurde erfolgreich bearbeitet: "${Utils.truncateRichText(
      data.answer,
      32
    )}"`
  );
}

export function deleteAnswer(id: string, data: PinboardCommentFormData) {
  data.is_trash = true;
  return editAnswer(
    id,
    data,
    `Die Antwort "${Utils.truncateRichText(
      data.answer,
      32
    )}" wurde erfolgreich entfernt.`
  );
}

export function archiveAnswer(id: string, data: PinboardCommentFormData) {
  data.is_archived = true;
  return editAnswer(
    id,
    data,
    `Die Antwort "${Utils.truncateRichText(
      data.answer,
      32
    )}" wurde erfolgreich archiviert.`
  );
}

export function unarchiveAnswer(id: string, data: PinboardCommentFormData) {
  data.is_archived = false;
  return editAnswer(
    id,
    data,
    `Die Antwort "${Utils.truncateRichText(
      data.answer,
      32
    )}" wurde erfolgreich veröffentlicht.`
  );
}

export function pinboardPostAnswer(): PinboardPostAnswerAction {
  return {
    type: PINBOARD_POST_ANSWER,
  };
}

export function pinboardPostAnswerSuccess(
  data: PinboardCommentFormData
): PinboardPostAnswerSuccessAction {
  return {
    type: PINBOARD_POST_ANSWER_SUCCESS,
    data,
  };
}

export function pinboardPostAnswerFailure(): PinboardPostAnswerFailureAction {
  return {
    type: PINBOARD_POST_ANSWER_FAILURE,
  };
}

export function pinboardUpdateAnswer(): PinboardUpdateAnswerAction {
  return {
    type: PINBOARD_UPDATE_ANSWER,
  };
}

export function pinboardUpdateAnswerSuccess(
  data: PinboardCommentFormData
): PinboardUpdateAnswerSuccessAction {
  return {
    type: PINBOARD_UPDATE_ANSWER_SUCCESS,
    data,
  };
}

export function pinboardUpdateAnswerFailure(): PinboardUpdateAnswerFailureAction {
  return {
    type: PINBOARD_UPDATE_ANSWER_FAILURE,
  };
}
