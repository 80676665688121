import {
  makeStyles,
  Theme,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import * as React from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { AppState } from "../../redux";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "#fff",
  },
}));

const useStylesMono = makeStyles((theme: Theme) => ({
  root: {
    color: "#000",
  },
}));

const TypographyWhiteText: React.FC<TypographyProps> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const classes = useStyles();
  const classesMono = useStylesMono();

  return (
    <Typography
      {...props}
      className={clsx(
        accessibility.monoMode ? classesMono.root : classes.root,
        props.className
      )}
    >
      {props.children}
    </Typography>
  );
};

export default TypographyWhiteText;
