import React from "react";
import HeadingKvh from "../../theming/HeadingKvh";
import TabsKvh from "../../theming/tabs/TabsKvh";
import { DocumentTemplates } from "./document/DocumentTemplates";
import { EventTemplates } from "./event/EventTemplates";
import { TaskTemplates } from "./task/TaskTemplates";

export const Templates: React.FC = () => {
  return (
    <>
      <HeadingKvh>Vorlagen</HeadingKvh>
      <TabsKvh
        openTabType={"templates"}
        tabs={[
          {
            label: "Veranstaltungen",
            content: <EventTemplates />,
          },
          {
            label: "Standardaufgaben",
            content: <TaskTemplates />,
          },
          {
            label: "Dokumente",
            content: <DocumentTemplates />,
          },
        ]}
      />
    </>
  );
};
