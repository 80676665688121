import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { FieldAttributes, useField } from "formik";

/**
 * CustomTextField Component:
 *
 * This component extends the functionality of Material-UI's TextField component
 * and integrates seamlessly with Formik, a popular form management library for React.
 *
 * @param {FieldAttributes<{}> & TextFieldProps} props - Props for CustomTextField component.
 * @returns {React.FC} - Returns the customized TextField component.
 */
const CustomTextField: React.FC<FieldAttributes<{}> & TextFieldProps> = ({
  helperText,
  ...props
}) => {
  // useField hook from Formik provides field state and handlers
  const [field, meta] = useField<{}>(props);
  // Extracting the validation error text from Formik's meta object
  const error = meta.error && meta.touched ? meta.error : ""; // get validation error text

  return (
    <TextField
      InputProps={{ required: false }} // don't trgger the required browser check
      variant="filled"
      {...field}
      {...props}
      helperText={error ? error : helperText}
      error={!!error}
      autoComplete="off"
    />
  );
};

export default CustomTextField;
