import { SpeakerData } from "../models/speakerData";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";

export class SpeakerService extends BaseCrudService {
  async getSpeakers() {
    const response = await this.getAll<SpeakerData[]>(apiRoutes.speakers);
    return response.data;
  }
}
