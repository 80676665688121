import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  TaskStatus,
  mapToTaskStatusString,
} from "../../../models/enums/taskStatus.enum";
import { toEditTaskData } from "../../../models/taskData";
import { UserData } from "../../../models/userData";
import { AppState } from "../../../redux";
import { showDialog } from "../../../redux/dialogs/actions";
import {
  addTask,
  deleteTask,
  getTask,
  updateTask,
} from "../../../redux/tasks/actions";
import { NoMatch } from "../../errorPages/noMatch";
import CustomDatepicker from "../../forms/CustomDatepicker";
import CustomTextField from "../../forms/CustomTextField";
import CustomSelect from "../../forms/selects/CustomSelect";
import { SelectData } from "../../forms/selects/selectData";
import usePageLoader from "../../hooks/usePageLoader";
import useValidId from "../../hooks/useValidId";
import routes from "../../routing/routes";
import CustomButton from "../../theming/CustomButton";
import HeadingKvh from "../../theming/HeadingKvh";
import Loader from "../../theming/loader/Loader";
import styles from "./../../../styles/custom-styles.module.scss";
import addTaskValidationSchema from "./addTaskValidationSchema";
import { SearchEventField } from "./shared/SearchEventField";
import { NIL } from "uuid";

interface ParamTypes {
  taskId: string;
}

const useStyles = makeStyles((theme) => ({
  hideIcon: {
    "& .MuiSelect-iconFilled": {
      paddingRight: "0px",
      display: "none",
    },
  },
}));

const CrudTask: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // receive the id from the url params (:id)
  const { taskId } = useParams<ParamTypes>();
  const isValidId = useValidId(routes.tasks_edit_id, taskId);

  const { isLoadingPage } = usePageLoader();

  const tasks = useSelector((state: AppState) => state.tasks);
  const taskNotFoundError = tasks.notFoundError;

  useEffect(() => {
    if (
      !tasks.editingTaskLoaded &&
      isValidId &&
      !tasks.isLoading &&
      !tasks.error
    ) {
      dispatch(getTask(taskId));
    }
  });

  // responsibility
  const currentUser = useSelector((state: AppState) =>
    state.user.currentUser
      ? state.user.currentUser!
      : ({ guid: "" } as UserData)
  );
  const [responsibilityOptions, setResponsibilityOptions] = useState(
    [] as SelectData[]
  );

  const takeResponsibility = () => {
    setResponsibilityOptions([
      {
        key: currentUser.guid,
        value: currentUser.guid,
        label: currentUser.fullname,
      },
    ]);
  };

  const passResponsibility = () => {
    setResponsibilityOptions([
      {
        key: "not-assigned",
        value: NIL,
        label: "Nicht zugewiesen",
      },
    ]);
  };

  useEffect(() => {
    if (
      !tasks.isLoading &&
      tasks.editingTaskLoaded &&
      responsibilityOptions.length === 0
    ) {
      if (!tasks.editingTask.responsibility) {
        passResponsibility();
      } else if (tasks.editingTask.responsibility) {
        setResponsibilityOptions([
          {
            key: tasks.editingTask.responsibility.guid.toString(),
            value: tasks.editingTask.responsibility.guid.toString(),
            label: tasks.editingTask.responsibility.fullname.toString(),
          },
        ]);
      }
    }
  }, [setResponsibilityOptions, responsibilityOptions, tasks]);

  const classes = useStyles();
  return (
    <>
      {(isLoadingPage || tasks.isLoading) && !tasks.error ? (
        <>
          <Loader />
        </>
      ) : !isLoadingPage &&
        taskId &&
        !tasks.isLoading &&
        (taskNotFoundError || !isValidId) ? (
        <>
          <NoMatch />
        </>
      ) : (
        <>
          <HeadingKvh>
            {taskId ? "Aufgabe bearbeiten" : "Neue Aufgabe anlegen"}
          </HeadingKvh>

          <Formik
            onSubmit={(data) => {
              data.responsibility =
                responsibilityOptions.length > 0
                  ? responsibilityOptions[0].value.toString()
                  : "";
              if (data.responsibility === "") data.responsibility = null;
              data.event = data.event && data.event !== "" ? data.event : null;

              if (!taskId) {
                dispatch(addTask(data));
              } else {
                dispatch(updateTask(data));
              }
            }}
            enableReinitialize
            validationSchema={
              taskId
                ? addTaskValidationSchema(tasks.editingTask)
                : addTaskValidationSchema
            }
            initialValues={toEditTaskData(tasks.editingTask)}
          >
            {({ setFieldValue }) => (
              <Form>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <CustomTextField
                      fullWidth
                      autoComplete="description"
                      name="description"
                      required
                      label="Aufgabenbeschreibung"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SearchEventField
                      onChangeEventId={(id: string) =>
                        setFieldValue("event", id)
                      }
                      initialEvent={tasks.editingTask.event}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={CustomDatepicker}
                      fullWidth
                      id="dueDate-picker"
                      label="Fälligkeit"
                      name="due_date"
                    />
                  </Grid>
                  <Grid item xs={6} sm={false}></Grid>
                  <Grid item xs={6}>
                    <Box display="flex">
                      <CustomSelect
                        className={classes.hideIcon}
                        fullWidth
                        noEmptyDefaultValue
                        disabled
                        label="Zuständigkeit"
                        name="responsibility"
                        id="responsibility"
                        labelId="responsibility-label"
                        options={responsibilityOptions}
                        value={
                          responsibilityOptions.length > 0
                            ? responsibilityOptions[0].value
                            : ""
                        }
                      />

                      <Box ml={2} height={60}>
                        {responsibilityOptions.length === 0 ||
                        responsibilityOptions[0].value !== currentUser.guid ? (
                          <Button
                            style={{ height: "inherit" }}
                            onClick={takeResponsibility}
                            fullWidth
                            variant="contained"
                            color="primary"
                          >
                            Mir zuweisen
                          </Button>
                        ) : (
                          <Button
                            style={{ height: "inherit" }}
                            onClick={passResponsibility}
                            fullWidth
                            variant="contained"
                            color="primary"
                          >
                            Zuweisen entfernen
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomSelect
                      noEmptyDefaultValue
                      label="Status"
                      name="status"
                      id="status-select"
                      labelId="status-select-label"
                      options={[
                        TaskStatus.Open,
                        TaskStatus.InProgress,
                        TaskStatus.Done,
                      ].map((item) => {
                        return {
                          key: item.toString(),
                          value: item.toString(),
                          label: mapToTaskStatusString(item),
                        };
                      })}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomTextField
                      fullWidth
                      multiline
                      rows={3}
                      autoComplete="additional_info"
                      name="additional_info"
                      label="Zusätzliche Beschreibung"
                    />
                  </Grid>
                </Grid>

                <Box my={3} display="flex" justifyContent="space-between">
                  <Box>
                    <Button
                      type="submit"
                      style={{ height: "inherit" }}
                      variant="contained"
                      color="primary"
                    >
                      {taskId ? "Änderungen speichern" : "Aufgabe anlegen"}
                    </Button>
                    {taskId ? (
                      <Button
                        type="button"
                        onClick={() => history.push(routes.tasks)}
                        style={{
                          height: "inherit",
                          marginLeft: "1rem",
                        }}
                        variant="contained"
                        color="secondary"
                      >
                        Ohne Speichern zurück
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Box>
                  {taskId ? (
                    <CustomButton
                      customColor={styles.red}
                      textColor={"#fff"}
                      hoverColor={styles["red-dark"]}
                      onClick={() =>
                        dispatch(
                          showDialog({
                            title: "Aufgabe löschen",
                            message:
                              "Sind Sie sich sicher, dass Sie die Aufgabe löschen möchten?",
                            action: () => {
                              dispatch(deleteTask(taskId));
                            },
                          })
                        )
                      }
                    >
                      Aufgabe löschen
                    </CustomButton>
                  ) : (
                    <></>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default CrudTask;
