import { Link } from "@material-ui/core";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { clearBookings } from "../../../../redux/bookings/actions";
import routes from "../../../routing/routes";
import "./Dashboard.scss";

interface ELearningDetailsLinkProps {
  idToLinkTo: string;
}

const ELearningDetailsLink: React.FC<ELearningDetailsLinkProps> = (props) => {
  const dispatch = useDispatch();
  return (
    <Link
      onClick={() => {
        dispatch(clearBookings());
      }}
      target="_blank"
      rel="noreferrer"
      component={RouterLink}
      to={`${routes.my_events_details_elearning}/${props.idToLinkTo}`}
      title="E-Learning Details"
    >
      {props.children}
    </Link>
  );
};

export default ELearningDetailsLink;
