import {
  DocumentData,
  DocumentUploadDataResponse,
} from "../models/documentData";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";

export class DocumentsService extends BaseCrudService {
  async uploadDocument(data: FormData) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = await this.create<FormData, DocumentUploadDataResponse>(
      apiRoutes.documents,
      data,
      {},
      config
    );
    return response;
  }

  async deleteDocument(document: DocumentData) {
    const response = await this.delete<DocumentData>(
      apiRoutes.documents,
      document.guid.toString()
    );
    return response;
  }

  async bulkDeleteDocument(guids: string[]) {
    return Promise.all(
      guids.map(async (id) => {
        return (
          await this.delete<DocumentData>(apiRoutes.documents, id.toString())
        ).data;
      })
    );
  }

  async editDocumentState(document: DocumentData, data: FormData) {
    const response = await this.edit(
      apiRoutes.documents,
      document.guid.toString(),
      data
    );
    return response;
  }

  async getDefaultDocuments(eventType: string, trainingType: string) {
    const response = await this.getAll<DocumentData[]>(
      apiRoutes.documents_default,
      { event_type: eventType, training_type: trainingType }
    );
    return response.data;
  }

  async getAllDefaultDocuments() {
    const response = await this.getAll<DocumentData[]>(
      apiRoutes.documents_default
    );
    return response.data;
  }

  async getDocument(guid: string) {
    const response = await this.get<DocumentData>(
      apiRoutes.documents,
      guid.toString()
    );
    return response.data;
  }

  async getDocumentsByEvent(
    eventGuid: string,
    isTemplate: boolean,
    trainingType: string
  ) {
    let route = "";
    if (isTemplate) {
      route = apiRoutes.templates_event;
    } else {
      route = apiRoutes.events;
    }
    const response = await this.getAll<DocumentData[]>(
      route + "/" + eventGuid.toString() + apiRoutes.documents,
      { training_type: trainingType }
    );
    return response.data;
  }
}
