import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { CartData } from "../models/cartData";

export class CartService extends BaseCrudService {
  async getCart() {
    const response = await this.getAll<CartData[]>(apiRoutes.cart);
    return response.data;
  }

  async addToCart(eventGuid: string, userGuid: string) {
    const response = await this.create(apiRoutes.cart, {
      event: eventGuid,
      book_for: userGuid,
    });
    return response;
  }

  async removeFromCart(eventGuid: string) {
    const response = await this.delete(apiRoutes.cart, eventGuid);
    return response;
  }
}
