import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { LinkAccountData } from "../models/linkAccountData";
import { AxiosResponse } from "axios";

export class LinkAccountService extends BaseCrudService {
  async getLinkedAccounts() {
    const response = await this.getAll<LinkAccountData[]>(
      apiRoutes.link_account
    );
    return response.data;
  }

  async deleteLinkAccount(guid: string) {
    const response = await this.delete<LinkAccountData>(
      apiRoutes.link_account,
      guid.toString()
    );
    return response.data;
  }

  async linkLanr(lanr: string) {
    const response = await this.create<
      { lanr: string },
      AxiosResponse<LinkAccountData>
    >(apiRoutes.link_account, {
      lanr: lanr,
    });
    return response.data;
  }

  async verifyLanr(lanr: string) {
    const response = await this.getAll(apiRoutes.link_account_validate, {
      lanr: lanr,
    });
    return response.data;
  }

  async verifyLinkLanrRequestCode(code: string) {
    const response = await this.create(apiRoutes.link_account_confirmation, {
      code: code,
    });
    return response.data;
  }
}
