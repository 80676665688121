import { Button, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CrudBillingAddressData } from "../../../../models/billingAddressData";
import { AppState } from "../../../../redux";
import {
  createBillingAddress,
  editBillingAddress,
  getBillingAddresses,
} from "../../../../redux/billingAddress/actions";
import BillingAddressForm from "./billingAddressForm";
import billingAddressValidationSchema from "./billingAddressValidationSchema";

const BillingAddress: React.FC = () => {
  const dispatch = useDispatch();

  const billingAddress = useSelector((state: AppState) => state.billingAddress);
  const [editMode, setEditMode] = useState(false);
  const [createMode, setCreateMode] = useState(false);

  useEffect(() => {
    if (!billingAddress.billingAddressLoaded) {
      dispatch(getBillingAddresses());
    }
  }, [dispatch, billingAddress.billingAddressLoaded]);

  return (
    <>
      {billingAddress.billingAddressList.length === 0 ? (
        !createMode ? (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              Sie haben noch keine Rechnungsadresse hinterlegt.
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  setCreateMode(true);
                  setEditMode(true);
                }}
              >
                Rechnungsadresse hinterlegen
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Formik
            onSubmit={(data: CrudBillingAddressData) => {
              dispatch(createBillingAddress(data));
              setCreateMode(false);
              setEditMode(false);
            }}
            enableReinitialize
            validationSchema={billingAddressValidationSchema}
            initialValues={
              {
                medical_office_or_company: "",
                medical_office_or_company_extra: "",
                first_name: "",
                last_name: "",
                address: "",
                street_address: "",
                zip_code: "",
                city: "",
                phone: "",
                email: "",
              } as CrudBillingAddressData
            }
          >
            {() => (
              <Form>
                <Grid container spacing={4}>
                  <BillingAddressForm editMode={editMode} />
                  <Grid item xs={6} sm={4}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                    >
                      Rechnungsadresse anlegen
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        )
      ) : (
        billingAddress.billingAddressList.map(
          (billingAddress: CrudBillingAddressData, index: number) => {
            return (
              <Formik
                key={"billing-address-form-" + index}
                onSubmit={(data) => {
                  dispatch(editBillingAddress(billingAddress.guid, data));
                  setEditMode(false);
                }}
                enableReinitialize
                validationSchema={billingAddressValidationSchema}
                initialValues={
                  {
                    medical_office_or_company:
                      billingAddress.medical_office_or_company,
                    medical_office_or_company_extra:
                      billingAddress.medical_office_or_company_extra,
                    first_name: billingAddress.first_name,
                    last_name: billingAddress.last_name,
                    address: billingAddress.address,
                    street_address: billingAddress.street_address,
                    zip_code: billingAddress.zip_code,
                    city: billingAddress.city,
                    phone: billingAddress.phone,
                    email: billingAddress.email,
                  } as CrudBillingAddressData
                }
              >
                {() => (
                  <Form>
                    <Grid container spacing={4}>
                      <BillingAddressForm editMode={editMode} />
                      {editMode ? (
                        <>
                          <Grid item xs={6} sm={3}>
                            <Button
                              size="large"
                              variant="contained"
                              color="primary"
                              fullWidth
                              type="submit"
                            >
                              Rechnungsadresse ändern
                            </Button>
                          </Grid>
                          <Grid item xs={6} sm={2}>
                            <Button
                              size="large"
                              variant="contained"
                              color="secondary"
                              fullWidth
                              onClick={() => {
                                setEditMode(false);
                              }}
                            >
                              Abbrechen
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <Grid item xs={6} sm={4}>
                          <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                              setEditMode(true);
                            }}
                          >
                            Rechnungsadresse bearbeiten
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Form>
                )}
              </Formik>
            );
          }
        )
      )}
    </>
  );
};

export default BillingAddress;
