import { Button, Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AppState } from "../../../../redux";
import { logout } from "../../../../redux/auth/actions";
import "./Navigation.scss";
import { getNavigationByRole } from "./navigationData";
import { clearDocuments } from "../../../../redux/documents/actions";

export const Navigation: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const navigationData = getNavigationByRole(currentUser?.user_type);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutFromMoodle = () => {
    const d = new Date();
    d.setTime(d.getTime() + 1);
    document.cookie = `MoodleSession=;expires=${d.toUTCString()};path=/`;
  };

  return (
    <div className={"main-menu-wrapper"}>
      <Button
        aria-controls="menu"
        variant={accessibility.monoMode ? "contained" : undefined}
        color={!accessibility.monoMode ? "primary" : "default"}
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          backgroundColor: !accessibility.monoMode ? "transparent" : "#000",
          padding: "0.25em",
        }}
      >
        <MenuIcon
          id={
            accessibility.monoMode
              ? "main-menu-button-mono"
              : "main-menu-button"
          }
        />
      </Button>
      <Menu
        id="main-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {navigationData.map((navItem, index: number) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                if (navItem.key === "logout") {
                  logoutFromMoodle();
                  dispatch(logout());
                } else {
                  if (navItem.key === "vorlagen") {
                    dispatch(clearDocuments()); // clear DocumentsList, when entering Templates
                  }
                  history.push(navItem.route);
                }
                handleClose();
              }}
            >
              {navItem.text}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
