import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { PlayCircleOutlineOutlined } from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KvhAddToCartSvg } from "../../../../assets/icons/custom-svg-components/KvhSvgCollection";
import { DashboardListData } from "../../../../models/dashboardData";
import { MyELearningListData } from "../../../../models/elearning";
import { mapCountToProgressStatus } from "../../../../models/enums/elearningProgressStatus.enum";
import {
  ELearningParticipationStatus,
  ParticipationStatus,
} from "../../../../models/enums/participationStatus.enum";
import { TrainingType } from "../../../../models/enums/trainingType.enum";
import { UserType } from "../../../../models/enums/userType.enum";
import { AppState } from "../../../../redux";
import { clickedEvaluationLink } from "../../../../redux/bookings/actions";
import { initialBookingEventState } from "../../../../redux/bookings/reducers";
import { bookFree } from "../../../../redux/dashboard/actions";
import { showDialog } from "../../../../redux/dialogs/actions";
import { Utils } from "../../../../services/utils";
import styles from "../../../../styles/custom-styles.module.scss";
import useMobile from "../../../hooks/useMobile";
import routes from "../../../routing/routes";
import "./Dashboard.scss";
import DashboardCounter from "./DashboardCounter";
import ELearningCardHeader from "./ELearningCardHeader";
import ELearningDetailsLink from "./ELearningDetailsLink";
import PinboardCounter from "./PinboardCounter";

interface ELearningCardProps {
  training: DashboardListData;
}

const ELearningCard: React.FC<ELearningCardProps> = (props) => {
  const truncateTitleLimit = 35;
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const isTestParticipant = currentUser?.user_type === UserType.TestParticipant;
  const dispatch = useDispatch();

  const { isMobile } = useMobile();
  // to prevent the evaluation popup to show every 15 seconds (everytime the dashboard refreshes)
  const [showEvaluationPopupOnce, setShowEvaluationPopupOnce] = useState(false);

  const booking: MyELearningListData =
    props.training.booking === undefined || props.training.booking === null
      ? initialBookingEventState
      : props.training.booking;

  // !NOTE: we don't have any progress percentage,
  // ! so for now the elearning status can either be open, in progress or done
  const progressStatus = mapCountToProgressStatus(
    booking.elearning_started_count,
    booking.elearning_status === ELearningParticipationStatus.Passed
  );

  // !NOTE: keep for now until it is clear, if there should be a progress bar of any kind
  // const progressPercentage = mapToProgress(progressStatus);
  // const random = Math.round(Math.random() * 40);
  // const offset =
  //   progressPercentage === 50
  //     ? progressPercentage + random
  //     : progressPercentage;
  // const style = {
  //   "--done": offset,
  //   "--progress": progressPercentage === 50 ? offset - 20 : offset,
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   width: "100%",
  // } as React.CSSProperties;

  const restrictAccessToLms = booking.restrict_access;

  const needsToBookedByTestUser =
    isTestParticipant &&
    (booking === undefined ||
      booking === null ||
      booking === initialBookingEventState);

  const startTraining = () => {
    if (needsToBookedByTestUser) {
      dispatch(bookFree(props.training.guid));
    } else {
      if (!isMobile) {
        window.open(
          `${routes.lms}/${booking.guid}`,
          "_blank",
          "noopener, noreferrer"
        );
      } else {
        dispatch(
          showDialog(
            {
              title:
                "Bearbeitung des Kurses auf mobilen Endgeräten mit geringer Auflösung nicht möglich",
              message: (
                <>
                  Die Bearbeitung von E-Learnings ist auf mobilen Endgeräten mit
                  geringer Auflösung nicht möglich. Wir können Ihnen so nicht
                  die vollständige Benutzererfahrung bieten. Um eine
                  reibungslose Bearbeitung des Kurses zu erlauben, wechseln Sie
                  bitte auf einen Desktop-PC oder ein mobiles Endgerät mit einer
                  Bildschirm-Auflösung von mindestens 1024x768.
                </>
              ),
            },
            true
          )
        );
      }
    }
  };

  // TODO: if there is time for refactoring in the future --> merge evaluation link functionality
  useEffect(() => {
    if (!showEvaluationPopupOnce) {
      if (
        props.training.training_type === TrainingType.ELearning &&
        booking.elearning_status === ELearningParticipationStatus.Passed &&
        !booking.reactivated &&
        props.training.evaluation_link &&
        !booking.user_has_clicked_evaluation_link &&
        booking.guid !== ""
      ) {
        dispatch(
          showDialog({
            title: `Bewertung des E-Learnings "${props.training.name}"`,
            message: (
              <>
                Möchten Sie sich einen kurzen Moment Zeit nehmen, um das
                E-Learning zu bewerten?
              </>
            ),
            confirmButtonText: "Zur Evaluation",
            action: () => {
              window.open(booking.event.evaluation_link);
              dispatch(clickedEvaluationLink(booking.guid, true, false));
            },
          })
        );
        setShowEvaluationPopupOnce(true);
      } else if (
        props.training.is_over &&
        props.training.training_type === TrainingType.BlendedLearning &&
        booking.status === ParticipationStatus.Participated &&
        booking.elearning_status === ELearningParticipationStatus.Passed &&
        !booking.reactivated &&
        props.training.evaluation_link_blended_learning &&
        !booking.user_has_clicked_evaluation_link_blended_learning &&
        booking.guid !== ""
      ) {
        dispatch(
          showDialog({
            title: `Bewertung des E-Learning-Teils des Blended-Learnings "${props.training.name}"`,
            message: (
              <>
                Möchten Sie sich einen kurzen Moment Zeit nehmen, um das
                E-Learning zu bewerten?
              </>
            ),
            confirmButtonText: "Zur Evaluation",
            action: () => {
              window.open(booking.event.evaluation_link_blended_learning);
              dispatch(clickedEvaluationLink(booking.guid, true, true));
            },
          })
        );
        setShowEvaluationPopupOnce(true);
      }
    }
  }, [booking, props.training, showEvaluationPopupOnce, dispatch]);

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{ display: "flex" }}
      justify="center"
    >
      <Card
        variant="elevation"
        className={
          accessibility.monoMode ? "dashboard-card-mono" : "dashboard-card"
        }
      >
        {props.training.name.length >= truncateTitleLimit ? (
          <Tooltip
            arrow
            placement="right"
            enterDelay={500}
            leaveDelay={200}
            style={{ fontSize: "16px" }}
            title={props.training.name}
          >
            <Box>
              <ELearningCardHeader
                title={
                  <>
                    <ELearningDetailsLink idToLinkTo={booking.guid}>
                      {Utils.truncateNames(
                        props.training.name,
                        truncateTitleLimit,
                        false
                      )}
                    </ELearningDetailsLink>
                  </>
                }
              />
            </Box>
          </Tooltip>
        ) : (
          <ELearningCardHeader
            title={
              <ELearningDetailsLink idToLinkTo={booking.guid}>
                {props.training.name}
              </ELearningDetailsLink>
            }
          />
        )}
        {booking.guid !== "" && (
          <Box className="dashboard-progress-container">
            {/* <Box
              className={accessibility.monoMode ? "progress-mono" : "progress"}
              style={style}
            ></Box> */}
            <Box pt={1} mx={2} display="flex" justifyContent="space-between">
              <Box>
                <small
                  style={{
                    color: accessibility.monoMode ? "#000" : undefined,
                  }}
                >
                  {" "}
                  #{props.training.public_id}
                </small>
              </Box>
              <Box>
                <small>Fortschritt: {progressStatus}</small>
              </Box>
            </Box>
          </Box>
        )}

        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={4}
              style={{
                minWidth: "80px",
              }}
            >
              {booking.guid !== "" &&
                props.training.unread_pinboard_count >= 0 && (
                  <PinboardCounter
                    booking={booking}
                    count={props.training.unread_pinboard_count}
                  />
                )}
            </Grid>
            <Grid item xs={4}>
              {booking.guid !== "" &&
                props.training.unread_document_count >= 0 && (
                  <DashboardCounter
                    booking={booking}
                    count={props.training.unread_document_count}
                  >
                    <DescriptionIcon
                      style={{
                        fontSize: "2.2em",
                        marginBottom: "-4px",
                        color: accessibility.monoMode
                          ? "#000"
                          : props.training.unread_document_count > 0
                          ? styles.primary
                          : styles.secondary,
                      }}
                    />
                  </DashboardCounter>
                )}
            </Grid>
            <Grid item xs={4}>
              <DashboardCounter
                booking={booking}
                count={
                  !props.training.is_participation_certificate_downloadable
                    ? 0
                    : 1
                }
                unreadCondition={
                  !booking.participation_certificate_was_downloaded
                }
                isExternalLink
                externalUrl={
                  props.training.participation_certificate_download_url
                }
              >
                <FontAwesomeIcon
                  icon={faTrophy}
                  size={"2x"}
                  color={accessibility.monoMode ? "#000" : styles.secondary}
                />
              </DashboardCounter>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              md={12}
              style={{ display: "flex", marginBottom: "0.5em" }}
              justify="flex-end"
            >
              <Button
                disabled={restrictAccessToLms && !isTestParticipant}
                onClick={startTraining}
              >
                <strong style={{ fontSize: "1.3rem" }}>
                  {needsToBookedByTestUser ? "Testbuchung" : "Starten"}
                </strong>
                {needsToBookedByTestUser ? (
                  <KvhAddToCartSvg strokeColor={styles.primary} size={"4em"} />
                ) : (
                  <PlayCircleOutlineOutlined
                    style={{
                      cursor: "pointer",
                      fontSize: "5rem",
                      color: accessibility.monoMode
                        ? "#000"
                        : restrictAccessToLms && !isTestParticipant
                        ? styles["secondary-accent-2"]
                        : styles.primary,
                    }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
          {props.training.booking !== undefined &&
            props.training.booking !== null && (
              <Box textAlign={"right"}>
                <small>
                  {`Bearbeitungszeitraum: ${new Date(
                    booking.overall_begin
                  ).toLocaleDateString()}-${new Date(
                    booking.overall_end
                  ).toLocaleDateString()}`}
                </small>
              </Box>
            )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ELearningCard;
