import { MyELearningListData } from "../../models/elearning";
import { MyEventListData } from "../../models/eventData";
import { Pagination } from "../../models/pagination";

export interface BookingState {
  myEventList: Pagination<MyELearningListData>;
  myEventListLoaded: boolean;
  myEventListPrint: MyEventListData[];
  myEvent: MyELearningListData;
  myEventLoaded: boolean;
  currentPage: number;
  isLoading: boolean;
}

export const BOOKINGS_REQUEST = "BOOKINGS_REQUEST";
export const BOOKINGS_FAILURE = "BOOKINGS_FAILURE";

export const CLEAR_BOOKINGS = "CLEAR_BOOKINGS";

// my events
export const SET_MYEVENTS_LIST_SUCCESS = "SET_MYEVENTS_LIST_SUCCESS";
export const SET_MYEVENTS_LIST_FOR_PRINT_SUCCESS =
  "SET_MYEVENTS_LIST_FOR_PRINT_SUCCESS";
export const SET_MYEVENT = "SET_MYEVENT";
export const UPDATE_MY_EVENT = "UPDATE_MY_EVENT";
export const SET_BOOKINGS_PAGE_COUNT = "SET_BOOKINGS_PAGE_COUNT";

// actions that are the same for all api calls (request and failure)
export interface BookingsRequestAction {
  type: typeof BOOKINGS_REQUEST;
}

export interface BookingsFailureAction {
  type: typeof BOOKINGS_FAILURE;
}

export interface SetMyEventsListAction {
  type: typeof SET_MYEVENTS_LIST_SUCCESS;
  data: Pagination<MyELearningListData>;
}

export interface SetMyEventsListForPrintAction {
  type: typeof SET_MYEVENTS_LIST_FOR_PRINT_SUCCESS;
  data: MyEventListData[];
}

export interface SetMyEventAction {
  type: typeof SET_MYEVENT;
  data: MyELearningListData;
}

export interface SetPageCountAction {
  type: typeof SET_BOOKINGS_PAGE_COUNT;
  pageCount: number;
}

export interface ClearBookingsAction {
  type: typeof CLEAR_BOOKINGS;
}

export interface UpdateMyEventAction {
  type: typeof UPDATE_MY_EVENT;
  userHasClickEvaluationLink?: boolean;
  userHasClickEvaluationLinkBlendedLearning?: boolean;
}

export type BookingActionTypes =
  | BookingsRequestAction
  | BookingsFailureAction
  | ClearBookingsAction
  | SetMyEventsListAction
  | SetMyEventsListForPrintAction
  | SetMyEventAction
  | SetPageCountAction
  | UpdateMyEventAction;
