import { Box, Typography } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../redux";

interface HeadingKvhProps {
  textColor?: string;
}

const HeadingKvh: React.FC<HeadingKvhProps> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);

  return (
    <Box
      className={
        accessibility.monoMode ? "mono-color-header-title" : "header-title"
      }
      mt={2}
    >
      <Typography variant="h4" gutterBottom style={{ color: props.textColor }}>
        {props.children}
      </Typography>
    </Box>
  );
};

export default HeadingKvh;
