import { Grid, Link } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { KvhInfoSvg } from "../../../../../assets/icons/custom-svg-components/KvhSvgCollection";
import { AppState } from "../../../../../redux";
import routes from "../../../../routing/routes";
import "./LearningEventsInfo.scss";

export const LearningEventsInfoLink: React.FC = () => {
  const accessibility = useSelector((state: AppState) => state.accessibility);

  return (
    <Grid container justify="flex-end" style={{ marginBottom: "10px" }}>
      <Link
        component={RouterLink}
        to={routes.learning_events_overview}
        target="_blank"
        rel="noreferrer"
        className={
          accessibility.monoMode
            ? "learning-events-overview-infolink-mono"
            : "learning-events-overview-infolink"
        }
      >
        <KvhInfoSvg />
        <span>Lernangebote im Überblick</span>
      </Link>
    </Grid>
  );
};
