import { ContentState, convertToRaw } from "draft-js";
import { FilterData } from "../services/filters/filterData";
import { Utils } from "../services/utils";
import { SpeakerBaseData } from "./speakerData";
import { TargetGroupIdData } from "./targetGroupData";

export interface EventTemplateData extends TargetGroupIdData {
  guid?: string; // don't send guid when adding event
  title: string;
  score: number;
  price: number;
  description: string;
  speakers: SpeakerBaseData[];
  min_seats: number;
  max_seats: number;
  documents: string[];
  laekh_form_id: string | null;
  laekh_category: string | null;
}

export interface EventTemplateFormData extends TargetGroupIdData {
  title: string;
  score: number;
  price: number;
  description: ContentState;
  descriptionState: ContentState;
  speakers: SpeakerBaseData[];
  minseats: number;
  maxseats: number;
  documents: string[];
  laekh_form_id: string | null;
  laekh_category: string | null;
}

export interface EventTemplateFilterData extends FilterData<{}> {
  search: string;
}

export interface EventTemplateFilterOptions {
  search?: string;
}

export function mapEventTemplateDataToDto(
  data: EventTemplateFormData
): EventTemplateData {
  const addEventTemplateDto: EventTemplateData = {
    ...data,
    price: parseFloat((data.price * 100).toFixed(2)),
    description: JSON.stringify(convertToRaw(data.description)),
    min_seats: data.minseats,
    max_seats: data.maxseats,
    laekh_category: data.laekh_category !== "" ? data.laekh_category : null,
    laekh_form_id: data.laekh_form_id !== "" ? data.laekh_form_id : null,
  };
  return addEventTemplateDto;
}

export function mapEventTemplateDtoToFormData(
  data: EventTemplateData
): EventTemplateFormData {
  const addEventTemplateDto: EventTemplateFormData = {
    ...data,
    price: data.price / 100,
    description: Utils.convertJsonToRteState(data.description),
    descriptionState: Utils.convertJsonToRteState(data.description),
    minseats: data.min_seats,
    maxseats: data.max_seats,
    laekh_category: data.laekh_category,
    laekh_form_id: data.laekh_form_id,
  };
  return addEventTemplateDto;
}
