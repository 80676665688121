import MomentUtils from "@date-io/moment";
import { Box, Container } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/de"; // it is required to select default locale manually
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { UserType } from "../../models/enums/userType.enum";
import { AppState } from "../../redux";
import { logout } from "../../redux/auth/actions";
import { showDialog } from "../../redux/dialogs/actions";
import CoreRoutes from "../routing/CoreRoutes";
import routes from "../routing/routes";
import ScrollToTopButton from "../routing/ScrollToTopButton";
import AlertDialog from "./dialog/AlertDialog";
import { Footer } from "./footer/Footer";
import { Header } from "./header/Header";
import BreadcrumbsKvh from "./header/navigation/breadcrumbs/BreacrumbsKvh";
import MaintenanceBanner from "./MaintenanceBanner";
import useFullWidth from "./useFullWidth";

export const Layout: React.FC = () => {
  const [disableBackdrop, setDisableBackdrop] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { isFullWidth, routeDoesNotMatchKnownRoutes } = useFullWidth();
  const auth = useSelector((state: AppState) => state.auth);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  useEffect(() => {
    if (
      currentUser &&
      auth.loggedIn &&
      !currentUser.birthday &&
      currentUser.user_type !== UserType.Employee &&
      currentUser.user_type !== UserType.Apprentice &&
      currentUser.user_type !== UserType.Speaker &&
      currentUser.user_type !== UserType.TestParticipant
    ) {
      setDisableBackdrop(true);
      const firstPartURL = window.location.href.split("k");
      dispatch(
        // works now, should be improved in future
        showDialog(
          {
            title: "Geburtsdatum nachtragen",
            message:
              "Bitte ergänzen Sie Ihr Geburtsdatum in ihrer Benutzerverwaltung, um weiterhin Fortbildungsmaßnahmen buchen zu können. ",
            action: () => {
              if (firstPartURL[0] === "https://veranstaltung.") {
                window.location.replace(
                  "https://www.kvhessen.de/mein-konto/benutzerdaten-aendern"
                );
              } else {
                window.location.replace(
                  "https://test.kvhessen.de/mein-konto/benutzerdaten-aendern"
                );
              }
              dispatch(logout());
            },
          },
          true,
          true
        )
      );
    }
  }, [currentUser, auth.loggedIn, dispatch]);

  return (
    <React.Fragment>
      <Box className="app-wrapper" data-testid="app-content">
        <Header />

        {location.pathname !== routes.home && !routeDoesNotMatchKnownRoutes && (
          <BreadcrumbsKvh />
        )}
        <MaintenanceBanner />
        {/* Set provider for datepicker utils to use the material datepickers in the main content of the app */}

        <ScrollToTopButton />
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={"de"}
        >
          <main className="main-content" data-testid="main-content" role="main">
            <Container
              maxWidth={isFullWidth ? "xl" : "lg"}
              style={isFullWidth ? { padding: 0 } : {}}
            >
              <Box py={!isFullWidth ? 3 : 0}>
                <CoreRoutes />
              </Box>
            </Container>
          </main>
        </MuiPickersUtilsProvider>
        <Footer />
        <AlertDialog disableBackdrop={disableBackdrop} />
      </Box>
    </React.Fragment>
  );
};
