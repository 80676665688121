import * as React from "react";
import "./Cart.scss";
import { Box, Tooltip } from "@material-ui/core";
import { CartData } from "../../../models/cartData";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSeedling,
  faSlash,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { CateringType } from "../../../models/enums/cateringType";
import { setCateringOption } from "../../../redux/cart/actions";

interface CateringOptionsProps {
  event: CartData;
}

const CateringOptions: React.FC<CateringOptionsProps> = (props) => {
  const dispatch = useDispatch();
  return (
    <>
      <Tooltip
        title={"Bitte wählen Sie ein Catering"}
        disableHoverListener
        arrow
        placement={"bottom"}
        open={props.event.catering_option ? false : true}
      >
        <Box component={"span"}>
          <Tooltip title={"Kein Catering gewünscht"} arrow placement={"top"}>
            <Box component={"span"} style={{ position: "relative" }}>
              <FontAwesomeIcon
                icon={faUtensils}
                size={"1x"}
                color={
                  props.event.catering_option === CateringType.NoMeal
                    ? "#3c3c3c"
                    : "#efefef"
                }
              />
              <FontAwesomeIcon
                icon={faSlash}
                style={{
                  position: "absolute",
                  left: "-3px",
                  top: "2px",
                  cursor: "pointer",
                }}
                color={
                  props.event.catering_option === CateringType.NoMeal
                    ? "#f44336"
                    : "#efefef"
                }
                onClick={() => {
                  dispatch(
                    setCateringOption(props.event.guid, CateringType.NoMeal)
                  );
                }}
              />
            </Box>
          </Tooltip>
          <Tooltip title={"Catering gewünscht"} arrow placement={"top"}>
            <Box component={"span"}>
              <FontAwesomeIcon
                icon={faUtensils}
                size={"1x"}
                style={{ marginLeft: "10px", cursor: "pointer" }}
                color={
                  props.event.catering_option === CateringType.DefaultMeal
                    ? "#3c3c3c"
                    : "#efefef"
                }
                onClick={() => {
                  dispatch(
                    setCateringOption(props.event.guid, CateringType.DefaultMeal)
                  );
                }}
              />
            </Box>
          </Tooltip>
          <Tooltip
            title={"Vegetarisches Catering gewünscht"}
            arrow
            placement={"top"}
          >
            <Box component={"span"}>
              <FontAwesomeIcon
                icon={faSeedling}
                size={"1x"}
                style={{ marginLeft: "10px", cursor: "pointer" }}
                color={
                  props.event.catering_option === CateringType.Vegetarian
                    ? "#95e917"
                    : "#efefef"
                }
                onClick={() => {
                  dispatch(
                    setCateringOption(props.event.guid, CateringType.Vegetarian)
                  );
                }}
              />
            </Box>
          </Tooltip>
        </Box>
      </Tooltip>
    </>
  );
};

export default CateringOptions;
