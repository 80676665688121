import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import moment from "moment";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  mapToTaskStatusString,
  TaskStatus,
} from "../../../models/enums/taskStatus.enum";
import { PAGE_SIZE } from "../../../models/pagination";
import { TaskFilterData, TasksFilterOptions } from "../../../models/taskData";
import { UserData } from "../../../models/userData";
import { AppState } from "../../../redux";
import { getTasks, setTasksPageCount } from "../../../redux/tasks/actions";
import CustomSearchField from "../../forms/CustomSearchField";
import CustomMultiSelect from "../../forms/selects/CustomMultiSelect";
import CustomSelect from "../../forms/selects/CustomSelect";
import { SearchEventField } from "./shared/SearchEventField";
import { NIL } from "uuid";

const statusOptions = [
  TaskStatus.Open,
  TaskStatus.InProgress,
  TaskStatus.Done,
].map((item) => {
  return {
    key: item.toString(),
    value: item.toString(),
    label: mapToTaskStatusString(item),
  };
});

export const taskFilterInitialValues: TaskFilterData = {
  search: "",
  event: "",
  status: [statusOptions[0].value, statusOptions[1].value],
  due_date: "",
  responsibility: "",
};

interface TaskFilterFormProps {}

const TaskFilterForm: React.FC<TaskFilterFormProps> = (props) => {
  const { setFieldValue, values } = useFormikContext<TaskFilterData>();
  // pagination and filters
  const page = useSelector((state: AppState) => state.tasks.currentPage);
  const [filterValues, setFilterValues] = useState<TasksFilterOptions>(
    taskFilterInitialValues
  );

  const tasks = useSelector((state: AppState) => state.tasks);
  const currentUser = useSelector((state: AppState) =>
    state.user.currentUser
      ? state.user.currentUser!
      : ({ guid: "" } as UserData)
  );

  const dispatch = useDispatch();

  const search = useCallback(
    (filters: TasksFilterOptions) => {
      if (
        !tasks.isLoading &&
        (filters !== filterValues ||
          page !== tasks.tasksPage.page ||
          !tasks.tasksPageLoaded)
      ) {
        if (filters !== filterValues && page === tasks.tasksPage.page) {
          dispatch(setTasksPageCount(1));
          dispatch(getTasks(1, filters)); // setPage does not change value of page instantly
        } else {
          let pageVal = page;

          // switch page if user deletes all tasks in the last page
          if (page > 1 && Math.ceil(tasks.tasksPage.count / PAGE_SIZE) < page) {
            pageVal = Math.max(1, Math.ceil(tasks.tasksPage.count / PAGE_SIZE));
            dispatch(setTasksPageCount(pageVal));
          }

          dispatch(getTasks(pageVal, filters));
        }
        setFilterValues(filters);
      }
    },
    [tasks, filterValues, page, dispatch]
  );
  useEffect(() => {
    search(filterValues);
  }, [tasks, page, dispatch, filterValues, search]);

  return (
    <>
      <Grid item xs={6}>
        <CustomSearchField
          customChangeHandler={(value: string) => {
            search({
              ...filterValues,
              search: value,
            });
          }}
          name="search"
          id="task-search"
          label="Suche"
        />
      </Grid>
      <Grid item xs={6}>
        <SearchEventField
          onChangeEventId={(id: string) => {
            search({
              ...filterValues,
              event: id !== "" ? id : undefined,
            });
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <CustomMultiSelect
          defaultText="Alle anzeigen"
          name="status"
          id="task-status-multiselect"
          label="Status"
          labelId="task-status-multiselect-label"
          defaultOptions={values.status || []}
          hasCustomChangeHandler={true}
          customChangeHandler={(changedMultiSelectValues) => {
            setFieldValue("status", changedMultiSelectValues);
            search({
              ...filterValues,
              status: changedMultiSelectValues as string[],
            });
          }}
          options={statusOptions}
        />
      </Grid>
      <Grid item xs={4}>
        <CustomSelect
          name="due_date"
          label="Fälligkeitsdatum"
          id="due-date-select"
          defaultText="Alle anzeigen"
          labelId="due-date-select-label"
          onChange={(
            event: React.ChangeEvent<{
              name?: string;
              value: any;
            }>
          ) => {
            setFieldValue("due_date", event.target.value);
            var due_date_from: string | undefined;
            var due_date_to: string | undefined;
            switch (event.target.value) {
              case "moreThanAMonthAgo":
                due_date_to = moment()
                  .subtract(1, "months")
                  .endOf("day")
                  .toISOString();
                break;
              case "moreThanTwoWeeksAgo":
                due_date_to = moment()
                  .subtract(2, "weeks")
                  .endOf("day")
                  .toISOString();
                break;
              case "moreThanOneWeekAgo":
                due_date_to = moment()
                  .subtract(1, "week")
                  .endOf("day")
                  .toISOString();
                break;
              case "past":
                due_date_to = moment().startOf("day").toISOString();
                break;
              case "today":
                due_date_from = moment().startOf("day").toISOString();
                due_date_to = moment().endOf("day").toISOString();
                break;
              case "future":
                due_date_from = moment().endOf("day").toISOString();
                break;
            }
            search({
              ...filterValues,
              due_date_to: due_date_to,
              due_date_from: due_date_from,
            });
          }}
          options={[
            {
              key: "moreThanAMonthAgo",
              value: "moreThanAMonthAgo",
              label: "vor mehr als einem Monat",
            },
            {
              key: "moreThanTwoWeeksAgo",
              value: "moreThanTwoWeeksAgo",
              label: "vor mehr als zwei Wochen",
            },
            {
              key: "moreThanOneWeekAgo",
              value: "moreThanOneWeekAgo",
              label: "vor mehr als einer Woche",
            },
            {
              key: "past",
              value: "past",
              label: "in der Vergangenheit",
            },
            {
              key: "today",
              value: "today",
              label: "heute",
            },
            {
              key: "future",
              value: "future",
              label: "in der Zukunft",
            },
          ]}
        />
      </Grid>
      <Grid item xs={4}>
        <CustomSelect
          name="responsibility"
          label="Zuständigkeit"
          id="responsibility-select"
          labelId="responsibility-select-label"
          defaultText="Alle anzeigen"
          onChange={(
            event: React.ChangeEvent<{
              name?: string;
              value: any;
            }>
          ) => {
            setFieldValue("responsibility", event.target.value);
            if (event.target.value === NIL) {
              search({
                ...filterValues,
                responsibility: undefined,
                has_responsibility: "false",
              });
            } else if (event.target.value !== "") {
              search({
                ...filterValues,
                responsibility: event.target.value,
                has_responsibility: undefined,
              });
            } else {
              search({
                ...filterValues,
                responsibility: undefined,
                has_responsibility: undefined,
              });
            }
          }}
          options={tasks.filterUsers
            .sort((a, b) => {
              // show current user first
              if (a.guid === currentUser.guid) return -1;
              else if (b.guid === currentUser.guid) return 1;
              return 0;
            })
            .map((user) => {
              if (user.guid === currentUser.guid) {
                return {
                  key: currentUser.guid,
                  value: currentUser.guid,
                  label: "Mir zugewiesen",
                };
              } else if (!user.guid) {
                return {
                  key: "not-assigned",
                  value: NIL, // empty uuid which should look something like this: 00000000-0000-0000-0000-000000000000
                  label: "Nicht zugewiesen",
                };
              } else {
                return {
                  key: user.guid.toString(),
                  value: user.guid.toString(),
                  label: user.fullname,
                };
              }
            })}
        />
      </Grid>
    </>
  );
};

export default TaskFilterForm;
