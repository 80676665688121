import { AlertActionTypes } from "./../alerts/types";
import { Dispatch } from "redux";
import { ErrorAction } from "../alerts/types";
import { linkAccountService } from "../../api";

import {
  CLEAR_LANR,
  VerifyLanrRequestAction,
  LINK_LANR_CODE_FAILURE,
  LINK_LANR_CODE_REQUEST,
  LINK_LANR_CODE_SUCCESS,
  LINK_LANR_REQUEST_SUCCESS,
  LinkAccountActionTypes,
  LinkLanrCodeFailureAction,
  LinkLanrCodeRequestAction,
  LinkLanrCodeSuccessAction,
  LinkLanrRequestSuccessAction,
  SET_LINK_ACCOUNT_LIST,
  SetLinkAccountListAction,
  VERIFY_LANR_FAILURE,
  VERIFY_LANR_REQUEST,
  VERIFY_LANR_SUCCESS,
  VerifyLanrClearAction,
  VerifyLanrFailureAction,
  VerifyLanrSuccessAction,
  DeleteFromLinkAccountListAction,
  DELETE_LINK_ACCOUNT_FROM_LIST,
  LINK_LANR_REQUEST,
  LinkLanrRequestAction,
  TryLinkAccountWithoutLoginAction,
  SET_TRY_LINK_ACCOUNT_WITHOUT_LOGIN,
  FETCH_LINKED_ACCOUNTS_FAILURE,
  FetchLinkedAccountsFailureAction,
  FetchLinkedAccountsRequestAction,
  FETCH_LINKED_ACCOUNTS_REQUEST,
} from "./types";
import { error, success } from "../alerts/actions";
import { LinkAccountData } from "../../models/linkAccountData";
import { AxiosError } from "axios";
import { ApiHelper } from "../../api/apiHelper";

export function getLinkedAccounts() {
  return (dispatch: Dispatch<LinkAccountActionTypes | ErrorAction>) => {
    dispatch(fetchLinkedAccountsRequest());
    linkAccountService
      .getLinkedAccounts()
      .then((response) => {
        dispatch(SetLinkAccountList(response));
      })
      .catch((response) => {
        dispatch(fetchLinkedAccountsFailure());
      });
  };
}

export function deleteLinkAccount(guid: string) {
  return (dispatch: Dispatch<LinkAccountActionTypes | ErrorAction>) => {
    dispatch(fetchLinkedAccountsRequest());
    linkAccountService
      .deleteLinkAccount(guid)
      .then((response) => {
        dispatch(DeleteFromAccountList(guid));
      })
      .catch(() => {
        dispatch(fetchLinkedAccountsFailure());
        dispatch(
          error(
            {
              message:
                "Beim Löschen des verknüpften Accounts ist ein Fehler aufgetreten!",
            },
            5000
          )
        );
      });
  };
}

export function verifyLanr(lanr: string) {
  return (dispatch: Dispatch<LinkAccountActionTypes | ErrorAction>) => {
    dispatch(verifyLanrRequest());
    linkAccountService
      .verifyLanr(lanr)
      .then((response) => {
        dispatch(verifyLanrSuccess());
      })
      .catch(() => {
        dispatch(verifyLanrFailure());
      });
  };
}

export function linkLanr(lanr: string) {
  return (dispatch: Dispatch<LinkAccountActionTypes | ErrorAction>) => {
    dispatch(linkLanrRequest());
    linkAccountService
      .linkLanr(lanr)
      .then((response) => {
        dispatch(linkLanrRequestSuccess(response));
      })
      .catch(() => {
        dispatch(verifyLanrFailure());
      });
  };
}

export function verifyLinkLanrRequestCode(code: string) {
  return (dispatch: Dispatch<LinkAccountActionTypes | AlertActionTypes>) => {
    dispatch(linkLanrRequestCodeRequest());
    linkAccountService
      .verifyLinkLanrRequestCode(code)
      .then((response) => {
        dispatch(linkLanrRequestCodeSuccess());
        dispatch(
          success(
            {
              message: "Die Verknüpfung des Benutzerkontos war erfolgreich.",
            },
            5000
          )
        );
      })
      .catch((axiosError: AxiosError) => {
        if (axiosError.response?.status === 400) {
          const errorMessage = ApiHelper.showErrorMessageFromApi(
            axiosError.response?.data
          );
          dispatch(linkLanrRequestCodeFailure(errorMessage));
          dispatch(
            error(
              {
                message:
                  "Die Verknüpfung des Benutzerkontos war nicht erfolgreich.",
              },
              5000
            )
          );
        } else {
          dispatch(linkLanrRequestCodeFailure());
        }
      });
  };
}

export function fetchLinkedAccountsRequest(): FetchLinkedAccountsRequestAction {
  return {
    type: FETCH_LINKED_ACCOUNTS_REQUEST,
  };
}

export function fetchLinkedAccountsFailure(): FetchLinkedAccountsFailureAction {
  return {
    type: FETCH_LINKED_ACCOUNTS_FAILURE,
  };
}

export function SetLinkAccountList(
  data: LinkAccountData[]
): SetLinkAccountListAction {
  return {
    type: SET_LINK_ACCOUNT_LIST,
    data,
  };
}

export function DeleteFromAccountList(
  guid: string
): DeleteFromLinkAccountListAction {
  return {
    type: DELETE_LINK_ACCOUNT_FROM_LIST,
    guid: guid,
  };
}

export function verifyLanrRequest(): VerifyLanrRequestAction {
  return {
    type: VERIFY_LANR_REQUEST,
  };
}

export function verifyLanrFailure(): VerifyLanrFailureAction {
  return {
    type: VERIFY_LANR_FAILURE,
  };
}

export function verifyLanrSuccess(): VerifyLanrSuccessAction {
  return {
    type: VERIFY_LANR_SUCCESS,
  };
}

export function clearVerifyLanr(): VerifyLanrClearAction {
  return {
    type: CLEAR_LANR,
  };
}

export function linkLanrRequest(): LinkLanrRequestAction {
  return {
    type: LINK_LANR_REQUEST,
  };
}

export function linkLanrRequestSuccess(
  data: LinkAccountData
): LinkLanrRequestSuccessAction {
  return {
    type: LINK_LANR_REQUEST_SUCCESS,
    data: data,
  };
}

export function linkLanrRequestCodeRequest(): LinkLanrCodeRequestAction {
  return {
    type: LINK_LANR_CODE_REQUEST,
  };
}

export function linkLanrRequestCodeSuccess(): LinkLanrCodeSuccessAction {
  return {
    type: LINK_LANR_CODE_SUCCESS,
  };
}

export function setTryLinkAccountWithoutLogin(): TryLinkAccountWithoutLoginAction {
  return {
    type: SET_TRY_LINK_ACCOUNT_WITHOUT_LOGIN,
  };
}

export function linkLanrRequestCodeFailure(
  errorMessage?: string
): LinkLanrCodeFailureAction {
  return {
    type: LINK_LANR_CODE_FAILURE,
    errorMessage: errorMessage,
  };
}
