import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { ELearningFormData } from "../../../../../models/elearning";
import { EventStatus } from "../../../../../models/enums/eventStatus.enum";
import { EventFormData } from "../../../../../models/eventData";
import { AppState } from "../../../../../redux";
import CustomMultiSelect from "../../../../forms/selects/CustomMultiSelect";
import { SelectData } from "../../../../forms/selects/selectData";
import SpecialFieldSearch from "../SpecialFieldSearch";

interface TargetGroupSectionProps {}

const TargetGroupSection: React.FC<TargetGroupSectionProps> = (props) => {
  const { values, setFieldValue } = useFormikContext<
    EventFormData | ELearningFormData
  >();
  const targetGroups = useSelector((state: AppState) => state.targetGroups);

  return (
    <>
      <Grid item xs={12}>
        <h3>Zielgruppenauswahl</h3>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomMultiSelect
          disabled={values.status === EventStatus.Completed}
          defaultOptions={values.participation_types}
          hasCustomChangeHandler={true}
          customChangeHandler={(values) => {
            setFieldValue("participation_types", values);
          }}
          required
          name="participation_types"
          id="targetGroup"
          label="Teilnahmetyp"
          labelId="participation-type-label"
          options={
            targetGroups.targetGroups.participation_types.map((x) => {
              const kvhMembers = x.only_kvh_members
                ? x.type + " (KVH-Mitglied)"
                : x.type;
              return {
                key: kvhMembers,
                value: x.id,
                label: kvhMembers,
              };
            }) as SelectData[]
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SpecialFieldSearch
          disabled={values.status === EventStatus.Completed}
          onChangeFields={(special_fields) => {
            setFieldValue("special_fields", [
              ...special_fields.map((x) => x?.id),
            ]);
          }}
          initialValues={targetGroups.targetGroups.special_fields.filter(
            (specialField) =>
              values.special_fields.findIndex((x) => specialField.id === x) !==
              -1
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomMultiSelect
          disabled={values.status === EventStatus.Completed}
          defaultOptions={values.coverage_areas}
          hasCustomChangeHandler={true}
          customChangeHandler={(values) => {
            setFieldValue("coverage_areas", values);
          }}
          name="coverage_areas"
          id="coverage-area"
          label="Versorgungsgebiet"
          labelId="coverage-area-label"
          options={
            targetGroups.targetGroups.coverage_areas.map((x) => {
              return {
                key: x.name,
                value: x.id,
                label: x.name,
              };
            }) as SelectData[]
          }
        />
      </Grid>
    </>
  );
};

export default TargetGroupSection;
