import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserType } from "../../../../../models/enums/userType.enum";
import { EventFormData } from "../../../../../models/eventData";
import { AppState } from "../../../../../redux";
import { getAllChecklistItems } from "../../../../../redux/checklist/actions";
import usePermission from "../../../../../services/usePermissions";
import CustomTextField from "../../../../forms/CustomTextField";
import routes from "../../../../routing/routes";
import ChecklistEmployee from "./ChecklistEmployee";
import ChecklistSpeaker from "./ChecklistSpeaker";
import EvaluationLink from "./EvaluationLink";
import { EventStatus } from "../../../../../models/enums/eventStatus.enum";
import { ELearningFormData } from "../../../../../models/elearning";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../../../models/enums/trainingType.enum";
import PinboardLink from "../../my-events/details/pinboard/PinboardLink";
import usePinboardActivated from "../../../../hooks/usePinboardActivated";

interface MiscellaneousProps {
  eventId: string;
}

export const Miscellaneous: React.FC<MiscellaneousProps> = (props) => {
  const dispatch = useDispatch();
  const { values } = useFormikContext<EventFormData | ELearningFormData>();

  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const { isStaff } = usePermission(currentUser?.user_type);
  const checklist = useSelector((state: AppState) => state.checklist);
  const editEvent = useSelector((state: AppState) => state.event.editEvent);

  const history = useHistory();
  const isCreateRoute =
    history.location.pathname === routes.event_create ||
    history.location.pathname === routes.elearning_create ||
    history.location.pathname === routes.blended_learning_create;

  useEffect(() => {
    if (
      currentUser?.user_type === UserType.Speaker &&
      !checklist.checklistLoaded
    ) {
      dispatch(getAllChecklistItems());
    }
  }, [dispatch, currentUser?.user_type, checklist.checklistLoaded]);

  const isPinboardActivated = usePinboardActivated(
    props.eventId,
    !isCreateRoute && editEvent.training_type !== TrainingType.DefaultEvent
  );

  return (
    <>
      {props.eventId !== "" && isPinboardActivated && (
        <PinboardLink eventId={props.eventId} positioning="start" />
      )}
      <Grid container spacing={4}>
        {!isCreateRoute && currentUser?.user_type !== UserType.Speaker && (
          <Grid item xs={12}>
            <EvaluationLink trainingType={editEvent.training_type} />
          </Grid>
        )}
        {[TrainingType.DefaultEvent, TrainingType.BlendedLearning].includes(
          editEvent.training_type
        ) &&
          currentUser &&
          currentUser.user_type === UserType.Speaker && <ChecklistSpeaker />}
        {isStaff() && (
          <>
            {!isCreateRoute &&
              editEvent.training_type !== TrainingType.ELearning && (
                <>
                  <ChecklistEmployee />
                </>
              )}
            <Grid item xs={12}>
              <h3>
                Kommentare{" "}
                {editEvent.training_type === TrainingType.DefaultEvent
                  ? "zur"
                  : "zum"}{" "}
                {mapToTrainingTypeString(editEvent.training_type)}
              </h3>
              <CustomTextField
                disabled={values.status === EventStatus.Completed}
                fullWidth
                name="commentary"
                id="commentary"
                multiline={true}
                rows={10}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
