import { TrainingType } from "../../models/enums/trainingType.enum";
import { SET_OPEN_TAB, SetOpenTabAction } from "./types";

export function setOpenTab(tab: number): SetOpenTabAction {
  return {
    type: SET_OPEN_TAB,
    tab,
  };
}

export function getParticipantsTabBasedOnTrainingType(
  trainingType: TrainingType
) {
  switch (trainingType) {
    case TrainingType.DefaultEvent:
      return 5;
    case TrainingType.ELearning:
      return 3;
    case TrainingType.BlendedLearning:
      return 5;
    default:
      return 0;
  }
}
