export enum TaskStatus {
  Undefined = 0,
  Open = 10,
  InProgress = 20,
  Done = 30,
}

export function mapToTaskStatusString(taskStatus?: TaskStatus): string {
  switch (taskStatus) {
    case TaskStatus.Undefined:
      return "";
    case TaskStatus.Open:
      return "Offen";
    case TaskStatus.InProgress:
      return "In Bearbeitung";
    case TaskStatus.Done:
      return "Abgeschlossen";
    default:
      return "";
  }
}
