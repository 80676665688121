import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux";

export const useGroupMapping = () => {
  const targetGroupsState = useSelector(
    (state: AppState) => state.targetGroups.targetGroups
  );
  /**
   * Maps a target group ID to a string representation including participation type.
   * @param {number} targetGroupNumber - The target group ID.
   * @param {TargetGroupsState} targetGroupsState - The targetGroups state.
   * @returns {string} The formatted string representation.
   */
  function mapTargetGroupIdToString(targetGroupNumber: number): string {
    const mappedParticipationType = targetGroupsState.participation_types.find(
      (x) => x.id === targetGroupNumber
    );
    const participationTypes = `${mappedParticipationType?.type || ""}${
      mappedParticipationType?.only_kvh_members ? " (KVH-Mitglied)" : ""
    }`;

    return participationTypes;
  }

  /**
   * Maps a special field ID to its name.
   * @param {number} targetGroupNumber - The target group ID.
   * @param {TargetGroupsState} targetGroupsState - The targetGroups state.
   * @returns {string} The special field name.
   */
  function mapSpecialFieldIdToString(targetGroupNumber: number): string {
    const mappedSpecialFields = targetGroupsState.special_fields.find(
      (x) => x.id === targetGroupNumber
    );
    const specialFields = mappedSpecialFields?.name || "";

    return specialFields;
  }

  /**
   * Maps a coverage area ID to its formatted name.
   * @param {number} targetGroupNumber - The target group ID.
   * @param {TargetGroupsState} targetGroupsState - The targetGroups state.
   * @returns {string} The formatted coverage area name.
   */
  function mapCoverageAreaIdToString(targetGroupNumber: number): string {
    const mappedCoverageAreas = targetGroupsState.coverage_areas.find(
      (x) => x.id === targetGroupNumber
    );
    const coverageAreas = mappedCoverageAreas?.name?.includes(" ")
      ? mappedCoverageAreas?.name.replace(" ", " und ")
      : mappedCoverageAreas?.name || "";

    return coverageAreas;
  }

  return {
    mapCoverageAreaIdToString,
    mapSpecialFieldIdToString,
    mapTargetGroupIdToString,
  };
};
