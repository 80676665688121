import {
  Breadcrumbs,
  Container,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import {
  KvhArrowSvg,
  KvhHomeSvg,
} from "../../../../../assets/icons/custom-svg-components/KvhSvgCollection";
import { MonoModeProps } from "../../../../../models/accessibility";
import { AppState } from "../../../../../redux";
import { Utils } from "../../../../../services/utils";
import routes from "../../../../routing/routes";
import { BaseNavData, getAllRoutes, NavigationData } from "../navigationData";
import { BreadcrumbsService } from "./breadcrumbsService";

const useStyles = makeStyles({
  root: {
    marginTop: "2px",
    backgroundColor: (props: MonoModeProps) =>
      props.monoMode ? "#fff" : "#ebebeb",
    borderBottom: (props: MonoModeProps) =>
      props.monoMode ? "1px solid #000" : "none",
    padding: "20px 0",
  },
  svgIcon: {
    "&:hover": {
      stroke: "#36a9e0",
    },
  },
});

const BreadcrumbsKvh: React.FC = () => {
  const location = useLocation();

  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  const [breadcrumbs, setBreadcrumbs] = useState<NavigationData[]>([]);

  // use useCallback hook to make sure the function isn't called on every render in useEffect hook
  const getBreadcrumbs = useCallback(() => {
    const navigationData = getAllRoutes(currentUser?.user_type);

    const breadcrumbsService = new BreadcrumbsService();
    const subPaths = breadcrumbsService.splitIntoSubpaths(location.pathname);

    const breadcrumbs = breadcrumbsService.getBreadCrumbs(
      subPaths,
      navigationData
    );

    return breadcrumbs;
  }, [location.pathname, currentUser]);

  // calc breadcrumbs everytime the location has changed
  React.useEffect(() => {
    setBreadcrumbs(getBreadcrumbs());
  }, [location.pathname, getBreadcrumbs]);

  const classes = useStyles({ monoMode: accessibility.monoMode });
  const [isHovered, setIsHovered] = useState(false); // set the hovered state of the home link to style the element manually

  return (
    <>
      <div className={classes.root}>
        <Container>
          <Breadcrumbs separator={<KvhArrowSvg />} aria-label="breadcrumb">
            <Link
              component={RouterLink}
              to={routes.events}
              onMouseEnter={() => {
                setIsHovered(true);
              }}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
            >
              <KvhHomeSvg
                fill={
                  isHovered ? (accessibility.monoMode ? "#777" : "#36a9e0") : ""
                }
              />
            </Link>
            {breadcrumbs.map((breadcrumbItem: BaseNavData, index: number) => {
              return breadcrumbs.length - 1 === index ? (
                <Typography color="textPrimary" key={index}>
                  {breadcrumbItem.text}
                </Typography>
              ) : (
                <Link
                  component={RouterLink}
                  to={
                    breadcrumbItem.route.includes(":id") &&
                    breadcrumbItem.route.lastIndexOf(":id") <
                      breadcrumbItem.route.length
                      ? Utils.getCurrentPathWithoutLastSubPath(
                          location.pathname
                        )
                      : breadcrumbItem.route
                  }
                  key={index}
                >
                  {breadcrumbItem.text}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Container>
      </div>
    </>
  );
};

export default BreadcrumbsKvh;
