import { TrainingType } from "../models/enums/trainingType.enum";
import { ELearningData, ELearningDto } from "./../models/elearning";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";

export class ELearningService extends BaseCrudService {
  async editELearning(data: ELearningData, id: string) {
    const response = await this.edit<ELearningData>(
      apiRoutes.elearning_root +
        (data.training_type === TrainingType.ELearning
          ? apiRoutes.elearnings
          : apiRoutes.blended_learnings),
      id.toString(),
      data
    );
    return response;
  }

  async getElearning(id: string) {
    const response = await this.get<ELearningDto>(
      apiRoutes.elearning_root + apiRoutes.elearnings,
      id
    );
    return response.data;
  }

  async getBlendedlearning(id: string) {
    const response = await this.get<ELearningDto>(
      apiRoutes.elearning_root + apiRoutes.blended_learnings,
      id
    );
    return response.data;
  }

  async addELearning(elearning: ELearningData) {
    const response = await this.create<ELearningData>(
      apiRoutes.elearning_root + apiRoutes.elearnings,
      elearning
    );
    return response;
  }

  async addBlendedLearning(blendedLearning: ELearningData) {
    const response = await this.create<ELearningData>(
      apiRoutes.elearning_root + apiRoutes.blended_learnings,
      blendedLearning
    );
    return response;
  }
}
