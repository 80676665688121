import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { ApiHelper } from "../../api/apiHelper";
import { documentsService } from "../../api/index";
import { DocumentData } from "../../models/documentData";
import { error } from "../alerts/actions";
import { ErrorAction } from "../alerts/types";
import {
  AddDefaultDocumentsListAction,
  AddDocumentsListAction,
  ADD_DEFAULT_DOCUMENTS_LIST,
  ADD_DOCUMENTS_LIST,
  ChangeDocumentsPublishStateListAction,
  CHANGE_DOCUMENT_PUBLISH_STATE,
  ClearDocumentsAction,
  CLEAR_DOCUMENTS,
  DocumentsActionTypes,
  DocumentsFailureAction,
  DocumentsRequestAction,
  DOCUMENTS_FAILURE,
  DOCUMENTS_REQUEST,
  MarkDocumentForDelete,
  MARK_DOCUMENT_FOR_DELETE,
  RemoveDocumentsListAction,
  REMOVE_DOCUMENTS_LIST,
  SetDocumentsListAction,
  SetDocumentsLoadedAction,
  SET_DOCUMENTS_LIST,
  SET_DOCUMENTS_LOADED,
} from "./types";

export function addDocument(documents: FormData[]) {
  return (dispatch: Dispatch<DocumentsActionTypes | ErrorAction>) => {
    dispatch(documentsRequest());
    documents.map((document) => {
      return documentsService
        .uploadDocument(document)
        .then((response) => {
          dispatch(addToDocumentsList(response.data));
        })
        .catch((err: AxiosError) => {
          dispatch(documentsFailure());
          if (err.response?.status === 400) {
            dispatch(
              error(
                {
                  message: ApiHelper.showErrorMessageFromApi(
                    err.response?.data
                  ),
                },
                5000
              )
            );
          } else {
            dispatch(
              error(
                {
                  message:
                    "Beim Hochladen des Dokuments ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!",
                },
                5000
              )
            );
          }
        });
    });
  };
}

export function deleteDocument(
  document: DocumentData,
  removeFromList: boolean = true
) {
  return (dispatch: Dispatch<DocumentsActionTypes | ErrorAction>) => {
    dispatch(documentsRequest());
    documentsService
      .deleteDocument(document)
      .then(() => {
        if (removeFromList) {
          dispatch(removeFromDocumentsList(document));
        }
      })
      .catch((err: AxiosError) => {
        dispatch(documentsFailure());
        dispatch(
          error(
            {
              message:
                "Beim Löschen des Dokuments ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!",
            },
            5000
          )
        );
      });
  };
}

export function bulkDeleteDocuments(guids: string[]) {
  return (dispatch: Dispatch<any>) => {
    dispatch(documentsRequest());
    documentsService
      .bulkDeleteDocument(guids)
      .then((tasks: DocumentData[]) => {
        dispatch(clearDocuments());
      })
      .catch((err: AxiosError) => {
        dispatch(documentsFailure());
      });
  };
}

export function editDocumentState(document: DocumentData, data: FormData) {
  return (dispatch: Dispatch<DocumentsActionTypes | ErrorAction>) => {
    dispatch(documentsRequest());
    //a default document must have at least one true default state!
    if (
      !document.is_default_on_site &&
      data.get("is_default_online") === "false"
    ) {
      document.is_default_on_site = true;
      data.append("is_default_on_site", "true");
    }

    if (
      !document.is_default_online &&
      data.get("is_default_on_site") === "false"
    ) {
      document.is_default_online = true;
      data.append("is_default_online", "true");
    }

    documentsService
      .editDocumentState(document, data)
      .then(() => {
        dispatch(changeDocumentState(document));
      })
      .catch((err: AxiosError) => {
        dispatch(documentsFailure());
        dispatch(
          error(
            {
              message:
                "Beim Ändern des Status der Veröffentlichung von " +
                document.title +
                " ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!",
            },
            5000
          )
        );
      });
  };
}

export function addDefaultDocuments(
  eventType: string = "",
  trainingType: string = ""
) {
  return (dispatch: Dispatch<DocumentsActionTypes | ErrorAction>) => {
    dispatch(documentsRequest());
    documentsService
      .getDefaultDocuments(eventType, trainingType)
      .then((response) => {
        dispatch(addDefaultDocumentsToDocumentsList(response, eventType));
      })
      .catch((err: AxiosError) => {
        dispatch(documentsFailure());
        dispatch(
          error(
            {
              message:
                "Beim hinzufügen der Standarddokumente ist ein Fehler aufgetreten.\nBitte versuchen Sie es erneut!",
            },
            5000
          )
        );
      });
  };
}

export function getDocument(guid: string) {
  return (dispatch: Dispatch<DocumentsActionTypes | ErrorAction>) => {
    dispatch(documentsRequest());
    documentsService
      .getDocument(guid)
      .then((response) => {
        dispatch(addToDocumentsList(response));
      })
      .catch((err: AxiosError) => {
        dispatch(documentsFailure());
        dispatch(
          error(
            {
              message: "Beim Laden der Dokumente ist ein Fehler aufgetreten!",
            },
            5000
          )
        );
      });
  };
}

export function getDocumentsByEvent(
  eventGuid: string,
  isTemplate: boolean,
  trainingType: string = ""
) {
  return (dispatch: Dispatch<DocumentsActionTypes | ErrorAction>) => {
    dispatch(documentsRequest());
    documentsService
      .getDocumentsByEvent(eventGuid, isTemplate, trainingType)
      .then((response) => {
        dispatch(setDocumentsList(response));
      })
      .catch((err: AxiosError) => {
        dispatch(documentsFailure());
        dispatch(
          error(
            {
              message: "Beim Laden der Dokumente ist ein Fehler aufgetreten!",
            },
            5000
          )
        );
      });
  };
}

export function getAllDefaultDocuments() {
  return (dispatch: Dispatch<DocumentsActionTypes | ErrorAction>) => {
    dispatch(documentsRequest());
    documentsService
      .getAllDefaultDocuments()
      .then((response) => {
        dispatch(setDocumentsList(response));
      })
      .catch((err: AxiosError) => {
        dispatch(documentsFailure());
      });
  };
}

export function documentsRequest(): DocumentsRequestAction {
  return {
    type: DOCUMENTS_REQUEST,
  };
}

export function documentsFailure(): DocumentsFailureAction {
  return {
    type: DOCUMENTS_FAILURE,
  };
}

export function addToDocumentsList(data: DocumentData): AddDocumentsListAction {
  return {
    type: ADD_DOCUMENTS_LIST,
    data,
  };
}

export function setDocumentsList(data: DocumentData[]): SetDocumentsListAction {
  return {
    type: SET_DOCUMENTS_LIST,
    data,
  };
}

export function addDefaultDocumentsToDocumentsList(
  data: DocumentData[],
  defaultType: string
): AddDefaultDocumentsListAction {
  return {
    type: ADD_DEFAULT_DOCUMENTS_LIST,
    data,
    defaultType,
  };
}

export function removeFromDocumentsList(
  data: DocumentData
): RemoveDocumentsListAction {
  return {
    type: REMOVE_DOCUMENTS_LIST,
    data,
  };
}

export function changeDocumentState(
  data: DocumentData
): ChangeDocumentsPublishStateListAction {
  return {
    type: CHANGE_DOCUMENT_PUBLISH_STATE,
    data,
  };
}

export function setDocumentsLoaded(): SetDocumentsLoadedAction {
  return {
    type: SET_DOCUMENTS_LOADED,
  };
}

export function clearDocuments(): ClearDocumentsAction {
  return {
    type: CLEAR_DOCUMENTS,
  };
}

export function markDocumentForDelete(
  document: DocumentData
): MarkDocumentForDelete {
  return {
    type: MARK_DOCUMENT_FOR_DELETE,
    document: document,
  };
}
