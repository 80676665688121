import React from "react";
import { EventListData } from "../../../../../models/eventData";
import EventItemRenderButton from "./EventItemRenderButton";

interface EventItemBookingProps {
  eventWorkload: number;
  eventData: EventListData;
}

export const EventItemBooking: React.FC<EventItemBookingProps> = (props) => {
  return (
    <>
      {props.eventData && (
        <EventItemRenderButton
          eventData={props.eventData}
          eventWorkload={props.eventWorkload}
        />
      )}
    </>
  );
};
