import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { TrainingType } from "../../models/enums/trainingType.enum";
import { UserType } from "../../models/enums/userType.enum";
import { AppState } from "../../redux";
import { ActivityHistory } from "../core/activityHistory/ActivityHistory";
import Cart from "../core/booking/Cart";
import Checkout from "../core/booking/checkout/Checkout";
import { WaitingListResponse } from "../core/booking/WaitingListResponse";
import ContactForm from "../core/contact/ContactForm";
import { CrudEvent } from "../core/events/create/CrudEvent";
import Dashboard from "../core/events/e-learning/Dashboard";
import LmsElearningView, {
  ScormPlayer,
} from "../core/events/e-learning/LmsElearningView";
import { LearningEventsOverview } from "../core/events/list/info/LearningEventsOverview";
import { ListEvents } from "../core/events/list/ListEvents";
import MyELearningDetails from "../core/events/my-events/details/MyELearningDetails";
import MyEventDetails from "../core/events/my-events/details/MyEventDetails";
import Pinboard from "../core/events/my-events/details/pinboard/Pinboard";
import MyEvents from "../core/events/my-events/MyEvents";
import MyLectures from "../core/events/my-lectures/MyLectures";
import MyLecturesDetails from "../core/events/my-lectures/MyLecturesDetails";
import Favorites from "../core/favorites/Favorites";
import LinkConfirmation from "../core/link-confirmation/LinkConfirmation";
import { Login } from "../core/login/Login";
import ProfileSettingsWrapper from "../core/profile-settings/ProfileSettingsWrapper";
import TaskRoutesWrapper from "../core/tasks/TaskRoutesWrapper";
import { CrudEventTemplate } from "../core/templates/event/CrudEventTemplate";
import { CrudTaskTemplate } from "../core/templates/task/CrudTaskTemplate";
import { Templates } from "../core/templates/Templates";
import { NoMatch } from "../errorPages/noMatch";
import AdminRedirect from "./AdminRedirect";
import AuthRoute from "./AuthRoute";
import PrivateRoute from "./PrivateRoute";
import routes from "./routes";
import Maintenance from "../errorPages/Maintenance";

export interface CoreRoute {
  route: string;
  componentToRender: React.ReactNode;
  exact?: boolean;
}

export const commonRoutes: CoreRoute[] = [
  {
    route: routes.settings,
    componentToRender: (
      <>
        <ProfileSettingsWrapper />
      </>
    ),
    exact: true,
  },
];

export const employeeRoutes: CoreRoute[] = [
  {
    route: routes.event_create,
    componentToRender: (
      <>
        <CrudEvent typeOfTrainingToBeCreated={TrainingType.DefaultEvent} />
      </>
    ),
    exact: true,
  },
  {
    route: routes.elearning_create,
    componentToRender: (
      <>
        <CrudEvent typeOfTrainingToBeCreated={TrainingType.ELearning} />
      </>
    ),
    exact: true,
  },
  {
    route: routes.blended_learning_create,
    componentToRender: (
      <>
        <CrudEvent typeOfTrainingToBeCreated={TrainingType.BlendedLearning} />
      </>
    ),
    exact: true,
  },
  {
    route: routes.event_edit_id,
    componentToRender: (
      <>
        <CrudEvent typeOfTrainingToBeCreated={TrainingType.DefaultEvent} />
      </>
    ),
    exact: true,
  },
  {
    route: routes.elearning_edit_id,
    componentToRender: (
      <>
        <CrudEvent typeOfTrainingToBeCreated={TrainingType.ELearning} />
      </>
    ),
    exact: true,
  },
  {
    route: routes.blended_learning_edit_id,
    componentToRender: (
      <>
        <CrudEvent typeOfTrainingToBeCreated={TrainingType.BlendedLearning} />
      </>
    ),
    exact: true,
  },
  {
    route: routes.tasks,
    componentToRender: (
      <>
        <TaskRoutesWrapper />
      </>
    ),
    exact: false,
  },
  {
    route: routes.templates,
    componentToRender: (
      <>
        <Templates />
      </>
    ),
    exact: true,
  },
  {
    route: routes.templates_new_event,
    componentToRender: (
      <>
        <CrudEventTemplate />
      </>
    ),
    exact: true,
  },
  {
    route: routes.templates_edit_event_id,
    componentToRender: (
      <>
        <CrudEventTemplate />
      </>
    ),
    exact: true,
  },
  {
    route: routes.templates_new_task,
    componentToRender: (
      <>
        <CrudTaskTemplate />
      </>
    ),
    exact: true,
  },
  {
    route: routes.templates_edit_task_id,
    componentToRender: (
      <>
        <CrudTaskTemplate />
      </>
    ),
    exact: true,
  },
  {
    route: routes.activityHistory,
    componentToRender: (
      <>
        <ActivityHistory />
      </>
    ),
    exact: true,
  },
  {
    route: routes.checkout,
    componentToRender: (
      <>
        <Checkout />
      </>
    ),
    exact: true,
  },
  {
    route: routes.pinboard_staff_elearning,
    componentToRender: (
      <>
        <Pinboard />
      </>
    ),
    exact: true,
  },
  {
    route: routes.pinboard_staff_blended_learning,
    componentToRender: (
      <>
        <Pinboard />
      </>
    ),
    exact: true,
  },
];

export const testParticipantRoutes: CoreRoute[] = [
  {
    route: routes.my_events,
    componentToRender: (
      <>
        <MyEvents />
      </>
    ),
    exact: true,
  },
  {
    route: routes.my_events_details_event_id,
    componentToRender: (
      <>
        <MyEventDetails />
      </>
    ),
    exact: true,
  },
  {
    route: routes.my_events_details_elearning_id,
    componentToRender: (
      <>
        <MyELearningDetails />
      </>
    ),
    exact: true,
  },
  {
    route: routes.pinboard_participant,
    componentToRender: (
      <>
        <Pinboard />
      </>
    ),
    exact: true,
  },
  {
    route: routes.elearning_dashboard,
    componentToRender: (
      <>
        <Dashboard />
      </>
    ),
    exact: true,
  },
  {
    route: routes.elearning_lms,
    componentToRender: (
      <>
        <LmsElearningView />
      </>
    ),
    exact: true,
  },
];

export const participantRoutes: CoreRoute[] = [
  ...testParticipantRoutes,
  {
    route: routes.events,
    componentToRender: (
      <>
        <ListEvents />
        <Cart />
      </>
    ),
    exact: true,
  },
  {
    route: routes.checkout,
    componentToRender: (
      <>
        <Checkout />
      </>
    ),
    exact: true,
  },
  {
    route: routes.waiting_list_response,
    componentToRender: (
      <>
        <WaitingListResponse />
      </>
    ),
    exact: true,
  },
  {
    route: routes.favorites,
    componentToRender: (
      <>
        <Favorites />
        <Cart />
      </>
    ),
    exact: true,
  },
  {
    route: routes.link_confirmation,
    componentToRender: (
      <>
        <LinkConfirmation />
      </>
    ),
    exact: true,
  },
];

export const speakerRoutes: CoreRoute[] = [
  {
    route: routes.my_lectures,
    componentToRender: (
      <>
        <MyLectures />
      </>
    ),
    exact: true,
  },
  {
    route: routes.my_lectures_details_event_id,
    componentToRender: (
      <>
        <MyLecturesDetails />
      </>
    ),
    exact: true,
  },
  {
    route: routes.my_lectures_details_elearning_id,
    componentToRender: (
      <>
        <MyELearningDetails />
      </>
    ),
    exact: true,
  },
  {
    route: routes.elearning_lms,
    componentToRender: (
      <>
        <LmsElearningView />
      </>
    ),
    exact: true,
  },
  {
    route: routes.pinboard_speaker,
    componentToRender: (
      <>
        <Pinboard />
      </>
    ),
    exact: true,
  },
];

export const adminRoute: CoreRoute[] = [
  {
    route: routes.admin,
    componentToRender: <AdminRedirect />,
  },
];

const CoreRoutes: React.FC = () => {
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const undefinedRoleRoutes = [
    ...commonRoutes,
    ...employeeRoutes,
    ...participantRoutes,
    ...speakerRoutes,
    ...adminRoute,
  ]; // use all routes if not logged in, to trigger the redirect of private routes

  function getRoutesByRole() {
    switch (currentUser?.user_type) {
      case UserType.Undefined:
        return undefinedRoleRoutes;
      case UserType.Admin:
        return [...adminRoute];
      case UserType.Speaker:
        return [...commonRoutes, ...speakerRoutes];
      case UserType.Participant:
        return [...commonRoutes, ...participantRoutes];
      case UserType.Employee:
      case UserType.Apprentice:
        return [...commonRoutes, ...employeeRoutes];
      case UserType.TestParticipant:
        return [...commonRoutes, ...testParticipantRoutes];
      default:
        return undefinedRoleRoutes;
    }
  }

  return (
    <Switch>
      <AuthRoute path={routes.login} exact>
        <Login />
      </AuthRoute>
      <Route
        exact
        path={routes.home}
        render={() => (
          <Redirect
            to={
              currentUser?.user_type === UserType.Admin
                ? routes.admin
                : routes.events
            }
          />
        )}
      />
      <Route
        path={routes.events}
        exact
        render={() =>
          currentUser?.user_type === UserType.Admin ? (
            <Redirect to={routes.admin} />
          ) : (
            <>
              <ListEvents />
              {currentUser &&
                [
                  UserType.Participant,
                  UserType.Employee,
                  UserType.Apprentice,
                ].includes(currentUser.user_type) && <Cart />}
            </>
          )
        }
      />
      <Route exact path={routes.booking}>
        <Redirect to={routes.checkout} />
      </Route>
      <Route exact path={routes.learning_events_overview}>
        <LearningEventsOverview />
      </Route>
      <Route exact path={routes.contact} component={ContactForm} />
      {/* <Route exact path={routes.testUI} component={TestUIComponent} /> */}
      {getRoutesByRole().map((item: CoreRoute) => (
        <PrivateRoute
          key={item.route}
          path={item.route}
          exact={item.exact}
          children={item.componentToRender}
        />
      ))}
      <Route exact path={routes.maintenance} component={Maintenance} />
      <Route exact path={routes.scorm_player_id}>
        <ScormPlayer />
      </Route>
      <Route component={NoMatch} />
    </Switch>
  );
};

export default CoreRoutes;
