import * as yup from "yup";
import { TaskTemplateData } from "../../../../models/taskData";

const taskTemplateValidationSchema = (event?: TaskTemplateData) =>
  yup.object({
    description: yup
      .string()
      .required("Die Aufgabenbeschreibung ist ein Pflichtfeld."),
    default_deadline_in_days: yup
      .number()
      .min(0, "Es sind keine negativen Tage zulässig."),
  });

export default taskTemplateValidationSchema;
