import * as yup from "yup";

const taskPreviewValidationSchema = () =>
  yup.object({
    description: yup
      .string()
      .required("Die Aufgabenbeschreibung ist ein Pflichtfeld."),
    due_date: yup
      .date()
      .typeError(
        "Dies ist kein gültiges Datum. Das Fälligkeitsdatum muss im Format DD.MM.JJJJ vorliegen."
      )
      .required("Die Angabe des Fälligkeitsdatum ist Pflicht!"),
  });

export default taskPreviewValidationSchema;
