import { LAEKHService } from "./laekhService";
import { AuthService } from "./auth/authService";
import { DocumentsService } from "./documentsService";
import { EventService } from "./event/eventService";
import { SpeakerService } from "./speakerService";
import { BookingService } from "./bookingService";
import { ParticipantsService } from "./participantsService";
import { LecturesService } from "./lecturesService";
import { CartService } from "./cartService";
import { UsersService } from "./usersService";
import { TasksService } from "./tasksService";
import { ContactService } from "./contactService";
import { TemplateService } from "./templateService";
import { FavoriteService } from "./favoriteService";
import { ChecklistService } from "./checklistService";
import { BillingAddressService } from "./billingAddressService";
import { LinkAccountService } from "./linkAccountService";
import { TargetGroupService } from "./targetGroupService";
import { ELearningService } from "./elearningService";
import { PinboardService } from "./pinboardService";
import { PinboardNoAlertService } from "./pinboardService";
import { DashboardService } from "./dashboardService";

// all instances of services
export const authService = new AuthService();
export const bookingService = new BookingService();
export const cartService = new CartService();
export const documentsService = new DocumentsService();
export const eventService = new EventService();
export const elearningService = new ELearningService();
export const lecturesService = new LecturesService();
export const participantsService = new ParticipantsService();
export const speakerService = new SpeakerService();
export const usersService = new UsersService();
export const tasksService = new TasksService();
export const contactService = new ContactService();
export const templateService = new TemplateService();
export const favoriteService = new FavoriteService();
export const checklistService = new ChecklistService();
export const billingAddressService = new BillingAddressService();
export const linkAccountService = new LinkAccountService();
export const targetGroupService = new TargetGroupService();
export const laekhService = new LAEKHService();
export const pinboardService = new PinboardService();
export const pinboardNoAlertService = new PinboardNoAlertService();
export const dashboardService = new DashboardService();
