import {
  WAITINGLIST_FAILURE,
  WAITINGLIST_REQUEST,
  WAITINGLIST_SUCCESS,
  WaitingListActionTypes,
  WaitingListState,
} from "./types";

export const initialDocumentsState: WaitingListState = {
  sendingRequest: false,
};

export function waitingListReducer(
  state = initialDocumentsState,
  action: WaitingListActionTypes
): WaitingListState {
  switch (action.type) {
    case WAITINGLIST_REQUEST:
      return {
        sendingRequest: true,
      };
    case WAITINGLIST_FAILURE:
      return {
        sendingRequest: false,
      };
    case WAITINGLIST_SUCCESS:
      return {
        sendingRequest: false,
      };
    default:
      return state;
  }
}

export default waitingListReducer;
