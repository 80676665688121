import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import deactivateEventService from "../../../assets/profile/deactivate_event_service.png";
import { mapToSexTypeString } from "../../../models/enums/sexType.enum";
import { AppState } from "../../../redux";
import { showDialog } from "../../../redux/dialogs/actions";
import { getTargetGroups } from "../../../redux/target-groups/actions";
import usePermission from "../../../services/usePermissions";
import CustomTextField from "../../forms/CustomTextField";
import {
  CHANGE_PASSWORD_URL,
  MY_ACCOUNT_URL,
} from "../../routing/externalUrls";
import CustomButton from "../../theming/CustomButton";
import TargetGroupList from "../events/list/eventItem/TargetGroupList";
import styles from "./../../../styles/custom-styles.module.scss";

interface ProfileContactProps {}

interface ProfileSettingsFormData {
  salutation: string;
  title: string;
  birthday: string;
  firstName: string;
  lastName: string;
  zipCode: string;
  city: string;
  streetAndNumber: string;
  country: string;
  phone: string;
  mobile: string;
  email: string;
  userId: string;
  lanr: string;
  bsnr: string;
  efn: string;
}

const ProfileContact: React.FC<ProfileContactProps> = (props) => {
  const userData = useSelector((state: AppState) => state.user.currentUser);
  const targetGroups = useSelector((state: AppState) => state.targetGroups);
  const dispatch = useDispatch();

  const { isStaff } = usePermission(userData?.user_type);

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    if (!targetGroups.isLoading && !targetGroups.targetGroupsLoaded) {
      dispatch(getTargetGroups());
    }
  }, [dispatch, targetGroups]);

  const initialData: ProfileSettingsFormData = {
    salutation: mapToSexTypeString(userData?.sex),
    title: "",
    birthday: "",
    firstName: "",
    lastName: "",
    zipCode: "",
    city: "",
    streetAndNumber: "",
    country: "",
    phone: "",
    mobile: "",
    email: "",
    userId: "",
    lanr: "",
    bsnr: "",
    efn: "",
  };

  return (
    <>
      <Formik
        onSubmit={() => {}}
        enableReinitialize
        initialValues={
          userData
            ? ({
                salutation: mapToSexTypeString(userData?.sex),
                title: userData?.academic_title || "",
                birthday: userData?.birthday,
                firstName: userData?.first_name,
                lastName: userData?.last_name,
                zipCode: userData?.address.zip_code,
                city: userData?.address.city,
                streetAndNumber: userData?.address.street_address,
                country: userData?.address.country,
                phone: userData?.phone,
                mobile: userData?.mobile,
                email: userData?.email,
                userId: userData?.username,
                lanr: userData?.lanr,
                bsnr: userData?.bsnr,
                efn: userData?.efn,
              } as ProfileSettingsFormData)
            : initialData
        }
      >
        {({ values }) => (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                Eine Übersicht Ihrer persönlichen Daten. Bitte beachten Sie,
                dass das Ändern Ihrer Daten aus technischen Gründen nicht über
                das Veranstaltungsportal erfolgen kann.
              </Grid>
              <Grid item xs={6} sm={3}>
                <CustomTextField
                  fullWidth
                  InputProps={{ readOnly: true }}
                  autoComplete="salutation"
                  name="salutation"
                  label="Anrede"
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <CustomTextField
                  fullWidth
                  InputProps={{ readOnly: true }}
                  autoComplete="title"
                  name="title"
                  label="Titel"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <CustomTextField
                  fullWidth
                  InputProps={{ readOnly: true }}
                  autoComplete="birthday"
                  name="birthday"
                  label="Geburtstag"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  fullWidth
                  InputProps={{ readOnly: true }}
                  autoComplete="User-ID"
                  name="userId"
                  label="User-ID"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  fullWidth
                  InputProps={{ readOnly: true }}
                  autoComplete="firstName"
                  name="firstName"
                  label="Vorname"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  fullWidth
                  InputProps={{ readOnly: true }}
                  autoComplete="lastName"
                  name="lastName"
                  label="Nachname"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomTextField
                  fullWidth
                  InputProps={{ readOnly: true }}
                  autoComplete="streetAndNumber"
                  name="streetAndNumber"
                  label="Straße und Hausnummer"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <CustomTextField
                  fullWidth
                  InputProps={{ readOnly: true }}
                  autoComplete="zipCode"
                  name="zipCode"
                  label="PLZ"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  fullWidth
                  InputProps={{ readOnly: true }}
                  autoComplete="city"
                  name="city"
                  label="Ort"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  fullWidth
                  InputProps={{ readOnly: true }}
                  autoComplete="country"
                  name="country"
                  label="Land"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CustomTextField
                  fullWidth
                  InputProps={{ readOnly: true }}
                  autoComplete="phone"
                  name="phone"
                  label="Telefon"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CustomTextField
                  fullWidth
                  InputProps={{ readOnly: true }}
                  autoComplete="mobile"
                  name="mobile"
                  label="Mobiltelefon"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  fullWidth
                  InputProps={{ readOnly: true }}
                  autoComplete="email"
                  name="email"
                  label="E-Mail"
                />
              </Grid>
              {/* Fake LANRs should not be displayed */}
              {userData?.lanr &&
                (userData.lanr.length === 7 ||
                  (userData.lanr.length === 9 &&
                    !userData.lanr.includes("FL"))) && (
                  <Grid item xs={12} sm={6} md={3}>
                    <CustomTextField
                      fullWidth
                      InputProps={{ readOnly: true }}
                      autoComplete="LANR"
                      name="lanr"
                      label="LANR"
                    />
                  </Grid>
                )}
              {userData?.bsnr && (
                <Grid item xs={12} sm={6} md={3}>
                  <CustomTextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    autoComplete="BSNR"
                    name="bsnr"
                    label="BSNR"
                  />
                </Grid>
              )}
              {userData?.efn && (
                <Grid item xs={12} sm={6} md={3}>
                  <CustomTextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    autoComplete="EFN"
                    name="efn"
                    label="EFN"
                  />
                </Grid>
              )}
              {!isStaff() && (
                <Grid item xs={12} md={9}>
                  <Card variant="outlined" style={{ marginBottom: "2em" }}>
                    <CardContent>
                      <h3>Ihre Zielgruppen</h3>
                      <TargetGroupList
                        targetGroups={{
                          coverage_areas: !userData?.coverage_areas
                            ? []
                            : userData?.coverage_areas,
                          participation_types: userData?.participation_types
                            ? userData?.participation_types
                            : [],
                          special_fields: !userData?.special_fields
                            ? []
                            : userData?.special_fields,
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          Zum Ändern Ihrer Nutzerdaten werden Sie zu Ihrem KVH-Benutzerkonto
          zurückgeleitet:
          <br />
          <br />
          <Button
            onClick={() => {
              window.open(MY_ACCOUNT_URL, "_blank", "noopener,noreferrer");
            }}
            variant="contained"
            color="primary"
            style={{ marginRight: "20px" }}
          >
            Daten ändern
          </Button>
          <Button
            onClick={() => {
              window.open(CHANGE_PASSWORD_URL, "_blank", "noopener,noreferrer");
            }}
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginRight: "20px" }}
          >
            Passwort ändern
          </Button>
          <CustomButton
            style={{ marginTop: xs ? "1em" : 0 }}
            onClick={() => {
              dispatch(
                showDialog(
                  {
                    title: "Online-Dienst deaktivieren",
                    message: (
                      <>
                        <Typography>
                          Der Online-Dienst "Veranstaltungsportal" kann unter
                          "Konto verwalten" in Ihrem KVH-Konto deaktiviert
                          werden, indem das Häkchen entfernt wird:
                        </Typography>
                        <Card
                          variant="outlined"
                          style={{ margin: "1em 0 1em 0" }}
                        >
                          <CardContent
                            style={{
                              backgroundColor: styles["secondary-accent-1"],
                            }}
                          >
                            <img
                              src={deactivateEventService}
                              alt="deactivate event service"
                              style={{ objectFit: "fill", width: "stretch" }}
                            />
                          </CardContent>
                        </Card>
                        <Typography>
                          Indem Sie den Online-Dienst deaktivieren, wird auch
                          der Zugang zum Veranstaltungsportal ab sofort nicht
                          mehr verfügbar sein. Möchten Sie den Zugang
                          reaktivieren, können Sie dies innerhalb der
                          gesetzlichen Fristen zur Datenaufbewahrung ebenfalls
                          über ein erneutes Setzen des Häkchens erreichen.
                        </Typography>
                      </>
                    ),
                    action: () => {
                      window.open(
                        MY_ACCOUNT_URL,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    },
                    confirmButtonText: "Online-Dienst deaktivieren",
                  },
                  false,
                  false,
                  "md",
                  "delete"
                )
              );
            }}
            customColor={styles.red}
            textColor={"#fff"}
            hoverColor={styles["red-dark"]}
          >
            Online-Dienst deaktivieren
          </CustomButton>
        </Grid>
        <Grid item xs={12} sm={3}></Grid>
      </Grid>
    </>
  );
};

export default ProfileContact;
