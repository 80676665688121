import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Utils } from "../../services/utils";

const useValidId = (route: string, guid: string) => {
  const location = useLocation();
  const [isValidId, setIsValidId] = useState<boolean>(false);

  const checkValidId = () => {
    const validPath = !!matchPath(location.pathname, {
      path: route,
      exact: true,
    });

    setIsValidId(validPath && Utils.isValidGUIDv4(guid));
  };

  useEffect(checkValidId, [guid, location.pathname, route]);

  return {
    isValidId,
  };
};

export default useValidId;
