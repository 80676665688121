import { Box, Button, Link } from "@material-ui/core";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import {
  KvhFontSizeAccessibilitySvg,
  KvhHighContrastSvg,
} from "../../../assets/icons/custom-svg-components/KvhSvgCollection";
import { AppState } from "../../../redux";
import {
  ChangeFontSizeMode,
  toggleMonoMode,
} from "../../../redux/accessibility/actions";
import { contactClear } from "../../../redux/contact/actions";
import routes from "../../routing/routes";
import "./Accessibility.scss";
import "./TopNavAndAccessibility.scss";

interface TopNavAndAccessibilityProps {}

const TopNavAndAccessibility: React.FC<TopNavAndAccessibilityProps> = (
  props
) => {
  const dispatch = useDispatch();
  const accessibility = useSelector((state: AppState) => state.accessibility);

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Box
          className={
            accessibility.monoMode ? "nav-item" : "nav-item nav-item-default"
          }
        >
          <Link
            component={RouterLink}
            to={routes.contact}
            onClick={() => {
              dispatch(contactClear());
            }}
          >
            Kontakt
          </Link>
        </Box>
        <Box>
          <Button
            className={
              accessibility.monoMode
                ? "accessability-btn-mono"
                : "accessability-btn"
            }
            variant={accessibility.monoMode ? "contained" : undefined}
            color={"secondary"}
            onClick={() => {
              dispatch(ChangeFontSizeMode());
            }}
            style={{
              backgroundColor: !accessibility.monoMode
                ? "transparent"
                : undefined,
              border: "none",
            }}
          >
            <KvhFontSizeAccessibilitySvg />
          </Button>
        </Box>
        <Box>
          <Button
            className={
              accessibility.monoMode
                ? "accessability-btn-mono"
                : "accessability-btn"
            }
            variant={accessibility.monoMode ? "contained" : undefined}
            color={"secondary"}
            onClick={() => {
              dispatch(toggleMonoMode());
            }}
            style={{
              backgroundColor: !accessibility.monoMode
                ? "transparent"
                : undefined,
              border: "none",
            }}
          >
            <KvhHighContrastSvg />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TopNavAndAccessibility;
