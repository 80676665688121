import {
  BILLING_ADDRESS_CLEAR,
  BILLING_ADDRESS_FAILURE,
  BILLING_ADDRESS_REQUEST,
  SET_BILLING_ADDRESS,
  BillingAddressActionTypes,
  BillingAddressState,
  RELOAD_BILLING_ADDRESS,
  SET_BILLING_ADDRESS_FOR_LINKED_USER,
} from "./types";

export const initialBillingAddressState: BillingAddressState = {
  billingAddressList: [],
  linkedUser: "",
  billingAddressOfLinkedUser: {
    address: {
      zip_code: "",
      city: "",
      street_address: "",
      country: "",
    },
    additional_billing_address: {
      medical_office_or_company: "",
      medical_office_or_company_extra: "",
      first_name: "",
      last_name: "",
      address: "",
      street_address: "",
      zip_code: "",
      city: "",
      phone: "",
      guid: "",
      email: "",
    },
  },
  billingAddressLoaded: false,
  isSending: false,
};

export function billingAddressReducer(
  state = initialBillingAddressState,
  action: BillingAddressActionTypes
): BillingAddressState {
  switch (action.type) {
    case BILLING_ADDRESS_REQUEST:
      return {
        ...state,
        isSending: true,
      };
    case BILLING_ADDRESS_FAILURE:
      return {
        ...state,
        isSending: false,
      };
    case SET_BILLING_ADDRESS:
      return {
        ...state,
        billingAddressList: action.data,
        billingAddressLoaded: true,
        isSending: false,
      };
    case SET_BILLING_ADDRESS_FOR_LINKED_USER:
      return {
        ...state,
        billingAddressOfLinkedUser: action.data,
        linkedUser: action.linkedUser,
        billingAddressLoaded: true,
        isSending: false,
      };
    case RELOAD_BILLING_ADDRESS:
      return {
        ...state,
        billingAddressLoaded: false,
        isSending: false,
      };
    case BILLING_ADDRESS_CLEAR:
      return initialBillingAddressState;
    default:
      return state;
  }
}

export default billingAddressReducer;
