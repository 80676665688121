import * as yup from "yup";

const combineValidationSchema = yup.object({
  lanrSearch: yup
    .string()
    .matches(
      new RegExp("^(\\d{7}|(?!FL)\\d{9})$"),
      "Es muss sich um eine gültige LANR handeln."
    ),
});

export default combineValidationSchema;
