import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux";
import "./AlertManager.scss";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AlertManger: React.FC = () => {
  const alerts = useSelector((state: AppState) => state.alerts);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (alerts.show) {
      setOpen(alerts.show);
    }
  }, [alerts]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={alerts.delay}
          onClose={handleClose}
          className={"alert-box-container"}
        >
          <Alert onClose={handleClose} severity={alerts.alert.type}>
            {alerts.alert.message}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default AlertManger;
