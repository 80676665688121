import {
  AccessibilityActionTypes,
  AccessibilityState,
  COLOR_MODE,
  FONT_SIZE_MODE,
} from "./types";

export const initialAccessibilityState: AccessibilityState = {
  fontSizeMode: "default",
  monoMode: false,
};

const changeFontSize = (fontSizeMode: "default" | "big" | "bigger") => {
  if (fontSizeMode === "default") {
    return "big";
  } else if (fontSizeMode === "big") {
    return "bigger";
  } else {
    return "default";
  }
};

const toggleMonoMode = (monoMode: boolean) => {
  return !monoMode;
};

export function getInitialAccessibilityState() {
  let accessibilitySettings = localStorage.getItem("accessibility_settings");

  if (accessibilitySettings) {
    const settingsFromLocalStorage = JSON.parse(
      accessibilitySettings
    ) as AccessibilityState;

    return settingsFromLocalStorage;
  }
  return initialAccessibilityState;
}

export function accessibilityReducer(
  state = getInitialAccessibilityState(),
  action: AccessibilityActionTypes
): AccessibilityState {
  switch (action.type) {
    case FONT_SIZE_MODE:
      const updatedState: AccessibilityState = {
        ...state,
        fontSizeMode: changeFontSize(state.fontSizeMode),
      };
      localStorage.setItem(
        "accessibility_settings",
        JSON.stringify(updatedState)
      );

      return updatedState;
    case COLOR_MODE:
      const updatedContrastState: AccessibilityState = {
        ...state,
        monoMode: toggleMonoMode(state.monoMode),
      };

      localStorage.setItem(
        "accessibility_settings",
        JSON.stringify(updatedContrastState)
      );

      return updatedContrastState;
    default:
      return state;
  }
}

export default accessibilityReducer;
