export enum SexType {
  Undefined = 0,
  Male = 10,
  Female = 20,
}

export function mapToSexTypeString(sexType?: SexType): string {
  switch (sexType) {
    case SexType.Undefined:
      return "";
    case SexType.Male:
      return "Herr";
    case SexType.Female:
      return "Frau";
    default:
      return "";
  }
}
