import * as React from "react";
import { ParticipantListData } from "../../../../../models/participantData";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import logo from "../../../../../assets/general/logo_kvh_print.jpg";
import { EventDataDto } from "../../../../../models/eventData";
import "./ParticipantsListPrint.scss";
import { LecturesData } from "../../../../../models/lecturesData";
import { EventType } from "../../../../../models/enums/eventType.enum";
import { CateringType } from "../../../../../models/enums/cateringType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSeedling,
  faSlash,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { UserType } from "../../../../../models/enums/userType.enum";
import { UserData } from "../../../../../models/userData";

type props = {
  participantList: ParticipantListData[];
  eventData: EventDataDto | LecturesData;
  currentUser?: UserData;
};
const maxRowsPerFirstPage = 12;
const maxRowsPerPage = 14;

export class ParticipantsListPrint extends React.PureComponent<props> {
  /**
   * Generates an empty TableRow to be used in printing, with a specified index.
   *
   * @param {number} index - The index of the empty row.
   * @returns {JSX.Element} - The generated empty TableRow.
   */
  emtpyRow = (index: number) => {
    return (
      <TableRow key={`empty-${index}`}>
        <TableCell
          colSpan={5}
          style={{ borderColor: "transparent" }}
        ></TableCell>
      </TableRow>
    );
  };
  /**
   * Function to add an empty row if needed based on the current page.
   * @param {number} index - The index of the current row.
   */
  addEmptyRowIfNeeded = (index: number) => {
    // Check if it's the first page and index is maxRowsPerFirstPage -1
    // (to break after maxRowsPerFirstPage)
    if (index === maxRowsPerFirstPage - 1) {
      return this.emtpyRow(index);
    }
    // Check for remaining pages
    if (index > maxRowsPerFirstPage - 1) {
      // Calculate the position within the current cycle of maxRowsPerPage
      const positionInCycle = (index - maxRowsPerFirstPage) % maxRowsPerPage;

      // Add an empty row after every maxRowsPerFirstPage + 1 row
      if (positionInCycle === maxRowsPerFirstPage + 1) {
        return this.emtpyRow(index);
      }
    }
    return null;
  };

  /**
   * Function to calculate the number of extra rows needed to fill the current page.
   * @returns {number} - The number of extra rows needed.
   */
  setExtraRows() {
    let itemsNeeded = 0;
    const rowsWithData = this.props.participantList.length;

    // Check if there are less rows than maxRowsPerFirstPage on the first page
    if (rowsWithData < maxRowsPerFirstPage) {
      itemsNeeded = maxRowsPerFirstPage - rowsWithData;
    }
    // Check if there are more rows than maxRowsPerFirstPage
    if (rowsWithData > maxRowsPerFirstPage) {
      // Subtract maxRowsPerFirstPage to account for the first page
      const remainingRows = rowsWithData - maxRowsPerFirstPage;
      // Calculate the extra rows beyond the maxRowsPerPage  limit
      itemsNeeded = maxRowsPerPage - (remainingRows % maxRowsPerPage);
    }
    return itemsNeeded;
  }

  maskEmail(email: string) {
    if ([undefined, null, ""].includes(email)) return "";
    let split = email.split("@");
    return (
      email.substr(0, 1) +
      new Array(split[0].length - 1).fill("*").join("") +
      "@" +
      split[1]
    );
  }

  public render() {
    return (
      <Grid container style={{ width: "790px" }}>
        <Grid item style={{ width: "150px" }}>
          <img src={logo} alt="kvh logo" style={{ height: "100px" }} />
        </Grid>
        <Grid item style={{ width: "560px" }}>
          <Grid container spacing={0}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <div className={"participantlist-print-title"}>
                {this.props.eventData.title}
              </div>
            </Grid>
            <Grid item xs={1} className={"participantlist-print-lable"}>
              VNR
            </Grid>
            <Grid item xs={5} className={"participantlist-print-value"}>
              {this.props.eventData.vnr}
            </Grid>
            <Grid item xs={1} className={"participantlist-print-lable"}>
              Ort
            </Grid>
            <Grid item xs={5} className={"participantlist-print-value"}>
              {this.props.eventData.location}
            </Grid>
            <Grid item xs={1} className={"participantlist-print-lable"}>
              Von
            </Grid>
            <Grid item xs={5} className={"participantlist-print-value"}>
              {this.props.eventData.begin &&
                new Date(this.props.eventData.begin).toLocaleDateString()}{" "}
              (
              {this.props.eventData.begin &&
                new Date(this.props.eventData.begin).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
              Uhr)
            </Grid>
            <Grid item xs={1} className={"participantlist-print-lable"}>
              Bis
            </Grid>
            <Grid item xs={5} className={"participantlist-print-value"}>
              {this.props.eventData.end &&
                new Date(this.props.eventData.end).toLocaleDateString()}{" "}
              (
              {this.props.eventData.end &&
                new Date(this.props.eventData.end).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
              Uhr)
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <h3>Teilnehmerliste</h3>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  className={"participantlist-print-tablecell"}
                  style={{ border: "1px solid black" }}
                >
                  <TableCell className={"custom-tablecell"}>Name</TableCell>
                  <TableCell className={"custom-tablecell"}>E-Mail</TableCell>
                  <TableCell className={"custom-tablecell"}>EFN</TableCell>
                  <TableCell className={"custom-tablecell"}>BSNR</TableCell>
                  {this.props.eventData.event_type === EventType.OnSite && (
                    <TableCell className={"custom-tablecell"}>
                      Unterschrift
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.participantList
                  .sort((firstUser, secondUser) =>
                    firstUser.user.last_name > secondUser.user.last_name
                      ? 1
                      : firstUser.user.last_name === secondUser.user.last_name
                      ? firstUser.user.first_name > secondUser.user.first_name
                        ? 1
                        : -1
                      : -1
                  )
                  .map((participant, index) => {
                    return (
                      <>
                        <TableRow
                          className={"participantlist-print-tablecell"}
                          key={index}
                          style={{
                            verticalAlign: "top",
                            border: "1px solid black",
                          }}
                        >
                          <TableCell className={"custom-tablecell"}>
                            {participant.catering_option !==
                              CateringType.Undefined &&
                              this.props.currentUser?.user_type !==
                                UserType.Speaker &&
                              (participant.catering_option ===
                              CateringType.NoMeal ? (
                                <Box
                                  component={"span"}
                                  style={{ position: "relative" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faUtensils}
                                    size={"1x"}
                                    color={"#3c3c3c"}
                                  />
                                  <FontAwesomeIcon
                                    icon={faSlash}
                                    style={{
                                      position: "absolute",
                                      left: "-2px",
                                      top: "2px",
                                    }}
                                    color={"#f44336"}
                                    size={"1x"}
                                  />
                                </Box>
                              ) : participant.catering_option ===
                                CateringType.DefaultMeal ? (
                                <FontAwesomeIcon
                                  icon={faUtensils}
                                  size={"1x"}
                                  color={"#3c3c3c"}
                                />
                              ) : (
                                participant.catering_option ===
                                  CateringType.Vegetarian && (
                                  <FontAwesomeIcon
                                    icon={faSeedling}
                                    size={"1x"}
                                    color={"#95e917"}
                                  />
                                )
                              ))}
                            {`${participant.user.last_name}, ${participant.user.first_name}`}
                            {participant.user.academic_title
                              ? `, ${participant.user.academic_title}`
                              : ""}
                          </TableCell>
                          <TableCell className={"custom-tablecell"}>
                            {this.maskEmail(participant.user.email)}
                          </TableCell>
                          <TableCell
                            className={"custom-tablecell"}
                            style={{ width: "80mm", height: "20mm" }}
                          >
                            {participant.user.efn}
                          </TableCell>
                          <TableCell className={"custom-tablecell"}>
                            {participant.user.bsnr}
                          </TableCell>
                          {this.props.eventData.event_type ===
                            EventType.OnSite && (
                            <TableCell
                              className={"custom-tablecell"}
                            ></TableCell>
                          )}
                        </TableRow>
                        {/* add empty rows to break the page, if needed  */}
                        {this.addEmptyRowIfNeeded(index)}
                      </>
                    );
                  })}
                {/* add extra rows to fill up the rest of thepage, if needed  */}
                {Array(this.setExtraRows())
                  .fill(0)
                  .map((val, index) => {
                    return (
                      <TableRow
                        className={"participantlist-print-tablecell"}
                        key={index}
                        style={{ border: "1px solid black" }}
                      >
                        <TableCell className={"custom-tablecell"}></TableCell>
                        <TableCell className={"custom-tablecell"}></TableCell>
                        <TableCell
                          className={"custom-tablecell"}
                          style={{ width: "80mm", height: "20mm" }}
                        ></TableCell>
                        <TableCell className={"custom-tablecell"}></TableCell>
                        {this.props.eventData.event_type ===
                          EventType.OnSite && (
                          <TableCell className={"custom-tablecell"}></TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
}
