import {
  CLEAR_PREVIEW,
  SET_PREVIEW,
  SetPreviewEventAction,
  ClearPreviewAction,
} from "./types";

import { EventFormData } from "../../models/eventData";

export function setPreview(data: EventFormData): SetPreviewEventAction {
  return {
    type: SET_PREVIEW,
    data,
  };
}

export function clearPreview(): ClearPreviewAction {
  return {
    type: CLEAR_PREVIEW,
  };
}
