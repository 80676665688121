import { Box, Link } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { MyELearningListData } from "../../../../models/elearning";
import { AppState } from "../../../../redux";
import routes from "../../../routing/routes";

interface DocumentsCounterProps {
  booking: MyELearningListData;
  count: number;
  unreadCondition?: boolean;
  isExternalLink?: boolean;
  externalUrl?: string;
}

const DashboardCounter: React.FC<DocumentsCounterProps> = ({
  isExternalLink = false,
  unreadCondition = true,
  ...props
}) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  // TODO: if there is time for refactoring the UI --> locally update the state of the documents count,
  // TODO: so the changes are displayed immediately and it doesn't have to wait for 15 seconds until the dashboard state is fetched
  return (
    <Box
      style={{ marginLeft: "auto", marginRight: "8px" }}
      display="flex"
      justifyContent="center"
      className={
        props.count > 0 && unreadCondition && !accessibility.monoMode
          ? "dashboard-card-counter-blue"
          : undefined
      }
    >
      {props.booking.guid !== "" &&
        (isExternalLink ? (
          <Link href={props.externalUrl} target={"_blank"}>
            {props.children}
            <span>{props.count}</span>
          </Link>
        ) : (
          <Link
            component={RouterLink}
            to={`${routes.my_events_details_elearning}/${props.booking.guid}`}
          >
            {props.children}
            <span>{props.count}</span>
          </Link>
        ))}
    </Box>
  );
};

export default DashboardCounter;
