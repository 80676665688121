import { faStar as faStarOutline } from "@fortawesome/free-regular-svg-icons/faStar";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Box, Tooltip } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AppState } from "../../../../redux";
import {
  getFavoritesCount,
  reloadFavoriteList,
} from "../../../../redux/favorites/actions";
import routes from "../../../routing/routes";
import "./Profile.scss";

export const FavoriteStar: React.FC = () => {
  const favorite = useSelector((state: AppState) => state.favorite);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!favorite.isLoading && !favorite.numberofFavoritesLoaded) {
      dispatch(getFavoritesCount());
    }
  }, [dispatch, favorite.numberofFavoritesLoaded, favorite.isLoading]);

  return (
    <Box mb={1}>
      {favorite.nubmerOfFavorites === 0 ? (
        <Tooltip
          color={"primary"}
          title={favorite.isLoading ? "" : "Sie haben keine Favoriten."}
          arrow
        >
          <Box component={"span"}>
            <FontAwesomeIcon
              icon={accessibility.monoMode ? faStarOutline : faStar}
              size={"2x"}
              color={accessibility.monoMode ? "#000" : "#efefef"}
              style={{ marginRight: "5px" }}
            />
          </Box>
        </Tooltip>
      ) : (
        <Badge
          badgeContent={favorite.nubmerOfFavorites}
          color="primary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          overlap="circle"
        >
          <FontAwesomeIcon
            icon={faStar}
            size={"2x"}
            color={accessibility.monoMode ? "#000" : "#36a9e0"}
            style={{ marginRight: "5px", cursor: "pointer" }}
            onClick={() => {
              dispatch(reloadFavoriteList());
              history.push(routes.favorites);
            }}
          />
        </Badge>
      )}
    </Box>
  );
};
