import { TargetGroupListData } from "../../models/targetGroupData";

export interface TargetGroupState {
  targetGroups: TargetGroupListData;
  isLoading: boolean;
  targetGroupsLoaded: boolean;
}

export const TARGET_GROUP_REQUEST = "TARGET_GROUP_REQUEST";
export const TARGET_GROUP_FAILURE = "TARGET_GROUP_FAILURE";
export const LOAD_TARGET_GROUP_DATA_SUCCESS = "LOAD_TARGET_GROUP_DATA_SUCCESS";

export interface TargetGroupsRequestAction {
  type: typeof TARGET_GROUP_REQUEST;
}

export interface TargetGroupsFailureAction {
  type: typeof TARGET_GROUP_FAILURE;
}

export interface LoadTargetGroupsAction {
  type: typeof LOAD_TARGET_GROUP_DATA_SUCCESS;
  data: TargetGroupListData;
}

export type TargetGroupActionTypes =
  | TargetGroupsRequestAction
  | TargetGroupsFailureAction
  | LoadTargetGroupsAction;
