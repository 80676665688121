import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Popover,
  Theme,
  Typography,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { EventFormData } from "../../../../../models/eventData";
import { AppState } from "../../../../../redux";

interface CreateTemplateFromEventButtonProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
  })
);

const CreateTemplateFromEventButton: React.FC<CreateTemplateFromEventButtonProps> = (
  props
) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext<EventFormData>();

  const templates = useSelector((state: AppState) => state.eventTemplates);

  const [
    anchorElNotAllRequiredFieldsSet,
    setAnchorElanchorElNotAllRequiredFieldsSet,
  ] = React.useState<HTMLElement | null>(null);
  const openNotAllRequiredFieldsSetPopup = Boolean(
    anchorElNotAllRequiredFieldsSet
  );

  const handlePopoverOpenNotAllRequiredFieldsSet = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorElanchorElNotAllRequiredFieldsSet(event.currentTarget);
  };

  const handlePopoverCloseNotAllRequiredFieldsSet = () => {
    setAnchorElanchorElNotAllRequiredFieldsSet(null);
  };

  // ! check if the required values are set every time the values of the form change
  const notAllRequiredFieldsAreSet = useCallback(() => {
    if (
      values.title !== "" &&
      values.score >= 0 &&
      values.score !== undefined &&
      values.laekh_category !== null &&
      values.laekh_category !== "" &&
      values.laekh_form_id !== null &&
      values.laekh_form_id !== ""
    ) {
      return false;
    }
    return true;
  }, [values.laekh_category, values.laekh_form_id, values.score, values.title]);

  return (
    <>
      <Popover
        id="not-all-required-fields-set-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openNotAllRequiredFieldsSetPopup}
        anchorEl={anchorElNotAllRequiredFieldsSet}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverCloseNotAllRequiredFieldsSet}
        disableRestoreFocus
      >
        <Box component={"div"} style={{ textAlign: "left", maxWidth: "30rem" }}>
          <Typography>
            Es kann aus dieser Veranstaltung keine Vorlage erstellt werden, da{" "}
            <b> nicht alle Pflichtfelder</b> ausgefüllt sind, um eine
            Fortbildungsmaßnahme erstellen zu können. Kontrollieren Sie die
            folgenden Felder und versuchen es erneut:
            <br />
            <b style={{ color: "red" }}>
              {(values.title === "" || !values.title) && (
                <>
                  <br />- Veranstaltungsname
                </>
              )}
              {(values.score === undefined || values.score < 0) && (
                <>
                  <br />- Fortbildungspunkte
                </>
              )}
              {values.laekh_form_id === undefined ||
                (values.laekh_form_id === "" && (
                  <>
                    <br />- LÄKH Form ID
                  </>
                ))}
              {values.laekh_category === undefined ||
                (values.laekh_category === "" && (
                  <>
                    <br />- LÄKH Kategorie
                  </>
                ))}
            </b>
          </Typography>
        </Box>
      </Popover>
      {!templates.eventTemplateMode && (
        <Grid item xs={6} sm={4}>
          <Box
            component={"div"}
            onMouseEnter={
              notAllRequiredFieldsAreSet()
                ? handlePopoverOpenNotAllRequiredFieldsSet
                : undefined
            }
            onMouseLeave={
              notAllRequiredFieldsAreSet()
                ? handlePopoverCloseNotAllRequiredFieldsSet
                : undefined
            }
          >
            <Button
              size="large"
              disabled={notAllRequiredFieldsAreSet()}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              onClick={() => {
                setFieldValue("submitWithTemplate", true);
              }}
            >
              Als Vorlage speichern und erstellen
            </Button>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default CreateTemplateFromEventButton;
