import { Typography } from "@material-ui/core";
import apiRoutes from "../../api/apiRoutes";
import useTimeout from "../hooks/UseTimeout";

const AdminRedirect = () => {
  const redirectToAdminPanel = () => {
    window.location.href = `${apiRoutes.admin}/`;
  };

  useTimeout(redirectToAdminPanel, 3000);

  return (
    <Typography align="center">
      Sie werden in wenigen Sekunden weitergeleitet...
    </Typography>
  );
};

export default AdminRedirect;
