import * as React from "react";
import { useSelector } from "react-redux";
import { MyELearningListData } from "../../../../models/elearning";
import { AppState } from "../../../../redux";
import PinboardLink from "../my-events/details/pinboard/PinboardLink";

interface PinboardCounterProps {
  booking: MyELearningListData;
  count: number;
}

const PinboardCounter: React.FC<PinboardCounterProps> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  // TODO: if there is time for refactoring the UI --> locally update the state of the pinboard count,
  // TODO: so the changes are displayed immediately and it doesn't have to wait for 15 seconds until the dashboard state is fetched

  return (
    <PinboardLink
      className={
        props.count > 0 && !accessibility.monoMode
          ? "dashboard-card-counter-blue"
          : undefined
      }
      eventId={props.booking.event.toString()} // this is guid of the event instead of the event itself, even though the model does not
      booking={props.booking}
      anchorText={props.count.toString()}
    />
  );
};

export default PinboardCounter;
