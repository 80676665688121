import { Box, Divider, Grid, Link } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import GetAppIcon from "@material-ui/icons/GetApp";
import * as React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux";
import "../MyEvents.scss";
import { DocumentsState } from "../../../../../redux/documents/types";
import Loader from "../../../../theming/loader/Loader";

interface EventDocumentsProps {
  documents: DocumentsState;
  no_automatic_participation_certificate: boolean;
}

const EventDocuments: React.FC<EventDocumentsProps> = ({
  documents,
  no_automatic_participation_certificate,
}) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
        <Box component={"div"} className={"myevents-details-headline"}>
          Materialien
        </Box>
      </Grid>
      <Grid item xs={12}>
        {documents.isLoading ? (
          <Loader />
        ) : (
          documents.documentList.map((document, index) => {
            if (
              document.title === "Teilnahmebescheinigung" &&
              no_automatic_participation_certificate
            ) {
              return <></>;
            } else
              return (
                <Box
                  display="flex"
                  className={"myevents-details-document-list-item"}
                  key={index}
                  px={1}
                >
                  <Box px={1}>
                    <DescriptionIcon />
                  </Box>
                  <Box
                    flexGrow={1}
                    px={1}
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {document.title}
                  </Box>
                  <Box px={1}>
                    <Link
                      href={document.file}
                      target={"_blank"}
                      className={"myevents-details-document-download-icon"}
                    >
                      <GetAppIcon
                        color={accessibility.monoMode ? "primary" : undefined}
                      />
                    </Link>
                  </Box>
                </Box>
              );
          })
        )}
      </Grid>
    </>
  );
};

export default EventDocuments;
