import { ELearningDto } from "../../models/elearning";
import { EventStatus } from "../../models/enums/eventStatus.enum";
import {
  EventType,
  InternalExternalEventType,
} from "../../models/enums/eventType.enum";
import { ParticipationStatus } from "../../models/enums/participationStatus.enum";
import { EventsSortBy } from "../../models/enums/sortBy.enum";
import { EventListData, UserLinkedAccountStats } from "../../models/eventData";
import { Pagination } from "../../models/pagination";
import { EventFilterService } from "../../services/filters/eventFiltersService";
import { TrainingType } from "./../../models/enums/trainingType.enum";
import {
  ADD_EVENT_SUCCESS,
  ADD_TO_LIST_OF_EVENTS_SUCCESS,
  CLEAR_EDIT_EVENT,
  CLEAR_EVENT_LIST,
  DELETE_EVENT_SUCCESS,
  EDIT_EVENT_STATUS_SUCCESS,
  EventActionTypes,
  EventState,
  EVENTS_FAILURE,
  EVENTS_REQUEST,
  INCREMENT_WAITING_LIST_IN_EVENT,
  REFETCH_EVENTS_PAGE_SUCCESS,
  SET_EDIT_EVENT_INVOICE,
  SET_EDIT_EVENT_SUCCESS,
  SET_EVENTS_PAGE_COUNT,
  SET_EVENT_FAVORITE_STATUS,
  SET_FILTER_DATA,
  SET_FILTER_VALUES,
  SET_HAS_MORE_EVENTS,
  SET_LIST_OF_EVENTS_SUCCESS,
} from "./types";

export const initialEventState: EventState = {
  eventList: [],
  addEvent: {
    vnr: "",
    title: "",
    location: "",
    city: "Frankfurt",
    zip_code: "60486",
    location_details: "",
    begin: new Date(),
    end: new Date(),
    score: 0,
    price: 0,
    min_seats: 0,
    max_seats: 0,
    description: "",
    status: EventStatus.Undefined,
    is_capped: false,
    internal_external: InternalExternalEventType.Internal,
    no_reminder: false,
    no_discount: false,
    no_automatic_participation_certificate: false,
    catering: false,
    event_type: EventType.Undefined,
    training_type: TrainingType.DefaultEvent,
    participation_types: [],
    special_fields: [],
    coverage_areas: [],
    speakers: [],
    documents: [],
    commentary: "",
    tasks: [],
    laekh_category: null,
    laekh_form_id: null,
    template: null,
  },
  editEvent: {
    vnr: "",
    title: "",
    location: "",
    city: "Frankfurt",
    zip_code: "60486",
    location_details: "",
    begin: new Date(),
    end: new Date(),
    score: 0,
    price: 0,
    min_seats: 0,
    max_seats: 0,
    description: "",
    status: EventStatus.Undefined,
    is_capped: false,
    internal_external: InternalExternalEventType.Internal,
    no_reminder: false,
    no_discount: false,
    no_automatic_participation_certificate: false,
    catering: false,
    event_type: EventType.Undefined,
    training_type: TrainingType.DefaultEvent,
    participation_types: [],
    special_fields: [],
    coverage_areas: [],
    speakers: [],
    documents: [],
    commentary: "",
    tasks: [],
    checklist: [],
    checklist_additional_info: "",
    evaluation_link: "",
    evaluation_link_speaker: "",
    laekh_category: null,
    laekh_form_id: null,
    template: null,
    max_seats_taken: false,
    duration_per_unit_in_minutes: 0,
    max_time_period_to_finish_elearning_module_in_weeks: 0,
    number_of_units: 0,
    pinboard_is_activated: false,
    publish_elearning_after_event: false,
    guid: "",
  },
  editEventLoaded: false,
  currentPage: 0,
  hasMoreItemsToLoad: true,
  isLoading: false,
  filterValues: {
    locations: [],
  },
  filterData: {
    search: "",
    location: [],
    event_type: [],
    training_type: [],
    status: "",
    participation_types: [],
    special_fields: [],
    coverage_areas: [],
    sorting: EventsSortBy.BeginOldest,
    my_target_group: false,
    begin_gte: "",
    begin_lte: "",
    guid: "",
  },
  error: false,
  notFoundError: false,
};

export function getInitialEventState() {
  // ! persist the filter state after page change and set as initial filter values
  const filterService = new EventFilterService(
    initialEventState.currentPage,
    initialEventState.filterData
  );
  const initialFilterData = filterService.parseFiltersFromUrl();

  // set the initial filter data (get from url)
  const initialState: EventState = {
    ...initialEventState,
    filterData: initialFilterData,
  };

  return initialState;
}

function incrementWaitingList(
  eventId: string,
  eventList: Pagination<EventListData>[],
  linkedUser: UserLinkedAccountStats | null
): Pagination<EventListData>[] {
  let copiedEventList = [...eventList];

  for (let i = 0; i < copiedEventList.length; i++) {
    for (let j = 0; j < copiedEventList[i].results.length; j++) {
      if (copiedEventList[i].results[j].guid === eventId) {
        copiedEventList[i].results[j].waiting_list++;

        // set the waiting list status of the user itself or the linked account
        if (!linkedUser) {
          copiedEventList[i].results[j].participation_status =
            ParticipationStatus.WaitingList;
        } else {
          const indexOfUser = copiedEventList[i].results[
            j
          ].participation_status_linked_accounts.findIndex(
            (x) => x.user === linkedUser.user
          );
          if (indexOfUser !== -1) {
            copiedEventList[i].results[j].participation_status_linked_accounts[
              indexOfUser
            ].status = ParticipationStatus.WaitingList;
          }
        }
        return copiedEventList;
      }
    }
  }
  return copiedEventList;
}

function editEventStatusID(
  eventId: string,
  eventStatus: number,
  eventList: Pagination<EventListData>[]
): Pagination<EventListData>[] {
  let copiedEventList = [...eventList];
  for (let i = 0; i < copiedEventList.length; i++) {
    for (let j = 0; j < copiedEventList[i].results.length; j++) {
      if (copiedEventList[i].results[j].guid === eventId) {
        copiedEventList[i].results[j].status = eventStatus;
        return copiedEventList;
      }
    }
  }
  return copiedEventList;
}

function setEventFavoriteStatus(
  eventId: string,
  favoriteStatus: boolean,
  eventList: Pagination<EventListData>[]
): Pagination<EventListData>[] {
  let copiedEventList = [...eventList];
  for (let i = 0; i < copiedEventList.length; i++) {
    for (let j = 0; j < copiedEventList[i].results.length; j++) {
      if (copiedEventList[i].results[j].guid === eventId) {
        copiedEventList[i].results[j].is_favorite = favoriteStatus;
        return copiedEventList;
      }
    }
  }
  return copiedEventList;
}

function setEventInvoice(event: ELearningDto): ELearningDto {
  let eventEdit = { ...event };
  eventEdit.is_invoiced = true;
  return eventEdit;
}

export function eventReducer(
  state = getInitialEventState(),
  action: EventActionTypes
): EventState {
  switch (action.type) {
    case EVENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case REFETCH_EVENTS_PAGE_SUCCESS:
      const updatedEventList = state.eventList.map(
        (content, i) =>
          i === action.page
            ? { ...content, ...action.data }
            : { ...content, count: action.data.count } // use same object but update the count
      );
      return {
        ...state,
        eventList: updatedEventList,
        isLoading: false,
        error: false,
        notFoundError: false,
        hasMoreItemsToLoad: updatedEventList[action.page].next !== null,
      };
    case ADD_TO_LIST_OF_EVENTS_SUCCESS:
      return {
        ...state,
        eventList: [...state.eventList, action.data],
        isLoading: false,
        error: false,
        notFoundError: false,
        // check if there is another page
        hasMoreItemsToLoad: action.data.next !== null,
      };
    case SET_LIST_OF_EVENTS_SUCCESS:
      return {
        ...state,
        eventList: [action.data],
        isLoading: false,
        error: false,
        notFoundError: false,
        // check if there is another page
        hasMoreItemsToLoad: action.data.next !== null,
      };
    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        addEvent: action.data,
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        eventList: initialEventState.eventList,
        editEvent: initialEventState.editEvent,
        editEventLoaded: initialEventState.editEventLoaded,
        isLoading: initialEventState.isLoading,
        error: false,
        notFoundError: false,
      };
    case SET_EDIT_EVENT_SUCCESS:
      return {
        ...state,
        editEvent: action.data,
        editEventLoaded: true,
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case EDIT_EVENT_STATUS_SUCCESS:
      return {
        ...state,
        eventList: [
          ...editEventStatusID(
            action.eventId,
            action.eventStatus,
            state.eventList
          ),
        ],
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case CLEAR_EDIT_EVENT:
      return {
        ...state,
        editEvent: initialEventState.editEvent,
        editEventLoaded: false,
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case SET_EDIT_EVENT_INVOICE:
      return {
        ...state,
        editEvent: setEventInvoice(state.editEvent),
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case SET_EVENT_FAVORITE_STATUS:
      return {
        ...state,
        eventList: [
          ...setEventFavoriteStatus(
            action.eventId,
            action.status,
            state.eventList
          ),
        ],
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case EVENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        notFoundError: action.notFoundError,
      };
    case SET_EVENTS_PAGE_COUNT:
      return {
        ...state,
        currentPage: action.pageCount,
      };
    case SET_HAS_MORE_EVENTS:
      return {
        ...state,
        hasMoreItemsToLoad: action.hasMoreEvents,
      };
    case CLEAR_EVENT_LIST:
      return {
        ...state,
        eventList: initialEventState.eventList,
        currentPage: initialEventState.currentPage,
        filterValues: initialEventState.filterValues,
        hasMoreItemsToLoad: initialEventState.hasMoreItemsToLoad,
        isLoading: initialEventState.isLoading,
        error: false,
        notFoundError: false,
      };
    case INCREMENT_WAITING_LIST_IN_EVENT:
      return {
        ...state,
        eventList: [
          ...incrementWaitingList(
            action.eventId,
            state.eventList,
            action.linkedUser
          ),
        ],
      };
    case SET_FILTER_VALUES:
      return {
        ...state,
        filterValues: action.filterValues,
        isLoading: false,
        error: false,
        notFoundError: false,
      };
    case SET_FILTER_DATA:
      return {
        ...state,
        filterData: action.filterData,
        // this will trigger a reload
        hasMoreItemsToLoad: true,
        currentPage: 0,
      };
    default:
      return state;
  }
}

export default eventReducer;
