import { Divider } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { ELearningListData } from "../../../../models/elearning";
import { TrainingType } from "../../../../models/enums/trainingType.enum";
import { Pagination } from "../../../../models/pagination";
import { AppState } from "../../../../redux";
import NoEventsFound from "../shared/NoEventsFound";
import { EventItem } from "./eventItem/EventItem";

interface PageItemsProps {
  pages: Pagination<ELearningListData>[];
}

const PageItems: React.FC<PageItemsProps> = (props) => {
  const eventState = useSelector((state: AppState) => state.event);
  const eventFilterData = eventState.filterData;

  // TODO: remove this after elearnings are established
  const showNoElearningsYetText = eventFilterData.training_type.includes(
    TrainingType.ELearning
  );

  return (
    <React.Fragment>
      {props.pages.map((page, pageIndex) => {
        if (pageIndex === 0 && page.count === 0) {
          return (
            <NoEventsFound
              key="no-events"
              customText={
                showNoElearningsYetText
                  ? "Es stehen in Kürze E-Learnings zur Verfügung."
                  : undefined
              }
            />
          );
        } else {
          return (
            <React.Fragment key={`page-${pageIndex}`}>
              {page.results.map((event) => {
                return (
                  <React.Fragment key={`event-${event.guid}`}>
                    <EventItem event={event} />
                    <Divider className={"event-list-separator"} />
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        }
      })}
    </React.Fragment>
  );
};

export default PageItems;
