import { Button } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { CustomSelectArrow } from "../../assets/icons/custom-svg-components/KvhSvgCollection";
import "./ScrollToTopButton.scss";

interface ScrollToTopButtonProps {}

const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = (props) => {
  const [showScroll, setShowScroll] = useState(false);
  const [prevScroll, setPrevScroll] = useState(window.scrollY);
  const [isLoading, setIsLoading] = useState(true); // loading is true

  const handleScroll = useCallback(
    (e) => {
      const window = e.currentTarget;

      if (showScroll && prevScroll > window.scrollY) {
        setShowScroll(false);
      } else if (!showScroll && prevScroll < window.scrollY) {
        setShowScroll(true);
      }
      setPrevScroll(window.scrollY);
      setIsLoading(false);
    },
    [prevScroll, showScroll]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // don't show the button if the page is not loaded yet
  return !isLoading ? (
    <Button
      variant="contained"
      color="primary"
      className={clsx("scrollTop", showScroll ? "show-button" : "hide-button")}
      onClick={scrollTop}
    >
      <CustomSelectArrow
        style={{
          color: "#fff",
          stroke: "#fff",
          transform: "rotate(-90deg)",
          fontSize: "28px",
        }}
      />
    </Button>
  ) : (
    <></>
  );
};

export default ScrollToTopButton;
