import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Hidden,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux";
import CartItem from "./CartItem";
import { useEffect } from "react";
import useInterval from "../../hooks/UseInterval";
import { CartData } from "../../../models/cartData";
import { getCart } from "../../../redux/cart/actions";
import {
  CHECK_CART_EACH_MINUTE,
  EXPIRATION_DURATION,
} from "../../../redux/cart/reducers";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Utils } from "../../../services/utils";

interface CartListProps {
  checkout?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "none",
    },
  })
);

const CartList: React.FC<CartListProps> = (props) => {
  const classes = useStyles();

  const cart = useSelector((state: AppState) => state.cart);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector((state: AppState) => state.auth.loggedIn);

  useInterval(() => {
    checkDurationOfCartItems(cart.cartList);
  }, CHECK_CART_EACH_MINUTE);

  const checkDurationOfCartItems = (cartItems: CartData[]) => {
    cartItems.forEach((cartItem) => {
      if (
        Date.now() >
        new Date(cartItem.created_on).getTime() + EXPIRATION_DURATION
      ) {
        dispatch(getCart());
      }
    });
  };

  const reducer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  function calculateDiscount() {
    const discounts = cart.cartList.map((x) => x.discount as number);
    const discountTotal = discounts.reduce(reducer);
    return discountTotal;
  }

  function getsDiscount() {
    const hasDiscount = cart.cartList.findIndex((x) => x.discount !== null);
    if (hasDiscount !== -1) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (!cart.cartInitialLoaded && isUserLoggedIn) {
      dispatch(getCart());
    }
    let newTotalPrice = 0;
    cart.cartList.map((cartItem) => {
      return (newTotalPrice = newTotalPrice + cartItem.price);
    });
    setTotalPrice(newTotalPrice);
  }, [
    setTotalPrice,
    cart.cartList,
    cart.cartInitialLoaded,
    dispatch,
    isUserLoggedIn,
  ]);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <>
      <TableContainer className={"shopping-cart-tablebody"}>
        <Table aria-label="cart items">
          {cart.cartList.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell className={"shopping-cart-emptyinfocell"}>
                  Es befindet sich keine Veranstaltung im Warenkorb.
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <>
              <TableHead>
                <TableRow>
                  <Hidden smDown>
                    <TableCell component="th" scope="row">
                      Kurs-Nr.
                    </TableCell>
                  </Hidden>
                  <TableCell align="left">Titel</TableCell>
                  <Hidden xsDown>
                    <TableCell align="left">Datum</TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell align="left">Referent</TableCell>
                  </Hidden>
                  {props.checkout && (
                    <Hidden smDown>
                      <TableCell align="left">Verpflegung</TableCell>
                    </Hidden>
                  )}
                  <Hidden smDown>
                    <TableCell align="left">Gebucht für</TableCell>
                  </Hidden>
                  <TableCell align="right">Preis</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart.cartList.map((cartItem, index) => {
                  return (
                    <CartItem
                      event={cartItem}
                      key={index}
                      checkout={props.checkout}
                    />
                  );
                })}
                {getsDiscount() && (
                  <TableRow>
                    {!xs && (
                      <TableCell
                        className={classes.root}
                        colSpan={xs ? 1 : sm ? 1 : props.checkout ? 4 : 3}
                      ></TableCell>
                    )}
                    <TableCell align="right" colSpan={xs ? 1 : sm ? 1 : 2}>
                      Rabatt:
                      <Hidden smUp>
                        <br />
                        <span style={{ fontSize: "12px" }}>10%</span>
                      </Hidden>
                    </TableCell>
                    <TableCell align="right">
                      <b>-{Utils.formatPrice(calculateDiscount())}</b>
                    </TableCell>
                    {xs && <TableCell></TableCell>}
                    <Hidden xsDown>
                      <TableCell>
                        <span style={{ fontSize: "12px" }}>10% je VA</span>
                      </TableCell>
                    </Hidden>
                  </TableRow>
                )}
                <TableRow>
                  {!xs && (
                    <TableCell
                      className={classes.root}
                      colSpan={xs ? 1 : sm ? 1 : props.checkout ? 4 : 3}
                    ></TableCell>
                  )}
                  <TableCell colSpan={xs ? 1 : sm ? 1 : 2} align="right">
                    Gesamtpreis:
                    <Hidden smUp>
                      <br />
                      <span style={{ fontSize: "12px" }}>inkl. Mwst</span>
                    </Hidden>
                  </TableCell>
                  <TableCell align="right">
                    <b>{Utils.formatPrice(totalPrice - calculateDiscount())}</b>
                  </TableCell>
                  {xs && <TableCell></TableCell>}
                  <Hidden xsDown>
                    <TableCell>
                      <span style={{ fontSize: "12px" }}>inkl. Mwst</span>
                    </TableCell>
                  </Hidden>
                </TableRow>
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default CartList;
