import { Box, Button, Container, Grid, Hidden, Link } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import logo from "../../../assets/general/logo.svg";
import logo_mono from "../../../assets/general/logo_mono.svg";
import { AppState } from "../../../redux";
import { MAIN_URL } from "../../routing/externalUrls";
import routes from "../../routing/routes";
import AlertManger from "../alerts/AlertManager";
import "./Header.scss";
import { Navigation } from "./navigation/Navigation";
import { Profile } from "./profile/Profile";
import TopNavAndAccessibility from "./TopNavAndAccessibility";

export const Header: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.user);
  const auth = useSelector((state: AppState) => state.auth);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  return (
    <>
      <header className={"box-shadow"}>
        <Container>
          <Grid container>
            <Hidden smUp>
              <Grid item xs={12}>
                <TopNavAndAccessibility />
              </Grid>
            </Hidden>

            <Grid item xs={12}>
              <Box display="flex">
                <Box className={"navigation-logo"}>
                  <Link
                    href={MAIN_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={accessibility.monoMode ? logo_mono : logo}
                      className={
                        accessibility.monoMode
                          ? "navbar-header-logo-mono"
                          : "navbar-header-logo"
                      }
                      alt="kvh logo"
                    />
                  </Link>
                </Box>
                <Box flexGrow={1}>
                  <Hidden xsDown>
                    <Grid item xs={12}>
                      <TopNavAndAccessibility />
                    </Grid>
                  </Hidden>
                  <Box display="flex" justifyContent="flex-end">
                    {auth.loggedIn ? (
                      <>
                        {user.isLoading ? (
                          <>
                            <Box display={"flex"} alignItems="center">
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                mr={"16px"}
                              >
                                <Skeleton width={100} height={18} />
                                <Skeleton width={100} height={24} />
                              </Box>
                              <Skeleton
                                variant="circle"
                                width={50}
                                height={50}
                              />
                            </Box>
                          </>
                        ) : (
                          <Profile />
                        )}
                        <Navigation />
                      </>
                    ) : (
                      location.pathname !== routes.login && (
                        <>
                          <Button
                            style={{
                              height: "62px",
                              padding: "0 10px",
                              fontSize: "16px",
                              position: "relative",
                              top: "4px",
                            }}
                            size="large"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              history.push(routes.login);
                            }}
                          >
                            Login
                          </Button>
                        </>
                      )
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <AlertManger />
        </Container>
      </header>
    </>
  );
};
