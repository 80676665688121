import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { billingAddressService } from "../../api";
import { ErrorAction, SuccessAction } from "./../alerts/types";
import {
  BILLING_ADDRESS_REQUEST,
  BILLING_ADDRESS_FAILURE,
  BILLING_ADDRESS_CLEAR,
  BillingAddressFailureAction,
  BillingAddressRequestAction,
  BillingAddressClearAction,
  BillingAddressActionTypes,
  SetBillingAddressAction,
  SET_BILLING_ADDRESS,
  RELOAD_BILLING_ADDRESS,
  ReloadBillingAddressAction,
  SET_BILLING_ADDRESS_FOR_LINKED_USER,
  SetBillingAddressForLinkedUserAction,
} from "./types";
import { error, success } from "../alerts/actions";
import {
  BillingAddressData,
  CrudBillingAddressData,
  UserBillingAddressDto,
} from "../../models/billingAddressData";

export function getBillingAddresses() {
  return (
    dispatch: Dispatch<BillingAddressActionTypes | ErrorAction | SuccessAction>
  ) => {
    dispatch(billingAddressRequest());
    billingAddressService
      .getBillingAddresses()
      .then((response) => {
        dispatch(setBillingAddress(response));
      })
      .catch((err: AxiosError) => {
        dispatch(billingAddressFailure());
      });
  };
}

export function getBillingAddressesForUser(userGuid: string) {
  return (
    dispatch: Dispatch<BillingAddressActionTypes | ErrorAction | SuccessAction>
  ) => {
    dispatch(billingAddressRequest());
    billingAddressService
      .getBillingAddressesForUser(userGuid)
      .then((response) => {
        dispatch(setBillingAddressForLinkedUser(response, userGuid));
      })
      .catch((err: AxiosError) => {
        dispatch(billingAddressFailure());
      });
  };
}

export function createBillingAddress(billingAddress: BillingAddressData) {
  return (
    dispatch: Dispatch<BillingAddressActionTypes | ErrorAction | SuccessAction>
  ) => {
    dispatch(billingAddressRequest());
    billingAddressService
      .createBillingAddress(billingAddress)
      .then((response) => {
        dispatch(reloadBillingAddress());
        dispatch(
          success(
            {
              message: "Ihre Rechnungsadresse wurde erfolgreich angelegt.",
            },
            3000
          )
        );
      })
      .catch((err: AxiosError) => {
        dispatch(billingAddressFailure());
        dispatch(
          error(
            {
              message: "Ihre Rechnungsadresse konnte nicht angelegt werden.",
            },
            5000
          )
        );
      });
  };
}

export function editBillingAddress(
  billingAddressGuid: string,
  billingAddress: BillingAddressData
) {
  return (
    dispatch: Dispatch<BillingAddressActionTypes | ErrorAction | SuccessAction>
  ) => {
    dispatch(billingAddressRequest());
    billingAddressService
      .editBillingAddress(billingAddressGuid, billingAddress)
      .then((response) => {
        dispatch(reloadBillingAddress());
        dispatch(
          success(
            {
              message: "Ihre Rechnungsadresse wurde erfolgreich geändert.",
            },
            3000
          )
        );
      })
      .catch((err: AxiosError) => {
        dispatch(billingAddressFailure());
        dispatch(
          error(
            {
              message: "Ihre Rechnungsadresse konnte nicht geändert werden.",
            },
            5000
          )
        );
      });
  };
}

export function billingAddressRequest(): BillingAddressRequestAction {
  return {
    type: BILLING_ADDRESS_REQUEST,
  };
}

export function billingAddressFailure(): BillingAddressFailureAction {
  return {
    type: BILLING_ADDRESS_FAILURE,
  };
}

export function setBillingAddress(
  data: CrudBillingAddressData[]
): SetBillingAddressAction {
  return {
    type: SET_BILLING_ADDRESS,
    data,
  };
}

export function setBillingAddressForLinkedUser(
  data: UserBillingAddressDto,
  userGuid: string
): SetBillingAddressForLinkedUserAction {
  return {
    type: SET_BILLING_ADDRESS_FOR_LINKED_USER,
    data: data,
    linkedUser: userGuid,
  };
}

export function reloadBillingAddress(): ReloadBillingAddressAction {
  return {
    type: RELOAD_BILLING_ADDRESS,
  };
}

export function billingAddressClear(): BillingAddressClearAction {
  return {
    type: BILLING_ADDRESS_CLEAR,
  };
}
