import { NavigationData } from "../navigationData";
import routes from "../../../../routing/routes";

export class BreadcrumbsService {
  findNavigationItemByKey(
    navData: NavigationData[],
    key: string
  ): NavigationData | undefined {
    // if empty array then return undefined
    if (navData.length === 0) return undefined;

    // return navigation data element if found, else search in subroutes recursively
    return (
      navData.find((d) => d.key === key || key === ":id") ||
      this.findNavigationItemByKey(
        navData.flatMap((d) => d.subRoutes || []),
        key
      )
    );
  }

  getBreadCrumbs(subPaths: string[], navigationData: NavigationData[]) {
    let breadcrumbs: NavigationData[] = [];

    subPaths.forEach((x: string, index: number) => {
      let result = null;
      const mainNavSubRoutes = breadcrumbs[0]?.subRoutes;

      if (index > 0 && mainNavSubRoutes !== undefined) {
        // find all sub routes of main route
        result = this.findNavigationItemByKey(mainNavSubRoutes, x);
      } else {
        // find first breadcrumb entry (main route)
        result = this.findNavigationItemByKey(navigationData, x);
      }

      if (result) {
        breadcrumbs = [...breadcrumbs, result];
      }
    });
    return breadcrumbs;
  }

  splitIntoSubpaths(location: string) {
    const subPaths: string[] = [];

    // slice the parts of the routes by the slashes and ignore main route for login etc
    location
      .replace(location !== routes.home ? routes.home : "", "")
      .slice(0) // start from first
      .split("/")
      .forEach((x) => subPaths.push(x));

    return subPaths;
  }
}
