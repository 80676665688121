import { Box, Button, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  mapToSexTypeString,
  SexType,
} from "../../../models/enums/sexType.enum";
import { ActivitiyHistory } from "../../../models/mailHistory";
import { AppState } from "../../../redux";
import { getTargetGroups } from "../../../redux/target-groups/actions";
import { getStatistics } from "../../../redux/activityHistory/actions";
import HeadingKvh from "../../theming/HeadingKvh";
import Loader from "../../theming/loader/Loader";
import TabsKvh from "../../theming/tabs/TabsKvh";
import TargetGroupList from "../events/list/eventItem/TargetGroupList";
import ActivityHistoryFilterForm from "./ActivityHistoryFilterForm";
import MailHistory from "./MailHistory";
import UserParticipations from "./Participations";
import { SearchUserField } from "./SearchUserField";

export interface RowProps {
  row: ActivitiyHistory;
}

export const ActivityHistory: React.FC = () => {
  const targetGroups = useSelector((state: AppState) => state.targetGroups);
  const activityHistory = useSelector(
    (state: AppState) => state.activityHistory
  );
  const [statistics, setStatistics] = useState<ActivitiyHistory | undefined>(
    activityHistory.statistics
  );
  const [loadForUser, setLoadForUser] = useState<string>("");
  const [isParticipationsTab, setIsParticipationsTab] = useState<boolean>(
    false
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!targetGroups.isLoading && !targetGroups.targetGroupsLoaded) {
      dispatch(getTargetGroups());
    }
  }, [dispatch, targetGroups]);

  return (
    <>
      <HeadingKvh>Aktivitätenhistorie</HeadingKvh>
      <Box my={3}>
        <Typography>
          Hier können Sie nach einem bestimmten Nutzer suchen, um sich dessen
          Aktivitäten anzeigen lassen zu können. Gesucht werden kann nach
          E-Mail, Nutzernamen oder Vor- und Nachnamen.
        </Typography>
      </Box>
      <Formik
        onSubmit={(_data: {
          search: string;
          statistics?: ActivitiyHistory;
        }) => {}}
        enableReinitialize
        initialValues={{
          search: "",
        }}
      >
        {() => {
          return (
            <>
              <Form style={{ marginBottom: "16px" }}>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={12}>
                    <b>Nutzersuche</b>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <SearchUserField
                      initialUser={undefined}
                      onChangeUserId={(id: string) => {
                        setLoadForUser(id);
                        dispatch(getStatistics(id));
                        setStatistics(undefined);
                      }}
                    />
                  </Grid>
                  {loadForUser !== "" && (
                    <Grid item xs={6} sm={4}>
                      <Button
                        size="medium"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          dispatch(getStatistics(loadForUser));
                          setStatistics(activityHistory.statistics);
                        }}
                      >
                        Aktivitäten für diesen Nutzer anzeigen
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Form>
            </>
          );
        }}
      </Formik>

      {activityHistory.isLoading ? (
        <>
          <Loader />
          <Box minHeight={500}></Box>
        </>
      ) : (
        <>
          {loadForUser !== "" && statistics ? (
            <Box my={3}>
              <h3>Nutzerinformation</h3>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Nutzername:{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {statistics?.user.fullname}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Anrede:{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {statistics?.user.sex === SexType.Undefined
                    ? "-"
                    : mapToSexTypeString(statistics?.user.sex)}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Titel:{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {[null, undefined, ""].includes(
                    statistics?.user.academic_title
                  )
                    ? "-"
                    : statistics?.user.academic_title}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    E-Mail:{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {[null, undefined, ""].includes(statistics?.user.email)
                    ? "-"
                    : statistics?.user.email}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Telefonnummer:{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {[null, undefined, ""].includes(statistics?.user.phone)
                    ? "-"
                    : statistics?.user.phone}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Handynummer:{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {[null, undefined, ""].includes(statistics?.user.mobile)
                    ? "-"
                    : statistics?.user.mobile}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>LANR: </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {statistics?.user.lanr ? statistics?.user.lanr : "-"}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Zielgruppen:{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TargetGroupList
                    targetGroups={{
                      coverage_areas:
                        statistics?.user.coverage_areas === null
                          ? []
                          : statistics?.user.coverage_areas,
                      participation_types:
                        statistics?.user.participation_types === null
                          ? []
                          : statistics?.user.participation_types,
                      special_fields:
                        statistics?.user.special_fields === null
                          ? []
                          : statistics?.user.special_fields,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box minHeight={500}></Box>
          )}
          {statistics ? (
            <>
              <h3>Aktivitäten</h3>
              <Box mt={3} mb={6}>
                <ActivityHistoryFilterForm
                  statistics={statistics}
                  updateStatistics={(stats: ActivitiyHistory) =>
                    setStatistics(stats)
                  }
                  isParticipationsTab={isParticipationsTab}
                />
              </Box>
              <TabsKvh
                onTabChange={() => {}}
                tabs={[
                  {
                    label: "Versandte Mails",
                    content: (
                      <MailHistory
                        mailHistory={statistics.mail_history}
                        onLoad={() => setIsParticipationsTab(false)}
                      />
                    ),
                  },
                  {
                    label: "Veranstaltungen",
                    content: (
                      <UserParticipations
                        participations={statistics.participations}
                        onLoad={() => setIsParticipationsTab(true)}
                      />
                    ),
                  },
                ]}
              />
            </>
          ) : (
            <Box minHeight={500}></Box>
          )}
        </>
      )}
    </>
  );
};
