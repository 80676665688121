import { Grid } from "@material-ui/core";
import * as React from "react";
import CustomTextField from "../../../forms/CustomTextField";

interface BillingAddressFormProps {
  editMode: boolean;
}
const BillingAddressForm: React.FC<BillingAddressFormProps> = (props) => {
  return (
    <>
      <Grid item xs={12}>
        <CustomTextField
          fullWidth
          disabled={!props.editMode}
          autoComplete="salutation"
          name="medical_office_or_company"
          label="Praxis / Firma"
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          fullWidth
          disabled={!props.editMode}
          name="medical_office_or_company_extra"
          label="Ergänzende Informationen zur Praxis / Firma"
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          fullWidth
          required
          disabled={!props.editMode}
          autoComplete="firstName"
          name="first_name"
          label="Vorname"
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          fullWidth
          required
          disabled={!props.editMode}
          autoComplete="lastName"
          name="last_name"
          label="Nachname"
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <CustomTextField
          fullWidth
          required
          disabled={!props.editMode}
          autoComplete="address"
          name="address"
          label="Adresse"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CustomTextField
          fullWidth
          required
          disabled={!props.editMode}
          autoComplete="street_address"
          name="street_address"
          label="Hausnummer"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CustomTextField
          fullWidth
          required
          disabled={!props.editMode}
          autoComplete="zip_code"
          name="zip_code"
          label="Postleitzahl"
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <CustomTextField
          fullWidth
          required
          disabled={!props.editMode}
          autoComplete="city"
          name="city"
          label="Stadt"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomTextField
          fullWidth
          disabled={!props.editMode}
          autoComplete="phone"
          name="phone"
          label="Telefon"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomTextField
          fullWidth
          required
          disabled={!props.editMode}
          autoComplete="email"
          name="email"
          label="E-Mail-Adresse"
          type="email"
        />
      </Grid>
    </>
  );
};

export default BillingAddressForm;
