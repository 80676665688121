import { targetGroupService } from "./../../api/index";
import { ErrorAction } from "../alerts/types";
import {
  TargetGroupActionTypes,
  TARGET_GROUP_REQUEST,
  TARGET_GROUP_FAILURE,
  TargetGroupsFailureAction,
  TargetGroupsRequestAction,
  LoadTargetGroupsAction,
  LOAD_TARGET_GROUP_DATA_SUCCESS,
} from "./types";
import { TargetGroupListData } from "../../models/targetGroupData";
import { Dispatch } from "redux";

export function getTargetGroups() {
  return (dispatch: Dispatch<TargetGroupActionTypes | ErrorAction>) => {
    dispatch(targetGroupRequest());

    Promise.all([
      targetGroupService.getParticipationTypes(),
      targetGroupService.getSpecialFields(""),
      targetGroupService.getCoverageAreas(),
    ])
      .then(([participationTypes, specialFields, coverageAreas]) => {
        dispatch(
          loadTargetGroupDataSuccess({
            participation_types: participationTypes,
            special_fields: specialFields,
            coverage_areas: coverageAreas,
          })
        );
      })
      .catch(() => {
        dispatch(targetGroupFailure());
      });
  };
}

export function loadTargetGroupDataSuccess(
  data: TargetGroupListData
): LoadTargetGroupsAction {
  return {
    type: LOAD_TARGET_GROUP_DATA_SUCCESS,
    data,
  };
}

export function targetGroupRequest(): TargetGroupsRequestAction {
  return {
    type: TARGET_GROUP_REQUEST,
  };
}
export function targetGroupFailure(): TargetGroupsFailureAction {
  return {
    type: TARGET_GROUP_FAILURE,
  };
}
