import { Button, createStyles, Grid, makeStyles } from "@material-ui/core";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TrainingType } from "../../../../models/enums/trainingType.enum";
import { error } from "../../../../redux/alerts/actions";
import { addDocument } from "../../../../redux/documents/actions";
import "./Documents.scss";

const useStyles = makeStyles((theme) =>
  createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
    },
  })
);

interface UploadAreaProps {
  defaultDocuments?: boolean;
  trainingType?: TrainingType;
}

const MAX_SCORM_FILE_SIZE_IN_BYTES = 2 * 1024 * 1024 * 1024; // equals 2 GB

export const UploadArea: React.FC<UploadAreaProps> = ({
  trainingType = TrainingType.DefaultEvent,
  ...props
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [uploadFiles, setUploadFiles] = useState<FileObject[]>([]);

  let documentUploadList: FormData[] = [];
  const acceptedFiles =
    trainingType === TrainingType.DefaultEvent
      ? [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]
      : [
          "application/zip",
          "application/x-zip",
          "application/x-zip-compressed",
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];

  return (
    <>
      <Grid item xs={12} sm={8}>
        <DropzoneAreaBase
          dropzoneText={`Hier klicken oder Dateien (PDF, Word, Excel${
            trainingType !== TrainingType.DefaultEvent
              ? ", SCORM (max. 2GB)"
              : ""
          }) ablegen`}
          maxFileSize={MAX_SCORM_FILE_SIZE_IN_BYTES}
          acceptedFiles={acceptedFiles}
          fileObjects={uploadFiles}
          onAdd={(newFileObjs) => {
            let combineArray: FileObject[] = [];
            setUploadFiles(combineArray.concat(uploadFiles, newFileObjs));
          }}
          onDelete={(deleteFileObj) => {
            let index = uploadFiles.indexOf(deleteFileObj);
            uploadFiles.splice(index, 1);
            setUploadFiles([...uploadFiles]);
          }}
          onDropRejected={() => {
            dispatch(
              error(
                {
                  message: `Dieser Dateityp wird nicht unterstützt. Es können nur PDF-, Excel-${
                    trainingType === TrainingType.DefaultEvent
                      ? " und Word-Dateien"
                      : ", Word- und SCORM-Dateien"
                  } hochgeladen werden.`,
                },
                5000
              )
            );
          }}
          showAlerts={false}
          showPreviews={true}
          showPreviewsInDropzone={false}
          useChipsForPreview
          previewGridProps={{
            container: { spacing: 1, direction: "row" },
          }}
          previewChipProps={{
            classes: { root: classes.previewChip },
          }}
          previewText="Ausgewählte Dateien"
        />
      </Grid>
      <Grid item sm={4} />
      <Grid item xs={12} sm={3}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          disabled={uploadFiles.length <= 0}
          onClick={() => {
            documentUploadList = [];
            uploadFiles.forEach((file) => {
              let data: FormData = new FormData();
              data.append("file", file.file);
              if (props.defaultDocuments) {
                data.append("is_default_on_site", "true");
                data.append("is_default_online", "true");
                data.append("publish_participant", "true");
                data.append("publish_speaker", "true");
              } else {
                data.append("is_default_on_site", "false");
                data.append("is_default_online", "false");
              }
              data.append("publish_after_event", "false");
              documentUploadList.push(data);
            });
            setUploadFiles([]);
            dispatch(addDocument(documentUploadList));
          }}
        >
          Hochladen
        </Button>
      </Grid>
    </>
  );
};
