import {
  Badge,
  Button,
  IconButton,
  Paper,
  Popper,
  Dialog,
  PopperPlacementType,
  Slide,
  ClickAwayListener,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import "./Cart.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux";
import HeadingKvh from "../../theming/HeadingKvh";
import { KvhCartSvg } from "../../../assets/icons/custom-svg-components/KvhSvgCollection";
import CartList from "./CartList";
import routes from "../../routing/routes";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { getCart } from "../../../redux/cart/actions";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EmptyCartButton from "./EmptyCartButton";

const Cart: React.FC = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const cart = useSelector((state: AppState) => state.cart);
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const isUserLoggedIn = useSelector((state: AppState) => state.auth.loggedIn);
  const dispatch = useDispatch();

  const handleClick = (newPlacement: PopperPlacementType) => (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  useEffect(() => {
    if (!cart.cartInitialLoaded && isUserLoggedIn) {
      dispatch(getCart());
    }
  }, [cart.cartInitialLoaded, dispatch, isUserLoggedIn]);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    if (open && sm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
  }, [open, sm]);

  return (
    <>
      <Box
        component={"div"}
        className={
          accessibility.monoMode
            ? "shopping-cart-icon-mono"
            : "shopping-cart-icon"
        }
      >
        <Badge
          badgeContent={cart.cartList.length}
          color="primary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <IconButton aria-label="cart" onClick={handleClick("bottom-end")}>
            <KvhCartSvg />
          </IconButton>
        </Badge>
      </Box>

      {[
        <Paper className={"shopping-cart-wrapper"} elevation={3}>
          <Box>
            <HeadingKvh>Warenkorb</HeadingKvh>
            <CartList />
            <Box
              component={"div"}
              className={"shopping-cart-bookingbutton-container"}
            >
              <EmptyCartButton cartList={cart.cartList} />
              <Button
                size="medium"
                variant="contained"
                color="secondary"
                onClick={handleClick("bottom-end")}
                style={{ marginRight: "20px" }}
              >
                Schliessen
              </Button>
              <Button
                size="medium"
                variant="contained"
                color="primary"
                disabled={cart.cartList.length === 0}
                onClick={() => {
                  document.body.style.removeProperty("overflow");
                  history.push(routes.checkout);
                }}
              >
                Zum Checkout
              </Button>
            </Box>
          </Box>
        </Paper>,
      ].map((paper) =>
        sm ? (
          <ClickAwayListener
            key={paper.key}
            onClickAway={() => {
              /* intercept touch */
            }}
          >
            <Dialog open={open} fullScreen={xs}>
              {paper}
            </Dialog>
          </ClickAwayListener>
        ) : (
          <Popper
            key={paper.key}
            open={open}
            anchorEl={anchorEl}
            placement={placement}
            style={{ zIndex: 600, overflow: "hidden" }}
            transition
          >
            {({ TransitionProps }) => (
              <Slide direction="left" {...TransitionProps} timeout={350}>
                {paper}
              </Slide>
            )}
          </Popper>
        )
      )}
    </>
  );
};

export default Cart;
