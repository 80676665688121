import { Dispatch } from "redux";
import { LAEKHData } from "../../models/laekhData";
import { ErrorAction } from "../alerts/types";
import { laekhService } from "./../../api/index";
import {
  LAEKHActionTypes,
  LAEKHFailureAction,
  LAEKHRequestAction,
  LAEKH_FAILURE,
  LAEKH_REQUEST,
  LoadLAEKHDataAction,
  LOAD_LAEKH_DATA_SUCCESS,
} from "./types";

export function getLAEKHData() {
  return (dispatch: Dispatch<LAEKHActionTypes | ErrorAction>) => {
    dispatch(loadLAEKHDataRequest());

    Promise.all([
      laekhService.getLAEKHCategories(),
      laekhService.getLAEKHFormIds(),
    ])
      .then(([categories, formIds]) => {
        dispatch(
          loadLAEKHDataSuccess({
            categories: categories,
            formIds: formIds,
          })
        );
      })
      .catch(() => {
        dispatch(loadLAEKHDataFailure());
      });
  };
}

export function loadLAEKHDataSuccess(data: LAEKHData): LoadLAEKHDataAction {
  return {
    type: LOAD_LAEKH_DATA_SUCCESS,
    data,
  };
}

export function loadLAEKHDataRequest(): LAEKHRequestAction {
  return {
    type: LAEKH_REQUEST,
  };
}
export function loadLAEKHDataFailure(): LAEKHFailureAction {
  return {
    type: LAEKH_FAILURE,
  };
}
