import styles from "./../../styles/custom-styles.module.scss";

export enum ELearningProgressStatus {
  Open = "Offen",
  InProgress = "In Bearbeitung",
  Done = "Abgeschlossen",
}

export function mapCountToProgressStatus(
  count: number,
  trainingPassed: boolean
) {
  if (count === 0) {
    return ELearningProgressStatus.Open;
  } else if (count > 0) {
    if (trainingPassed) {
      return ELearningProgressStatus.Done;
    }
    return ELearningProgressStatus.InProgress;
  }
  return ELearningProgressStatus.Open;
}

export function mapToProgress(status: ELearningProgressStatus) {
  if (status === ELearningProgressStatus.Open) {
    return 0;
  } else if (status === ELearningProgressStatus.InProgress) {
    return 50;
  } else {
    return 100;
  }
}

export function getProgressColor(progress: ELearningProgressStatus) {
  if (progress === ELearningProgressStatus.InProgress) return styles.primary;
  else if (progress === ELearningProgressStatus.Open) return styles.orange;
  else return styles.green;
}
