import { ParticipantListData } from "../models/participantData";

export const pdfParticipantTestData: ParticipantListData[] = [
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61,
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
  {
    event: "01c12d9d-566b-45db-89f3-5fde2ba9da76",
    user: {
      guid: "6729eb75-8b98-4fff-96a7-8c88a5d2e7c0",
      fullname: "Harald Schäfer",
      academic_title: "Dr.",
      first_name: "Harald",
      last_name: "Schäfer",
      email: "t.herr@test.de",
      lanr: "1234567",
      bsnr: null,
      efn: "",
    },
    status: 20,
    created_on: new Date("2024-01-04T10:55:05.965992+01:00"),
    last_changed_on: new Date("2024-01-04T11:11:12.897830+01:00"),
    last_changed_by: "Test_User1",
    changeStatus: true,
    catering_option: 0,
    user_has_clicked_evaluation_link: false,
    elearning_status: 61, // jojoj
    elearning_started_count: 2,
    overall_end: new Date("2024-01-04T10:55:05.965992+01:00"),
    reactivated: false,
    is_within_elearning_period: true,
    guid: "ba56bc62-6c51-4233-8f9b-a61c8b816a40",
  },
];
