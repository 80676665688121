import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { KvhTargetGroupSvg } from "../../../../../assets/icons/custom-svg-components/KvhSvgCollection";
import { TargetGroupIdData } from "../../../../../models/targetGroupData";
import { AppState } from "../../../../../redux";
import styles from "../../../../../styles/custom-styles.module.scss";
import { Link } from "@material-ui/core";
import { useGroupMapping } from "./EventGroupMappers";

interface ITargetGroupListProps {
  targetGroups: TargetGroupIdData;
}

interface ShowMoreProps {
  content: string;
}

const ShowMore: React.FC<ShowMoreProps> = ({ content }) => {
  const [showAll, setShowAll] = useState(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const accessibility = useSelector((state: AppState) => state.accessibility);
  // Toggle between showAll and showLess states
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  // Display all items or only the first two based on the showAll state
  const displayedContent =
    showAll || content.split(", ").length <= 2
      ? content
      : content.split(", ").slice(0, 2).join(", ");

  return (
    <>
      <Box component="span" display="flex" alignItems="center">
        <KvhTargetGroupSvg />
        <Box component="span" display="block" alignItems="flex-start">
          {displayedContent}
          {/* Show More/Show Less button */}
          {content.split(", ").length > 2 && (
            <Link
              onMouseEnter={() => {
                setIsHovered(true);
              }}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
              style={{
                color: accessibility.monoMode
                  ? "#000"
                  : isHovered
                  ? styles.primary
                  : styles.berry,
                cursor: "pointer",
                marginLeft: "4px",
                display: "inline",
              }}
              onClick={toggleShowAll}
            >
              {"..."} {showAll ? "Weniger anzeigen" : "Mehr anzeigen"}
            </Link>
          )}
        </Box>
      </Box>
    </>
  );
};

const TargetGroupListEventOverview: React.FC<ITargetGroupListProps> = ({
  targetGroups,
}) => {
  const {
    mapTargetGroupIdToString,
    mapSpecialFieldIdToString,
    mapCoverageAreaIdToString,
  } = useGroupMapping();

  // Combine all relevant parts of targetGroups into one string
  const allContent = [
    ...targetGroups.participation_types.map((id) =>
      mapTargetGroupIdToString(id)
    ),
    ...targetGroups.special_fields.map((id) => mapSpecialFieldIdToString(id)),
    ...targetGroups.coverage_areas.map((id) => mapCoverageAreaIdToString(id)),
  ]
    .filter((item) => item.length > 0)
    .join(", ");

  return <ShowMore content={allContent} />;
};

export default TargetGroupListEventOverview;
