export const MAIN_URL =
  process.env.REACT_APP_KVH_MAIN_URL || "https://www.kvhessen.de";
export const EVENTS_2021 = `${MAIN_URL}/termine-fortbildung/fortbildung/`; // TODO: remove?
export const PRIVACY_URL = `${MAIN_URL}/datenschutz/`;
export const IMPRINT_URL = `${MAIN_URL}/impressum/`;
export const TERMS_AND_CONDITIONS_URL = `${MAIN_URL}/nutzungsbedingungen/`;
export const LOGIN_URL = `${MAIN_URL}/login/`;
export const MY_ACCOUNT_URL = `${MAIN_URL}/mein-konto`;
export const DOCTORS_OFFICE_LANR = `${MAIN_URL}/praxismanagement/arzt-psychotherapeutenregister/`;
export const CHANGE_ALIAS = `${MY_ACCOUNT_URL}/alias-vergeben-aendern/`;
export const REGISTER_URL = `${MY_ACCOUNT_URL}/registrieren/`;
export const FORGOT_PASSWORD_URL = `${MY_ACCOUNT_URL}/passwort-vergessen/`;
export const CHANGE_PASSWORD_URL = `${MY_ACCOUNT_URL}/passwort-aendern/`;
