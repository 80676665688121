import { Pagination } from "../../models/pagination";
import { PinboardListData } from "../../models/pinboardData";

export interface PinboardState {
  pinboardList: Pagination<PinboardListData>;
  pinboardListLoaded: boolean;
  pinboard: PinboardListData;
  isPinboardOpen: boolean;
  pinboardLoaded: boolean;
  currentPage: number;
  isLoading: boolean;
  error: boolean;
}

export const PINBOARD_REQUEST = "PINBOARD_REQUEST";
export const PINBOARD_SUCCESS = "PINBOARD_SUCCESS";
export const PINBOARD_FAILURE = "PINBOARD_FAILURE";
export const PINBOARD_PAGE_COUNT = "PINBOARD_PAGE_COUNT";
export const PINBOARD_TOGGLE_OPEN_STATE = "PINBOARD_TOGGLE_OPEN_STATE";
export const PINBOARD_CLEAR = "PINBOARD_CLEAR";

export interface PinboardRequestAction {
  type: typeof PINBOARD_REQUEST;
}

export interface PinboardSuccessAction {
  type: typeof PINBOARD_SUCCESS;
  data: Pagination<PinboardListData>;
}

export interface PinboardFailureAction {
  type: typeof PINBOARD_FAILURE;
}

export interface PinboardPageCountAction {
  type: typeof PINBOARD_PAGE_COUNT;
  pageCount: number;
}

export interface PinboardToggleOpenStateAction {
  type: typeof PINBOARD_TOGGLE_OPEN_STATE;
}

export interface PinboardClearAction {
  type: typeof PINBOARD_CLEAR;
}

export type PinboardActionTypes =
  | PinboardRequestAction
  | PinboardSuccessAction
  | PinboardFailureAction
  | PinboardPageCountAction
  | PinboardToggleOpenStateAction
  | PinboardClearAction;
