export interface AlertState {
  alert: AlertBody;
  delay: number;
  show: boolean;
}

export interface AlertBody {
  message?: string;
  type?: "error" | "success" | "info" | undefined;
}

export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const INFO = "INFO";
export const CLEAR = "CLEAR";

export interface SuccessAction {
  type: typeof SUCCESS;
  alert: AlertBody;
  delay?: number;
}

export interface ErrorAction {
  type: typeof ERROR;
  alert: AlertBody;
  delay?: number;
}

export interface InfoAction {
  type: typeof INFO;
  alert: AlertBody;
  delay?: number;
}

export interface ClearAction {
  type: typeof CLEAR;
}

export type AlertActionTypes =
  | SuccessAction
  | ErrorAction
  | InfoAction
  | ClearAction;
