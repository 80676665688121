import React, { useEffect } from "react";
import moment from "moment";
import "./PreviewEvent.scss";
import { Box, Button, Divider, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux";
import { getDefaultTasksForEventPreview } from "../../../../../redux/templates/tasks/actions";
import { Field, Form, Formik } from "formik";
import { SimpleTaskData } from "../../../../../models/taskData";
import CustomTextField from "../../../../forms/CustomTextField";
import CustomDatepicker from "../../../../forms/CustomDatepicker";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import taskPreviewValidationSchema from "./TaskPreviewValidationSchema";
import { setPreview } from "../../../../../redux/preview/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import {
  mapToTrainingTypeString,
  mapTrainingTypeToArticleWithNounPossessiveCase,
  TrainingType,
} from "../../../../../models/enums/trainingType.enum";
import { RelatedTaskDeadline } from "../../../../../models/enums/relatedTaskDeadline.enum";

interface TaskPreviewProps {
  eventType: number;
  typeOfTrainingToBeCreated: TrainingType;
}

export const TaskPreview: React.FC<TaskPreviewProps> = (props) => {
  const dispatch = useDispatch();
  const defaultTasks = useSelector((state: AppState) => state.taskTemplates);
  const preview = useSelector((state: AppState) => state.preview);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  const [newTasksForEventList, setNewTasksForEventList] = React.useState<
    SimpleTaskData[]
  >([]);

  useEffect(() => {
    if (!defaultTasks.defaultTasksForEventPreviewLoaded) {
      dispatch(getDefaultTasksForEventPreview(props.eventType));
    }
  }, [
    dispatch,
    defaultTasks.defaultTasksForEventPreviewLoaded,
    props.eventType,
    newTasksForEventList,
    preview,
  ]);

  const renderDeadlineText = (
    deadline_days: number,
    related_deadline: number
  ) => {
    if (
      deadline_days === 0 &&
      related_deadline === RelatedTaskDeadline.Undefined
    ) {
      return "Bei Veranstaltungsbeginn";
    }
    if (related_deadline === RelatedTaskDeadline.Before) {
      return deadline_days + " Tage vor Veranstaltungsbeginn";
    }
    if (related_deadline === RelatedTaskDeadline.After) {
      return deadline_days + " Tage nach Veranstaltungsbeginn";
    }
    if (related_deadline === RelatedTaskDeadline.On_Cancelled) {
      return "Bei Stornierung";
    }
  };

  const trainingType = mapToTrainingTypeString(props.typeOfTrainingToBeCreated);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <h3>
            Aufgaben{" "}
            {mapTrainingTypeToArticleWithNounPossessiveCase(
              props.typeOfTrainingToBeCreated,
              trainingType
            )}
          </h3>
        </Grid>
      </Grid>
      <Grid container spacing={0} className={"preview-task-list-header"}>
        <Grid item xs={8}>
          <b>Beschreibung</b>
        </Grid>
        <Grid item xs={3}>
          <b>Fälligkeit</b>
        </Grid>
        <Grid item xs={1}>
          <b>
            {props.typeOfTrainingToBeCreated === TrainingType.ELearning
              ? ""
              : "Standard"}
          </b>
        </Grid>
      </Grid>
      {props.typeOfTrainingToBeCreated !== TrainingType.ELearning && (
        <>
          {defaultTasks.defaultTasksForEventPreview
            .filter(
              (task) =>
                task.event_related_deadline !== RelatedTaskDeadline.On_Cancelled
            )
            .map((task, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid
                    container
                    spacing={0}
                    className={"preview-task-list-item"}
                  >
                    <Grid item xs={8}>
                      {task.description}
                    </Grid>
                    <Grid item xs={3}>
                      {renderDeadlineText(
                        task.default_deadline_in_days,
                        task.event_related_deadline
                      )}
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        size={"2x"}
                        color={accessibility.monoMode ? "#000" : "#95e917"}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                </React.Fragment>
              );
            })}
        </>
      )}
      {props.typeOfTrainingToBeCreated === TrainingType.ELearning &&
        newTasksForEventList.length === 0 && (
          <Box mt={2} mb={3} display="flex" justifyContent="center">
            Dem {trainingType} wurden noch keine individuellen Aufgaben
            hinzugefügt
          </Box>
        )}
      {newTasksForEventList.map((task, index) => {
        return (
          <React.Fragment key={index}>
            <Grid container spacing={0} className={"preview-task-list-item"}>
              <Grid item xs={8}>
                {task.description}
              </Grid>
              <Grid item xs={3}>
                {task.due_date.toDate().toLocaleDateString()}
              </Grid>
              <Grid item xs={1} style={{ textAlign: "center" }}>
                <DeleteIcon
                  className={
                    accessibility.monoMode
                      ? "preview-task-list-item-delButton-mono"
                      : "preview-task-list-item-delButton"
                  }
                  onClick={() => {
                    let index = newTasksForEventList.indexOf(task);
                    newTasksForEventList.splice(index, 1);
                    setNewTasksForEventList([...newTasksForEventList]);
                    dispatch(
                      setPreview({
                        ...preview.previewEvent,
                        tasks: [...newTasksForEventList],
                      })
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Divider />
          </React.Fragment>
        );
      })}
      <Grid container style={{ margin: "20px 0" }}>
        <Grid item xs={12}>
          <b>
            Aufgabe{" "}
            {props.typeOfTrainingToBeCreated === TrainingType.DefaultEvent
              ? "der"
              : "dem"}{" "}
            {trainingType} hinzufügen
          </b>
        </Grid>
      </Grid>
      <Formik
        onSubmit={(data: SimpleTaskData) => {
          setNewTasksForEventList([...newTasksForEventList, data]);
          dispatch(
            setPreview({
              ...preview.previewEvent,
              tasks: [...newTasksForEventList, data],
            })
          );
        }}
        enableReinitialize
        validationSchema={taskPreviewValidationSchema}
        initialValues={{
          description: "",
          due_date: moment(),
        }}
      >
        {() => (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={8}>
                <CustomTextField
                  fullWidth
                  autoComplete="description"
                  name="description"
                  required
                  label="Aufgabenbeschreibung"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  as={CustomDatepicker}
                  fullWidth
                  id="due_date"
                  label="Fälligkeitsdatum"
                  name="due_date"
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  size="large"
                  style={{ height: "100%" }}
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  <AddIcon />
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
