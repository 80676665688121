import {
  AlertBody,
  CLEAR,
  ClearAction,
  ERROR,
  ErrorAction,
  INFO,
  InfoAction,
  SUCCESS,
  SuccessAction,
} from "./types";

export function success(alert: AlertBody, delay?: number): SuccessAction {
  return { type: SUCCESS, alert, delay };
}

export function error(alert: AlertBody, delay?: number): ErrorAction {
  return { type: ERROR, alert, delay };
}

export function info(alert: AlertBody, delay?: number): InfoAction {
  return { type: INFO, alert, delay };
}

export function clear(): ClearAction {
  return { type: CLEAR };
}
