import { Grid } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ELearningFormData } from "../../../../models/elearning";
import { EventStatus } from "../../../../models/enums/eventStatus.enum";
import {
  EventType,
  mapToEventTypeKeyString,
} from "../../../../models/enums/eventType.enum";
import { EventFormData } from "../../../../models/eventData";
import { AppState } from "../../../../redux";
import {
  addDefaultDocuments,
  getDocumentsByEvent,
  setDocumentsLoaded,
} from "../../../../redux/documents/actions";
import { Utils } from "../../../../services/utils";
import CustomCheckbox from "../../../forms/CustomCheckbox";
import CustomDatepicker from "../../../forms/CustomDatepicker";
import CustomTextField from "../../../forms/CustomTextField";
import CustomTimepicker from "../../../forms/CustomTimepicker";
import CustomSelect from "../../../forms/selects/CustomSelect";

export const Dates: React.FC = () => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();
  const isEditPage = id ? true : false;

  const { setFieldValue, values } = useFormikContext<
    EventFormData | ELearningFormData
  >();

  const documents = useSelector((state: AppState) => state.documents);

  useEffect(() => {
    if (isEditPage) {
      if (
        documents.documentList.length === 0 &&
        !documents.documentListLoaded
      ) {
        const isValidGuid = Utils.isValidGUIDv4(id);
        if (isValidGuid) {
          dispatch(getDocumentsByEvent(id, false));
          dispatch(setDocumentsLoaded());
        }
      }
    }
  }, [dispatch, documents, id, isEditPage]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={4}>
        <CustomSelect
          name="eventType"
          id="eventtype"
          required={values.status === EventStatus.Published}
          disabled={values.status === EventStatus.Completed}
          label="Art des Vortrags"
          labelId="event-location-type-label"
          defaultValueForEmptyElement={0}
          onChange={(
            e: React.ChangeEvent<{ name?: string; value: unknown }>
          ) => {
            const currentEventType = values.eventType;
            const currentLocation = values.location;
            setFieldValue("eventType", e.target.value);
            if (e.target.value === EventType.OnSite) {
              dispatch(
                addDefaultDocuments(mapToEventTypeKeyString(EventType.OnSite))
              );
              if (
                currentEventType === EventType.Online ||
                values.location.includes("Online-Veranstaltung")
              ) {
                setFieldValue("location", "");
              } else if (currentEventType === EventType.Undefined) {
                setFieldValue("location", currentLocation);
              }
            } else if (e.target.value === EventType.Online) {
              dispatch(
                addDefaultDocuments(mapToEventTypeKeyString(EventType.Online))
              );
              setFieldValue("location", "Online-Veranstaltung");
            }
          }}
          options={[
            { key: "prasenz", value: EventType.OnSite, label: "Präsenz" },
            { key: "online", value: EventType.Online, label: "Online" },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <CustomTextField
          required={values.status === EventStatus.Published}
          disabled={values.status === EventStatus.Completed}
          fullWidth
          name="location"
          id="location"
          label="Veranstaltungsort"
        />
      </Grid>

      {values.eventType === EventType.OnSite && (
        <>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              disabled={values.status === EventStatus.Completed}
              required={values.status === EventStatus.Published}
              fullWidth
              name="city"
              id="city"
              label="Stadt"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              disabled={values.status === EventStatus.Completed}
              required={values.status === EventStatus.Published}
              fullWidth
              name="zip_code"
              id="zip_code"
              label="Postleitzahl"
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <CustomTextField
          fullWidth
          disabled={values.status === EventStatus.Completed}
          multiline={true}
          style={{ whiteSpace: "pre-wrap" }}
          rows={5}
          placeholder={
            values.eventType === EventType.OnSite
              ? "z.B. Angaben zu Straße, Etage, Raum etc."
              : "z.B. Zoomlink zu Online-Veranstaltung"
          }
          name="locationDetails"
          id="locationDetails"
          label="Details zum Veranstaltungsort"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={CustomDatepicker}
          fullWidth
          disabled={values.status === EventStatus.Completed}
          required={values.status === EventStatus.Published}
          id="beginDate"
          label="Startdatum"
          name="beginDate"
          customChangeHandler={(date: MaterialUiPickersDate) => {
            setFieldValue("beginDate", date, false);
            if (
              values.endDate &&
              !values.endDate.isAfter(date) &&
              date?.isValid()
            ) {
              setFieldValue("endDate", date, false);
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={CustomDatepicker}
          fullWidth
          disabled={values.status === EventStatus.Completed}
          required={values.status === EventStatus.Published}
          id="endDate"
          label="Enddatum"
          name="endDate"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={CustomTimepicker}
          fullWidth
          disabled={values.status === EventStatus.Completed}
          required={values.status === EventStatus.Published}
          id="event-start-time-picker"
          label="Startzeit"
          name="beginTime"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          as={CustomTimepicker}
          fullWidth
          disabled={values.status === EventStatus.Completed}
          required={values.status === EventStatus.Published}
          id="event-end-time-picker"
          label="Endzeit"
          name="endTime"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomCheckbox
          disabled={values.status === EventStatus.Completed}
          name="catering"
          label={<>Es wird ein Catering angeboten</>}
        />
      </Grid>
    </Grid>
  );
};
