import {
  LAEKHActionTypes,
  LAEKHState,
  LAEKH_FAILURE,
  LAEKH_REQUEST,
  LOAD_LAEKH_DATA_SUCCESS,
} from "./types";

export const initialLAEKHState: LAEKHState = {
  laekhData: {
    categories: [],
    formIds: [],
  },
  isLoading: false,
};

export function laekhReducer(
  state = initialLAEKHState,
  action: LAEKHActionTypes
): LAEKHState {
  switch (action.type) {
    case LAEKH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_LAEKH_DATA_SUCCESS:
      return {
        ...state,
        laekhData: action.data,
        isLoading: false,
      };
    case LAEKH_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default laekhReducer;
