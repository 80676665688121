import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Button,
  DialogActions,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import React from "react";
import { Cookies } from "../../../../services/cookies";
import CustomCheckbox from "../../../forms/CustomCheckbox";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

export const CookieConsent: React.FC = () => {
  let acceptedSystemCookies = Cookies.get("allow_system_cookies") === "true";
  let acceptedStatCookies = Cookies.get("allow_stats_cookies") === "true";
  const [showCookieConsent, setShowCookieConsent] = React.useState(
    !acceptedSystemCookies
  );

  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={() => setShowCookieConsent(true)}
        style={{
          position: "relative",
          left: "0",
          bottom: "150px",
          padding: "4px",
        }}
      >
        <ReportProblemOutlinedIcon />
      </Button>
      <Dialog maxWidth={"lg"} fullWidth={true} open={showCookieConsent}>
        <Formik
          onSubmit={(data) => {
            Cookies.set(
              "allow_system_cookies",
              data.systemcookies ? "true" : "false"
            );
            Cookies.set(
              "allow_stats_cookies",
              data.statistics ? "true" : "false"
            );
            setShowCookieConsent(false);
          }}
          initialValues={{
            systemcookies: true,
            statistics: acceptedStatCookies,
          }}
        >
          {(formikProps) => {
            const handleAccept = () => {
              formikProps.submitForm();
            };

            const handleAcceptAll = () => {
              formikProps.setFieldValue("statistics", true);
              formikProps.submitForm();
            };

            return (
              <Form>
                <DialogTitle>Cookie-Benachrichtigung</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Wir verwenden Cookies, die für den Betrieb der Webseite
                    erforderlich sind. Zusätzliche Cookies werden nur mit Ihrer
                    Einwilligung verwendet. Wir verwenden sie, um die
                    Benutzerfreundlichkeit unserer Webseite zu verbessern und
                    Inhalte zu personalisieren, unsere Angebote zu optimieren
                    und die Zugriffe auf unsere Webseite zu analysieren. Sie
                    können Ihre Einwilligung jederzeit mit Wirkung für die
                    Zukunft widerrufen. Weitere Informationen dazu und zu Ihren
                    Rechten finden Sie in unserer Datenschutzerklärung.
                  </DialogContentText>
                  <Box component="div" m={2}>
                    <Box component="div" py={2}>
                      <CustomCheckbox
                        disabled={true}
                        name={"systemcookies"}
                        label={
                          <>
                            <b style={{ color: "#777" }}>Notwendige Cookies</b>{" "}
                            helfen dabei, unsere Webseite nutzbar zu machen,
                            indem sie Grundfunktionen wie Seitennavigation und
                            Zugriff auf sichere Bereiche unserer Webseite
                            ermöglichen. Unsere Webseite kann ohne diese Cookies
                            nicht richtig funktionieren.
                          </>
                        }
                      />
                    </Box>
                    <Box component="div" py={2}>
                      <CustomCheckbox
                        name={"statistics"}
                        label={
                          <>
                            <b>Statistik-Cookies</b> helfen uns zu verstehen,
                            wie Besucher unsere Webseite nutzen, indem
                            Informationen anonym gesammelt und gemeldet werden.
                          </>
                        }
                      />
                    </Box>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    size="medium"
                    variant="contained"
                    color="secondary"
                    onClick={handleAccept}
                  >
                    Auswahl akzeptieren
                  </Button>
                  <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    autoFocus
                    onClick={handleAcceptAll}
                  >
                    Alle akzeptieren
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};
