import {
  DashboardActionTypes,
  DashboardState,
  DASHBOARD_CLEAR,
  DASHBOARD_FAILURE,
  DASHBOARD_PAGE_COUNT,
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
} from "./types";

export const REFRESH_RATE_DASHBOARD_IN_SECONDS = 1000;

export const initialDashboardState: DashboardState = {
  dashboardList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  dashboardListLoaded: false,
  currentPage: 1,
  isLoading: false,
  error: false,
};

export function dashboardReducer(
  state = initialDashboardState,
  action: DashboardActionTypes
): DashboardState {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardList: action.data,
        dashboardListLoaded: true,
        isLoading: false,
      };
    case DASHBOARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case DASHBOARD_PAGE_COUNT:
      return {
        ...state,
        currentPage: action.pageCount,
      };
    case DASHBOARD_CLEAR:
      return initialDashboardState;
    default:
      return state;
  }
}

export default dashboardReducer;
