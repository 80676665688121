import { Pagination } from "../../models/pagination";
import { FavoriteListData } from "../../models/favoriteData";

export interface FavoriteState {
  eventFavoriteList: Pagination<FavoriteListData>;
  eventFavoriteListLoaded: boolean;
  currentPage: number;
  nubmerOfFavorites: number;
  numberofFavoritesLoaded: boolean;
  isLoading: boolean;
  isLoadingCount: boolean;
}

// general actions
export const FAVORITE_REQUEST = "FAVORITE_REQUEST";
export const FAVORITE_COUNT_REQUEST = "FAVORITE_COUNT_REQUEST";
export const FAVORITE_FAILURE = "FAVORITE_FAILURE";

// favorite list
export const SET_LIST_OF_FAVORITES_SUCCESS = "SET_LIST_OF_FAVORITES_SUCCESS";
export const REFRESH_FAVORITES_LIST = "REFRESH_FAVORITES_LIST";

export const RELOAD_FAVORITES_LIST = "RELOAD_FAVORITES_LIST";

export const SET_FAVORITES_COUNT = "SET_FAVORITES_COUNT";
export const SET_FAVORITES_PAGE_COUNT = "SET_FAVORITES_PAGE_COUNT";

export const CLEAR_FAVORITES_LIST = "CLEAR_FAVORITES_LIST";

// actions that are the same for all api calls (request and failure)
export interface FavoriteRequestAction {
  type: typeof FAVORITE_REQUEST;
}
export interface FavoriteCountRequestAction {
  type: typeof FAVORITE_COUNT_REQUEST;
}

export interface FavoriteFailureAction {
  type: typeof FAVORITE_FAILURE;
}

// other favorite actions
export interface SetListOfFavoritesSuccessAction {
  type: typeof SET_LIST_OF_FAVORITES_SUCCESS;
  data: Pagination<FavoriteListData>;
}

export interface RefreshFavoriteListAction {
  type: typeof REFRESH_FAVORITES_LIST;
  add: boolean;
  eventId?: string;
}

export interface ReloadFavoriteListAction {
  type: typeof RELOAD_FAVORITES_LIST;
}

export interface SetPageCountAction {
  type: typeof SET_FAVORITES_PAGE_COUNT;
  pageCount: number;
}

export interface SetFavoritesCountAction {
  type: typeof SET_FAVORITES_COUNT;
  nubmerOfFavorites: number;
}

export interface ClearFavoriteListAction {
  type: typeof CLEAR_FAVORITES_LIST;
}

export type FavoriteActionTypes =
  | FavoriteRequestAction
  | FavoriteCountRequestAction
  | FavoriteFailureAction
  | SetListOfFavoritesSuccessAction
  | RefreshFavoriteListAction
  | ReloadFavoriteListAction
  | SetPageCountAction
  | SetFavoritesCountAction
  | ClearFavoriteListAction;
