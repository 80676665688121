import { Chip, Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import * as React from "react";
import { useEffect, useState } from "react";
import { targetGroupService } from "../../../../api";
import { CustomSelectArrow } from "../../../../assets/icons/custom-svg-components/KvhSvgCollection";
import { SpecialField } from "../../../../models/targetGroupData";
import CustomTextField from "../../../forms/CustomTextField";

interface SpecialFieldSearchProps {
  onChangeFields: (specialFields: SpecialField[]) => void;
  initialValues: SpecialField[];
  disabled?: boolean;
}

const SpecialFieldSearch: React.FC<SpecialFieldSearchProps> = (props) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchOptions, setSearchOptions] = useState<SpecialField[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>();

  const [autocompleteValue, setAutocompleteValue] = useState<SpecialField[]>(
    props.initialValues
  );
  const [editedSearch, setEditedSearch] = useState<boolean>(false);

  const handleSearchQueryChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setEditedSearch(true);
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let query = searchQuery;

      if (query != null) {
        (async () => {
          if (query === searchQuery && query.length > 0) {
            var results = await targetGroupService.getSpecialFields(
              "?search=" + query
            );
            setSearchOptions(results);
          }
        })();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery, setSearchOptions]);

  useEffect(() => {
    if (!searchOpen) {
      setSearchOptions([]);
    }
  }, [searchOpen]);

  useEffect(() => {
    if (!editedSearch && props.initialValues) {
      setAutocompleteValue(props.initialValues);
    }
  }, [editedSearch, setAutocompleteValue, props.initialValues]);

  return (
    <Autocomplete
      multiple
      limitTags={2}
      disabled={props.disabled}
      id="special-fields"
      options={searchOptions}
      noOptionsText={"Fachbereich eingeben"}
      // react shows loadingText if no options provided
      loadingText={"Fachbereich eingeben"}
      value={autocompleteValue}
      popupIcon={<CustomSelectArrow />}
      open={searchOpen}
      onOpen={() => {
        setSearchOpen(true);
      }}
      onClose={() => {
        setSearchOpen(false);
      }}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          return (
            <Chip
              variant="outlined"
              label={option.name}
              {...getTagProps({ index })}
            />
          );
        });
      }}
      // backend will filter the results
      filterOptions={(options, _) => {
        return options;
      }}
      onChange={(
        event: React.ChangeEvent<{}>,
        item: (string | SpecialField)[]
      ) => {
        if (typeof item !== "string") {
          const special_fields = item as SpecialField[];
          props.onChangeFields(
            special_fields.filter((option) => option.id !== undefined)
          );
          setAutocompleteValue(
            special_fields.filter((option) => option.id !== undefined)
          );
        }
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => {
        return typeof option !== "string" && option.id !== -1
          ? option.name + (option.name ? ` (#${option.kbv_code})` : "")
          : option.toString();
      }}
      renderOption={(option) => (
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            KBV-Code: {option.kbv_code}
            <br />
            {option.name}
          </Grid>
        </Grid>
      )}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          label="Fachbereich"
          name="selected_special_field"
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
      )}
    />
  );
};

export default SpecialFieldSearch;
