export enum EventType {
  Undefined = 0,
  OnSite = 10,
  Online = 20,
}

export enum InternalExternalEventType {
  Internal = 10,
  External = 20,
}

export function mapInternalExternalEventTypeToString(
  internalExternalEvenType: InternalExternalEventType
): string {
  switch (internalExternalEvenType) {
    case InternalExternalEventType.Internal:
      return "Intern";
    case InternalExternalEventType.External:
      return "Extern";
    default:
      return "";
  }
}

export function mapToEventTypeString(eventType?: EventType): string {
  switch (eventType) {
    case EventType.Undefined:
      return "";
    case EventType.OnSite:
      return "Präsenz-Veranstaltung";
    case EventType.Online:
      return "Online-Veranstaltung";
    default:
      return "";
  }
}

export function mapToEventTypeKeyString(eventType?: EventType): string {
  switch (eventType) {
    case EventType.Undefined:
      return "";
    case EventType.OnSite:
      return "onsite";
    case EventType.Online:
      return "online";
    default:
      return "";
  }
}
