import {
  Box,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Pagination as PaginationUI } from "@material-ui/lab";
import { Form, Formik } from "formik";
import * as React from "react";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BookingFilterData } from "../../../../models/bookingData";
import { mapToParticipationStatusesString } from "../../../../models/enums/participationStatus.enum";
import { BookingsSortBy } from "../../../../models/enums/sortBy.enum";
import { PAGE_SIZE } from "../../../../models/pagination";
import { AppState } from "../../../../redux";
import {
  getAllMyEvents,
  getAllMyEventsForPrint,
  setPageCount,
} from "../../../../redux/bookings/actions";
import { BookingFilterService } from "../../../../services/filters/bookingFilterService";
import HeadingKvh from "../../../theming/HeadingKvh";
import Loader from "../../../theming/loader/Loader";
import NoEventsFound from "../shared/NoEventsFound";
import BookingFilterForm from "./BookingFilterForm";
import { MyEventsPrintList } from "./MyEventsPrintList";
import { useReactToPrint } from "react-to-print";
import SvgIconBase from "../../../../assets/icons/custom-svg-components/SvgIconBase";
import { ReactComponent as Print } from "../../../../assets/icons/print.svg";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../../models/enums/trainingType.enum";
import MyEventTitleContent, { SUB_EVENTS } from "./MyEventTitleContent";
import { v4 as uuidv4 } from "uuid";
import { mapToEventTypeString } from "../../../../models/enums/eventType.enum";
import { getELearningDates } from "../../../../models/elearning";

const MyEvents: React.FC = () => {
  const dispatch = useDispatch();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const eventList = useSelector((state: AppState) => state.booking.myEventList);
  const eventListPrint = useSelector(
    (state: AppState) => state.booking.myEventListPrint
  );
  const userData = useSelector((state: AppState) => state.user.currentUser);
  const booking = useSelector((state: AppState) => state.booking);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setPageCount(value));
  };

  const getInitialFilterData = React.useCallback(() => {
    let initialFilterData: BookingFilterData = {
      event__training_type: "",
      status: [],
      sorting: BookingsSortBy.EndAscending,
    };

    // ! persist the filter state after page change and set as initial filter values
    const filterService = new BookingFilterService(
      booking.currentPage,
      initialFilterData
    );
    initialFilterData = filterService.parseFiltersFromUrl();

    return initialFilterData;
  }, [booking.currentPage]);

  const loadData = React.useCallback(() => {
    if (!booking.isLoading && !booking.myEventListLoaded) {
      dispatch(getAllMyEvents(booking.currentPage, getInitialFilterData()));
      dispatch(getAllMyEventsForPrint(getInitialFilterData()));
    }
  }, [
    booking.currentPage,
    dispatch,
    getInitialFilterData,
    booking.isLoading,
    booking.myEventListLoaded,
  ]);

  useEffect(loadData, [loadData]);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={10}>
          <HeadingKvh>Meine Buchungen</HeadingKvh>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            onClick={handlePrint}
            aria-label="print my events"
            style={{
              float: "right",
              cursor: "pointer",
            }}
            color={"inherit"}
          >
            <SvgIconBase
              style={{ stroke: "#3c3c3c", fontSize: "25px" }}
              component={Print}
            />
          </IconButton>
        </Grid>
      </Grid>
      {/*Printable version of myevent list */}
      <Box style={{ display: "none" }}>
        <MyEventsPrintList
          ref={componentRef}
          eventList={eventListPrint}
          userData={userData}
        />
      </Box>
      {/*End of Printable version of myevent list */}
      <Formik
        onSubmit={(data: BookingFilterData) => {}}
        enableReinitialize
        initialValues={getInitialFilterData()}
      >
        {() => (
          <Form>
            <BookingFilterForm page={booking.currentPage} />
          </Form>
        )}
      </Formik>

      <Grid container spacing={0} className={"myevents-list-header"}>
        <Hidden mdDown>
          <Grid item xs={1}>
            Kurs-Nr.
          </Grid>
        </Hidden>
        <Grid item xs={10} sm={7} md={4} lg={3}>
          <Hidden mdDown>Titel</Hidden>
          <Hidden lgUp>Veranstaltung</Hidden>
        </Grid>
        <Hidden smDown>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            Veranstaltungstermin
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            Bearbeitungszeitraum E-Learning
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            Veranstaltungstyp
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            Status
          </Grid>
        </Hidden>
      </Grid>
      {booking.isLoading ? (
        <Loader />
      ) : eventList.count === 0 ? (
        <NoEventsFound />
      ) : (
        eventList.results.map((booking) => {
          const { begin, end } = getELearningDates(booking);
          const eLearningDates = [
            TrainingType.ELearning,
            TrainingType.BlendedLearning,
          ].includes(booking.event.training_type)
            ? begin.toLocaleDateString() + "-" + end.toLocaleDateString()
            : "-";
          const when =
            booking.event.training_type === TrainingType.ELearning
              ? "-"
              : new Date(booking.event.begin).toLocaleDateString();
          const trainingType = [
            TrainingType.ELearning,
            TrainingType.BlendedLearning,
          ].includes(booking.event.training_type)
            ? `${mapToTrainingTypeString(booking.event.training_type)}`
            : "";
          const eventType = mapToEventTypeString(booking.event.event_type);
          const location =
            booking.event.location.length > 0 &&
            booking.event.location !==
              mapToEventTypeString(booking.event.event_type)
              ? ` (${booking.event.location})`
              : "";
          const where =
            `${eventType}${location}`.length > 0
              ? ` (${eventType}${location})`
              : "";
          // remove double-parenthesis
          let whatWhere = (trainingType + where).replaceAll("))", ")");
          // remove first parenthesis if no parenthesis are needed at all
          if (whatWhere.charAt(1) === "(")
            whatWhere = whatWhere.replace("(", "");
          // replace last opening parenthesis with dash separating event-type and location
          if ((whatWhere.match(/\(/g) || []).length > 1)
            // eslint-disable-next-line
            whatWhere = whatWhere.replace(/\(([^\()]+)\)$/, " - $1)");
          // remove the closing parenthesis which is missing an opening parenthesis
          if (
            (whatWhere.match(/\)/g) || []).length === 1 &&
            (whatWhere.match(/\(/g) || []).length === 0
          )
            whatWhere = whatWhere.replace(")", "");
          return (
            <React.Fragment key={uuidv4()}>
              <Grid container spacing={0} className={"myevents-list-item"}>
                <Hidden mdDown>
                  <Grid item xs={1}>
                    # {booking.event.public_id}
                  </Grid>
                </Hidden>
                <Grid item xs={10} sm={7} md={4} lg={3}>
                  <Hidden lgUp>
                    # {booking.event.public_id}
                    <br />
                  </Hidden>
                  <Hidden smDown>
                    <MyEventTitleContent
                      text={booking.event.title}
                      event={booking}
                    />
                  </Hidden>
                  <Hidden mdUp>
                    <b>Titel:</b>{" "}
                    <MyEventTitleContent
                      key={booking.event.guid}
                      text={booking.event.title}
                      event={booking}
                    />
                  </Hidden>
                  {booking.event.training_type ===
                    TrainingType.BlendedLearning && (
                    <Box component={"div"} style={{ display: "block" }}>
                      {SUB_EVENTS.map((subEvent) => (
                        <MyEventTitleContent
                          key={booking.event.guid}
                          text={subEvent}
                          event={booking}
                        />
                      ))}
                    </Box>
                  )}
                  <Hidden mdUp>
                    {when.length > 1 && (
                      <div>
                        <b>Veranstaltungstermin: </b> {when}
                      </div>
                    )}
                    {eLearningDates.length > 1 && (
                      <div>
                        <b>Bearbeitungszeitraum E-Learning: </b>{" "}
                        {eLearningDates}
                        {booking.reactivated && (
                          <>
                            <br />
                            <Typography variant="caption">
                              <strong>
                                (verlängert bis{" "}
                                {new Date(
                                  booking.overall_end
                                ).toLocaleDateString()}
                                )
                              </strong>
                            </Typography>
                          </>
                        )}
                      </div>
                    )}
                    <div>
                      <b>Veranstaltungstyp:</b> {whatWhere}
                    </div>
                  </Hidden>
                  <Hidden smUp>
                    <br /> <b>Status:</b>{" "}
                    {mapToParticipationStatusesString(booking.status)}
                  </Hidden>
                </Grid>
                <Hidden smDown>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {when}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {eLearningDates}
                    {booking.reactivated && (
                      <>
                        <br />
                        <Typography variant="caption">
                          <strong>
                            (verlängert bis{" "}
                            {new Date(booking.overall_end).toLocaleDateString()}
                            )
                          </strong>
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {whatWhere}
                  </Grid>
                </Hidden>
                <Hidden xsDown>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    {mapToParticipationStatusesString(booking.status)}
                  </Grid>
                </Hidden>
              </Grid>
              <Divider />
            </React.Fragment>
          );
        })
      )}

      <Grid container style={{ marginTop: "10px" }}>
        <Box component={"div"} style={{ margin: "auto" }}>
          <PaginationUI
            count={Math.ceil(eventList.count / PAGE_SIZE)}
            page={booking.currentPage}
            onChange={handleChange}
          />
        </Box>
      </Grid>
    </>
  );
};

export default MyEvents;
