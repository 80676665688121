import { LecturesData } from "../../models/lecturesData";
import { Pagination } from "../../models/pagination";

export interface LecturesState {
  lecturesList: Pagination<LecturesData>;
  lectureDetails: LecturesData;
  lectureDetailsLoaded: boolean;
  currentPage: number;
  isLoading: boolean;
}

// general actions
export const LECTURES_REQUEST = "LECTURES_REQUEST";
export const LECTURES_FAILURE = "LECTURES_FAILURE";

// lecture list
export const SET_LIST_OF_LECTURES_SUCCESS = "SET_LIST_OF_LECTURES_SUCCESS";
export const SET_LECTURE = "SET_LECTURE";
export const CLEAR_LECTURES = "CLEAR_LECTURES";
export const UPDATE_LECTURE = "UPDATE_LECTURE";
export const SET_LECTURES_PAGE_COUNT = "SET_LECTURES_PAGE_COUNT";

export interface LecturesRequestAction {
  type: typeof LECTURES_REQUEST;
}

export interface LecturesFailureAction {
  type: typeof LECTURES_FAILURE;
}

// other event actions
export interface SetListOfLecturesSuccessAction {
  type: typeof SET_LIST_OF_LECTURES_SUCCESS;
  data: Pagination<LecturesData>;
}

export interface SetLectureAction {
  type: typeof SET_LECTURE;
  data: LecturesData;
}

export interface ClearLecturesAction {
  type: typeof CLEAR_LECTURES;
}

export interface SetPageCountAction {
  type: typeof SET_LECTURES_PAGE_COUNT;
  pageCount: number;
}

export interface UpdateLectureAction {
  type: typeof UPDATE_LECTURE;
  userHasClickEvaluationLink: boolean;
}

export type LecturesActionTypes =
  | LecturesRequestAction
  | LecturesFailureAction
  | SetListOfLecturesSuccessAction
  | SetLectureAction
  | ClearLecturesAction
  | UpdateLectureAction
  | SetPageCountAction;
