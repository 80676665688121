import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import { KeyboardTimePicker, TimePickerProps } from "@material-ui/pickers";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { FieldAttributes, useField, useFormikContext } from "formik";
import * as React from "react";
import { EventFormData } from "../../models/eventData";

interface CustomTimepickerProps {
  value?: ParsableDate;
  customChangeHandler?: (
    time: MaterialUiPickersDate | null,
    value?: string | null
  ) => void;
}

const CustomTimepicker: React.FC<
  FieldAttributes<{}> & TimePickerProps & CustomTimepickerProps
> = ({ customChangeHandler, ...props }) => {
  const [field, meta] = useField<{}>(props);
  const { setFieldValue } = useFormikContext<EventFormData>();
  const error = meta.error && meta.touched ? meta.error : ""; // get validation error text

  const handleTimeChange = (
    time: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => {
    if (customChangeHandler) {
      customChangeHandler(time, value);
    } else {
      setFieldValue(field.name, time);
    }
  };

  return (
    <KeyboardTimePicker
      {...field}
      {...props}
      autoComplete="off"
      InputProps={{ required: false }}
      minutesStep={15}
      inputVariant="filled"
      variant="inline"
      ampm={false}
      helperText={error}
      error={!!error}
      onChange={handleTimeChange}
      keyboardIcon={<QueryBuilderIcon />}
      KeyboardButtonProps={{
        "aria-label": `change ${field.name}`,
      }}
    />
  );
};

export default CustomTimepicker;
