import { PinboardFormData } from "../../models/pinboardData";

export interface PinboardTopicState {
  postedQuestion: {
    title: string;
    post: string;
    event: string;
  };
  isLoading: boolean;
  error: boolean;
}

export const PINBOARD_POST_QUESTION = "PINBOARD_POST_QUESTION";
export const PINBOARD_POST_QUESTION_SUCCESS = "PINBOARD_POST_QUESTION_SUCCESS";
export const PINBOARD_POST_QUESTION_FAILURE = "PINBOARD_POST_QUESTION_FAILURE";
export const PINBOARD_UPDATE_QUESTION = "PINBOARD_UPDATE_QUESTION";
export const PINBOARD_UPDATE_QUESTION_SUCCESS =
  "PINBOARD_UPDATE_QUESTION_SUCCESS";
export const PINBOARD_UPDATE_QUESTION_FAILURE =
  "PINBOARD_UPDATE_QUESTION_FAILURE";

export interface PinboardPostQuestionAction {
  type: typeof PINBOARD_POST_QUESTION;
}

export interface PinboardPostSuccessAction {
  type: typeof PINBOARD_POST_QUESTION_SUCCESS;
  data: PinboardFormData;
}

export interface PinboardPostQuestionFailureAction {
  type: typeof PINBOARD_POST_QUESTION_FAILURE;
}

export interface PinboardUpdateQuestionAction {
  type: typeof PINBOARD_UPDATE_QUESTION;
}

export interface PinboardUpdateQuestionSuccessAction {
  type: typeof PINBOARD_UPDATE_QUESTION_SUCCESS;
  data: PinboardFormData;
}

export interface PinboardUpdateQuestionFailureAction {
  type: typeof PINBOARD_UPDATE_QUESTION_FAILURE;
}

export type PinboardQuestionActionTypes =
  | PinboardPostQuestionAction
  | PinboardPostSuccessAction
  | PinboardPostQuestionFailureAction
  | PinboardUpdateQuestionAction
  | PinboardUpdateQuestionSuccessAction
  | PinboardUpdateQuestionFailureAction;
