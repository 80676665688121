import { AppState } from "./../index";
import { Dispatch } from "redux";
import { favoriteService } from "../../api";
import { Pagination } from "../../models/pagination";

import {
  FAVORITE_REQUEST,
  FavoriteRequestAction,
  SET_LIST_OF_FAVORITES_SUCCESS,
  ClearFavoriteListAction,
  CLEAR_FAVORITES_LIST,
  FavoriteFailureAction,
  SetListOfFavoritesSuccessAction,
  FAVORITE_FAILURE,
  FavoriteActionTypes,
  RefreshFavoriteListAction,
  REFRESH_FAVORITES_LIST,
  ReloadFavoriteListAction,
  RELOAD_FAVORITES_LIST,
  SET_FAVORITES_PAGE_COUNT,
  SetPageCountAction,
  SET_FAVORITES_COUNT,
  SetFavoritesCountAction,
  FavoriteCountRequestAction,
  FAVORITE_COUNT_REQUEST,
} from "./types";
import { setEventFavorite } from "../events/actions";
import { EventActionTypes } from "../events/types";
import { ErrorAction } from "../alerts/types";
import { FavoriteListData } from "../../models/favoriteData";

export function getAllFavorites(page: number) {
  return (dispatch: Dispatch<FavoriteActionTypes | ErrorAction>) => {
    dispatch(favoriteRequest());
    favoriteService
      .getFavorites(page)
      .then((response: Pagination<FavoriteListData>) => {
        dispatch(setListOfFavoritesSuccess(response));
      })
      .catch(() => {
        dispatch(favoriteFailure());
      });
  };
}

export function getFavoritesCount() {
  return (dispatch: Dispatch<FavoriteActionTypes | ErrorAction>) => {
    dispatch(favoriteCountRequest());
    favoriteService
      .getFavoritesCount()
      .then((response: { count: number }) => {
        dispatch(setFavoriteCount(response.count));
      })
      .catch(() => {
        dispatch(favoriteFailure());
      });
  };
}

export function addFavorite(eventId: string) {
  return (
    dispatch: Dispatch<FavoriteActionTypes | EventActionTypes>,
    getState: () => AppState
  ) => {
    dispatch(favoriteRequest());
    let numberofFavorites = getState().favorite.nubmerOfFavorites;
    favoriteService
      .addFavorite(eventId)
      .then((response) => {
        numberofFavorites += 1;
        dispatch(setEventFavorite(eventId, true));
        dispatch(setFavoriteCount(numberofFavorites));
        dispatch(refreshFavoritesListSuccess(true));
      })
      .catch(() => {
        dispatch(favoriteFailure());
      });
  };
}

export function deleteFavorite(eventId: string) {
  return (
    dispatch: Dispatch<FavoriteActionTypes | EventActionTypes>,
    getState: () => AppState
  ) => {
    dispatch(favoriteRequest());
    let numberofFavorites = getState().favorite.nubmerOfFavorites;
    favoriteService
      .deleteFavorite(eventId)
      .then((response) => {
        numberofFavorites -= 1;
        dispatch(setEventFavorite(eventId, false));
        dispatch(setFavoriteCount(numberofFavorites));
        dispatch(refreshFavoritesListSuccess(false, eventId));
      })
      .catch(() => {
        dispatch(favoriteFailure());
      });
  };
}

export function favoriteRequest(): FavoriteRequestAction {
  return {
    type: FAVORITE_REQUEST,
  };
}
export function favoriteCountRequest(): FavoriteCountRequestAction {
  return {
    type: FAVORITE_COUNT_REQUEST,
  };
}

export function favoriteFailure(): FavoriteFailureAction {
  return {
    type: FAVORITE_FAILURE,
  };
}

export function setListOfFavoritesSuccess(
  data: Pagination<FavoriteListData>
): SetListOfFavoritesSuccessAction {
  return {
    type: SET_LIST_OF_FAVORITES_SUCCESS,
    data,
  };
}

export function refreshFavoritesListSuccess(
  add: boolean,
  eventId?: string
): RefreshFavoriteListAction {
  return {
    type: REFRESH_FAVORITES_LIST,
    add,
    eventId,
  };
}

export function reloadFavoriteList(): ReloadFavoriteListAction {
  return {
    type: RELOAD_FAVORITES_LIST,
  };
}

export function setPageCount(pageCount: number): SetPageCountAction {
  return {
    type: SET_FAVORITES_PAGE_COUNT,
    pageCount: pageCount,
  };
}

export function setFavoriteCount(
  nubmerOfFavorites: number
): SetFavoritesCountAction {
  return {
    type: SET_FAVORITES_COUNT,
    nubmerOfFavorites: nubmerOfFavorites,
  };
}

export function clearFavoriteList(): ClearFavoriteListAction {
  return {
    type: CLEAR_FAVORITES_LIST,
  };
}
