import { Box, Grid } from "@material-ui/core";
import { Pagination as PaginationUI } from "@material-ui/lab";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD_PAGE_SIZE } from "../../../../models/dashboardData";
import { AppState } from "../../../../redux";
import {
  dashboardPageCount,
  getDashboard,
} from "../../../../redux/dashboard/actions";
import { REFRESH_RATE_DASHBOARD_IN_SECONDS } from "../../../../redux/dashboard/reducers";
import useInterval from "../../../hooks/UseInterval";
import HeadingKvh from "../../../theming/HeadingKvh";
import DashboardItems from "./DashboardItems";

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const eLearnings = useSelector((state: AppState) => state.dashboard);

  useEffect(() => {
    // load initially one time
    if (!eLearnings.isLoading && !eLearnings.dashboardListLoaded) {
      dispatch(getDashboard(eLearnings.currentPage));
    }
  }, [
    dispatch,
    eLearnings.currentPage,
    eLearnings.isLoading,
    eLearnings.dashboardListLoaded,
  ]);

  useInterval(() => {
    dispatch(getDashboard(eLearnings.currentPage));
  }, REFRESH_RATE_DASHBOARD_IN_SECONDS * 5);

  return (
    <>
      <HeadingKvh>E-Learning</HeadingKvh>
      <DashboardItems />
      {eLearnings.dashboardList.count > 0 && (
        <Grid container style={{ marginTop: "10px" }}>
          <Box component={"div"} style={{ margin: "auto" }}>
            <PaginationUI
              count={Math.ceil(
                eLearnings.dashboardList.count / DASHBOARD_PAGE_SIZE
              )}
              page={eLearnings.currentPage}
              onChange={(event, value) => {
                dispatch(dashboardPageCount(value));
                dispatch(getDashboard(value));
              }}
            />
          </Box>
        </Grid>
      )}
    </>
  );
};

export default Dashboard;
