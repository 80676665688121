export enum UserType {
  Undefined = 0,
  Speaker = 10,
  Participant = 20,
  Employee = 30,
  Apprentice = 40,
  TestParticipant = 50,
  Admin = 90,
}

export function mapToUserTypeString(userType?: UserType): string {
  // TODO: maybe find other neutral terms for the roles
  switch (userType) {
    case UserType.Undefined:
      return "";
    case UserType.Speaker:
      return "Referent";
    case UserType.Participant:
      return "Mitglied";
    case UserType.Employee:
      return "KVH"; // instead of Mitarbeiter
    case UserType.Apprentice:
      return "Auszubildender";
    case UserType.Admin:
      return "Administrator";
    case UserType.TestParticipant:
      return "Test-Teilnehmer";
    default:
      return "";
  }
}
