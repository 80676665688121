import { PinboardCommentFormData } from "../../models/pinboardData";

export interface PinboardCommentState {
  postedAnswer: PinboardCommentFormData;
  isLoading: boolean;
  error: boolean;
}

export const PINBOARD_POST_ANSWER = "PINBOARD_POST_ANSWER";
export const PINBOARD_POST_ANSWER_SUCCESS = "PINBOARD_POST_ANSWER_SUCCESS";
export const PINBOARD_POST_ANSWER_FAILURE = "PINBOARD_POST_ANSWER_FAILURE";
export const PINBOARD_UPDATE_ANSWER = "PINBOARD_UPDATE_ANSWER";
export const PINBOARD_UPDATE_ANSWER_SUCCESS = "PINBOARD_UPDATE_ANSWER_SUCCESS";
export const PINBOARD_UPDATE_ANSWER_FAILURE = "PINBOARD_UPDATE_ANSWER_FAILURE";

export interface PinboardPostAnswerAction {
  type: typeof PINBOARD_POST_ANSWER;
}

export interface PinboardPostAnswerSuccessAction {
  type: typeof PINBOARD_POST_ANSWER_SUCCESS;
  data: PinboardCommentFormData;
}

export interface PinboardPostAnswerFailureAction {
  type: typeof PINBOARD_POST_ANSWER_FAILURE;
}

export interface PinboardUpdateAnswerAction {
  type: typeof PINBOARD_UPDATE_ANSWER;
}

export interface PinboardUpdateAnswerSuccessAction {
  type: typeof PINBOARD_UPDATE_ANSWER_SUCCESS;
  data: PinboardCommentFormData;
}

export interface PinboardUpdateAnswerFailureAction {
  type: typeof PINBOARD_UPDATE_ANSWER_FAILURE;
}

export type PinboardAnswerActionTypes =
  | PinboardPostAnswerAction
  | PinboardPostAnswerSuccessAction
  | PinboardPostAnswerFailureAction
  | PinboardUpdateAnswerAction
  | PinboardUpdateAnswerSuccessAction
  | PinboardUpdateAnswerFailureAction;
