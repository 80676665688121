import { LecturesData } from "../../models/lecturesData";
import {
  LecturesState,
  LecturesActionTypes,
  SET_LIST_OF_LECTURES_SUCCESS,
  SET_LECTURE,
  LECTURES_FAILURE,
  LECTURES_REQUEST,
  CLEAR_LECTURES,
  SET_LECTURES_PAGE_COUNT,
  UPDATE_LECTURE,
} from "./types";

export const initialLecturesState: LecturesState = {
  lecturesList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  lectureDetails: {
    guid: "",
    vnr: "",
    public_id: 0,
    title: "",
    location: "",
    location_details: "",
    begin: new Date(),
    end: new Date(),
    score: 0,
    price: 0,
    description: "",
    min_seats: 0,
    max_seats: 0,
    status: 0,
    is_capped: false,
    event_type: 0,
    training_type: 0,
    checklist: [],
    checklist_additional_info: "",
    speaking_event: "",
    participation_id: "",
  },
  lectureDetailsLoaded: false,
  currentPage: 1,
  isLoading: false,
};

function changeUserHasClickedEvaluationLink(
  event: LecturesData,
  hasClickedEvaluationLink: boolean
): LecturesData {
  let updatedEvent = { ...event };
  updatedEvent.user_has_clicked_evaluation_link = hasClickedEvaluationLink;
  return updatedEvent;
}

export function lecturesReducer(
  state = initialLecturesState,
  action: LecturesActionTypes
): LecturesState {
  switch (action.type) {
    case LECTURES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SET_LIST_OF_LECTURES_SUCCESS:
      return {
        ...state,
        lecturesList: action.data,
        isLoading: false,
      };
    case SET_LECTURE:
      return {
        ...state,
        lectureDetails: action.data,
        lectureDetailsLoaded: true,
        isLoading: false,
      };
    case LECTURES_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SET_LECTURES_PAGE_COUNT:
      return {
        ...state,
        currentPage: action.pageCount,
      };
    case UPDATE_LECTURE:
      return {
        ...state,
        lectureDetails: {
          ...changeUserHasClickedEvaluationLink(
            state.lectureDetails,
            action.userHasClickEvaluationLink
          ),
        },
        isLoading: false,
      };
    case CLEAR_LECTURES:
      return initialLecturesState;
    default:
      return state;
  }
}

export default lecturesReducer;
