import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { TrainingType } from "../../../../models/enums/trainingType.enum";
import { AppState } from "../../../../redux";
import CancelButton from "./buttons/CancelButton";
import CreateOrUpdateButton from "./buttons/CreateOrUpdateButton";
import CreateTemplateFromEventButton from "./buttons/CreateTemplateFromEventButton";
import DeleteEventButton from "./buttons/DeleteEventButton";
import ProcessingEvent from "./ProcessingEvent";

interface EventFormButtonsProps {
  hasBeenDeleted: () => void;
  isEditMode: boolean;
  id: string;
  typeOfTrainingToBeCreated: TrainingType;
}

const EventFormButtons: React.FC<EventFormButtonsProps> = (props) => {
  const event = useSelector((state: AppState) => state.event);

  return (
    <>
      <Grid container spacing={4}>
        {event.isLoading ? (
          <Grid
            item
            xs={props.isEditMode ? 6 : 12}
            sm={props.isEditMode ? 3 : 7}
          >
            <ProcessingEvent
              isEditMode={props.isEditMode}
              typeOfTrainingToBeCreated={props.typeOfTrainingToBeCreated}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={6} sm={3}>
              <CreateOrUpdateButton
                isEditMode={props.isEditMode}
                typeOfTrainingToBeCreated={props.typeOfTrainingToBeCreated}
              />
            </Grid>
            {!props.isEditMode &&
              props.typeOfTrainingToBeCreated === TrainingType.DefaultEvent && (
                <CreateTemplateFromEventButton />
              )}
          </>
        )}
        <Grid item xs={6} sm={2}>
          <CancelButton
            isEditMode={props.isEditMode}
            typeOfTrainingToBeCreated={props.typeOfTrainingToBeCreated}
          />
        </Grid>
        {props.isEditMode && (
          <>
            <Grid item xs={6} sm={4}></Grid>
            <Grid item xs={6} sm={3}>
              <DeleteEventButton
                typeOfTrainingToBeCreated={props.typeOfTrainingToBeCreated}
                hasBeenDeleted={() => {
                  props.hasBeenDeleted();
                }}
                id={props.id}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default EventFormButtons;
