import { LAEKHCategory, LAEKHFormId } from "../models/laekhData";
import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";

export class LAEKHService extends BaseCrudService {
  async getLAEKHCategories() {
    const response = await this.getAll<LAEKHCategory[]>(
      apiRoutes.laekh_categories
    );
    return response.data;
  }

  async getLAEKHFormIds() {
    const response = await this.getAll<LAEKHFormId[]>(apiRoutes.laekh_form_ids);
    return response.data;
  }
}
