import { ELearningFormData } from "./../../models/elearning";
import { EventFormData } from "../../models/eventData";

export interface PreviewState {
  previewEvent: ELearningFormData;
  previewEventLoaded: boolean;
}

// general actions
export const PREVIEW_REQUEST = "PREVIEW_REQUEST";
export const PREVIEW_FAILURE = "PREVIEW_FAILURE";

export const SET_PREVIEW = "SET_PREVIEW";

export const CLEAR_PREVIEW = "CLEAR_PREVIEW";

// actions that are the same for all api calls (request and failure)
export interface PreviewRequestAction {
  type: typeof PREVIEW_REQUEST;
}

export interface PreviewFailureAction {
  type: typeof PREVIEW_FAILURE;
}

export interface SetPreviewEventAction {
  type: typeof SET_PREVIEW;
  data: EventFormData;
}

export interface ClearPreviewAction {
  type: typeof CLEAR_PREVIEW;
}

export type PreviewActionTypes =
  | PreviewRequestAction
  | PreviewFailureAction
  | SetPreviewEventAction
  | ClearPreviewAction;
