import routes from "../../../routing/routes";
import { UserType } from "./../../../../models/enums/userType.enum";

export interface NavigationData extends BaseNavData {
  showSubRoutes?: boolean; // determines whether the routes should be shown in the sidebar as sub routes
  subRoutes?: NavigationData[]; // all subroutes that exist (are needed for breadcrumbs)
}

export interface BaseNavData {
  key: string; // key needs to be unique inside the NavigationData object
  text: string;
  route: string;
}

const events: NavigationData = {
  key: "veranstaltungen",
  text: "Veranstaltungen",
  route: routes.events,
};

const eventsWithSubRoutes: NavigationData = {
  ...events,
  subRoutes: [
    {
      key: "veranstaltung-anlegen",
      route: routes.event_create,
      text: "Veranstaltung anlegen",
    },
    {
      key: "veranstaltung-bearbeiten",
      route: routes.event_edit,
      text: "Veranstaltung bearbeiten",
    },
    {
      key: "e-learning-anlegen",
      route: routes.elearning_create,
      text: "E-Learning anlegen",
    },
    {
      key: "blended-learning-anlegen",
      route: routes.blended_learning_create,
      text: "Blended-Learning anlegen",
    },
    {
      key: "e-learning-bearbeiten",
      route: routes.elearning_edit_id,
      text: "E-Learning bearbeiten",
      subRoutes: [
        {
          key: "mitarbeiter-e-learning-pinnwand",
          route: routes.pinboard_staff_elearning,
          text: "Pinnwand",
        },
      ],
    },
    {
      key: "blended-learning-bearbeiten",
      route: routes.blended_learning_edit_id,
      text: "Blended-Learning bearbeiten",
      subRoutes: [
        {
          key: "mitarbeiter-blended-learning-pinnwand",
          route: routes.pinboard_staff_blended_learning,
          text: "Pinnwand",
        },
      ],
    },
    {
      key: "lernangebote",
      route: routes.learning_events_overview,
      text: "Lernangebote",
    },
  ],
};

const myEvents: NavigationData = {
  key: "meine-buchungen",
  text: "Meine Buchungen",
  route: routes.my_events,
  subRoutes: [
    {
      key: "details",
      text: "Details",
      route: routes.my_events_details,
    },
    {
      key: "details-e-learning",
      text: "Details",
      route: routes.my_events_details_elearning_id,
      subRoutes: [
        {
          key: "details-e-learning-pinnwand",
          route: routes.pinboard_participant,
          text: "Pinnwand",
        },
      ],
    },
  ],
};

const myLectures: NavigationData = {
  key: "meine-vortraege",
  text: "Meine Vorträge",
  route: routes.my_lectures,
  subRoutes: [
    {
      key: "details",
      text: "Details",
      route: routes.my_lectures_details_event_id,
      subRoutes: [
        {
          key: "referent-vortrag-pinnwand",
          text: "Pinnwand",
          route: routes.pinboard_speaker,
        },
      ],
    },
    {
      key: "details-e-learning",
      text: "Details",
      route: routes.my_lectures_details_elearning_id,
      subRoutes: [
        {
          key: "referent-vortrag-e-learning-pinnwand",
          text: "Pinnwand",
          route: routes.pinboard_speaker,
        },
      ],
    },
  ],
};

const tasks: NavigationData = {
  key: "aufgaben",
  text: "Aufgaben",
  route: routes.tasks,
  subRoutes: [
    {
      key: "aufgabe-erstellen",
      route: routes.tasks_new,
      text: "Aufgabe anlegen",
    },
    {
      key: "aufgabe-bearbeiten",
      route: routes.tasks_edit,
      text: "Aufgabe ändern",
    },
  ],
};

const templates: NavigationData = {
  key: "vorlagen",
  text: "Vorlagen",
  route: routes.templates,
  subRoutes: [
    {
      key: "veranstaltung",
      text: "Veranstaltung",
      route: routes.templates,
      subRoutes: [
        {
          key: "veranstaltungsvorlage-erstellen",
          route: routes.templates_new_event,
          text: "erstellen",
        },
        {
          key: "veranstaltungsvorlage-bearbeiten",
          route: routes.templates_edit_event,
          text: "bearbeiten",
        },
      ],
    },
    {
      key: "aufgaben",
      text: "Aufgaben",
      route: routes.templates,
      subRoutes: [
        {
          key: "aufgabenvorlage-erstellen",
          route: routes.templates_new_task,
          text: "erstellen",
        },
        {
          key: "aufgabenvorlage-bearbeiten",
          route: routes.templates_edit_task,
          text: "bearbeiten",
        },
      ],
    },
  ],
};

const activityHistory: NavigationData = {
  key: "aktivitaetenhistorie",
  text: "Aktivitätenhistorie",
  route: routes.activityHistory,
};

const settings: NavigationData = {
  key: "einstellungen",
  text: "Einstellungen",
  route: routes.settings,
};

const logout: NavigationData = {
  key: "logout",
  text: "Logout",
  route: routes.login,
};

const login: NavigationData = {
  key: "login",
  text: "Login",
  route: routes.login,
};

const contact: NavigationData = {
  key: "kontakt",
  text: "Kontakt",
  route: routes.contact,
};

const favorites: NavigationData = {
  key: "favoriten",
  text: "Favoriten",
  route: routes.favorites,
};

const linkConfirmation: NavigationData = {
  key: "konto-verknuepfen",
  text: "Kontoverknüpfung",
  route: routes.link_confirmation,
};

const eLearningDashboard: NavigationData = {
  key: "e-learning-dashboard",
  text: "E-Learning",
  route: routes.elearning_dashboard,
};

const eLearningLms: NavigationData = {
  key: "e-learning-lms",
  text: "E-Learning",
  route: routes.elearning_lms,
};

const checkout: NavigationData = {
  key: "buchung",
  text: "Buchung",
  route: routes.booking,
  subRoutes: [
    {
      key: "checkout",
      route: routes.checkout,
      text: "Checkout",
    },
    {
      key: "antwort",
      route: routes.waiting_list_response,
      text: "Antwort",
    },
  ],
};

const admin: NavigationData = {
  key: "admin",
  text: "Admin",
  route: routes.admin,
};

const maintenance: NavigationData = {
  key: "wartungsarbeiten",
  text: "Wartungsarbeiten",
  route: routes.maintenance,
};

const commonRoutes: NavigationData[] = [settings, logout];
const commonRoutesNotLoggedIn: NavigationData[] = [events, maintenance];

const navigationItemsEmployee: NavigationData[] = [
  eventsWithSubRoutes,
  tasks,
  templates,
  activityHistory,
  ...commonRoutes,
];

const navigationItemsSpeaker: NavigationData[] = [
  events,
  myLectures,
  ...commonRoutes,
];

const navigationItemsUser: NavigationData[] = [
  eventsWithSubRoutes,
  myEvents,
  eLearningDashboard,
  favorites,
  ...commonRoutes,
];

const navigationItemsTestUser: NavigationData[] = [
  eventsWithSubRoutes,
  myEvents,
  eLearningDashboard,
  ...commonRoutes,
];

//use when user roles are ready

export function getNavigationByRole(userType?: UserType): NavigationData[] {
  switch (userType) {
    case UserType.Undefined:
      return commonRoutesNotLoggedIn;
    case UserType.Admin:
      return [logout];
    case UserType.Speaker:
      return navigationItemsSpeaker;
    case UserType.Participant:
      return navigationItemsUser;
    case UserType.Employee:
    case UserType.Apprentice:
      return navigationItemsEmployee;
    case UserType.TestParticipant:
      return navigationItemsTestUser;
    default:
      return commonRoutesNotLoggedIn;
  }
}

export function getAllRoutes(userType?: UserType) {
  return [
    eventsWithSubRoutes,
    myEvents,
    eLearningDashboard,
    eLearningLms,
    myLectures,
    tasks,
    activityHistory,
    templates,
    favorites,
    linkConfirmation,
    ...commonRoutes,
    contact,
    login,
    checkout,
    admin,
    maintenance,
  ];
}
