import {
  BookingFilterData,
  MyEventListPossibleFilters,
} from "../../models/bookingData";
import { BookingsSortBy } from "../../models/enums/sortBy.enum";
import { Utils } from "../utils";
import FilterService, { SortDirection } from "./filterService";
import { history } from "../../components/routing/history";
import { ParticipationStatus } from "../../models/enums/participationStatus.enum";

export class BookingFilterService extends FilterService<
  BookingFilterData,
  BookingsSortBy
> {
  constructor(page: number, filterData: BookingFilterData) {
    const sorting = filterData.sorting as BookingsSortBy;

    super({
      sort: sorting,
      page: page,
      attributes: filterData,
    });
  }

  /**
   * Gets the current filter values from the query params in the URL and parses the values and assigns them to a BookingFilterData object that can be used to initalize a filter form.
   * The value for ordering attribute comes from base filter service.
   */
  parseFiltersFromUrl(): BookingFilterData {
    const query = new URLSearchParams(history.location.search);

    const typeValue = query.get("event__training_type") || "";
    const statusValues = this.parseNumberListFromFilterUrl(
      query.get("status") || "",
      ParticipationStatus.Undefined
    );
    const queryFilter = super.parseFiltersFromUrl(query);

    return {
      ...queryFilter,
      event__training_type: typeValue,
      status: statusValues,
    };
  }

  /**
   * Maps the string representation of the BookingsSortBy enum to one of the provided filters possibilites for sorting.
   * @param sort The enum or string value to parse to actual sort string.
   */
  mapSorting(sort: string | BookingsSortBy): string {
    switch (sort) {
      case BookingsSortBy.StatusAscending:
        return super.parseOrdering(
          Utils.propertyOf<MyEventListPossibleFilters>("status")
        );
      case BookingsSortBy.StatusDescending:
        return super.parseOrdering(
          Utils.propertyOf<MyEventListPossibleFilters>("status"),
          SortDirection.Descending
        );
      case BookingsSortBy.BeginAscending:
        return super.parseOrdering(
          Utils.propertyOf<MyEventListPossibleFilters>("overall_begin")
        );
      case BookingsSortBy.BeginDescending:
        return super.parseOrdering(
          Utils.propertyOf<MyEventListPossibleFilters>("overall_begin"),
          SortDirection.Descending
        );
      case BookingsSortBy.EndAscending:
        return super.parseOrdering(
          Utils.propertyOf<MyEventListPossibleFilters>("overall_end")
        );
      case BookingsSortBy.EndDescending:
        return super.parseOrdering(
          Utils.propertyOf<MyEventListPossibleFilters>("overall_end"),
          SortDirection.Descending
        );
      default:
        return super.parseOrdering(
          Utils.propertyOf<MyEventListPossibleFilters>("overall_end")
        );
    }
  }

  /**
   * Maps the sort value and its direction to a possible BookingsSortBy enum value. Returns the default sorting if none of the provided values matches.
   * @param sort Value of attribute that should be sorted.
   */
  mapSortStringToEnum(sort: string): BookingsSortBy {
    switch (sort) {
      case super.parseOrdering(
        Utils.propertyOf<MyEventListPossibleFilters>("status")
      ):
        return BookingsSortBy.StatusAscending;
      case super.parseOrdering(
        Utils.propertyOf<MyEventListPossibleFilters>("status"),
        SortDirection.Descending
      ):
        return BookingsSortBy.StatusDescending;
      case super.parseOrdering(
        Utils.propertyOf<MyEventListPossibleFilters>("overall_begin")
      ):
        return BookingsSortBy.BeginAscending;
      case super.parseOrdering(
        Utils.propertyOf<MyEventListPossibleFilters>("overall_begin"),
        SortDirection.Descending
      ):
        return BookingsSortBy.BeginDescending;
      case super.parseOrdering(
        Utils.propertyOf<MyEventListPossibleFilters>("overall_end")
      ):
        return BookingsSortBy.EndAscending;
      case super.parseOrdering(
        Utils.propertyOf<MyEventListPossibleFilters>("overall_end"),
        SortDirection.Descending
      ):
        return BookingsSortBy.EndDescending;
      default:
        return BookingsSortBy.EndAscending;
    }
  }
}
