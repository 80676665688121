import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles/";
import { Overrides } from "@material-ui/core/styles/overrides";
import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../redux";
import defaultTheme, { defaultPalette } from "./defaultTheme";
import { monoOverrides, monoPalette } from "./monoColorTheme";

const CustomThemeProvider: React.FC = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        ...defaultTheme,
        palette: accessibility.monoMode ? monoPalette : defaultPalette,
        typography: {
          ...defaultTheme.typography,
          fontSize:
            accessibility.fontSizeMode === "default"
              ? 16
              : accessibility.fontSizeMode === "big"
              ? 18
              : accessibility.fontSizeMode === "bigger"
              ? 19
              : 16,
          body1: {
            fontSize:
              accessibility.fontSizeMode === "default"
                ? 16
                : accessibility.fontSizeMode === "big"
                ? 18
                : accessibility.fontSizeMode === "bigger"
                ? 19
                : 16,
            lineHeight: "1.4",
          },
          allVariants: {
            color: accessibility.monoMode ? "#000" : "#3c3c3c",
          },
        },
        overrides: accessibility.monoMode
          ? (monoOverrides as Overrides)
          : defaultTheme.overrides,
      }),
    [accessibility.fontSizeMode, accessibility.monoMode]
  );

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default CustomThemeProvider;
