import { AxiosResponse } from "axios";
import { ELearningDto, ELearningListData } from "../../models/elearning";
import {
  CrudEventDataDto,
  EventFilterValues,
  EventListData,
} from "../../models/eventData";
import { Pagination } from "../../models/pagination";
import { API_URL, CURRENT_API_VERSION } from "../apiConfig";
import apiRoutes from "../apiRoutes";
import { BaseCrudService } from "../baseCrudService";

export class EventService extends BaseCrudService {
  getEvents(filter: string) {
    const response = this.getAll<Pagination<EventListData | ELearningListData>>(
      apiRoutes.events + filter
    );
    return response;
  }

  getFilterValues(filters: string) {
    const response = this.getAll<EventFilterValues>(
      apiRoutes.events_filter_values + filters
    );
    return response;
  }

  // Implementation for getting details of an event
  async getEvent(guid: string) {
    const response = await this.get<ELearningDto>(apiRoutes.events, guid);
    return response.data;
  }

  async addEvent(event: CrudEventDataDto) {
    const response = await this.create<CrudEventDataDto>(
      apiRoutes.events,
      event
    );
    return response;
  }

  async editEvent(event: CrudEventDataDto, guid: string) {
    const response = await this.edit<CrudEventDataDto>(
      apiRoutes.events,
      guid,
      event
    );
    return response;
  }

  async deleteEvent(id: string) {
    const response = await this.delete<CrudEventDataDto>(
      apiRoutes.events,
      id.toString()
    );
    return response;
  }

  async editEventStatus(eventStatus: number, id: string) {
    const response = await this.edit<
      { status: number },
      AxiosResponse<CrudEventDataDto>
    >(apiRoutes.events, id.toString(), { status: eventStatus });
    return response;
  }

  async switchEnrolmentCap(isCapped: boolean, guid: string) {
    const response = await this.edit<
      { is_capped: boolean },
      AxiosResponse<CrudEventDataDto>
    >(apiRoutes.events, guid, { is_capped: isCapped });
    return response;
  }

  async editEventDocumentList(eventDocumentList: string[], id: string) {
    const response = await this.edit<
      { documents: string[] },
      AxiosResponse<CrudEventDataDto>
    >(apiRoutes.events, id, { documents: eventDocumentList });
    return response;
  }

  async triggerInvoice(eventId: string) {
    const response = await this.create<CrudEventDataDto>(
      `${apiRoutes.events}/${eventId.toString()}${apiRoutes.invoice}`
    );
    return response;
  }

  async triggerInvoiceCorrection(
    eventId: string,
    participantsForInvoiceCorrection: string[]
  ) {
    const response = await this.create<
      { users: string[] },
      AxiosResponse<CrudEventDataDto>
    >(
      `${apiRoutes.events}/${eventId.toString()}${
        apiRoutes.invoice_correction
      }`,
      { users: participantsForInvoiceCorrection }
    );
    return response;
  }

  triggerCsvDownloadOfInvoice(eventId: string) {
    // trigger the download of the csv file via GET on invoice route
    window.location.href =
      API_URL +
      CURRENT_API_VERSION +
      apiRoutes.events +
      "/" +
      eventId.toString() +
      apiRoutes.invoice;
  }
}
