import moment from "moment";
import * as yup from "yup";
import { ELearningData } from "../../../../models/elearning";
import { EventStatus } from "../../../../models/enums/eventStatus.enum";
import {
  mapToTrainingTypeString,
  mapTrainingTypeToArticleWithNounPossessiveCase,
  TrainingType,
} from "../../../../models/enums/trainingType.enum";
import { EventDataDto } from "../../../../models/eventData";
import { EventType } from "./../../../../models/enums/eventType.enum";
import { integer, positiveInteger } from "./validationRules";

const eventValidationSchema = (
  trainingType: TrainingType,
  event?: EventDataDto | ELearningData
) => {
  const trainingTypeAsString = mapToTrainingTypeString(trainingType);
  const trainingTypeAsArticleWithNounPossessiveCase = mapTrainingTypeToArticleWithNounPossessiveCase(
    trainingType,
    trainingTypeAsString
  );

  return yup.object({
    title: yup
      .string()
      .required(
        `Der Name ${trainingTypeAsArticleWithNounPossessiveCase} ist ein Pflichtfeld.`
      ),
    vnr: yup
      .string()
      .matches(new RegExp("^[0-9]{19}$"), "Die VNR muss 19-stellig sein."),
    score: yup.number().when("status", {
      is: (value: number) => value === EventStatus.Published,
      then: integer
        .min(0, "Die Fortbildungspunkte dürfen nicht kleiner als 0 sein.")
        .required("Die Angabe der Fortbildungspunkte ist Pflicht."),
      otherwise: integer.min(
        0,
        "Die Fortbildungspunkte dürfen nicht kleiner als 0 sein."
      ),
    }),
    price: yup.number().when("status", {
      is: (value: number) => value === EventStatus.Published,
      then: yup
        .number()
        .min(
          0,
          `Die Kosten ${trainingTypeAsArticleWithNounPossessiveCase} dürfen nicht kleiner als 0 sein.`
        )
        .required("Die Angabe der Kosten ist Pflicht."),
      otherwise: yup
        .number()
        .min(
          0,
          `Die Kosten ${trainingTypeAsArticleWithNounPossessiveCase} dürfen nicht kleiner als 0 sein.`
        ),
    }),
    minseats: yup.number().when("status", {
      is: (value: number) =>
        value === EventStatus.Published &&
        trainingType !== TrainingType.ELearning,
      then: positiveInteger.required(
        "Die Angabe der minimalen Teilnehmeranzahl ist Pflicht."
      ),
      otherwise: positiveInteger,
    }),
    maxseats: yup.number().when("status", {
      is: (value: number) =>
        value === EventStatus.Published &&
        trainingType !== TrainingType.ELearning,
      then: positiveInteger
        .test(
          "maxseats",
          "Die maximale Teilnehmeranzahl muss größer als die minimale Teilnehmeranzahl sein.",
          function (maxSeats: number | undefined) {
            // !NOTE: theres a bug in yup that prevents using moreThan with a reference to the field (see https://github.com/jquense/yup/pull/1208)
            // !NOTE: use test function as workaround
            let minSeats = this.resolve(yup.ref("minseats")) as number;
            if (maxSeats && minSeats) return maxSeats >= minSeats;
            return false;
          }
        )
        .required("Die Angabe der maximalen Teilnehmeranzahl ist Pflicht."),
      otherwise: positiveInteger.test(
        "maxseats",
        "Die maximale Teilnehmeranzahl muss größer als die minimale Teilnehmeranzahl sein.",
        function (maxSeats: number | undefined) {
          // !NOTE: theres a bug in yup that prevents using moreThan with a reference to the field (see https://github.com/jquense/yup/pull/1208)
          // !NOTE: use test function as workaround
          let minSeats = this.resolve(yup.ref("minseats")) as number;
          if (maxSeats && minSeats) return maxSeats >= minSeats;
          return false;
        }
      ),
    }),
    participation_types: yup
      .array()
      .min(1, "Es muss mindestens ein Teilnahmetyp ausgewählt sein.")
      .required("Es muss mindestens ein Teilnahmetyp ausgewählt sein."),
    special_fields: yup.array(),
    coverage_areas: yup.array(),
    laekh_form_id: yup.string().when("status", {
      is: (value: number) => value === EventStatus.Published,
      then: yup
        .string()
        .required("Die Angabe der LÄKH Form ID ist Pflicht."),
      otherwise: yup.string(),
    }),
    laekh_category: yup.string().when("status", {
      is: (value: number) => value === EventStatus.Published,
      then: yup
        .string()
        .required("Die Angabe der LÄKH Kategorie ist Pflicht."),
      otherwise: yup.string(),
    }),
    status: yup
      .number()
      .moreThan(
        0,
        `Der Status ${trainingTypeAsArticleWithNounPossessiveCase} muss gesetzt sein.`
      ) // fallback, if the default value is 0 (status values are numbers starting from 0 -> 0 meaning undefined)
      .required(
        `Der Status ${trainingTypeAsArticleWithNounPossessiveCase} muss gesetzt sein.`
      ),
    internalExternalEvent: yup
      .number()
      .moreThan(
        0,
        "Es muss ausgewählt sein, ob es sich um eine interne oder externe Veranstaltung handelt."
      )
      .required(
        "Es muss ausgewählt sein, ob es sich um eine interne oder externe Veranstaltung handelt."
      ),
    numberOfUnits: yup.number().when("status", {
      is: (value: number) =>
        value === EventStatus.Published &&
        trainingType !== TrainingType.DefaultEvent,
      then: positiveInteger.required(
        "Die Anzahl der Lerneinheiten ist eine Pflichtangabe."
      ),
      otherwise: integer.min(0), // ! don't use positiveInteger because it caused the form to be invalid and therefore can not be submitted when creating an event whitout elearning fields
    }),
    durationPerUnitInMinutes: yup.number().when("status", {
      is: (value: number) =>
        value === EventStatus.Published &&
        trainingType !== TrainingType.DefaultEvent,
      then: positiveInteger.required(
        "Die Dauer pro Lerneinheit ist eine Pflichtangabe."
      ),
      otherwise: integer.min(0), // ! don't use positiveInteger because it caused the form to be invalid and therefore can not be submitted when creating an event whitout elearning fields
    }),
    maxTimePeriodToFinishElearningModuleInWeeks: yup.number().when("status", {
      is: () => trainingType !== TrainingType.DefaultEvent,
      then: positiveInteger
        .required(
          "Die Angabe des maximalen Bearbeitungszeitraumes ist Pflicht."
        )
        .min(
          event &&
            (event as ELearningData)
              .max_time_period_to_finish_elearning_module_in_weeks !== undefined
            ? ((event as ELearningData)
                .max_time_period_to_finish_elearning_module_in_weeks as number)
            : 1,
          `Die maximale Bearbeitungsdauer muss mindestens ${
            event &&
            (event as ELearningData)
              .max_time_period_to_finish_elearning_module_in_weeks !== undefined
              ? ((event as ELearningData)
                  .max_time_period_to_finish_elearning_module_in_weeks as number)
              : 1
          } Woche${
            event &&
            (event as ELearningData)
              .max_time_period_to_finish_elearning_module_in_weeks !==
              undefined &&
            ((event as ELearningData)
              .max_time_period_to_finish_elearning_module_in_weeks as number) >
              1
              ? "n"
              : ""
          } sein.`
        ),
      otherwise: integer.min(0), // ! don't use positiveInteger because it caused the form to be invalid and therefore can not be submitted when creating an event whitout elearning fields
    }),
    pinboardIsActivated: yup.boolean(),
    description: yup.object().when("status", {
      is: (value: number) => value === EventStatus.Published,
      then: yup
        .object()
        .test(
          "description",
          `Die Beschreibung ${trainingTypeAsArticleWithNounPossessiveCase} ist ein Pflichtfeld.`,
          function (value: any) {
            return value.hasText();
          }
        )
        .required("Die Veranstaltungsbeschreibung ist ein Pflichtfeld."),
      otherwise: yup.object(),
    }),
    location: yup.string().when("status", {
      is: (value: number) =>
        value === EventStatus.Published &&
        trainingType !== TrainingType.ELearning,
      then: yup.string().required("Der Veranstaltungsort ist ein Pflichtfeld."),
      otherwise: yup.string(),
    }),
    city: yup.string().when(["status", "eventType"], {
      is: (status: number, eventType: number) =>
        status === EventStatus.Published &&
        eventType === EventType.OnSite &&
        trainingType !== TrainingType.ELearning,
      then: yup
        .string()
        .required(
          "Die Angabe der Stadt, in der die Veranstaltung stattfindet, ist Pflicht."
        )
        .nullable(),
      otherwise: yup.string().nullable(),
    }),
    zip_code: yup.string().when(["status", "eventType"], {
      is: (status: number, eventType: number) =>
        status === EventStatus.Published &&
        eventType === EventType.OnSite &&
        trainingType !== TrainingType.ELearning,
      then: yup.string().required("Die Angabe der PLZ ist Pflicht.").nullable(),
      otherwise: yup.string().nullable(),
    }),
    eventType: yup.number().when("status", {
      is: (value: number) =>
        value === EventStatus.Published &&
        trainingType !== TrainingType.ELearning,
      then: yup
        .number()
        .moreThan(0, "Die Angabe der Art des Vortrages ist Pflicht.")
        .required("Die Angabe der Art des Vortrages ist Pflicht."),
      otherwise: yup.number(),
    }),
    locationDetails: yup.string(),
    beginDate: yup.date().when({
      is: (value: Date) =>
        value !== null && trainingType !== TrainingType.ELearning,
      then: yup
        .date()
        .typeError(
          "Dies ist kein gültiges Datum. Das Startdatum muss im Format DD.MM.JJJJ vorliegen."
        )
        .test(
          "beginDate",
          "Das Datum muss in der Zukunft liegen!",
          function (value) {
            if (event && moment(value).isSame(event?.begin, "day")) return true;
            const momentDate = moment(value, "DD.MM.YYYY");

            // check if the date is today or lies further in the future
            return moment().diff(momentDate, "days") <= 0;
          }
        )
        .required("Die Angabe des Startdatums ist Pflicht!"),
      otherwise: yup.date().nullable(),
    }),
    endDate: yup.date().when({
      is: (value: Date) =>
        value !== null && trainingType !== TrainingType.ELearning,
      then: yup
        .date()
        .typeError(
          "Dies ist kein gültiges Datum. Das Enddatum muss im Format DD.MM.JJJJ vorliegen."
        )
        .min(
          yup.ref("beginDate"),
          "Das Enddatum darf nicht vor dem Startdatum liegen."
        )
        .test(
          "endDate",
          "Das Datum der Veranstaltung muss in der Zukunft liegen!",
          function (value) {
            if (
              (trainingType !== TrainingType.ELearning && value === null) ||
              (event && moment(value).isSame(event?.end, "day"))
            )
              return true;
            const momentDate = moment(value, "DD.MM.YYYY");

            // check if the date is today or lies further in the future
            return moment().diff(momentDate, "days") <= 0;
          }
        )
        .required("Die Angabe des Enddatums ist Pflicht!"),
      otherwise: yup.date().nullable(),
    }),
    beginTime: yup.date().when({
      is: (value: Date) =>
        value !== null && trainingType !== TrainingType.ELearning,
      then: yup
        .date()
        .typeError(
          "Dies ist keine gültige Zeitangabe. Die Startzeit muss im Format hh:mm in der 24-Stunden-Zählung vorliegen!"
        )
        .required("Die Angabe der Startzeit ist Pflicht!"),
      otherwise: yup.date().nullable(),
    }),
    endTime: yup.date().when({
      is: (value: Date) =>
        value !== null && trainingType !== TrainingType.ELearning,
      then: yup
        .date()
        .typeError(
          "Dies ist keine gültige Zeitangabe. Die Endzeit muss im Format hh:mm in der 24-Stunden-Zählung vorliegen!"
        )
        .test(
          "beginTime",
          "Die Endzeit der Veranstaltung darf nicht vor der Startzeit liegen.",
          function (value) {
            const { beginTime } = this.parent;
            return moment(value, "HH:mm").isSameOrAfter(
              moment(beginTime, "HH:mm")
            );
          }
        )
        .required("Die Angabe der Endzeit ist Pflicht!"),
      otherwise: yup.date().nullable(),
    }),
    commentary: yup.string(),
  });
};

export default eventValidationSchema;
