import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { Pagination as PaginationUI } from "@material-ui/lab";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  mapToPopupTitle,
  mapUserToPinboardRoute,
  PinboardPopupType,
} from "../../../../../../models/enums/pinboardType.enum";
import { mapToTrainingTypeString } from "../../../../../../models/enums/trainingType.enum";
import { UserType } from "../../../../../../models/enums/userType.enum";
import { PAGE_SIZE } from "../../../../../../models/pagination";
import { PinboardListData } from "../../../../../../models/pinboardData";
import { AppState } from "../../../../../../redux";
import { getMyEvent } from "../../../../../../redux/bookings/actions";
import {
  getBlendedLearning,
  getELearning,
} from "../../../../../../redux/e-learnings/actions";
import {
  getPinboard,
  pinboardPageCount,
} from "../../../../../../redux/pinboard/actions";
import useValidId from "../../../../../hooks/useValidId";
import routes from "../../../../../routing/routes";
import HeadingKvh from "../../../../../theming/HeadingKvh";
import Loader from "../../../../../theming/loader/Loader";
import { StyleProps } from "../../../shared/Content";
import "./Pinboard.scss";
import PinboardPopup from "./PinboardPopup";
import PinboardToggleActions from "./PinboardToggleActions";
import PinboardTopic from "./PinboardTopic";

const useStyles = makeStyles({
  icon: {
    width: 30,
    height: 30,
    transition: "opacity 300ms, border 300ms",
  },
  checkedIcon: {
    width: 29,
    height: 15,
    position: "absolute",
    transform: "rotate(-45deg)",
    transition: "border-color 300ms, background-color 300ms",
    border: (props: StyleProps) =>
      props.monoMode ? "3px solid #000" : "3px solid #ffbb00",
    borderTop: (props: StyleProps) => (props.monoMode ? "none" : "none"),
    borderRight: (props: StyleProps) => (props.monoMode ? "none" : "none"),
    top: "0px",
    left: "4px",
  },
});

export interface PinboardProps {
  bookingId?: string;
}

const Pinboard: React.FunctionComponent<PinboardProps> = (props) => {
  let { id } = useParams<{ id: string }>();

  const event = useSelector((state: AppState) => state.event);
  const bookings = useSelector((state: AppState) => state.booking);
  const pinboard = useSelector((state: AppState) => state.pinboard);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  const dispatch = useDispatch();
  const classes = useStyles({ monoMode: accessibility.monoMode });

  const participationId = props.bookingId || bookings.myEvent.guid;

  const eventId = id !== undefined ? id : bookings.myEvent.event.guid;
  const isBlendedLearning = window.location.pathname.startsWith(
    routes.blended_learning_edit
  );

  const { isValidId } = useValidId(
    mapUserToPinboardRoute(currentUser, isBlendedLearning).replace(
      ":id",
      eventId
    ),
    eventId
  );

  const isValid = window.location.pathname.includes(routes.lms)
    ? props.bookingId !== undefined
    : isValidId;

  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [pageChange, setPageChange] = useState(0);
  const [showArchive, setShowArchive] = useState(false);

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (!checked) dispatch(pinboardPageCount(1));
    setShowArchive(checked);
    dispatch(getPinboard(pinboard.currentPage, eventId, checked));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const closeNewPost = () => {
    setOpen(false);
  };

  // fetch current pinboard-page (triggered on load-change)
  useEffect(() => {
    if (
      eventId !== "" &&
      event.editEvent.pinboard_is_activated &&
      !pinboard.pinboardListLoaded &&
      !pinboard.isLoading
    ) {
      dispatch(
        getPinboard(pinboard.currentPage, eventId, showArchive)
      );
    }
  }, [
    dispatch,
    eventId,
    pinboard.currentPage,
    pinboard.isLoading,
    pinboard.pinboardListLoaded,
    showArchive,
    event.editEvent.pinboard_is_activated,
  ]);

  // fetch next pinboard-page (triggered on page-change)
  useEffect(() => {
    if (
      pageChange > 0 &&
      eventId !== "" &&
      event.editEvent.pinboard_is_activated
    )
      dispatch(
        getPinboard(pinboard.currentPage, eventId, showArchive)
      );
  }, [
    dispatch,
    eventId,
    pinboard.currentPage,
    pageChange,
    showArchive,
    event.editEvent.pinboard_is_activated,
  ]);

  useEffect(() => {
    if (
      eventId !== "" &&
      currentUser &&
      !event.isLoading &&
      !event.editEventLoaded
    ) {
      if (isBlendedLearning) {
        dispatch(getBlendedLearning(eventId));
      } else {
        dispatch(getELearning(eventId));
      }
    }
  }, [
    dispatch,
    event.editEventLoaded,
    eventId,
    currentUser,
    event.isLoading,
    isBlendedLearning,
  ]);

  useEffect(() => {
    if (
      currentUser &&
      isValid &&
      participationId !== "" &&
      !bookings.isLoading &&
      !bookings.myEventLoaded
    ) {
      dispatch(getMyEvent(participationId.toString()));
    }
  }, [dispatch, currentUser, participationId, isValid, bookings]);

  return (
    <>
      {!props.bookingId ? (
        <>
          <HeadingKvh>{event.editEvent.title || "Pinnwand"}</HeadingKvh>
        </>
      ) : (
        <Box style={{ paddingLeft: "1.5rem" }}>
          <FontAwesomeIcon
            size="2x"
            style={{ marginRight: "7px" }}
            color={accessibility.monoMode ? "#000" : "#777"}
            icon={faComments}
          />{" "}
          <strong style={{ fontSize: "1.5rem" }}>Pinnwand</strong>
          <PinboardToggleActions />
        </Box>
      )}
      {event.editEvent.pinboard_is_activated ? (
        <Collapse in={pinboard.isPinboardOpen} collapsedHeight={0}>
          {eventId !== "" && (
            <Box style={{ float: "right" }}>
              <Button
                variant="contained"
                size="large"
                style={{ padding: "6px 40px" }}
                color="primary"
                onClick={handleClickOpen}
              >
                {mapToPopupTitle(PinboardPopupType.PostQuestion)}
              </Button>
              <PinboardPopup
                eventId={eventId}
                open={open}
                close={closeNewPost}
                popupType={PinboardPopupType.PostQuestion}
              />
            </Box>
          )}
          <Grid
            container
            style={{ marginTop: "20px", padding: "8px" }}
            spacing={2}
          >
            <Grid item md={1}></Grid>
            <Grid item md={11}>
              {!pinboard.isLoading && isValid ? (
                <>
                  {eventId !== "" && pinboard.pinboardList.count === 0 && (
                    <Box mt={2} mb={3} display="flex" justifyContent="center">
                      Es wurden noch keine Pinnwandeinträge erstellt.
                    </Box>
                  )}
                  {eventId !== "" &&
                    currentUser &&
                    [UserType.Employee, UserType.Apprentice].includes(
                      currentUser?.user_type
                    ) && (
                      <Box style={{ verticalAlign: "middle", margin: "20px" }}>
                        <Checkbox
                          onFocus={() => {
                            setFocused(true);
                          }}
                          onBlur={() => {
                            setFocused(false);
                          }}
                          checked={showArchive}
                          onChange={handleCheck}
                          color="default"
                          className={
                            focused
                              ? accessibility.monoMode
                                ? "focused-mono"
                                : "focused"
                              : ""
                          }
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon
                              )}
                            />
                          }
                          icon={<span className={classes.icon} />}
                          inputProps={{ "aria-label": "checkbox" }}
                          style={{ marginRight: "10px" }}
                        />{" "}
                        Archivierte Beiträge einblenden
                      </Box>
                    )}
                  {pinboard.pinboardList.count > 0 &&
                    pinboard.pinboardList.results.map(
                      (topic: PinboardListData) => {
                        return (
                          <PinboardTopic
                            key={topic.guid}
                            question={topic}
                            showArchive={showArchive}
                          />
                        );
                      }
                    )}
                </>
              ) : (
                <Loader />
              )}
            </Grid>
          </Grid>
          {eventId !== "" && pinboard.pinboardList.count > 0 && (
            <Grid container style={{ marginTop: "10px" }}>
              <Box component={"div"} style={{ margin: "auto" }}>
                <PaginationUI
                  count={Math.ceil(pinboard.pinboardList.count / PAGE_SIZE)}
                  page={pinboard.currentPage}
                  onChange={(event, value) => {
                    setPageChange((counter) => ++counter);
                    dispatch(pinboardPageCount(value));
                  }}
                />
              </Box>
            </Grid>
          )}
        </Collapse>
      ) : (
        <Box textAlign={"center"}>
          Die Pinnwandfunktion ist für dieses{" "}
          {mapToTrainingTypeString(event.editEvent.training_type)} nicht
          aktiviert.
        </Box>
      )}
    </>
  );
};

export default Pinboard;
