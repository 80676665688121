import { Typography } from "@material-ui/core";
import { AxiosError, AxiosResponse } from "axios";
import React from "react";
import { Dispatch } from "redux";
import { AppState } from "..";
import { authService, usersService } from "../../api";
import { ApiHelper } from "../../api/apiHelper";
import { ApiResponse } from "../../api/apiResponse";
import { history } from "../../components/routing/history";
import routes from "../../components/routing/routes";
import {
  AuthResultData,
  LoginData,
  MoodleBaseData,
} from "../../models/userData";
import { error, info, success } from "../alerts/actions";
import { AlertActionTypes, ErrorAction, SuccessAction } from "../alerts/types";
import { showDialog } from "../dialogs/actions";
import { ShowAction } from "../dialogs/types";
import { clearEventList } from "../events/actions";
import { EventActionTypes } from "../events/types";
import { clearFavoriteList } from "../favorites/actions";
import { FavoriteActionTypes } from "../favorites/types";
import { getCurrentUserData } from "../users/actions";
import { UserActionTypes } from "../users/types";
import {
  AuthActionTypes,
  FetchingAuthDataFinishedAction,
  FETCHING_AUTH_DATA_ACTION_FINISHED,
  FetchMoodleDataSuccessAction,
  FETCH_MOODLE_DATA_SUCCESS,
  GetSessionTokenSuccessAction,
  GET_SESSION_TOKEN_SUCCESS,
  LoginFailureAction,
  LoginMoodleSuccessAction,
  LoginSuccessAction,
  LOGIN_FAILURE,
  LOGIN_MOODLE_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT,
  LogoutAction,
  MoodleErrorAction,
  MOODLE_ERROR,
  REQUEST,
  RequestAction,
} from "./types";

export function checkLoggedInState() {
  return (
    dispatch: Dispatch<UserActionTypes | AuthActionTypes | AlertActionTypes>,
    getState: () => AppState
  ) => {
    dispatch(authRequest());

    const wasLoggedIn = getState().auth.loggedIn; // check if state was logged in

    return usersService
      .checkCurrentSession()
      .then((response) => {
        if (response?.status === 200 && response.data.isAuthenticated) {
          if (!wasLoggedIn) {
            getCurrentUserData(dispatch);
          }
          dispatch(getSessionsToken());
        } else if (response?.status === 200 && !response.data.isAuthenticated) {
          if (wasLoggedIn) {
            dispatch(loggingOut());
            dispatch(
              info(
                {
                  message:
                    "Sie wurden ausgeloggt. Wir freuen uns auf Ihren nächsten Besuch!",
                },
                5000
              )
            );
          } else {
            dispatch(fetchingAuthDataFinished());
          }
        }
      })
      .catch(() => {
        dispatch(loggingOut());
      });
  };
}

export function login(data: LoginData, from: any) {
  return (
    dispatch: Dispatch<
      | UserActionTypes
      | AuthActionTypes
      | EventActionTypes
      | FavoriteActionTypes
      | AlertActionTypes
      | ShowAction
    >
  ) => {
    dispatch(authRequest());
    return authService
      .login(data)
      .then(() => {
        dispatch(loginSuccess(from));
        dispatch(
          success(
            {
              message: "Der Login war erfolgreich.",
            },
            3000
          )
        );

        // !NOTE: clear the event and favorites list after login, to trigger reload, so the user related data is correctly loaded
        // !NOTE: (because the list was loaded before login and does not hold all info, e.g. linked accounts participation status)
        dispatch(clearFavoriteList());
        dispatch(clearEventList());
        // fetch the user data
        getCurrentUserData(dispatch);
      })
      .catch((err: AxiosError<AuthResultData>) => {
        const errorStatus = err.response?.status;
        const errorObject = err.response?.data;
        dispatch(loginFailure());

        if (errorStatus === 400) {
          // for 400 status codes show the error message coming from API
          if (errorObject?.redirect_url) {
            if (errorObject?.redirect_url.includes("login")) {
              dispatch(
                showDialog(
                  {
                    title: "Passwortänderung nötig",
                    message:
                      "Sie müssen vor dem ersten Zugriff auf das Veranstaltungsportal Ihr initiales Passwort in Ihrem KVH-Nutzerkonto ändern.",
                    action: () => {
                      window.open(
                        errorObject?.redirect_url,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    },
                    confirmButtonText: "Passwort ändern",
                  },
                  false,
                  true,
                  "sm"
                )
              );
            } else if (errorObject?.redirect_url.includes("passwort-aendern")) {
              dispatch(
                showDialog(
                  {
                    title: "Initialpasswort abgelaufen",
                    message:
                      "Ihr Initialpasswort ist abgelaufen. Bitte fordern Sie ein neues Passwort an und ändern dieses in Ihrem KVH-Nutzerkonto.",
                    action: () => {
                      window.open(
                        errorObject?.redirect_url,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    },
                    confirmButtonText: "Neues Passwort anfordern",
                  },
                  false,
                  true,
                  "sm"
                )
              );
            } else if (
              errorObject?.hasOwnProperty("vmp_service_is_activated")
            ) {
              if (!errorObject?.vmp_service_is_activated) {
                dispatch(
                  showDialog(
                    {
                      title: "Online-Dienst inaktiv",
                      message: (
                        <>
                          <Typography>
                            Der Online-Dienst "Veranstaltungsportal" ist nicht
                            aktiviert. Der Dienst kann unter "Konto verwalten"
                            in Ihrem KVH-Konto aktiviert werden.
                          </Typography>
                          <br />
                          <iframe
                            allow="fullscreen;"
                            title="Erklärvideo Aktivierung Online-Dienst"
                            width="640"
                            height="360"
                            src="https://www.youtube.com/embed/VPv5mu5VEbs"
                          ></iframe>
                        </>
                      ),
                      action: () => {
                        if (errorObject?.redirect_url) {
                          window.open(
                            errorObject?.redirect_url,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                      },
                      confirmButtonText: "Online-Dienst aktivieren",
                    },
                    false,
                    false,
                    "md"
                  )
                );
              }
            }
          } else {
            dispatch(
              error(
                {
                  message: ApiHelper.showErrorMessageFromApi(errorObject),
                },
                5000
              )
            );
          }
        } else if (errorStatus === 503) {
          // maintenance
          history.push(routes.maintenance);
        }
        dispatch(
          error(
            {
              message:
                "Der Login ist fehlgeschlagen. Prüfen Sie Ihre Nutzerdaten und versuchen Sie es erneut!",
            },
            5000
          )
        );
      });
  };
}

export function logout() {
  return (dispatch: Dispatch<LogoutAction | ErrorAction | SuccessAction>) => {
    return authService
      .logout()
      .then((response: AxiosResponse<ApiResponse>) => {
        history.push(routes.home);
        dispatch(loggingOut());
        dispatch(
          success({
            message:
              response.data.detail +
              " Wir freuen uns auf Ihren nächsten Besuch!",
          })
        );
      })
      .catch((errorResponse: AxiosError<ApiResponse>) => {
        // use the error message that is coming from backend, if it is undefined use default message
        const errorMessage =
          errorResponse.response?.data.detail ||
          "Sie können sich nicht ausloggen. Überprüfen Sie, ob Sie eingeloggt sind.";
        dispatch(
          error({
            message: errorMessage,
          })
        );
      });
  };
}

export function fetchMoodleData(eventGuid: string) {
  return (
    dispatch: Dispatch<FetchMoodleDataSuccessAction | MoodleErrorAction>
  ) => {
    return usersService
      .fetchMoodleData(eventGuid)
      .then((response: MoodleBaseData) => {
        dispatch(fetchMoodleDataSuccess(response));
      })
      .catch((errorResponse: AxiosError<ApiResponse>) => {
        dispatch(moodleError());
      });
  };
}

export function loginToMoodle(eventGuid: string) {
  return (dispatch: Dispatch<LoginMoodleSuccessAction | MoodleErrorAction>) => {
    return usersService
      .loginUserInMoodle(eventGuid)
      .then((response) => {
        if (response.status === 204) {
          dispatch(moodleLoginSuccess());
          // login was successful
        }
      })
      .catch((errorResponse: AxiosError<ApiResponse>) => {
        dispatch(moodleError());
      });
  };
}

export function loginSuccess(from?: any): LoginSuccessAction {
  return {
    type: LOGIN_SUCCESS,
    from,
  };
}

export function fetchMoodleDataSuccess(
  data: MoodleBaseData
): FetchMoodleDataSuccessAction {
  return {
    type: FETCH_MOODLE_DATA_SUCCESS,
    data: data,
  };
}

export function moodleLoginSuccess(): LoginMoodleSuccessAction {
  return {
    type: LOGIN_MOODLE_SUCCESS,
  };
}

export function moodleError(): MoodleErrorAction {
  return {
    type: MOODLE_ERROR,
  };
}

export function authRequest(): RequestAction {
  return {
    type: REQUEST,
  };
}

export function loginFailure(): LoginFailureAction {
  return {
    type: LOGIN_FAILURE,
  };
}

export function getSessionsToken(): GetSessionTokenSuccessAction {
  return {
    type: GET_SESSION_TOKEN_SUCCESS,
  };
}

export function loggingOut(): LogoutAction {
  return { type: LOGOUT };
}

export function fetchingAuthDataFinished(): FetchingAuthDataFinishedAction {
  return { type: FETCHING_AUTH_DATA_ACTION_FINISHED };
}
