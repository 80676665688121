import { Button, ButtonProps, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../redux";

interface CustomButtonProps {
  customColor?: string;
  textColor?: string;
  hoverColor?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: React.ReactNode;
}

interface MonoProps {
  monoMode: boolean;
}

const useStyles = makeStyles({
  root: {
    background: (props: CustomButtonProps & MonoProps) =>
      props.monoMode ? "#000" : props.customColor,
    color: (props: CustomButtonProps & MonoProps) =>
      props.monoMode ? "#fff" : props.textColor ? props.textColor : "#fff",
    "&:hover": {
      background: (props: CustomButtonProps & MonoProps) =>
        props.monoMode ? "#fff" : props.hoverColor,
      color: (props: CustomButtonProps & MonoProps) =>
        props.monoMode ? "#000" : "#fff",
    },
  },
});

const CustomButton: React.FC<CustomButtonProps & ButtonProps> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const classes = useStyles({ ...props, monoMode: accessibility.monoMode });
  const { customColor, hoverColor, className, textColor, ...other } = props;
  return (
    <>
      <Button
        variant="contained"
        className={[classes.root, className].join(" ")}
        startIcon={props.icon}
        {...other}
      >
        {props.children}
      </Button>
    </>
  );
};

export default CustomButton;
