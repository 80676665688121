import { BillingAddressClearAction } from "./../billingAddress/types";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { cartService } from "../../api";
import { CartData } from "../../models/cartData";
import { AlertActionTypes } from "./../alerts/types";
import {
  CartActionTypes,
  CartFailureAction,
  CartRequestAction,
  CART_FAILURE,
  CART_REQUEST,
  ClearCartAction,
  CLEAR_CART,
  SaveCartAction,
  SAVE_CART,
  SetCateringOptionAction,
  SET_CATERING_OPTION,
} from "./types";
import { success } from "../alerts/actions";
import { billingAddressClear } from "../billingAddress/actions";

export function getCart() {
  return (dispatch: Dispatch<CartActionTypes | AlertActionTypes>) => {
    getCartItems(dispatch);
  };
}

export function getCartItems(
  dispatch: Dispatch<CartActionTypes | AlertActionTypes>
) {
  cartService
    .getCart()
    .then((response) => {
      dispatch(saveCart(response));
    })
    .catch((err: AxiosError) => {
      dispatch(cartFailure());
    });
}

export function addEventToCart(eventGuid: string, userGuid: string) {
  return (dispatch: Dispatch<CartActionTypes | AlertActionTypes>) => {
      cartService
      .addToCart(eventGuid, userGuid)
      .then(() => {
        getCartItems(dispatch);
      })
      .catch((err: AxiosError) => {
        dispatch(cartFailure());
      });
  };
}

export function removeEventFromCart(eventId: string, reservedPlace?: boolean) {
  return (
    dispatch: Dispatch<
      CartActionTypes | AlertActionTypes | BillingAddressClearAction
    >
  ) => {
    cartService
      .removeFromCart(eventId)
      .then((response) => {
        getCartItems(dispatch);
        dispatch(billingAddressClear());
        if (reservedPlace) {
          dispatch(
            success(
              {
                message:
                  "Ihr reservierter Veranstaltungsplatz wurde erfolgreich freigegeben.",
              },
              3000
            )
          );
        }
      })
      .catch((err: AxiosError) => {
        dispatch(cartFailure());
      });
  };
}

export function cartRequest(): CartRequestAction {
  return {
    type: CART_REQUEST,
  };
}
export function cartFailure(): CartFailureAction {
  return {
    type: CART_FAILURE,
  };
}

export function setCateringOption(
  cartId: string,
  cateringOption: number
): SetCateringOptionAction {
  return {
    type: SET_CATERING_OPTION,
    cartId,
    cateringOption,
  };
}

export function saveCart(cartItemList: CartData[]): SaveCartAction {
  return {
    type: SAVE_CART,
    data: cartItemList,
  };
}

export function clearCart(): ClearCartAction {
  return { type: CLEAR_CART };
}
