import * as yup from "yup";

const billingAddressValidationSchema = yup.object({
  first_name: yup
    .string()
    .nullable()
    .required(
      "Der Vorname ist ein Pflichtfeld, damit wir Ihre Rechnung zuordnen können."
    ),
  last_name: yup
    .string()
    .nullable()
    .required(
      "Der Nachname ist ein Pflichtfeld, damit wir Ihre Rechnung zuordnen können."
    ),
  address: yup
    .string()
    .required(
      "Der Straßenname ist ein Pflichtfeld, damit wir Ihre Rechnung zuordnen können."
    ),
  street_address: yup
    .string()
    .required("Die Straßennummer ist ein Pflichtfeld."),
  zip_code: yup.string().required("Die Postleitzahl ist ein Pflichtfeld."),
  city: yup
    .string()
    .required(
      "Die Stadt ist ein Pflichtfeld, damit wir Ihre Rechnung zuordnen können."
    ),
  email: yup
    .string()
    .email(
      "Ihre E-Mail-Adresse ist nicht gültig. Bitte überprüfen Sie Ihre Eingabe und versuchen es erneut!"
    )
    .nullable()
    .required(
      "Die Angabe der E-Mail-Adresse ist Pflicht, damit wir Ihre Rechnung zuordnen können."
    ),
  medical_office_or_company: yup
    .string()
    .max(40, "Eingabe darf nicht länger als 40 Zeichen sein"),
  medical_office_or_company_extra: yup
    .string()
    .max(40, "Eingabe darf nicht länger als 40 Zeichen sein"),
});

export default billingAddressValidationSchema;
