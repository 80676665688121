export enum TrainingType {
  DefaultEvent = 0,
  ELearning = 10,
  BlendedLearning = 20,
}

export function mapToTrainingTypeString(trainingType?: TrainingType): string {
  switch (trainingType) {
    case TrainingType.DefaultEvent:
      return "Veranstaltung";
    case TrainingType.ELearning:
      return "E-Learning";
    case TrainingType.BlendedLearning:
      return "Blended-Learning";
    default:
      return "Veranstaltung";
  }
}

export function mapTrainingTypeToArticleWithNoun(
  typeOfTrainingToBeCreated: TrainingType,
  trainingType: string
): string {
  return typeOfTrainingToBeCreated === TrainingType.DefaultEvent
    ? "die Veranstaltung"
    : `das ${trainingType}`;
}

export function mapTrainingTypeToArticleWithNounPossessiveCase(
  typeOfTrainingToBeCreated: TrainingType,
  trainingType: string
) {
  const trainingTypeNounWithArticle = `${
    typeOfTrainingToBeCreated !== TrainingType.DefaultEvent ? "des" : "der"
  } ${trainingType}${
    typeOfTrainingToBeCreated !== TrainingType.DefaultEvent ? "s" : ""
  }`;
  return trainingTypeNounWithArticle;
}
