import {
  LOAD_TARGET_GROUP_DATA_SUCCESS,
  TargetGroupActionTypes,
  TargetGroupState,
  TARGET_GROUP_FAILURE,
  TARGET_GROUP_REQUEST,
} from "./types";

export const initialTargetGroupState: TargetGroupState = {
  targetGroups: {
    participation_types: [],
    special_fields: [],
    coverage_areas: [],
  },
  isLoading: false,
  targetGroupsLoaded: false,
};

export function targetGroupReducer(
  state = initialTargetGroupState,
  action: TargetGroupActionTypes
): TargetGroupState {
  switch (action.type) {
    case TARGET_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_TARGET_GROUP_DATA_SUCCESS:
      return {
        ...state,
        targetGroups: action.data,
        isLoading: false,
        targetGroupsLoaded: true,
      };
    case TARGET_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default targetGroupReducer;
