export interface Pagination<T> {
  count: number;
  next: null | string;
  previous: null | string;
  results: T[];
  page?: number;
}

export interface DefaultPaginationFilter {
  page?: number;
}

export const PAGE_SIZE = 10;
