import { Pagination } from "../../models/pagination";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { pinboardService } from "../../api";
import { PinboardListData } from "../../models/pinboardData";
import { error } from "../alerts/actions";
import { ErrorAction } from "../alerts/types";
import {
  PinboardActionTypes,
  PinboardClearAction,
  PinboardFailureAction,
  PinboardPageCountAction,
  PinboardRequestAction,
  PinboardSuccessAction,
  PinboardToggleOpenStateAction,
  PINBOARD_CLEAR,
  PINBOARD_FAILURE,
  PINBOARD_PAGE_COUNT,
  PINBOARD_REQUEST,
  PINBOARD_SUCCESS,
  PINBOARD_TOGGLE_OPEN_STATE,
} from "./types";

export function getPinboard(
  page: number,
  eventId: string,
  showArchive: boolean = false
) {
  return (dispatch: Dispatch<PinboardActionTypes | ErrorAction>) => {
    dispatch(pinboardRequest());
    pinboardService
      .getPinboard(page, eventId, showArchive)
      .then((response: Pagination<PinboardListData>) => {
        response.page = page;
        dispatch(pinboardPageCount(page));
        dispatch(pinboardSuccess(response));
      })
      .catch((err: AxiosError) => {
        dispatch(pinboardFailure());
        dispatch(
          error(
            {
              message:
                "Beim Laden der Beiträge ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!",
            },
            5000
          )
        );
      });
  };
}

export function pinboardRequest(): PinboardRequestAction {
  return {
    type: PINBOARD_REQUEST,
  };
}

export function pinboardSuccess(
  data: Pagination<PinboardListData>
): PinboardSuccessAction {
  return {
    type: PINBOARD_SUCCESS,
    data,
  };
}

export function pinboardFailure(): PinboardFailureAction {
  return {
    type: PINBOARD_FAILURE,
  };
}

export function pinboardPageCount(pageCount: number): PinboardPageCountAction {
  return {
    type: PINBOARD_PAGE_COUNT,
    pageCount: pageCount,
  };
}

export function pinboardToggleOpenState(): PinboardToggleOpenStateAction {
  return {
    type: PINBOARD_TOGGLE_OPEN_STATE,
  };
}

export function pinboardClear(): PinboardClearAction {
  return {
    type: PINBOARD_CLEAR,
  };
}
