import { CustomSelectArrow } from "../../assets/icons/custom-svg-components/KvhSvgCollection";
import {
  borderDefault,
  borderError,
  borderFocus,
  validationErrorPrefix,
} from "./sharedThemeVariables";
import { ThemeOptions } from "@material-ui/core";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";

export const defaultPalette: PaletteOptions = {
  background: {
    default: "#fff",
  },
  primary: {
    main: "#36a9e0",
  },
  secondary: {
    main: "#777",
  },
  text: {
    primary: "#3c3c3c",
    disabled: "#999",
  },
  error: {
    main: "#f44336",
  },
  contrastThreshold: 2.5,
};

export const typographyDefault: TypographyOptions = {
  fontFamily: ['"Nunito Sans"', '"Arial"', "sans-serif"].join(","),
  fontSize: 16,
  fontWeightRegular: 300,
  fontWeightBold: "bold",
  allVariants: {
    color: "#3c3c3c",
  },
  body1: {
    fontSize: 16,
    lineHeight: "1.4",
  },
  h2: {
    fontSize: "1.8rem",
  },
  h3: {
    fontSize: "1.6rem",
  },
  h4: {
    fontSize: "1.4rem",
  },
};

export const typographyBiggest: TypographyOptions = {
  fontFamily: ['"Nunito Sans"', '"Arial"', "sans-serif"].join(","),
  fontSize: 26,
  fontWeightRegular: 300,
  fontWeightBold: "bold",
  allVariants: {
    color: "#3c3c3c",
  },
  body1: {
    // fontSize: 26,
    lineHeight: "1.4",
  },
  h2: {
    fontSize: "1.8rem",
  },
  h3: {
    fontSize: "1.6rem",
  },
  h4: {
    fontSize: "1.4rem",
  },
};

export default {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1140, // use 1140px to match the kvh container style
      xl: 1920,
    },
  },
  props: {
    MuiButtonBase: {
      // change default props
      disableRipple: true, // no ripple for all buttons on the whole application
    },
    MuiButton: {
      disableElevation: true, // no elevation for all buttons
    },
    MuiLink: {
      underline: "none",
    },
    MuiSelect: {
      IconComponent: CustomSelectArrow,
    },
  },
  palette: defaultPalette,
  typography: typographyDefault,
  overrides: {
    MuiCheckbox: {
      root: {
        border: "2px solid #a2a2a2",
        backgroundColor: "#f8f8f8",
        borderRadius: 0,
        width: 32,
        height: 32,
        transition: "opacity 300ms, border 300ms",
        "&$checked": {
          color: "#ffbb00",
        },
      },
    },
    MuiToggleButton: {
      root: {
        border: "1px solid #999",
        color: "#3c3c3c",
        "&:hover": {
          backgroundColor: "#999",
          color: "#fff",
        },
        "&$selected": {
          color: "#fff",
          backgroundColor: "#777",
          "&:hover": {
            backgroundColor: "#999",
            color: "#fff",
          },
        },
      },
    },
    MUIRichTextEditor: {
      root: {
        marginTop: 20,
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.12)",
      },
      container: {
        width: "100%",
      },
      editor: {
        backgroundColor: "#f8f8f8",
        padding: "20px",
        height: "300px",
        maxHeight: "300px",
        overflow: "auto",
      },
      placeHolder: {
        padding: "20px",
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: "1.5rem",
      },
    },
    draftEditorProps: {
      spellCheck: true,
      stripPastedStyles: true,
    },
    MuiIconButton: {
      root: {
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    MuiSelect: {
      icon: {
        transition: "ease-in-out 0.2s",
        transform: "rotate(90deg)",
      },
      iconOpen: {
        transition: "ease-in-out 0.2s",
        transform: "rotate(-90deg)",
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiFilledInput-root"]': {
          padding: 0,
          "& .MuiAutocomplete-endAdornment": {
            right: "18px",
          },
        },
        '&&[class*="MuiFilledInput-root"] $input': {
          padding: "20px",
        },
      },
      popupIndicator: {
        transition: "ease-in-out 0.2s",
        transform: "rotate(90deg)",
      },
      popupIndicatorOpen: {
        transition: "ease-in-out 0.2s",
        transform: "rotate(-90deg)",
      },
    },
    MuiListItem: {
      root: {
        height: "50px",
        "&$selected": {
          color: "#fff",
          backgroundColor: "#f49325",
          "&:hover": {
            backgroundColor: "#36a9e0",
            color: "#fff",
          },
        },
      },
      button: {
        "&:hover": {
          backgroundColor: "#36a9e0",
          color: "#fff",
        },
      },
    },
    MuiButton: {
      root: {
        border: "0",
        minWidth: "12px",
        borderRadius: "1px",
        textTransform: "none",
      },
      containedPrimary: {
        "&:hover": {
          color: "#fff",
        },
      },
      contained: {
        "&$disabled": {
          backgroundColor: "#fafafa",
        },
      },
    },
    MuiBadge: {
      colorPrimary: {
        color: "#fff",
        backgroundColor: "#f49325",
        border: "0",
      },
    },
    MuiInputLabel: {
      filled: {
        transform: "translate(12px, 23px) scale(1)",
      },
      formControl: {
        left: "8px",
        top: "-3px",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#3c3c3c",
        "&$focused": {
          color: "#3c3c3c",
        },
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginTop: "0px",
        marginRight: "10px",
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        backgroundColor: "#f8f8f8",
        border: "0px",
        borderRadius: "1px",
        "&:hover": {
          backgroundColor: "#f8f8f8",
        },
        "&$focused": {
          backgroundColor: "#cceaff",
          borderColor: "#0094ff",
        },
        "&$disabled": {
          backgroundColor: "#fafafa",
        },
        "&$error": {
          "&::before": validationErrorPrefix,
          backgroundColor: "#fcddd9",
          "&:hover": {
            "&:before": validationErrorPrefix,
            "&:after": borderError,
          },
        },
      },
      multiline: {
        padding: "20px 20px",
      },
      input: {
        padding: "20px 20px",
      },
      underline: {
        "&:before": borderDefault,
        "&:after": borderFocus,
        "&:hover": {
          "&:before": borderDefault,
          "&:after": borderFocus,
        },
        "&$disabled": {
          "&:before": {
            borderBottomStyle: "solid",
          },
        },
      },
    },
    MuiLink: {
      root: {
        color: "#b02d88",
        "&:hover": {
          color: "#36a9e0",
          borderBottom: "none",
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#f49325",
      },
    },
    MuiTab: {
      root: {
        "@media (min-width: 600px)": {
          minWidth: "148px",
        },
        backgroundColor: "#f8f8f8",
        "&$selected": {
          backgroundColor: "#fff",
          color: "#3c3c3c",
        },
      },
    },
    MuiDialogContentText: {
      root: {
        color: "#3c3c3c",
      },
    },
  },
} as ThemeOptions;
