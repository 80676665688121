import { Box, Button, Divider, Grid, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RedoIcon from "@material-ui/icons/Redo";
import { Pagination as PaginationUI } from "@material-ui/lab";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PAGE_SIZE } from "../../../../models/pagination";
import {
  EventTemplateFilterData,
  EventTemplateFilterOptions,
} from "../../../../models/templateData";
import { AppState } from "../../../../redux";
import { showDialog } from "../../../../redux/dialogs/actions";
import { clearDocuments } from "../../../../redux/documents/actions";
import { clearPreview } from "../../../../redux/preview/actions";
import { clearSpeakers } from "../../../../redux/speaker/actions";
import {
  applyTemplateForEvent,
  clearTemplateEvent,
  deleteEventTemplate,
  getAllEventTemplates,
  setEventTemplatePageCount,
} from "../../../../redux/templates/events/actions";
import { EventTemplateFilterService } from "../../../../services/filters/eventTemplateFilterService";
import CustomSearchField from "../../../forms/CustomSearchField";
import routes from "../../../routing/routes";
import "./EventTemplates.scss";

export const EventTemplates: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const page = useSelector(
    (state: AppState) => state.eventTemplates.currentPage
  );
  const eventTemplates = useSelector((state: AppState) => state.eventTemplates);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  const getInitialFilterData = React.useCallback(() => {
    let initialFilterData: EventTemplateFilterData = {
      search: "",
    };

    // ! persist the filter state after page change and set as initial filter values
    const filterService = new EventTemplateFilterService(
      page,
      initialFilterData
    );
    initialFilterData = filterService.parseFiltersFromUrl();

    return initialFilterData;
  }, [page]);

  const templates = useSelector((state: AppState) => state.eventTemplates);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setEventTemplatePageCount(value));
  };

  const loadData = React.useCallback(() => {
    if (!eventTemplates.isLoading && !eventTemplates.eventListLoaded)
      dispatch(getAllEventTemplates(page, getInitialFilterData()));
  }, [
    eventTemplates.isLoading,
    eventTemplates.eventListLoaded,
    dispatch,
    page,
    getInitialFilterData,
  ]);

  useEffect(loadData, [loadData]);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(clearSpeakers());
              dispatch(clearDocuments());
              dispatch(clearTemplateEvent());
              history.push(routes.templates_new_event);
            }}
          >
            Neue Veranstaltungsvorlage
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} sm={8}>
            <Formik
              onSubmit={(data: EventTemplateFilterOptions) => {}}
              initialValues={{
                search: getInitialFilterData().search,
              }}
            >
              {() => (
                <Form>
                  <CustomSearchField
                    customChangeHandler={(value: string) => {
                      dispatch(
                        getAllEventTemplates(page, {
                          search: value,
                        } as EventTemplateFilterOptions)
                      );
                    }}
                    name="search"
                    id="event-template-search"
                    label="Suche nach Veranstaltungsvorlage"
                    initialValue={getInitialFilterData().search}
                  />
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid container spacing={0} className={"event-templates-list-header"}>
            <Grid item xs={9}>
              Vorlagenname
            </Grid>
            <Grid item xs={3}>
              Aktion
            </Grid>
          </Grid>
          {templates.eventTemplateList.results.map((event, index) => {
            return (
              <React.Fragment key={index}>
                <Grid
                  container
                  spacing={0}
                  className={"event-templates-list-item"}
                >
                  <Grid item xs={9} style={{ margin: "auto" }}>
                    {event.title}
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip
                      arrow
                      title={"Vorlage für neue Veranstaltung verwenden"}
                    >
                      <RedoIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatch(clearSpeakers());
                          dispatch(clearDocuments());
                          dispatch(clearPreview());
                          dispatch(applyTemplateForEvent(event));
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip arrow title={"Vorlage bearbeiten"}>
                      <EditIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatch(clearSpeakers());
                          dispatch(clearDocuments());
                          dispatch(clearTemplateEvent());
                          history.push(
                            routes.templates_edit_event + "/" + event.guid
                          );
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip arrow title={"Vorlage löschen"}>
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        className={
                          accessibility.monoMode
                            ? "documents-list-item-delButton-mono"
                            : "documents-list-item-delButton"
                        }
                        onClick={() => {
                          dispatch(
                            showDialog({
                              title: "Veranstaltungsvorlage löschen",
                              message: (
                                <>
                                  Sind Sie sicher, dass Sie die
                                  Veranstaltungsvorlage <b>{event.title}</b>{" "}
                                  löschen möchten?
                                </>
                              ),
                              action: () => {
                                dispatch(deleteEventTemplate(event?.guid as string));
                              },
                            })
                          );
                        }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Divider />
              </React.Fragment>
            );
          })}
        </Grid>
        <Grid container style={{ marginTop: "10px" }}>
          <Box component={"div"} style={{ margin: "auto" }}>
            <PaginationUI
              count={Math.ceil(templates.eventTemplateList.count / PAGE_SIZE)}
              page={templates.currentPage}
              onChange={handleChange}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
