import * as React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface ScrollToTopProps {}

/**
 * Component can be used to set the initial position of the scrollbar to the top of the page when redirecting / routing to another page.
 * E.g. when scrolling to the end of a list and routing to another page the scrollbar should not remain at the bottom of the page.
 */
const ScrollToTop: React.FC<ScrollToTopProps> = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  return null;
};

export default ScrollToTop;
