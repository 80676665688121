import { useFormikContext } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { EventStatus } from "../../../../models/enums/eventStatus.enum";
import {
  mapToTrainingTypeString,
  mapTrainingTypeToArticleWithNoun,
  TrainingType,
} from "../../../../models/enums/trainingType.enum";
import { EventFormData } from "../../../../models/eventData";
import { AppState } from "../../../../redux";
import TabsKvh from "../../../theming/tabs/TabsKvh";
import { Content } from "../shared//Content";
import { BasicInformation } from "../shared/basic-information/BasicInformation";
import { Dates } from "../shared/Dates";
import { Documents } from "../shared/Documents";
import { Miscellaneous } from "../shared/misc/Miscellaneous";
import { Participants } from "../shared/participants/Participants";
import { SpeakersView } from "../shared/SpeakersView";

interface EventTabsProps {
  viewMode: "createEvent" | "editEvent" | "template";
  typeOfTrainingToBeCreated: TrainingType;
  eventId?: string;
}

const EventTabs: React.FC<EventTabsProps> = (props) => {
  const { values, setFieldValue } = useFormikContext<EventFormData>();
  const templates = useSelector((state: AppState) => state.eventTemplates);
  const editEventState = useSelector(
    (state: AppState) => state.event.editEvent
  );

  const defaultTabs = [
    {
      label: "Basisinformationen",
      content: (
        <BasicInformation
          typeOfTrainingToBeCreated={props.typeOfTrainingToBeCreated}
        />
      ),
    },
    {
      label: "Inhalt",
      content: <Content />,
    },
  ];

  const createEventTabs = [
    {
      label: "Referenten",
      content: <SpeakersView template_mode={templates.eventTemplateMode} />,
    },
    {
      label: "Termine",
      content: <Dates />,
    },
    {
      label: "Material",
      content: (
        <Documents
          template_id={templates.eventTemplate.guid}
          typeOfTrainingToBeCreated={props.typeOfTrainingToBeCreated}
        />
      ),
    },
    {
      label: "Sonstiges",
      content: <Miscellaneous eventId={props.eventId ? props.eventId : ""} />,
    },
  ];

  const editEventTabs = [
    {
      label: "Referenten",
      content: <SpeakersView />,
    },
    {
      label: "Termine",
      content: <Dates />,
    },
    {
      label: "Material",
      content: (
        <Documents
          typeOfTrainingToBeCreated={props.typeOfTrainingToBeCreated}
        />
      ),
    },
    {
      label: "Teilnehmer",
      content: (
        <Participants
          eventData={editEventState}
          trainingType={props.typeOfTrainingToBeCreated}
        />
      ),
      disabled: editEventState.status === EventStatus.NotVisible,
      disabledTooltip: `Der Reiter "Teilnehmer" ist nicht verfügbar, solange ${mapTrainingTypeToArticleWithNoun(
        props.typeOfTrainingToBeCreated,
        mapToTrainingTypeString(props.typeOfTrainingToBeCreated)
      )} nicht sichtbar ist.`,
    },
    {
      label: "Sonstiges",
      content: <Miscellaneous eventId={props.eventId ? props.eventId : ""} />,
    },
  ];

  const templateTabs = [
    {
      label: "Referenten",
      content: <SpeakersView template_mode={true} />,
    },
    {
      label: "Material",
      content: (
        <Documents
          template_mode={true}
          typeOfTrainingToBeCreated={props.typeOfTrainingToBeCreated}
        />
      ),
    },
  ];

  const elearningTabs = [
    {
      label: "Material",
      content: (
        <Documents
          template_id={templates.eventTemplate.guid}
          typeOfTrainingToBeCreated={props.typeOfTrainingToBeCreated}
        />
      ),
    },
    {
      label: "Sonstiges",
      content: <Miscellaneous eventId={props.eventId ? props.eventId : ""} />,
    },
  ];

  const editELearningTabs = [
    {
      label: "Material",
      content: (
        <Documents
          typeOfTrainingToBeCreated={props.typeOfTrainingToBeCreated}
        />
      ),
    },
    {
      label: "Teilnehmer",
      content: (
        <Participants
          eventData={editEventState}
          trainingType={TrainingType.ELearning}
        />
      ),
      disabled: editEventState.status === EventStatus.NotVisible,
      disabledTooltip:
        'Der Reiter "Teilnehmer" ist nicht verfügbar, solange das E-Learning nicht sichtbar ist.',
    },
    {
      label: "Sonstiges",
      content: <Miscellaneous eventId={props.eventId ? props.eventId : ""} />,
    },
  ];

  function getTabs() {
    switch (props.viewMode) {
      case "createEvent":
        if (props.typeOfTrainingToBeCreated === TrainingType.DefaultEvent) {
          return createEventTabs;
        } else if (props.typeOfTrainingToBeCreated === TrainingType.ELearning) {
          return elearningTabs;
        } else {
          return createEventTabs;
        }

      case "editEvent":
        if (props.typeOfTrainingToBeCreated !== TrainingType.ELearning) {
          return editEventTabs;
        } else {
          return editELearningTabs;
        }

      case "template":
        return templateTabs;
      default:
        return createEventTabs;
    }
  }

  return (
    <>
      <TabsKvh
        onTabChange={() => {
          setFieldValue("descriptionState", values.description); //Workaround for strange behavior, when ENTER/BACKSPACE in Editor
        }}
        tabs={[...defaultTabs, ...getTabs()]}
      />
    </>
  );
};

export default EventTabs;
