import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import { FieldAttributes, useField } from "formik";
import * as React from "react";
import { SelectData } from "./selectData";

interface CustomSelectProps {
  id: string;
  labelId: string;
  label: string;
  options: SelectData[];
  defaultText?: string; // used for empty option
  defaultValueForEmptyElement?: number | string;
  noEmptyDefaultValue?: boolean;
}

const CustomSelect: React.FunctionComponent<
  FieldAttributes<{}> & CustomSelectProps & SelectProps
> = ({
  defaultText,
  defaultValueForEmptyElement,
  noEmptyDefaultValue,
  labelId,
  label,
  options,
  required,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const error = meta.error && meta.touched ? meta.error : ""; // get validation error text

  return (
    <>
      <FormControl fullWidth variant="filled" error={!!error}>
        <InputLabel required={required} id={labelId}>
          {label}
        </InputLabel>
        <Select
          {...field}
          {...props}
          autoComplete="off"
          MenuProps={{
            disableAutoFocus: true,
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
        >
          {!noEmptyDefaultValue && (
            <MenuItem
              value={
                defaultValueForEmptyElement !== undefined
                  ? defaultValueForEmptyElement
                  : ""
              }
            >
              {defaultText ? defaultText : "Bitte auswählen"}
            </MenuItem>
          )}
          {options &&
            options.map((option) => {
              return (
                <MenuItem
                  key={option.key}
                  value={option.value}
                  disabled={
                    typeof option.disabled === undefined
                      ? false
                      : option.disabled
                  }
                >
                  {option.label ? option.label : option.value}
                </MenuItem>
              );
            })}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </>
  );
};

export default CustomSelect;
