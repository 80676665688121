import { DialogProps } from "@material-ui/core";
import {
  ButtonAppearance,
  CLOSE,
  CloseAction,
  DialogBody,
  SHOW,
  ShowAction,
} from "./types";

export function showDialog(
  dialog: DialogBody,
  simple?: boolean,
  redirect?: boolean,
  size?: DialogProps["maxWidth"],
  buttonApperance?: ButtonAppearance
): ShowAction {
  return { type: SHOW, dialog, simple, redirect, size, buttonApperance };
}

export function closeDialog(): CloseAction {
  return { type: CLOSE };
}
