import { Grid } from "@material-ui/core";
import { useFormikContext } from "formik";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BookingsSortBy } from "../../../../models/enums/sortBy.enum";
import { LectureFilterData } from "../../../../models/lecturesData";
import { AppState } from "../../../../redux";
import { getAllLectures } from "../../../../redux/lectures/actions";
import { LectureFilterService } from "../../../../services/filters/lectureFilterService";
import usePermission from "../../../../services/usePermissions";
import { Utils } from "../../../../services/utils";
import CustomMultiSelect from "../../../forms/selects/CustomMultiSelect";
import CustomSelect from "../../../forms/selects/CustomSelect";

interface LectureFilterFormProps {
  page: number;
}

const LectureFilterForm: React.FC<LectureFilterFormProps> = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const { setFieldValue, values } = useFormikContext<LectureFilterData>();

  const { getEventStatusForSelectBasedOnUserTypeForFilter } = usePermission(
    currentUser?.user_type
  );

  function getSortOptions() {
    let options = (Object.values(BookingsSortBy) as string[]).map(
      (sortByEnumValue) => {
        return {
          key: Utils.getEnumKeyByValue(BookingsSortBy, sortByEnumValue),
          value: sortByEnumValue,
        };
      }
    );
    options = options.filter(
      (x) =>
        !([
          BookingsSortBy.BeginAscending,
          BookingsSortBy.EndAscending,
          BookingsSortBy.EndDescending,
        ] as string[]).includes(x.value)
    );

    return options;
  }

  const sortOptions = React.useMemo(() => {
    return getSortOptions();
  }, []);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <CustomMultiSelect
            defaultText="Alle"
            name="status"
            id="status-multiselect"
            label="Status der Veranstaltung"
            labelId="status-multiselect-label"
            defaultOptions={values.status || []}
            hasCustomChangeHandler={true}
            onBlur={() => {
              const lectureFilterService = new LectureFilterService(
                props.page,
                values
              );

              const statusHasChanged = lectureFilterService.checkIfFilterHasChanged();

              if (statusHasChanged) {
                dispatch(
                  getAllLectures(props.page, {
                    ...values,
                    status: values.status,
                  })
                );
              }
            }}
            onClose={() => {
              // !workaround to make the onBlur method work
              setTimeout(() => {
                const statusMultiSelect = document.getElementById(
                  "status-multiselect"
                );
                if (statusMultiSelect) statusMultiSelect.blur();
              }, 0);
            }}
            customChangeHandler={(changedMultiSelectValues) => {
              setFieldValue("status", changedMultiSelectValues);
            }}
            options={getEventStatusForSelectBasedOnUserTypeForFilter()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomSelect
            name="sorting"
            id="sorting-select"
            label="Sortierung"
            defaultValueForEmptyElement={BookingsSortBy.BeginAscending}
            defaultText={BookingsSortBy.BeginAscending}
            onChange={(
              event: React.ChangeEvent<{
                name?: string;
                value: unknown;
              }>
            ) => {
              // overwrite the change method
              setFieldValue("sorting", event.target.value);

              dispatch(
                getAllLectures(props.page, {
                  ...values,
                  sorting: event.target.value as BookingsSortBy,
                })
              );
            }}
            labelId="sorting-select-label"
            options={sortOptions}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LectureFilterForm;
