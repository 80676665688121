import {
  CLEAR_FAVORITES_LIST,
  FAVORITE_REQUEST,
  FavoriteActionTypes,
  FavoriteState,
  REFRESH_FAVORITES_LIST,
  RELOAD_FAVORITES_LIST,
  SET_FAVORITES_PAGE_COUNT,
  SET_LIST_OF_FAVORITES_SUCCESS,
  SET_FAVORITES_COUNT,
  FAVORITE_COUNT_REQUEST,
  FAVORITE_FAILURE,
} from "./types";
import { Pagination } from "../../models/pagination";
import { FavoriteListData } from "../../models/favoriteData";

export const initialFavoriteState: FavoriteState = {
  eventFavoriteList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  eventFavoriteListLoaded: false,
  currentPage: 1,
  nubmerOfFavorites: 0,
  numberofFavoritesLoaded: false,
  isLoading: false,
  isLoadingCount: false,
};

function refreshEventFavoriteList(
  add: boolean,
  eventFavoriteList: Pagination<FavoriteListData>,
  eventId?: string
) {
  let copiedEventFavoriteList = eventFavoriteList;
  if (add) {
    copiedEventFavoriteList.count++;
  } else {
    copiedEventFavoriteList.count--;
    let index = copiedEventFavoriteList.results.findIndex(
      (favorite) => favorite.event.guid === eventId
    );
    copiedEventFavoriteList.results.splice(index, 1);
  }
  return copiedEventFavoriteList;
}

export function favoriteReducer(
  state = initialFavoriteState,
  action: FavoriteActionTypes
): FavoriteState {
  switch (action.type) {
    case FAVORITE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FAVORITE_COUNT_REQUEST:
      return {
        ...state,
        isLoadingCount: true,
      };
    case FAVORITE_FAILURE:
      return {
        ...state,
        isLoadingCount: false,
        isLoading: false,
      };
    case SET_LIST_OF_FAVORITES_SUCCESS:
      return {
        ...state,
        eventFavoriteList: action.data,
        eventFavoriteListLoaded: true,
        isLoading: false,
      };
    case REFRESH_FAVORITES_LIST:
      return {
        ...state,
        eventFavoriteList: refreshEventFavoriteList(
          action.add,
          state.eventFavoriteList,
          action.eventId
        ),
        eventFavoriteListLoaded: true,
        isLoading: false,
      };
    case RELOAD_FAVORITES_LIST:
      return {
        ...state,
        eventFavoriteListLoaded: false,
      };
    case SET_FAVORITES_COUNT:
      return {
        ...state,
        nubmerOfFavorites: action.nubmerOfFavorites,
        isLoadingCount: false,
        numberofFavoritesLoaded: true,
      };
    case SET_FAVORITES_PAGE_COUNT:
      return {
        ...state,
        currentPage: action.pageCount,
        eventFavoriteListLoaded: false,
      };
    case CLEAR_FAVORITES_LIST:
      return initialFavoriteState;
    default:
      return state;
  }
}

export default favoriteReducer;
